import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bss-icon',
  template: `
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 160 142"
      style="enable-background:new 0 0 150 150;"
      xml:space="preserve"
    >
      <style type="text/css">
        .st0{fill:{{color}};}
        .st1{display:none;fill:{{color}};}
        .st2{fill:{{color}};}
      </style>
      <g>
        <path
          class="st0"
          d="M131.8,119.8c8.7,0,13.8-3.8,13.8-10.1c0-3.5-1.8-6.5-4.7-7.9c-2.4-1.1-5.2-1.9-16.9-4.6c-3.4-0.7-6-1.4-8.2-2
		l0,0c0,1.1,0,2.1,0,3.2l0,0c0,2.8-0.2,5.7,0,8.5c0.1,2,0.6,4.1,1.6,5.9l0,0c0.3,0.6,0.7,1.2,1.2,1.8l0,0
		C121.4,117.9,126.1,119.8,131.8,119.8L131.8,119.8z"
        />
        <path
          class="st1"
          d="M289.8,112.1c-0.5,0-0.9-0.1-1.2-0.2s-0.6-0.3-0.7-0.4c-0.2-0.2-0.3-0.4-0.3-0.6c-0.1-0.2-0.1-0.5-0.1-0.8
		c0-0.7,0.2-1.3,0.7-1.6c0.5-0.3,1.3-0.5,2.4-0.5c0.7,0,1.5,0,2.4,0c0.8,0,1.7,0,2.5,0l0.3-1.3c-1,0-1.9-0.1-2.7-0.1
		c-0.8,0-1.6,0-2.4,0c-1.6,0-2.8,0.3-3.5,0.8s-1.1,1.4-1.1,2.5v0.3c0,1,0.3,1.7,0.8,2.3c0.5,0.5,1.4,0.9,2.6,1l2.7,0.2
		c0.9,0.1,1.6,0.3,1.9,0.6c0.3,0.3,0.5,0.8,0.5,1.5c0,0.5,0,0.9-0.1,1.2c-0.1,0.3-0.3,0.6-0.5,0.7c-0.2,0.2-0.6,0.3-1,0.4
		c-0.4,0.1-1,0.1-1.6,0.1c-1,0-2,0-2.8,0c-0.8,0-1.6,0-2.3,0l-0.3,1.3c0.5,0,0.9,0,1.3,0c0.4,0,0.8,0,1.3,0c0.4,0,0.9,0,1.3,0
		c0.4,0,0.9,0,1.4,0c0.9,0,1.6-0.1,2.3-0.2c0.6-0.2,1.1-0.4,1.5-0.7c0.4-0.3,0.6-0.7,0.8-1.1c0.2-0.4,0.2-1,0.2-1.5v-0.2
		c0-0.4-0.1-0.9-0.2-1.2c-0.1-0.4-0.3-0.7-0.6-1c-0.3-0.3-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.3-1.7-0.4L289.8,112.1L289.8,112.1z
		 M304.5,106.6c-0.7,0.2-1.3,0.5-1.8,1c-0.5,0.5-0.8,1.1-1,1.8c-0.2,0.7-0.3,1.7-0.3,2.8v1.9c0,1.1,0.1,2,0.3,2.8
		c0.2,0.7,0.6,1.3,1,1.8s1,0.8,1.8,1c0.7,0.2,1.6,0.3,2.5,0.3c1,0,1.8-0.1,2.5-0.3c0.7-0.2,1.3-0.5,1.8-1c0.5-0.5,0.8-1.1,1-1.8
		c0.2-0.7,0.3-1.7,0.3-2.8v-1.9c0-1.1-0.1-2-0.3-2.8c-0.2-0.8-0.6-1.4-1-1.8c-0.5-0.4-1-0.8-1.8-1c-0.7-0.2-1.6-0.3-2.5-0.3
		C306.1,106.3,305.3,106.4,304.5,106.6L304.5,106.6z M311.2,114c0,0.9-0.1,1.7-0.2,2.3c-0.1,0.6-0.4,1.1-0.7,1.4s-0.8,0.6-1.3,0.7
		c-0.5,0.1-1.2,0.2-1.9,0.2c-0.8,0-1.4-0.1-1.9-0.2c-0.5-0.1-0.9-0.4-1.3-0.7c-0.3-0.3-0.6-0.8-0.7-1.4c-0.2-0.6-0.2-1.3-0.2-2.3
		v-1.9c0-0.9,0.1-1.7,0.2-2.3c0.1-0.6,0.4-1.1,0.7-1.4c0.3-0.3,0.8-0.6,1.3-0.7c0.5-0.1,1.2-0.2,1.9-0.2c0.7,0,1.4,0.1,1.9,0.2
		s1,0.4,1.3,0.7c0.3,0.3,0.6,0.8,0.7,1.4c0.1,0.6,0.2,1.4,0.2,2.3V114L311.2,114z M324.9,102.2c0.4,0,0.7,0,1,0l0.3-1.3
		c-0.5,0-0.9,0-1.3,0c-0.3,0-0.7,0-1,0c-1,0-1.8,0.1-2.4,0.3c-0.7,0.2-1.2,0.5-1.6,0.9c-0.4,0.4-0.7,1-0.9,1.7
		c-0.2,0.7-0.3,1.6-0.3,2.6v13h1.4v-11.5h4.8l0.3-1.2h-5c0-0.9,0.1-1.7,0.2-2.3c0.1-0.6,0.3-1,0.6-1.4s0.7-0.5,1.2-0.6
		c0.5-0.1,1.1-0.2,1.9-0.2C324.3,102.2,324.5,102.2,324.9,102.2L324.9,102.2z M329.7,115.2c0,0.9,0.1,1.6,0.2,2.2s0.4,1,0.7,1.3
		c0.3,0.3,0.8,0.5,1.4,0.7c0.6,0.1,1.3,0.2,2.1,0.2h1.6l0.3-1.3h-1.9c-0.6,0-1.1,0-1.5-0.1c-0.4-0.1-0.7-0.3-0.9-0.5
		s-0.4-0.5-0.5-0.9c-0.1-0.4-0.1-0.9-0.1-1.5v-7.2h5l0.3-1.2h-5.2v-3.5l-1.4,0.3V115.2L329.7,115.2z M352.4,119.5h1.5l3.9-12.8h-1.4
		l-3.2,11.2l-3.2-11.2h-1.5l-3.2,11.2l-3.2-11.2h-1.5l3.9,12.8h1.5l3.2-10.9L352.4,119.5L352.4,119.5z M363.6,115.3
		c0-1,0.3-1.7,0.8-2.2c0.5-0.4,1.4-0.6,2.6-0.6h0.3c0.6,0,1.2,0,1.7,0.1c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.2,0.6,0.5,0.8,0.8
		s0.3,0.8,0.3,1.3v3H367c-0.7,0-1.2-0.1-1.7-0.2c-0.4-0.1-0.8-0.3-1-0.5c-0.3-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.2-0.7-0.2-1.1V115.3
		L363.6,115.3z M372.6,110.9c0-0.8-0.1-1.4-0.3-2c-0.2-0.5-0.5-1-0.9-1.3c-0.4-0.3-1-0.6-1.7-0.7c-0.7-0.2-1.5-0.2-2.5-0.2
		c-0.6,0-1.2,0-1.9,0s-1.2,0-1.6,0l-0.3,1.3c0.5,0,1.2-0.1,1.8-0.1c0.7,0,1.3,0,1.9,0c0.8,0,1.5,0.1,2,0.2c0.5,0.1,0.9,0.3,1.2,0.5
		c0.3,0.2,0.5,0.6,0.6,1c0.1,0.4,0.2,0.9,0.2,1.5v1.5c-0.3-0.4-0.8-0.7-1.5-0.9s-1.6-0.3-2.7-0.3c-1.7,0-2.9,0.3-3.7,0.9
		c-0.8,0.6-1.1,1.6-1.1,3v0.3c0,1.3,0.4,2.3,1.1,2.9c0.7,0.6,2,0.9,3.7,0.9h4.1c0.6,0,1-0.1,1.2-0.3c0.2-0.2,0.3-0.6,0.3-1.1V110.9
		L372.6,110.9z M380.5,119.5v-8.3c0-0.6,0-1.2,0.1-1.6c0.1-0.4,0.3-0.8,0.5-1c0.2-0.2,0.6-0.4,1-0.5c0.4-0.1,0.9-0.2,1.6-0.2
		c0.4,0,0.9,0,1.4,0c0.5,0,0.8,0,1,0l0.3-1.3c-0.3,0-0.7,0-1.2,0c-0.5,0-1,0-1.6,0c-0.7,0-1.4,0.1-2,0.2c-0.6,0.1-1,0.4-1.4,0.7
		c-0.4,0.3-0.7,0.8-0.9,1.4c-0.2,0.6-0.3,1.3-0.3,2.1v8.4H380.5L380.5,119.5z M400,113.5c0.2,0,0.5,0,0.6-0.1s0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0.1-0.5,0.1-0.8v-0.6c0-1.7-0.4-3-1.2-3.8c-0.8-0.8-2.2-1.2-4-1.2c-0.9,0-1.7,0.1-2.4,0.3
		c-0.7,0.2-1.2,0.6-1.7,1c-0.4,0.5-0.8,1.1-1,1.8c-0.2,0.7-0.3,1.6-0.3,2.7v1.6c0,1,0.1,1.8,0.2,2.5c0.2,0.7,0.5,1.3,0.9,1.8
		c0.4,0.5,1,0.9,1.7,1.1c0.7,0.2,1.6,0.4,2.7,0.4c0.6,0,1.3,0,2,0c0.7,0,1.3,0,1.9,0l0.3-1.3c-0.8,0-1.5,0-2.1,0c-0.7,0-1.4,0-2.1,0
		c-0.8,0-1.5-0.1-2-0.2c-0.5-0.1-0.9-0.4-1.2-0.8c-0.3-0.4-0.5-0.9-0.6-1.5c-0.1-0.6-0.2-1.4-0.2-2.3H400L400,113.5z M397.8,107.7
		c0.5,0.1,0.9,0.3,1.2,0.6c0.3,0.3,0.5,0.7,0.7,1.2c0.1,0.5,0.2,1.1,0.2,1.9v0.8h-7.8c0-1,0.1-1.7,0.2-2.4c0.1-0.6,0.4-1.1,0.7-1.4
		c0.3-0.3,0.7-0.6,1.2-0.7c0.5-0.1,1.1-0.2,1.8-0.2C396.7,107.5,397.3,107.6,397.8,107.7L397.8,107.7z"
        />
        <path
          class="st0"
          d="M50.6,131.9c-6.6,0-12.3-0.6-16.9-1.9c-4.7-1.3-8.7-3.5-11.8-6.5c-3.1-3.1-5.4-7.2-6.9-12.3
		c-1.4-5-2.2-11.4-2.2-18.9V10.7h17.6v39.2c2.3-1.8,5.1-3.3,8.4-4.3c3.6-1.1,8.5-1.7,14.4-1.7c7,0,12.9,0.8,17.5,2.5
		c4.7,1.7,8.5,4.2,11.3,7.5c2.8,3.3,4.9,7.5,6,12.4c1.2,4.9,1.8,10.6,1.8,17.1v8.9c0,6.6-0.6,12.4-1.7,17.3c-1.1,5-3.2,9.2-6.2,12.5
		c-3,3.3-7.1,5.8-12.2,7.4C64.8,131.1,58.3,131.9,50.6,131.9L50.6,131.9z M51.7,58.5c-4.5,0-8.2,0.3-10.9,0.9
		c-2.7,0.6-4.8,1.7-6.3,3.3c-1.5,1.6-2.6,3.9-3.1,6.6c-0.6,2.9-0.9,6.5-0.9,10.9v11.9c0,5.6,0.3,10,0.8,13.3
		c0.5,3.2,1.5,5.7,3.1,7.4c1.5,1.7,3.6,2.9,6.2,3.4c2.7,0.6,6.3,0.9,10.7,0.9c4.1,0,7.5-0.3,10.2-0.8c2.6-0.5,4.7-1.6,6.2-3.3
		c1.5-1.7,2.6-4.2,3.3-7.4c0.7-3.3,1-7.9,1-13.5v-7.9c0-5.6-0.3-10.2-0.9-13.6c-0.6-3.3-1.7-5.9-3.1-7.8c-1.4-1.8-3.5-3-6.1-3.5
		C59.1,58.8,55.7,58.5,51.7,58.5L51.7,58.5z"
        />
        <path
          class="st0"
          d="M129.7,56.3c-8.7,0-13.8,3.8-13.8,10.1c0,3.5,1.8,6.5,4.7,7.9c2.4,1.1,5.2,1.9,16.9,4.6c3.4,0.7,6,1.4,8.2,2
		l0,0c0-1.1,0-2.1,0-3.2l0,0c0-2.8,0.2-5.7,0-8.5c-0.1-2-0.6-4.1-1.6-5.9l0,0c-0.3-0.6-0.7-1.2-1.2-1.8l0,0
		C140.1,58.2,135.4,56.3,129.7,56.3L129.7,56.3z"
        />
      </g>
    </svg>
  `,
  styles: [],
})
export class BSSIconComponent implements OnInit {
  @Input() color: string = 'white';

  constructor() {}

  ngOnInit(): void {}
}
