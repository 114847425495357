import { Component, OnInit } from '@angular/core';
import { AUBescheinigung, ReadableAUBescheinigungStatus } from '@entities/AUBescheinigung';
import { BehaviorSubject, combineLatest, Observable, from } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AUBescheinigungService } from '@stores/ZEFAUBescheinigung.service';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { AuBescheinigungDatenErfassenComponent } from '@pages/TabGroup/presentation/au-bescheinigung/au-bescheinigung-daten-erfassen/au-bescheinigung-daten-erfassen.component';

export type AUBescheinigungStatusFilter = 'alle' | ReadableAUBescheinigungStatus;

@Component({
  selector: 'app-au-bescheinigung',
  templateUrl: './au-bescheinigung.component.html',
  styleUrls: ['./au-bescheinigung.component.scss'],
})
export class AuBescheinigungComponent implements OnInit {
  AUBescheinigungStati: AUBescheinigungStatusFilter[] = ['alle', 'offen', 'akzeptiert', 'zurückgewiesen'];

  statusFilter: AUBescheinigungStatusFilter = 'alle';
  readonly selectedStatusSubject = new BehaviorSubject<AUBescheinigungStatusFilter>('alle');
  readonly selectedStatusFilter$ = this.selectedStatusSubject.pipe(distinctUntilChanged());

  readonly auBescheinigungen$: Observable<AUBescheinigung[]> = combineLatest([
    this.selectedStatusFilter$,
    this.auBescheinigungService.currentUserAUBescheinigungen$,
  ]).pipe(
    map(([filter, auBescheinigungen]) => {
      return auBescheinigungen
        .filter(auBescheinigung => filter === 'alle' || auBescheinigung.getReadableStatus() === filter)
        .sort(this.auBescheinigungService.compareAUBescheinigungen);
    })
  );

  constructor(
    private readonly auBescheinigungService: AUBescheinigungService,
    private readonly bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {}

  /**@description Öffnet die Option eine neue AUBescheinigung zu erstellen */
  async openBottomSheet() {
    const config: MatBottomSheetConfig<void> = { disableClose: true };
    const bottomsheetRef = this.bottomSheet.open(AuBescheinigungDatenErfassenComponent, {
      data: from([config]),
    });
    await bottomsheetRef.afterDismissed().toPromise();
  }

  /**@description Scrollt in das jeweils geöffnete Panel */
  scrollInto(index) {
    const header = document.getElementsByClassName('mat-expansion-panel-header');
    const correctHeader = header[index];
    correctHeader.scrollIntoView();
  }
}
