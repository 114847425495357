import { Injectable, OnInit, Inject, Injector } from '@angular/core';

import { ControllerService } from '../../../globalServices/controller.service';
import { Auftrag } from '@entities/Auftrag';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { DatabaseService } from '@app/globalServices/database.service';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as moment from 'moment';
import { taplog } from '@app/utils/rxjsUtils';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';

@Injectable({
  providedIn: 'root',
})
export class AuftraegeService extends StoreControllerServiceBase<Auftrag> {
  protected GetStoreName(): string {
    return Auftrag.StoreName;
  }

  protected entityConstructor(entity): Auftrag {
    return new Auftrag(entity);
  }

  constructor(
    private readonly controllerservice: ControllerService,
    private readonly dbService: DatabaseService,
    private readonly globalService: GlobalSettingService,
    protected readonly injector: Injector,
    protected readonly backendService: ZEFBackendService,
    protected readonly userSessionService: UserSessionService
  ) {
    super(injector);
  }

  /**@description Takes AuftragId and returns the Description */
  getAuftragDescription(auftragsId: string): Observable<string> {
    return this.firstInStoreData$(auftrag => auftrag.getNummer() === auftragsId).pipe(
      map(auftrag => auftrag?.getBeschreibung())
    );
  }

  async Sync(emit?: boolean): Promise<string[]> {
    await this.trySync(emit);
    return [Auftrag.StoreName];
  }

  // TODO standardize refresh

  async trySync(emit?: boolean): Promise<void> {
    // der äußere Try-Catch Block ist dafür da, Fehler von alten Webservices abzufangen
    try {
      if (!(await this.getAuftraegeForPnr(emit))) {
        if (!(await this.refreshAuftraegeAb(emit))) {
          await this.refreshAuftraege(emit);
        }
      }
    } catch (error) {
      console.error('Beim refreshen der Aufträge gabe es folgenden Fehler: ' + error.getMessage());
    }
  }

  async refreshAuftraege(emit?: boolean) {
    await this.controllerservice.reloadStore(Auftrag.StoreName, 'GetAuftraege', emit).toPromise();
  }

  /**@description Refresht die Aufträge, aber nur Aufträge besorgen ab Datum - Format des Datums: TagMonatJahr Bsp: 31011993 */
  async refreshAuftraegeAb(emit?: boolean) {
    const date = await this.globalService.auftragsSyncAb.get();
    const datum = moment(date).format('DDMMYYYY');
    const pnr = await this.userSessionService.pnr$.pipe(first()).toPromise();

    await this.controllerservice
      .reloadStore(Auftrag.StoreName, `GetAuftraegeAb/PNR/${pnr}/DATUM/${datum}`, emit)
      .toPromise();
    var auftraege = await this.LoadData();
    return !(auftraege === null || auftraege === undefined || auftraege.length === 0);
  }

  /**@description Refresht die Aufträge, aber nur Aufträge die dem Mitarbeiter per Einsatzplaner zugeordnet wurden */
  async getAuftraegeForPnr(emit?: boolean) {
    const pnr = await this.userSessionService.pnr$.pipe(first()).toPromise();
    await this.controllerservice.reloadStore(Auftrag.StoreName, `GetAuftraegeForPnr/PNR/${pnr}`, emit).toPromise();
    var auftraege = await this.LoadData();
    return !(auftraege === null || auftraege === undefined || auftraege.length === 0);
  }
}
