import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { BuchungsBottomsheetComponent, EmployeePreselectionParameter } from '../BuchungsBottomsheetComponent';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { Employee } from '@entities/Employee';

@Component({
  selector: 'app-kommen-erfassen',
  templateUrl: './kommen-erfassen.component.html',
  styleUrls: ['./kommen-erfassen.component.scss'],
})
export class KommenErfassenComponent
  extends BuchungsBottomsheetComponent<EmployeePreselectionParameter>
  implements OnInit
{
  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }

  public TitleStyle: TitleStyle = {
    name: 'Kommen',
    color: 'black',
    fontweight: 'bold',
  };

  public SaveKommenButton: ButtonStyle = {
    color: 'white',
    backgroundColor: 'rgb(0, 145, 24)',
    icon: 'assets/icons/inbox_into.png',
    description: 'Kommen buchen',
  };

  protected createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return formBuilder.group({
      mitarbeiter: ['', [Validators.required, Validators.minLength(1)]],
      kommentar: ['', [Validators.maxLength(750)]],
    });
  }
}
