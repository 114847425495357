<div class="content" id="content" *ngIf="showMenu">
  <div class="topPart">
    <img [src]="closeMenuUrl" class="closeMenu" (click)="closeMenu()" />
    <img class="logo" src="assets/icons/my_blueapp_zeiterfassung_neg.svg" />
    <div *ngIf="showAddToHome" (click)="this.gotoInstallApp()" id="a2hs" class="addToHome">
      Zum Homebildschirm hinzufügen
    </div>
    <br />
    <div *ngIf="isProd" class="versionProd">{{ versionsnummer }}</div>
    <div *ngIf="!isProd" class="version">{{ versionsnummer }}</div>
  </div>
  <!-- 
    <div class="botPart">

      <div class="menuImageBottom">
        <img src="assets/icons/menu_bottom.svg">
      </div>

      <mat-grid-list cols="2" rowHeight="2:0.9">
        <mat-grid-tile class="mgTile" *ngFor="let tile of tiles" (click)="handleClick(tile.link)" [colspan]="tile.cols" [rowspan]="tile.rows" [style.background]="tile.color">
          <mat-card-content>
            <img *ngIf="tile.link !== 'impressum'" class="menuIcons" [src]="tile.backgroundImage"/>
            <img *ngIf="tile.link === 'impressum'" style="width:5vh;" [src]="tile.backgroundImage"/>
            <br />
            <a class="text1">{{ tile.text }}</a>
            <mat-divider style="position: absolute; width: 90%; bottom: 5px; left: 5%"></mat-divider>
          </mat-card-content>
        </mat-grid-tile>
      </mat-grid-list>

    </div> -->
  <div class="botPart">
    <div class="menuImageBottom">
      <img src="assets/icons/menu_bottom.svg" />
    </div>
    <mat-grid-list cols="2" rowHeight="2:0.9" gutterSize="3vh">
      <mat-grid-tile
        class="mgTile"
        *ngFor="let tile of tiles"
        (click)="handleClick(tile.link)"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
        [style.background]="tile.color"
      >
        <mat-card-content class="max">
          <div class="card-layout">
            <div class="grid-top">
              <img class="menuIcons" [src]="tile.backgroundImage" />
            </div>
            <div class="grid-bottom">
              <a class="text1">{{ tile.text }}</a>
            </div>
          </div>
        </mat-card-content>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
