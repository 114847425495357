import { BookingType } from '@app/utils/types';
import { isNullOrUndefined } from '@app/utils/utils';

export class SaveButtonStyle {
  color = 'white';
  backgroundColor = 'rgb(18, 126, 198)';
  icon = 'assets/icons/briefcase_document.png';
  description = 'Arbeitszeit buchen';

  constructor(type?: BookingType) {
    if (isNullOrUndefined(type)) {
      return;
    }
    this.setButtonStyle(type);
  }

  setButtonStyle(type: BookingType) {
    switch (type) {
      case BookingType.ARBEITSZEIT: {
        this.description = 'Arbeitszeit buchen';
        this.backgroundColor = 'rgb(0, 145, 24)';
        this.icon = 'assets/icons/inbox_into.png';
        return;
      }
      case BookingType.AUFTRAGSZEIT: {
        this.description = 'Auftrag buchen';
        this.backgroundColor = 'rgb(18, 126, 198)';
        this.icon = 'assets/icons/briefcase_document.png';
        return;
      }
      case BookingType.FEHLZEIT: {
        this.description = 'Fehlzeit buchen';
        this.backgroundColor = 'rgb(218, 84, 45)';
        this.icon = 'assets/icons/cup.png';
        return;
      }
    }
  }
}
