import * as moment from 'moment';
import { OneOrMany } from './types';

export function readFileAsync(file: any): Promise<string> {
  const result = new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (fileEvent: any) => {
      if (fileEvent && fileEvent.target?.result && typeof fileEvent.target.result === 'string') {
        resolve(fileEvent.target.result);
        return;
      }
      reject(fileEvent);
    };
    reader.onerror = (errorEvent: any) => {
      reject(errorEvent);
    };
    reader.readAsDataURL(file);
  });
  return result;
}

declare const qrcode: {
  callback: (result: string | Error) => void;
  decode: (imageSource: string) => void;
};

export function qrImageToString(fileData: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    qrcode.callback = (result: string | Error) => {
      if (typeof result === 'string') {
        resolve(result);
        return;
      }
      reject(result);
    };
    qrcode.decode(fileData);
  });
}

export function firstOfMany<T>(many: OneOrMany<T>): T {
  if (!Array.isArray(many)) return many;
  if (many.length > 0) return many[0];
  return undefined;
}

export function median<T>(arr: T[], fSort?: (a: T, b: T) => number, fReduce?: (a: T, b: T) => T): T {
  const sorted = fSort ? [...arr].sort(fSort) : arr;
  const mid = Math.floor(sorted.length / 2);

  const reduce = fReduce ? fReduce : (a: T, b: T) => a;

  return arr.length % 2 !== 0 ? sorted[mid] : reduce(sorted[mid - 1], sorted[mid]);
}

/**@description Helperfunction für Reusability */
export function isNullOrUndefined(data: any): boolean {
  if (data === null || data === undefined) {
    return true;
  }
  return false;
}

export function isNumberKey(key: string): boolean {
  const numberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  if (numberKeys.includes(key)) return true;

  return false;
}

/**@description Adds a leading zero on a timevalue(any one or two-digit number) */
export function addLeadingZeroInTimevalue(timeValue: string | number): string {
  const timeValueString = timeValue.toString();
  if (timeValueString.length === 2) return timeValueString;
  return '0' + timeValueString;
}

/**@description Adds leading zeroes to day and month on a datevalue */
export function addLeadingZeroInDateValue(dateValue: Date): string {
  return dateValue.toLocaleDateString('de-De', { day: '2-digit', month: '2-digit', year: 'numeric' });
}

/**@description Checks for two timeslots in format HH:MM whether it can be interprated as a daychange */
export function isDayChange(startingTime: string, endTime: string) {
  if (endTime === '-1:-1') return false;
  const startingTimeSplit = startingTime.split(':');
  const startHours = parseInt(startingTimeSplit[0], 10);
  const startMinutes = parseInt(startingTimeSplit[1], 10);

  const endTimeSplit = endTime.split(':');
  const endHours = parseInt(endTimeSplit[0], 10);
  const endMinutes = parseInt(endTimeSplit[1], 10);

  const hoursSmaller = endHours < startHours;
  const hoursEqual = endHours === startHours;
  const minutesSmaller = endMinutes < startMinutes;

  if (hoursSmaller || (hoursEqual && minutesSmaller)) {
    return true;
  }
  return false;
}

/**@description Returns a date with time set on 00:00:00:00*/
export function getDateWithoutTime(date: Date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

/**@description Encodes an URL parameter to an object*/
export function decodeURLParameter(parameter: string) {
  const base64String = decodeURI(parameter);
  const parameterJSONString = atob(base64String);
  return JSON.parse(parameterJSONString);
}

/**@description Shortens a String to the given length and adds '...' */
export function shortenString(text: string, length: number) {
  if (text.length > length) {
    const splitText = text.slice(0, length);
    return splitText + '...';
  }
  return text;
}
