import { BehaviorSubject } from 'rxjs';
import { Component, forwardRef, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms';
import { BaseTextfieldComponent } from '@base-components/form-components/BaseTextfieldComponent';

@Component({
  selector: 'app-comment-field',
  templateUrl: './comment-field.component.html',
  styleUrls: ['./comment-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommentFieldComponent),
      multi: true,
    },
  ],
})
export class CommentFieldComponent
  extends BaseTextfieldComponent
  implements OnDestroy, ControlValueAccessor, Validator
{
  constructor() {
    super();
  }

  readonly commentControl = new UntypedFormControl();

  public text;
  private readonly textSubject = new BehaviorSubject<string>(undefined);
  protected getSelection$() {
    return this.textSubject.asObservable();
  }

  public disabled: boolean;
  private onChange: any = () => {};
  private onTouched: any = () => {};

  inputChange(value: string) {
    this.text = value;
    this.onChange(this.text);
    this.onTouched();
    this.commentControl.setValue(value);
  }

  //#region ControlValueAccessor

  writeValue(value): void {
    this.text = value;
    this.commentControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value() {
    return this.text;
  }

  set value(val) {
    if (val) {
      this.text = val;
      this.onChange(this.text);
    }
  }

  //#endregion

  //#region Validator

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {}

  //#endregion
}
