import { Mutator, query as rxQuery, QueryConfig, QueryOutput } from 'rx-query';
import { combineLatest as rxCombineLatest, Observable, of } from 'rxjs';
import { filter as rxFilter, first, map as rxMap, switchMap as rxSwitchMap } from 'rxjs/operators';

export type ObservableQuery<T> = Observable<QueryOutput<T>>;

export const query = <T, P = unknown>(
  name: string,
  fObs: (p: P) => Observable<T>,
  config?: QueryConfig<T, P>
): ObservableQuery<T> => {
  return rxQuery<T, P>(name, fObs, { retries: 0, cacheTime: 0, ...config });
};

export const mapToQuery =
  <T>(name: string, config?: QueryConfig<T, T>) =>
  (src: Observable<T>): ObservableQuery<T> => {
    return rxQuery<T, T>(name, () => src, { retries: 0, cacheTime: 0, ...config });
  };
export const mapTo = mapToQuery;

export const mapQuery =
  <T, R>(fData: (t: T) => R, fMutator?: (m1: Mutator<T>) => Mutator<R>) =>
  (src: ObservableQuery<T>): ObservableQuery<R> => {
    return src.pipe(
      rxMap(qry => ({
        ...qry,
        data: qry.status === 'success' ? fData(qry.data) : undefined,
        mutate: fMutator && qry.status === 'success' ? fMutator(qry.mutate) : undefined,
      }))
    );
  };
export const qMap = mapQuery;
export const map = mapQuery;

const mapState = <T, T2>(src: QueryOutput<T>, fMutator?: (m1: Mutator<T>) => Mutator<T2>): QueryOutput<T2> => {
  return {
    ...src,
    data: undefined,
    mutate: fMutator ? fMutator(src.mutate) : undefined,
  };
};
export const switchMapQuery =
  <T, R>(fData: (t: T) => ObservableQuery<R>, fMutator?: (m1: Mutator<T>) => Mutator<R>) =>
  (src: ObservableQuery<T>): ObservableQuery<R> => {
    return src.pipe(
      rxSwitchMap(qry => (qry.status === 'success' ? fData(qry.data) : of(mapState<T, R>(qry, fMutator))))
    );
  };
export const qSwitchMap = switchMapQuery;
export const switchMap = switchMapQuery;

const filterQuery =
  <T>(f: (t: T) => boolean) =>
  (src: ObservableQuery<T>): ObservableQuery<T> => {
    return src.pipe(rxFilter(data => data.status !== 'success' || f(data.data)));
  };
export const qFilter = filterQuery;
export const filter = qFilter;

export const onlyValues =
  <T>(f?: (t: T) => boolean) =>
  (src: ObservableQuery<T>): Observable<T> => {
    return src.pipe(
      rxFilter(output => output.status === 'success' && (!f || f(output.data))),
      rxMap(output => output.data)
    );
  };

export const toPromise = async <T>(src: ObservableQuery<T>): Promise<T> => {
  const result = await src
    .pipe(
      rxFilter(output => ['success', 'error'].includes(output.status)),
      first()
    )
    .toPromise();

  if (result.status === 'error') throw result.error;
  return result.data;
};

export class MutatorHelper {
  static combineMutator2<T1, T2>(m1: Mutator<T1>, m2: Mutator<T2>): Mutator<[T1, T2]> {
    return (tuple: [T1, T2]) => [m1(tuple[0]), m2(tuple[1])];
  }

  static combineMutator3<T1, T2, T3>(m1: Mutator<T1>, m2: Mutator<T2>, m3: Mutator<T3>): Mutator<[T1, T2, T3]> {
    return (tuple: [T1, T2, T3]) => [m1(tuple[0]), m2(tuple[1]), m3(tuple[2])];
  }

  static combineMutator4<T1, T2, T3, T4>(
    m1: Mutator<T1>,
    m2: Mutator<T2>,
    m3: Mutator<T3>,
    m4: Mutator<T4>
  ): Mutator<[T1, T2, T3, T4]> {
    return (tuple: [T1, T2, T3, T4]) => [m1(tuple[0]), m2(tuple[1]), m3(tuple[2]), m4(tuple[3])];
  }
}

export const combineLatest = <T1, T2>(
  src1: ObservableQuery<T1>,
  src2: ObservableQuery<T2>,
  fMutator: (m1: Mutator<T1>, m2: Mutator<T2>) => Mutator<[T1, T2]> = MutatorHelper.combineMutator2
): ObservableQuery<[T1, T2]> => {
  return rxCombineLatest([src1, src2]).pipe(
    rxMap(([output1, output2]) => {
      if (output1.status === 'success' && output2.status === 'success')
        return {
          status: 'success',
          data: [output1.data, output2.data],
          mutate: fMutator(output1.mutate, output2.mutate),
        };

      const otherStates = ['error', 'loading', 'refreshing', 'mutating', 'mutate-error'];
      const outputs = [output1, output2];

      for (let i = 0; i < outputs.length; i++) {
        if (otherStates.includes(outputs[i].status)) {
          return { ...outputs[i], data: undefined, mutate: undefined };
        }
      }

      throw new Error('Unknown Query-State in qCombineLatest');
    })
  );
};

export const combineLatest3 = <T1, T2, T3>(
  src1: ObservableQuery<T1>,
  src2: ObservableQuery<T2>,
  src3: ObservableQuery<T3>,
  fMutator: (m1: Mutator<T1>, m2: Mutator<T2>, m3: Mutator<T3>) => Mutator<[T1, T2, T3]> = MutatorHelper.combineMutator3
): ObservableQuery<[T1, T2, T3]> => {
  return rxCombineLatest([src1, src2, src3]).pipe(
    rxMap(([output1, output2, output3]) => {
      if (output1.status === 'success' && output2.status === 'success' && output3.status === 'success')
        return {
          status: 'success',
          data: [output1.data, output2.data, output3.data],
          mutate: fMutator(output1.mutate, output2.mutate, output3.mutate),
        };

      const otherStates = ['error', 'loading', 'refreshing', 'mutating', 'mutate-error'];
      const outputs = [output1, output2, output3];

      for (let i = 0; i < outputs.length; i++) {
        if (otherStates.includes(outputs[i].status)) {
          return { ...outputs[i], data: undefined, mutate: undefined };
        }
      }

      throw new Error('Unknown Query-State in qCombineLatest');
    })
  );
};
