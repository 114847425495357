import { Component, Injector, OnInit } from '@angular/core';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BuchungsBottomsheetComponent, EmployeePreselectionParameter } from '../BuchungsBottomsheetComponent';
import { FehlzeitenService } from '@pages/TabGroup/shared/ZEFFehlzeiten.service';
import { Fehlzeit } from '@entities/Fehlzeiten';
import { AutocompleteParameters } from '@base-components/form-components/autocomplete-input/autocomplete-input.component';
import { map } from 'rxjs/operators';
import { getfirst } from '@app/utils/rxjsUtils';

export interface FehlzeitenPreselectParameters extends EmployeePreselectionParameter {
  Kennzeichen: string;
}

@Component({
  selector: 'app-fehlzeit-erfassen',
  templateUrl: './fehlzeit-erfassen.component.html',
  styleUrls: ['./fehlzeit-erfassen.component.scss'],
})
export class FehlzeitErfassenComponent
  extends BuchungsBottomsheetComponent<FehlzeitenPreselectParameters>
  implements OnInit
{
  constructor(protected readonly injector: Injector, private readonly fehlzeitenService: FehlzeitenService) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }

  public TitleStyle: TitleStyle = {
    name: 'Abwesend',
    color: 'black',
    fontweight: 'bold',
  };

  public SaveFehlzeitButton: ButtonStyle = {
    color: 'white',
    backgroundColor: 'rgb(218, 84, 45)',
    icon: 'assets/icons/cup.png',
    description: 'Fehlzeit buchen',
  };

  protected createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return formBuilder.group({
      mitarbeiter: ['', [Validators.required]],
      abwesenheitsgrund: ['', [Validators.required]],
      kommentar: ['', Validators.maxLength(750)],
    });
  }

  protected readBuchungFromForm() {
    return {
      ...super.readBuchungFromForm(),
    };
  }

  protected getDefaultBuchung() {
    return {
      ...super.getDefaultBuchung(),
      Kennzeichen: (this.formGroup.value.abwesenheitsgrund as Fehlzeit).getKennzeichen(),
    };
  }

  readonly abwesenheitsParameters: AutocompleteParameters<Fehlzeit> = {
    displayFunc: (fehlzeit: Fehlzeit) => (fehlzeit ? fehlzeit?.getBeschreibung() || fehlzeit?.getKennzeichen() : ''),
    toSearchText: (fehlzeit: Fehlzeit) => fehlzeit?.getBeschreibung().toLowerCase(),
    Placeholder: 'Abwesenheitsgrund',
    options$: this.fehlzeitenService.buchbareFehlzeiten$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.fehlzeitenService.buchbareFehlzeiten$.pipe(
          map(fehlzeiten =>
            fehlzeiten.find(fehlzeit => inputText === (fehlzeit?.getBeschreibung() || fehlzeit?.getKennzeichen()))
          )
        )
      ),
  };
}
