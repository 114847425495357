import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { generateInterval } from '@app/utils/dateTimeUtils';
import { isNullOrUndefined, isNumberKey } from '@app/utils/utils';
const placeHolder = 'Uhrzeit wählen';
@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    const selectedTimeChanges = changes['selectedTime'];
    const currentValue = selectedTimeChanges.currentValue;
    if (isNullOrUndefined(currentValue) || currentValue === '') {
      this.itemSelected(placeHolder);
      return;
    }
    this.itemSelected(currentValue);
  }

  readonly timeSlots: string[] = generateInterval();

  inputMode = false;
  @Input() selectedTime = placeHolder;
  @Output() selectedTimeChange = new EventEmitter<string>();

  private timePickerInput: ElementRef;

  @ViewChild('timePickerInput') set content(content: ElementRef) {
    if (content) {
      this.timePickerInput = content;
      const inputElement = <HTMLInputElement>this.timePickerInput.nativeElement;
      inputElement.focus();
      this.scrollToCurrentInput(this.selectedTime);
    }
  }

  itemSelected(selectedItem: string) {
    this.selectedTime = selectedItem;
    this.selectionComplete(selectedItem);
  }

  selectionComplete(selectedItem: string) {
    this.inputMode = false;
    this.selectedTimeChange.emit(selectedItem);
  }

  onFocus() {
    this.inputMode = true;
    this.scrollToCurrentInput(this.selectedTime);
  }

  onBlur() {
    this.inputMode = false;
    if (this.selectedTime === '') this.itemSelected(placeHolder);
  }

  onKeyDown(keyPressedEvent: KeyboardEvent) {
    keyPressedEvent.preventDefault();
    this.inputMode = true;
    const keyPressed = keyPressedEvent.key;
    const isBackSpace = keyPressed === 'Backspace';
    if (!isNumberKey(keyPressed) && !isBackSpace) {
      return;
    }
    this.selectedTime = this.buildDisplayInput(keyPressed, this.selectedTime);
    if (this.selectedTime.length === 5) {
      this.selectionComplete(this.selectedTime);
      return;
    }

    this.scrollToCurrentInput(this.selectedTime);
  }

  /**@description Wertet den Keypress passend für unsere Komponente aus und wendet ihn an, da wir das default benehmen ja untersagt haben */
  private buildDisplayInput(keyPressed: string, currentDisplayInput: string): string {
    const isFirstChar = currentDisplayInput.length === 0;
    const isBackspace = keyPressed === 'Backspace';

    if (isFirstChar && isBackspace) return '';

    const removeLastChar = isBackspace && !isFirstChar;
    if (removeLastChar) {
      let charsToRemove = 1;
      if (currentDisplayInput.length === 3) {
        charsToRemove = 2;
      }
      currentDisplayInput = currentDisplayInput.substr(0, currentDisplayInput.length - charsToRemove);
      return currentDisplayInput;
    }

    const hourPartIsClear = keyPressed !== '1' && keyPressed !== '2';
    if (isFirstChar && hourPartIsClear) {
      currentDisplayInput = '0' + keyPressed;
    } else {
      currentDisplayInput += keyPressed;
    }

    const hourPartSet = currentDisplayInput.length === 2;
    if (hourPartSet) {
      currentDisplayInput += ':';
    }

    return currentDisplayInput;
  }

  /**@description Springt innerhalb der Liste der Timeslots zum ausgewählten */
  private scrollToCurrentInput(tempInput: string) {
    if (tempInput === '' || tempInput === '-1:-1') tempInput = new Date().toLocaleTimeString();
    const selectId = this.buildSelectId(tempInput);
    const slotElement = document.getElementById(selectId);
    slotElement?.scrollIntoView();
  }

  /**@description Baut aus dem aktuellen Input eine ID zum Anspringen, existiert die genaue Zeit nicht in der Auswahl, wird zur vollen Stunde gesprungen */
  buildSelectId(tempInput: string): string {
    if (isNullOrUndefined(tempInput)) return;
    const currentLength = tempInput.length;
    let slotPrefix = '';
    switch (currentLength) {
      case 0: {
        //
        slotPrefix = tempInput + '00:00';
        break;
      }
      case 1: {
        // 1 oder 2 gedrückt
        slotPrefix = tempInput + '0:00';
        break;
      }
      case 3: {
        // nicht 1 oder 2 gedrückt, also ist eingabe nun 0X:
        slotPrefix = tempInput + '00';
        break;
      }
      case 4: {
        // eingabe aktuell XX:X
        slotPrefix = tempInput + '0';
        break;
      }
      case 5: {
        // eingabe vollständig
        slotPrefix = tempInput;
        break;
      }
    }
    if (!this.timeSlots.includes(slotPrefix)) {
      // existiert die eingabe nicht als vorlage, springe den start vom jeweiligen slot an
      const startOfTimeslot = tempInput.substr(0, 3);
      slotPrefix = startOfTimeslot + '00';
    }
    return slotPrefix + 'Slot';
  }

  switchToInputMode() {
    if (this.selectedTime === placeHolder || this.selectedTime === '-1:-1') {
      this.selectedTime = '';
    }
    this.inputMode = true;
  }

  getDisplayValueOrPlaceholder(): string {
    if (this.selectedTime === placeHolder || this.selectedTime === '' || this.selectedTime === '-1:-1')
      return placeHolder;
    const hasSeconds = this.selectedTime.split(':')[2];
    let newTime = hasSeconds ? this.selectedTime.substring(0, this.selectedTime.lastIndexOf(':')) : this.selectedTime;
    return newTime + ' Uhr';
  }
}

export class TimepickerTime {
  constructor(value: string) {
    this.value = value;
  }
  value: string;
  getHours(): number {
    const valueSplit = this.value.split(':');
    const hourValue = parseInt(valueSplit[0], 10);
    return hourValue;
  }

  getMinutes(): number {
    const valueSplit = this.value.split(':');
    const minuteValue = parseInt(valueSplit[1], 10);
    return minuteValue;
  }

  getSeconds(): number {
    const valueSplit = this.value.split(':');
    const hasSeconds = !!valueSplit[2];
    return hasSeconds ? parseInt(valueSplit[2], 10) : 0;
  }
}
