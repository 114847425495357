import { Component, Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { A2hsService } from '@app/globalServices/a2hs.service';
import { DialogService } from '@app/globalServices/dialog.service';
import { EmployeeLoginService } from '@app/globalServices/employee-login.service';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { SyncService } from '@app/globalServices/sync.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { AllocationManagerService } from '@app/globalServices/webservice-connection-services/allocation-manager.service';
import { ConnectionInfoCacheService } from '@app/globalServices/webservice-connection-services/connectioninfo-cache.service';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { DomainErrorCode } from '@app/utils/ErrorHandling/DomainError';
import { getfirst } from '@app/utils/rxjsUtils';
import { ModalActionType, ValidationResult } from '@app/utils/types';
import { decodeURLParameter } from '@app/utils/utils';
import { QrcodeComponent } from '@global-components/qrcode/qrcode.component';
import { MitarbeiterService } from '@pages/TabGroup/shared/ZEFMitarbeiter.service';
import { BehaviorSubject, combineLatest, from, of, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CryptoService } from 'src/app/globalServices/Crypto.service';
import { TermConverterService } from 'src/app/globalServices/termConverter.service';
import { environment } from 'src/environments/environment';

type LoginMethod =
  | 'none'
  | 'qrscan'
  | 'manual'
  | 'lan'
  | 'localhost'
  | 'lastUser'
  | 'connectionparameter'
  | 'keyparameter';

interface ILoginQrCode {
  uuid: string;
  pass_key: string;
  mandant: string;
  pnr: string;
  kennwort: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
@Injectable()
export class LoginComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,

    private readonly dialogService: DialogService,
    private readonly a2hs: A2hsService,
    private readonly termconverterService: TermConverterService,

    private readonly globalSettingService: GlobalSettingService,
    private readonly userSessionService: UserSessionService,
    private readonly employeeLoginService: EmployeeLoginService,
    private readonly allocationService: AllocationManagerService,
    private readonly connectionInfoService: ConnectionInfoCacheService,
    private readonly backendService: ZEFBackendService,
    private readonly syncService: SyncService,
    private readonly crypto: CryptoService,

    private readonly mitarbeiterService: MitarbeiterService
  ) {}

  //#region Parameters

  readonly guidParameter$ = this.route.queryParams.pipe(map(params => params['guid'] as string));

  readonly givenGuid$ = combineLatest([this.guidParameter$, this.allocationService.allocationGuid.value$]).pipe(
    map(([param, setting]) => (!!param ? param : !!setting ? setting : ''))
  );

  readonly hasGuid$ = this.givenGuid$.pipe(map(guid => !!guid));

  readonly mandantParameter$ = this.route.queryParams.pipe(map(params => params['mandant'] as string));
  readonly hasMandantParameter$ = this.mandantParameter$.pipe(map(name => !!name));

  readonly pnrParameter$ = this.route.queryParams.pipe(map(params => params['pnr'] as string));

  readonly haspnrParameter$ = this.pnrParameter$.pipe(map(pnr => !!pnr));

  readonly localhostPort$ = this.route.queryParams.pipe(map(params => params['localhostport']));

  readonly connectionParameter$ = this.route.queryParams.pipe(map(params => params['connection']));

  readonly hasGuidMandantPnrParameter$ = combineLatest([
    this.hasGuid$,
    this.hasMandantParameter$,
    this.haspnrParameter$,
  ]).pipe(map(([guid, mandant, pnr]) => guid || mandant || pnr));

  //#endregion

  private readonly hasConnectionData$ = combineLatest([this.hasGuid$, this.connectionInfoService.connectionInfo$]).pipe(
    map(([hasGuid, connectionInfo]) => hasGuid || !!connectionInfo)
  );

  //#region Login - Methods

  private readonly loginMethodSubject = new BehaviorSubject<LoginMethod>('none');
  readonly selectedLoginMethod$ = this.loginMethodSubject.asObservable();

  private readonly hasLastUser$ = this.userSessionService.lastUser.value$.pipe(map(user => !!user));
  readonly lastUserLoginEnabled$ = combineLatest([this.hasLastUser$, this.hasConnectionData$]).pipe(
    map(([hasUser, hasConnection]) => hasUser && hasConnection)
  );

  readonly manualLoginEnabled$ = of(true); /* this.hasConnectionData$.pipe(
		map((hasData) => hasData && environment.configurationname !== 'prod'),
		distinctUntilChanged()
	);*/

  readonly lanLoginEnabled$ = of(environment.runtimeEnvironment === 'desktop');

  readonly localhostLoginEnabled$ = this.localhostPort$.pipe(map(port => !!port));

  readonly demoEnabled = false;

  readonly installEnabled = false; // !this.a2hs.isLaunchedFromHomescreen() && environment.configurationname !== 'serve';
  readonly triggerInstall = async () => await this.a2hs.triggerInstall();

  readonly qrScanEnabled$ = of(environment.runtimeEnvironment !== 'desktop');

  //#endregion Login - Methods

  //#region LoginFormfields

  readonly noMethodSelected$ = this.selectedLoginMethod$.pipe(
    map(method => 'none' === method),
    distinctUntilChanged()
  );

  readonly formMethodSelected$ = this.selectedLoginMethod$.pipe(
    map(method => !['none', 'qrscan'].includes(method)),
    distinctUntilChanged()
  );

  readonly showGuidField$ = this.selectedLoginMethod$.pipe(
    map(mode => ['lastUser', 'manual'].includes(mode)),
    distinctUntilChanged()
  );
  readonly disableGuidField$ = combineLatest([this.selectedLoginMethod$, this.hasGuid$]).pipe(
    map(([method, hasGuid]) => method === 'lastUser' && hasGuid)
  );

  readonly showMandantSelector$ = this.selectedLoginMethod$.pipe(
    switchMap(mode => {
      if (!['lastUser', 'manual', 'localhost', 'lan'].includes(mode)) return of(false);
      if (mode === 'connectionparameter') return this.hasGuid$;
      return of(true);
    })
  );
  readonly disableMandantField$ = this.selectedLoginMethod$.pipe(map(mode => ['lastUser'].includes(mode)));

  readonly showAddressField$ = of(false);

  readonly showPortField$ = this.selectedLoginMethod$.pipe(
    switchMap(mode => {
      if (!['localhost', 'lan'].includes(mode)) return of(false);
      return of(true);
    })
  );

  readonly showPnrField$ = this.selectedLoginMethod$.pipe(
    switchMap(mode => {
      if (!['lastUser', 'manual', 'localhost', 'lan', 'connectionparameter'].includes(mode)) return of(false);
      return of(true);
    })
  );
  readonly disablePnrField$ = this.selectedLoginMethod$.pipe(map(mode => ['lastUser'].includes(mode)));

  readonly showPwField$ = of(true);

  readonly showScanner$ = this.selectedLoginMethod$.pipe(map(mode => ['qrscan'].includes(mode)));

  //#endregion

  readonly lastUserDisplayName$ = this.mitarbeiterService.currentUser$.pipe(
    map(user => {
      const vorname = user.Vorname;
      const nachname = user.Nachname;
      if (vorname && nachname) return vorname + ' ' + nachname;
      if (vorname || nachname) return vorname || nachname;
      return '';
    })
  );

  //#region Form-Fields

  guid = '';
  mandant = '';
  pnr = '';
  hidePasswort = true;
  passwort = '';
  ipaddress = 'localhost';
  port = '9001';

  imageLogin = false;
  pictureMode = this.termconverterService.getIosStandAloneOrQs(this.imageLogin);

  //#endregion

  async ngOnInit() {
    const rerouteToTabs = await this.userSessionService.loggedIn.get();
    //#5374 Nach abmeldung nicht mehr direkt in die manuelle Anmeldung
    // const hasGuidMandantPnrParameter = await this.hasGuidMandantPnrParameter$.pipe(first()).toPromise();
    const encodedLoginParameter = this.route.snapshot.queryParams['p'];

    if (rerouteToTabs) {
      await this.router.navigate(['/tabgroup']);
      return;
    } else if (encodedLoginParameter) {
      const decodedParameter = decodeURLParameter(encodedLoginParameter);

      this.guid = decodedParameter.DNSGuid;
      this.mandant = decodedParameter.MandantName;
      this.pnr = decodedParameter.Pnr;

      this.loginMethodSubject.next('manual');
    } else {
      this.guid = this.route.snapshot.queryParams['guid'] || (await this.allocationService.allocationGuid.get()) || '';
      this.pnr = this.route.snapshot.queryParams['pnr'] || (await this.userSessionService.lastUser.get()) || '';
      this.mandant = this.route.snapshot.queryParams['mandant'] || (await this.userSessionService.mandant.get()) || '';

      //#5374 Nach abmeldung nicht mehr direkt in die manuelle Anmeldung
      // if (hasGuidMandantPnrParameter) this.loginMethodSubject.next('manual');
    }
  }

  /**@description Holt den Namen des letzten Mitarbeiters, wenn zuvor einer Angemeldet war */
  async getNameOfLastMitarbeiter() {
    const letzteMitarbeiter = await getfirst(this.mitarbeiterService.currentUser$);
    const vorname = letzteMitarbeiter.Vorname;
    const nachname = letzteMitarbeiter.Nachname;
    if (vorname || nachname) return vorname + ' ' + nachname;
    return '';
  }

  clearInputs() {
    this.guid = '';
    this.pnr = '';
    this.passwort = '';
    this.ipaddress = '';
    this.port = '';
  }

  /**@description Wird aufgerufen, wenn der Nutzer "Demo" klickt. Verbindungsdaten hier hardcodiert */
  async demoLogin() {
    const demoguid = environment.demoguid ? environment.demoguid : '905DBD8A-0017-4244-A91A-6D72E37BA538';
    await this.login(demoguid, '001', '001', '001');
  }

  async parameterLogin() {
    if (!this.guid || !this.mandant || !this.pnr || !this.passwort) {
      this.dialogService.ShowInformation('Fehler beim Login', 'Fehlende Anmeldeinformationen!');
    } else {
      await this.login(this.guid, this.mandant, this.pnr, this.passwort);
    }
  }

  async qrScanLogin() {
    const validator = async (s: string) => await this.validateLoginObject(s);
    const result = await QrcodeComponent.ScanQRCode(this.dialog, validator);
    if (result.Type === ModalActionType.OK) {
      const content = JSON.parse(result.Data) as ILoginQrCode;
      const plainPassword = this.crypto.DecodeBase64(content.kennwort);
      await this.login(content.uuid, content.mandant, content.pnr, plainPassword);
    }
    await this.selectLoginMethod('none');
  }

  private async login(guid: string, mandant: string, pnr: string, pw: string) {
    const callback = this.dialogService.ShowSpinner('Verbindungsaufbau...');
    try {
      await this.allocationService.allocationGuid.set(guid);
      const connectionSuccessful = await this.connectionInfoService
        .refreshAllocation()
        .pipe(
          switchMap(refreshResult => (refreshResult ? this.backendService.ping() : of(refreshResult))),
          this.dialogService.HandleDefaultErrors()
        )
        .toPromise();
      if (!connectionSuccessful) return;
    } finally {
      callback();
    }

    await this.userSessionService.mandant.set(mandant);

    const loginSuccessful = await from(this.employeeLoginService.login(pnr, pw))
      .pipe(
        this.handleLoginErrors(),
        this.dialogService.HandleDefaultErrors()
        // taplog('LOGIN SUCCESS')
      )
      .toPromise();

    if (!loginSuccessful) {
      console.error('login failed');
      return;
    }

    await this.globalSettingService.demoMode.set(true);
    await this.syncService.querySyncAfterLogin.set(true);
    await this.router.navigate(['/tabgroup']);
  }

  private handleLoginErrors(): OperatorFunction<boolean, boolean> {
    return src =>
      src.pipe(
        this.dialogService.DomainErrorHandler(
          DomainErrorCode.LicLimitReached,
          'Lizenzüberschreitung',
          'Limit an aktiven Geräten wurde erreicht',
          e => false
        ),
        this.dialogService.DomainErrorHandler(
          DomainErrorCode.AuthIdUnknown,
          'Authorisierungsfehler',
          'Unbekannte Personalnummer',
          e => false
        ),
        this.dialogService.DomainErrorHandler(
          DomainErrorCode.AuthHashInvalid,
          'Authorisierungsfehler',
          'Ungültiges Passwort',
          e => false
        ),
        this.dialogService.DomainErrorHandler(
          DomainErrorCode.TenantUnknown,
          'Verbindungsfehler',
          'Ein Mandant mit diesem Namen ist nicht bekannt.',
          e => false
        ),
        this.dialogService.DomainErrorHandler(
          DomainErrorCode.TenantBlocked,
          'Verbindungsfehler',
          'Mandant ist derzeit blockiert.',
          e => false
        ),
        this.dialogService.DomainErrorHandler(
          DomainErrorCode.PWANotEnabled,
          'Verbindungsfehler',
          'Nutzung der PWA im topzeit Administrator nicht eingerichtet!',
          e => false
        ),
        this.dialogService.DomainErrorHandler(
          DomainErrorCode.PWANoAccess,
          'Verbindungsfehler',
          'Nutzung der PWA ist für diesen Mitarbeiter nicht freigeschaltet!',
          e => false
        )
      );
  }

  async selectLoginMethod(method: LoginMethod | 'none') {
    this.loginMethodSubject.next(method);

    if (method === 'qrscan') {
      await this.qrScanLogin();
    }
  }

  validateLoginObject(o: string): ValidationResult {
    return true;
  }

  async applyLoginParameters(qrContent: ILoginQrCode) {
    if (this.crypto.DecodeBase64(qrContent.pass_key) !== 'bssblue') {
      await this.dialogService.ShowInformation('Formatfehler', 'Invalider QRCode');
      return;
    }

    this.guid = qrContent?.uuid || this.guid;
    this.pnr = qrContent?.pnr || this.pnr;
    this.passwort = qrContent?.kennwort || this.passwort;
    this.mandant = qrContent?.mandant || this.mandant;
  }

  //#region QR / Image
  /**@description Aktiviert den Login per Bild statt Scan, zum Test für Marc */
  toggleImageLogin() {
    this.imageLogin = !this.imageLogin;
    this.pictureMode = this.termconverterService.getIosStandAloneOrQs(this.imageLogin);
  }

  //#endregion
}
