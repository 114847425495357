import { Injectable } from '@angular/core';
import { BrokerError } from '@app/utils/ErrorHandling/Error';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GlobalSettingService } from '../settings/global-setting.service';
import { Setting } from '../settings/SettingService';
import { IAllocation } from './Allocation';
import { HttpClientWrapper } from './httpclient-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class AllocationManagerService {
  public readonly allocationGuid: Setting<string>;
  constructor(private readonly httpClient: HttpClientWrapper, private readonly settings: GlobalSettingService) {
    this.allocationGuid = this.settings.createSetting<string>('allocationGuid');
  }

  //#region Broker-Kommunikation

  brokerRequest(guid: string): Observable<IAllocation> {
    const brokerUrl =
      'https://broker1.bssservices.de/broker/rest/request_allocation?broker_key=bssblue' +
      '&uuid=' +
      guid +
      '&pass_key=bssblue';

    return this.httpClient.Get<any>({ url: brokerUrl }).pipe(
      tap(response => this.checkStatusCode(response)),
      map(response => this.responseToAllocation(guid, response))
    );
  }

  private checkStatusCode(response) {
    if (response && response.statusCode === 0) {
      return; // === ok
    }

    if (response && response.statusCode && response.statusCode === 404) {
      throw new BrokerError('WebService nicht verfügbar, Verbindung unbekannt', response.statusCode);
    }
    if (response && response.statusCode && response.statusCode === 405) {
      throw new BrokerError('WebService nicht verfügbar, Verbindung abgelaufen', response.statusCode);
    }
    if (response && response.statusCode) {
      throw new BrokerError('WebService nicht erreichbar.', response.statusCode);
    }
    console.error('BrokerError', response);
    throw new BrokerError('Kritischer Verbindungsfehler.', -1);
  }

  private responseToAllocation(guid: string, response): IAllocation {
    const alloc: IAllocation = {
      guid,
      hostname: response['hostname'],
      hostPort: response['port'] ?? '3000',
      ip: response['allocation_ipv4'],
      port: response['allocation_portv4'],
      ipv6: response['allocation_ipv6'],
      portv6: response['allocation_portv6'],
    };
    return alloc;
  }

  //#endregion
}
