<!-- <img class="bg" src="assets/images/bg.jpg" /> -->

<div class="responsive-container" id="container">
  <div class="responsive-element">
    <app-toolbar class="grid-1-1"></app-toolbar>
    <div class="grid-1-2">
      <router-outlet></router-outlet>
    </div>

    <!-- <button  class="addServiceWorkerButton" (click)="addServiceWorker()" mat-raised-button>
        <mat-icon>add</mat-icon>
        <a style="font-size: 14px">install app</a>
    </button> -->
  </div>
</div>
