import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import Map from 'ol/Map.js';
import Overlay from 'ol/Overlay.js';
import View from 'ol/View.js';
import TileLayer from 'ol/layer/Tile.js';
import { fromLonLat } from 'ol/proj.js';
import OSM from 'ol/source/OSM.js';
@Component({
  selector: 'app-karten',
  templateUrl: './karten.component.html',
  styleUrls: ['./karten.component.scss'],
})
export class KartenComponent implements OnInit, AfterViewInit {
  @Input() longitude: number;
  @Input() latitude: number;
  @Output() closeEvent = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    const map = this.createMap(this.longitude, this.latitude);

    this.setMarkerAndLabel(map, this.longitude, this.latitude);
  }

  ngAfterViewInit() {
    // karten contributor neu platzieren, scheint css nicht zu greifen
    const contributorElement = document.getElementsByTagName('ul')[0];
    contributorElement.setAttribute('style', 'position: absolute; right: 0vw; padding: 0; margin: 0; bottom: 0vh;');
  }

  /**@description Erstellt die Map und setzt das Center auf die mitgegebnen Koordinaten */
  private createMap(longitude, latitude) {
    const layer = new TileLayer({
      source: new OSM(),
    });
    const map = new Map({
      target: 'map',
      layers: [layer],
      view: new View({
        center: fromLonLat([longitude, latitude]),
        zoom: 16,
      }),
    });
    return map;
  }

  /**@description Setzt den Marker und das Label an den mitgegebenen Koordinaten */
  private setMarkerAndLabel(map: any, longitude, latitude) {
    const position = fromLonLat([longitude, latitude]);
    // stempelungsPosition marker
    const marker = new Overlay({
      position: position,
      positioning: 'center-center',
      element: document.getElementById('marker'),
      stopEvent: false,
    });
    map.addOverlay(marker);
    // stempelungsPosition label
    const stempelungsPosition = new Overlay({
      position: position,
      element: document.getElementById('stempelungsPosition'),
    });
    map.addOverlay(stempelungsPosition);
  }

  /**@description Wird durchs Klicken auf das X aufgerufen und emittet das Output-Event um in andern Komponenten die Karte zu schließen */
  close() {
    this.closeEvent.emit(true);
  }
}
