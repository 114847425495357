<button
  mat-flat-button
  class="button"
  (click)="Action?.Execute(null)"
  [disabled]="Action?.Disabled"
  [ngStyle]="{
    'background-color': bgColor(),
    color: color()
  }"
>
  <div class="button-container">
    <img
      *ngIf="(ButtonStyle || Default).icon !== null"
      class="button-confirm-icon"
      [src]="(ButtonStyle || Default).icon"
    />
    {{ (ButtonStyle || Default).description }}
  </div>
</button>
