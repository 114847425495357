import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { v4 as uuidv4 } from 'uuid';
import { AntraegeService } from 'src/app/pages/TabGroup/shared/ZEFAntraege.service';
import { Antrag, AntragsArt, AntragsStatus, ReadableAntragsArt } from '@entities/Antrag';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { distinctUntilChanged, first, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { BaseBottomsheetComponent } from '@base-components/BaseBottomsheetComponent';
import { DialogService } from '@app/globalServices/dialog.service';
import { ModalAction, ModalActionType } from '@app/utils/types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TimeService, getTimeFormatForTimePicker, calcMinutenFromTime } from '@app/globalServices/time.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { getDateWithoutTime } from '@app/utils/utils';

@Component({
  selector: 'app-antrag-buchen',
  templateUrl: './antrag-buchen.component.html',
  styleUrls: ['./antrag-buchen.component.scss'],
})
export class AntragBuchenComponent extends BaseBottomsheetComponent<void, Antrag> implements OnInit {
  antragsArtenForSelecting: ReadableAntragsArt[] = [
    'Urlaub',
    'Urlaub halber Tag',
    'Zeitausgleich',
    'Sonderurlaub',
    'Zeitkorrektur',
    'Dienstgang',
    'Schule',
    'Krankheit',
    'Sonstiges',
  ];

  constructor(
    public readonly globalSettingService: GlobalSettingService,
    public readonly _adapter: DateAdapter<any>,
    private readonly antraegeService: AntraegeService,
    private readonly session: UserSessionService,
    private readonly dialogService: DialogService,
    protected readonly injector: Injector,
    private readonly timeService: TimeService
  ) {
    super(injector);
  }

  async ngOnInit() {
    this._adapter.setLocale('de');
    this.antragForm.controls['art'].patchValue('Urlaub');
  }

  antragForm: UntypedFormGroup = new UntypedFormGroup({
    art: new UntypedFormControl('Urlaub'),
    vonDate: new UntypedFormControl(''),
    bisDate: new UntypedFormControl(''),
    vonTime: new UntypedFormControl('00:00'),
    bisTime: new UntypedFormControl('00:00'),
    kommentar: new UntypedFormControl(''),
  });

  isZeitkorrektur$ = this.antragForm.controls['art'].valueChanges.pipe(
    startWith(this.antragForm.controls['art'].value),
    map(art => {
      return art == 'Zeitkorrektur';
    }),
    distinctUntilChanged()
  );

  isValidTimeRange$ = this.antragForm.valueChanges.pipe(
    map(values => {
      return calcMinutenFromTime(values['bisTime']) >= calcMinutenFromTime(values['vonTime']);
    })
  );

  isValidDateRange$ = this.antragForm.valueChanges.pipe(
    map(values => {
      const von = values.vonDate;
      const bis = values.bisDate;
      if (!von || !bis) {
        return false;
      }
      return getDateWithoutTime(von).valueOf() <= getDateWithoutTime(bis).valueOf();
    })
  );

  disableSubmit$ = combineLatest([this.isZeitkorrektur$, this.isValidTimeRange$, this.isValidDateRange$]).pipe(
    map(([isZeitkorrektur, isValidTime, isValidDate]) => {
      return isZeitkorrektur ? isValidTime : isValidDate;
    })
  );

  readonly SaveButtonAction$: Observable<ModalAction> = this.disableSubmit$.pipe(
    map(validDate => {
      return {
        Type: ModalActionType.OK,
        Disabled: !validDate,
        Execute: () => this.save(),
      };
    })
  );

  readonly SaveButton: ButtonStyle = {
    color: 'white',
    backgroundColor: '#009118',
    icon: 'assets/icons/paper_jet2.png',
    description: 'Neuen Antrag buchen',
  };

  getDateRange$ = combineLatest([this.antragForm.valueChanges, this.isZeitkorrektur$]).pipe(
    map(([values, isZeitkorrektur]) => {
      const vonDate = values.vonDate == '' ? 'Von' : this.timeService.getDateFromRawDate(values.vonDate, true);
      const bisDate = values.bisDate == '' ? 'Bis' : this.timeService.getDateFromRawDate(values.bisDate, true);

      var zeitkorrekturTitle = isZeitkorrektur && values.vonDate == '' ? 'Datum' : vonDate;

      return isZeitkorrektur ? zeitkorrekturTitle : vonDate + ' - ' + bisDate;
    })
  );

  /**@description Speichert den Antrag und lädt ihn hoch */
  async save() {
    const antrag = await this.getAntrag();
    try {
      await this.antraegeService.setAntrag(antrag);
      await this.dialogService.ShowSpinnerWhile(
        this.antraegeService.Sync(true),
        'Upload',
        'Ihr Antrag wird versendet.'
      );
    } catch (error) {
      await this.dialogService.ShowInformation('Fehler beim Versenden', 'Ihr Antrag wurde lokal zwischengespeichert.');
    }
    this.Close({ Type: ModalActionType.OK, Data: antrag });
  }

  setAntragArt(antragArt: string) {
    this.antragForm.controls['art'].setValue(antragArt);
  }

  /**@description Creates a fresh antrag and sets fields the way they need to be */
  async getAntrag() {
    const Pnr = await this.session.pnr$.pipe(first()).toPromise();
    const Art: AntragsArt = ReadableAntragsArt.toAntragsArt(this.antragForm.controls['art'].value);
    const Status: AntragsStatus = 0;
    const Guid = uuidv4();
    var Von = this.antragForm.controls['vonDate'].value;
    var Bis = Art == 'R' ? Von : this.antragForm.controls['bisDate'].value;
    const AntragTag = new Date().toLocaleString('de-DE', { hour12: false });

    const antrag = new Antrag({
      Pnr,
      Guid,
      Art,
      Status,
      Von,
      Bis,
      AntragTag,
      Text: await this.getAntragText(Art),
    });
    antrag.setChanged(true);
    return antrag;
  }

  /**@description Für Zeitkorrekturen muss der Kommentart ein bestimmtes Format haben, damit die Auswertung die Uhrzeiten auslesen kann */
  private getAntragText(Art): string {
    const kommentar = this.antragForm.controls['kommentar'].value;
    if (Art == 'R') {
      const date = this.timeService.getDateFromRawDate(this.antragForm.controls['vonDate'].value, true);
      const vonTime = this.antragForm.controls['vonTime'].value;
      const bisTime = this.antragForm.controls['bisTime'].value;
      const zkText = '[Zeitkorrektur am ' + date + ' von ' + vonTime + ' bis ' + bisTime + ']';
      return kommentar != '' ? zkText + ' Grund: ' + kommentar : zkText;
    } else {
      return kommentar;
    }
  }
}
