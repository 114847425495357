import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CryptoService } from './Crypto.service';
import { GlobalSettingService } from './settings/global-setting.service';

export interface UserSessionInfo {
  id: string;
  salt: string;
  hash: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  constructor(
    private readonly router: Router,
    private readonly crypto: CryptoService,
    private readonly settingsService: GlobalSettingService
  ) {}

  readonly mandant = this.settingsService.createSetting<string>('selectedMandant', '');

  readonly lastUser = this.settingsService.createSetting<string>('lastUser');

  readonly loggedIn = this.settingsService.createSetting<boolean>('loggedIn', false);

  readonly deviceID$ = this.settingsService.deviceID.value$;

  //#region LoginDetails

  private readonly loginDetailsKey = 'loginDetails';
  readonly loginDetails = this.settingsService.createSetting<UserSessionInfo>(this.loginDetailsKey, undefined, {
    id: null,
    hash: null,
    salt: null,
    type: '',
  });
  private readonly loginDetailsSetter = this.settingsService.setterFunction<UserSessionInfo>(this.loginDetailsKey);

  async setLoginDetails(pnr: string, pw: string) {
    const [hash, salt] = this.crypto.hashUnsalted(pw);
    const session: UserSessionInfo = { id: pnr, hash, salt, type: '' };
    await this.loginDetailsSetter(session);
  }

  //#endregion LoginDetails

  readonly pnr$ = this.loginDetails.value$.pipe(map(details => details.id));
}
