<app-bottom-sheet-button
  *ngIf="taetigkeitWechselns$ | async"
  [Action]="onTaetigkeitWechselnClick$ | async"
  [ButtonStyle]="getButtonStyle('Tätigkeit wechseln')"
></app-bottom-sheet-button>
<app-bottom-sheet-button
  *ngIf="auftragStarten$ | async"
  [Action]="onAuftragStartClick$ | async"
  [ButtonStyle]="getButtonStyle('Auftrag starten')"
></app-bottom-sheet-button>
<app-bottom-sheet-button
  *ngIf="auftragWechseln$ | async"
  [Action]="onAuftragWechselnClick$ | async"
  [ButtonStyle]="getButtonStyle('Auftrag wechseln')"
></app-bottom-sheet-button>
<app-bottom-sheet-button
  *ngIf="auftragBeenden$ | async"
  [Action]="onAuftragBeendenClick()"
  [ButtonStyle]="getButtonStyle('Auftrag beenden')"
></app-bottom-sheet-button>
<app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle">cancel</app-bottom-sheet-button>
