import { Component, SkipSelf } from '@angular/core';
import { DateSelectorBandStateService } from './date-selector-band.state.service';

@Component({
  selector: 'app-date-selector-band',
  templateUrl: './date-selector-band.component.html',
  styleUrls: ['./date-selector-band.component.scss'],
})
export class DateSelectorBandComponent {
  constructor(@SkipSelf() private readonly dateSelectorState: DateSelectorBandStateService) {}
  public pickedDate = this.dateSelectorState.getSelected();

  select(date: Date) {
    this.dateSelectorState.select(date);
  }

  next() {
    this.dateSelectorState.addDay();
    this.pickedDate = this.dateSelectorState.getSelected();
  }
  previous() {
    this.dateSelectorState.addDay(-1);
    this.pickedDate = this.dateSelectorState.getSelected();
  }
}
