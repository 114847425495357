import { environment } from 'src/environments/environment';

export class BaseError {
  message: string;
  name: string;
  constructor(message: string) {
    this.message = message;
    this.name = 'Fehler';
  }
  getMessage() {
    return this.message;
  }
  getName() {
    return this.name;
  }
  alert() {
    if (environment.configurationname === 'prod') {
      alert(this.name + ': ' + this.message);
    }
  }
}

/**
 * @description Mit Errorcode versehene Errorklasse
 */
export class CodedError extends BaseError {
  readonly ErrorCode: number;
  constructor(message, errorCode: number) {
    super(message);
    this.ErrorCode = errorCode;
  }
}

export class TitledError extends BaseError {
  readonly Title: string;

  constructor(message: string, title: string) {
    super(message);
    this.Title = title;
  }
}

export class BrokerError extends CodedError {
  constructor(message, statusCode: number) {
    super(message, statusCode);
    this.name = `Verbindungsfehler (B-${statusCode})`;
  }
}

export class UnknownErrorWrapperError extends BaseError {
  error: Error;
  constructor(message, error: Error) {
    super(message); // (1)
    this.name = 'Fehler'; // (2)
    this.error = error;
  }
}

export class ValidationError extends TitledError {
  constructor(message: string, title: string) {
    super(message, title);
    this.name = 'Validierungsfehler';
  }
}

export class ConnectionError extends BaseError {
  constructor(message) {
    super(message); // (1)
    this.name = 'ConnectionError'; // (2)
  }
}

export class HttpConnectionError extends CodedError {
  response: Response;
  body;
  constructor(message, response: Response, body?) {
    super(message, response.status);
    this.response = response;
    this.body = body;
    this.name = 'Verbindungsfehler (HTTP-' + this.ErrorCode + ')';
  }
}

export class JSONResponseError extends BaseError {
  constructor(message) {
    super(message); // (1)
    this.name = 'JSONResponseError'; // (2)
    if (environment.configurationname === 'prod') {
      this.alert();
    }
  }
}

export class LoginError extends BaseError {
  constructor(message) {
    super(message); // (1)
    this.name = 'Loginfehler'; // (2)
    if (environment.configurationname === 'prod') {
      this.alert();
    }
  }
}

export class PositionError extends BaseError {
  constructor(message) {
    super(message); // (1)
    this.name = 'Positionsfehler'; // (2)
    if (environment.configurationname === 'prod') {
      this.alert();
    }
  }
}

export class CameraError extends BaseError {
  constructor(message) {
    super(message); // (1)
    this.name = 'CameraError'; // (2)
    if (environment.configurationname === 'prod') {
      this.alert();
    }
  }
}
