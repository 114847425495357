import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  // color = 'primary';
  // mode = 'indeterminate';
  value = 50;
  innerWidth;

  @Input() Progress$?: Observable<number>;

  mode() {
    return this.Progress$ === undefined ? 'indeterminate' : 'determinate';
  }

  constructor() {}

  ngOnInit() {}
}
