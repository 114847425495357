<h1 class="title-text" mat-dialog-title>{{ (Parameter.title$ | async) || Parameter.title }}</h1>
<div mat-dialog-content class="content-container">
  <app-spinner *ngIf="Parameter.showSpinner || false" [Progress$]="Parameter.progress$"></app-spinner>
  <p class="description-text">{{ (Parameter.description$ | async) || Parameter.description }}</p>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    *ngFor="let btndata of Parameter.actions"
    [ngClass]="buttonClass(btndata.buttonType)"
    (click)="buttonClick($event, btndata.buttonType)"
  >
    {{ btndata.buttonText }}
  </button>
</div>
