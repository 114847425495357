import { Injectable } from '@angular/core';

import { GlobalSettingService } from './settings/global-setting.service';
import { MitarbeiterService } from '@pages/TabGroup/shared/ZEFMitarbeiter.service';
import { AuftraegeService } from '@pages/TabGroup/shared/ZEFAuftraege.service';
import { TaetigkeitService } from '@pages/TabGroup/shared/ZEFTaetigkeit.service';
import { KundenService } from '@pages/TabGroup/shared/ZEFKunden.service';
import { FehlzeitenService } from '@pages/TabGroup/shared/ZEFFehlzeiten.service';
import { AuswertungService } from '@pages/TabGroup/shared/ZEFAuswertung.service';
import { AntraegeService } from '@pages/TabGroup/shared/ZEFAntraege.service';
import { SaldenService } from '@pages/TabGroup/shared/ZEFSalden.service';
import { BuchungTempService } from '@pages/TabGroup/shared/ZEFBuchung_Temp.service';
import { DialogService } from './dialog.service';
import { UserSessionService } from './user-session-state.service';
import { exhaustMap, throttleTime } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TimeService } from './time.service';
import { StempelungService } from '@pages/TabGroup/shared/ZEFStempelung.service';
import { ISyncable } from '@pages/TabGroup/shared/StoreControllerServiceBase';
import { ControllerService } from './controller.service';
import { AUBescheinigungService } from '@stores/ZEFAUBescheinigung.service';

export type SyncStep = [string, ISyncable];

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly settings: GlobalSettingService,
    private readonly timeService: TimeService,
    private readonly controller: ControllerService,

    private readonly mitarbeiterService: MitarbeiterService,
    private readonly session: UserSessionService,

    private readonly auswertungsService: AuswertungService,
    private readonly saldenService: SaldenService,

    private readonly buchung_TempService: BuchungTempService,
    private readonly buchungService: StempelungService,

    private readonly AuftraegeService: AuftraegeService,
    private readonly taetigkeitsService: TaetigkeitService,
    private readonly kundenService: KundenService,
    private readonly abwesenheitsGrundService: FehlzeitenService,
    private readonly antraegeService: AntraegeService,
    private readonly auBescheinigungenService: AUBescheinigungService
  ) {}

  private syncSubject = new Subject<string>();
  readonly syncEvents$ = this.syncSubject.asObservable();

  public syncHandler$ = this.syncEvents$.pipe(
    throttleTime(30000),
    exhaustMap(async (ev: string) => {
      if (ev == 'onFocus' || ev == 'init') {
        const steps = await this.getAutoSyncSteps();
        await this.sync(steps);
      } else if (ev != 'offline') {
        await this.syncData();
      } else if (ev == 'offline') {
        await this.offlineSync();
      }
    })
  );

  readonly syncOnStart = this.settings.createSetting<boolean>('syncOnStart', true);

  readonly syncAuftragVorBuchung = this.settings.createSetting<boolean>('syncAuftragVorBuchung', true);

  readonly auftragSyncLimitDate = this.settings.createSetting<Date>('auftragSyncLimit', null);

  readonly syncPersonalVorBuchung = this.settings.createSetting<boolean>('syncPersonalVorBuchung', false);

  readonly syncFehlzeitVorBuchung = this.settings.createSetting<boolean>('syncFehlzeitVorBuchung', false);

  readonly querySyncAfterLogin = this.settings.createSetting<boolean>('querySyncAfterLogin', true);

  readonly queryUserBeforeSync = this.settings.createSetting<boolean>('querySync', true);

  /**
   * @description Synchronisiert alles
   * */
  async syncData(manually?: boolean): Promise<void> {
    if (manually && !navigator.onLine) {
      await this.dialogService.ShowInformation(
        'Synchronisation',
        'Für eine Synchronisation wird eine Internetverbindung benötigt. '
      );
      return;
    }

    const steps: SyncStep[] = [
      this.employeesSync,
      this.bookingsSync,
      this.bookingFramesSync,
      this.settingsSync,
      this.antraegeSync,
      this.auftraegeSync,
      this.taetigkeitenSync,
      this.customersSync,
      this.auswertungSync,
      this.fehlzeitenSync,
      this.auBescheinigungsSync,
    ];

    await this.sync(steps);
  }

  async getAutoSyncSteps(): Promise<SyncStep[]> {
    const steps: SyncStep[] = [
      this.employeesSync,
      this.bookingsSync,
      this.bookingFramesSync,
      this.settingsSync,
      this.antraegeSync,
      this.auswertungSync,
      this.fehlzeitenSync,
      this.auBescheinigungsSync,
    ];
    return steps;
  }

  async getEmployeeSyncSteps(): Promise<SyncStep[]> {
    const steps: SyncStep[] = [this.employeesSync];
    return steps;
  }

  async getAuftragsSyncSteps(): Promise<SyncStep[]> {
    const steps: SyncStep[] = [this.customersSync, this.auftraegeSync, this.taetigkeitenSync];
    return steps;
  }

  async getSaldenSyncSteps(): Promise<SyncStep[]> {
    const steps: SyncStep[] = [this.saldenSync];
    return steps;
  }

  async getBookingSyncSteps(): Promise<SyncStep[]> {
    const steps: SyncStep[] = [
      // this.bookingsSync,
      this.bookingFramesSync,
      this.auswertungSync,
    ];
    return steps;
  }

  async syncBookings(): Promise<'ok' | 'sent' | 'offline'> {
    let steps = [this.bookingsSync];

    if (!(await this.sync(steps))) return 'offline';

    steps = await this.getBookingSyncSteps();
    if (!(await this.sync(steps))) return 'sent';

    return 'ok';
  }

  async sync(steps: SyncStep[]): Promise<boolean> {
    if (!navigator.onLine) return;

    const [progress$, description$, updateDialog] = this.initProgressCounter(
      steps.length + 1,
      'Synchronisation wird durchgeführt.'
    );

    let syncronizedStores: string[] = [];

    const dialogCloseCallback = this.dialogService.ShowSpinner('Synchronisation', description$, progress$);
    try {
      try {
        for (const [name, syncable] of steps) {
          updateDialog(name);
          syncronizedStores = syncronizedStores.concat(await syncable.Sync());
        }
        this.controller.reportSync(syncronizedStores);
        return true;
      } finally {
        dialogCloseCallback();
      }
    } catch (err) {
      console.error(err);
      await this.dialogService.ShowError(err);
    }
    return false;
  }

  async offlineSync() {
    if (navigator.onLine) {
      const confirmation = await this.dialogService.QueryYesNo(
        'Synchronisation',
        'Möchten Sie jetzt ihre Offline-Daten synchronisieren?'
      );
      if (confirmation) {
        await this.syncData();
      }
    }
    await this.querySyncAfterLogin.set(false);
  }

  public triggerSync(event: string) {
    this.syncSubject.next(event);
  }

  initProgressCounter(
    steps: number,
    description: string
  ): [Observable<number>, Observable<string>, (desc: string) => void] {
    const descriptionSubject = new BehaviorSubject<string>(description);
    const progressSubject = new BehaviorSubject<number>(0);

    const updateDialog = (storename: string) => {
      descriptionSubject.next('Synchronisiere ' + storename);
      progressSubject.next(progressSubject.value + 100 / (steps + 1));
    };

    return [progressSubject.asObservable(), descriptionSubject.asObservable(), updateDialog];
  }

  //#region SyncSteps

  readonly employeesSync: SyncStep = ['Mitarbeiter', this.mitarbeiterService];
  readonly antraegeSync: SyncStep = ['Anträge', this.antraegeService];
  readonly customersSync: SyncStep = ['Kunden', this.kundenService];
  readonly auftraegeSync: SyncStep = ['Aufträge', this.AuftraegeService];
  readonly taetigkeitenSync: SyncStep = ['Tätigkeiten', this.taetigkeitsService];
  readonly bookingsSync: SyncStep = ['Buchungsliste', this.buchung_TempService];
  readonly bookingFramesSync: SyncStep = ['Zeitdaten', this.buchungService];
  readonly auswertungSync: SyncStep = ['Auswertung', this.auswertungsService];
  readonly fehlzeitenSync: SyncStep = ['Fehlzeitdefinitionen', this.abwesenheitsGrundService];
  readonly saldenSync: SyncStep = ['Salden', this.saldenService];
  readonly settingsSync: SyncStep = ['Einstellungen', this.abwesenheitsGrundService];

  readonly auBescheinigungsSync: SyncStep = ['AU-Bescheinigungen', this.auBescheinigungenService];

  //#endregion
}
