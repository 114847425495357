import { StringEnumClass, SmartEnum } from '@app/utils/types';
import { isNullOrUndefined } from '@app/utils/utils';
import * as moment from 'moment';
import { IndexedDBTypes } from './dbType';

//#region AntragsStatus

export type AntragsStatus = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const ReadableAntragsStatus = StringEnumClass(
  'offen',
  'in Bearbeitung',
  'genehmigt',
  'nicht genehmigt',
  'erledigt',
  'Stornierung gewünscht',
  'storniert'
)((valueSet, baseClass) => ({
  ...baseClass,

  toAntragsStatus: (readable: typeof baseClass.type): AntragsStatus => {
    const result = (baseClass.values as Array<string>).indexOf(readable);
    if (result < 0 || result > 6) throw new TypeError(`"${readable}" not in ReadableAntragsStatus`);
    return result as AntragsStatus;
  },

  fromAntragsStatus: (status: AntragsStatus): typeof baseClass.type => {
    return baseClass.values[status];
  },
}));
export type ReadableAntragsStatus = typeof ReadableAntragsStatus.type;

//#endregion

//#region AntragsArt

export const AntragsArt = SmartEnum('Z', 'G', 'H', 'O', 'S', 'K', 'R', 'D', '-1');
export type AntragsArt = typeof AntragsArt.type;

export const ReadableAntragsArt = StringEnumClass(
  'Urlaub',
  'Urlaub halber Tag',
  'Krankheit',
  'Zeitausgleich',
  'Schule',
  'Sonderurlaub',
  'Dienstgang',
  'Sonstiges',
  'Zeitkorrektur'
)((valueSet, baseClass) => ({
  ...baseClass,
  toAntragsArt: (description: typeof baseClass.type): AntragsArt => {
    switch (description) {
      case 'Zeitausgleich':
        return 'Z';
      case 'Urlaub':
        return 'G';
      case 'Urlaub halber Tag':
        return 'H';
      case 'Sonderurlaub':
        return 'O';
      case 'Schule':
        return 'S';
      case 'Krankheit':
        return 'K';
      case 'Zeitkorrektur':
        return 'R';
      case 'Dienstgang':
        return 'D';
      case 'Sonstiges':
        return '-1';
      default:
        throw new TypeError(`"${description}" not in 'ReadableAntragsArt'`);
    }
  },

  fromAntragsArt: (art: AntragsArt): typeof baseClass.type => {
    if (art.toString() == '' || art == null) art = '-1';
    switch (art) {
      case 'Z':
        return 'Zeitausgleich';
      case 'G':
        return 'Urlaub';
      case 'H':
        return 'Urlaub halber Tag';
      case 'O':
        return 'Sonderurlaub';
      case 'S':
        return 'Schule';
      case 'K':
        return 'Krankheit';
      case 'R':
        return 'Zeitkorrektur';
      case 'D':
        return 'Dienstgang';
      case '-1':
        return 'Sonstiges';
      default:
        throw new TypeError(`"${art}" not in 'AntragsArt'`);
    }
  },
}));
export type ReadableAntragsArt = typeof ReadableAntragsArt.type;

//#endregion

const antragsDayFormat = 'DD.MM.YYYY hh:mm:ss';
const dayFormat = 'DD.MM.YYYY';

export class Antrag extends IndexedDBTypes.ChangetrackedDBType {
  public static StoreName = 'Antrag';

  @IndexedDBTypes.KlassenName('Antrag') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private Guid: string;
  @IndexedDBTypes.IndexField('string') private Pnr: string;
  /** Numbercode of status of an antrag */
  @IndexedDBTypes.DataField('number') private Status: AntragsStatus;
  /** Shortsign of an antrag */
  @IndexedDBTypes.DataField('string') Art: AntragsArt;
  @IndexedDBTypes.DataField('string') Text: string;
  @IndexedDBTypes.DataField('string') private Info: string;
  @IndexedDBTypes.DataField('string') private Index: string;
  @IndexedDBTypes.DataField('string') private Von: string;
  @IndexedDBTypes.DataField('string') private Bis: string;
  @IndexedDBTypes.DataField('string') private AntragTag: string;
  @IndexedDBTypes.DataField('string') _ArtReadable: ReadableAntragsArt;
  @IndexedDBTypes.DataField('string') StatusReadable: ReadableAntragsStatus;

  @IndexedDBTypes.IndexField('string') private changed: boolean;

  @IndexedDBTypes.DataField('Date') private _vonDate: Date;
  public set vonDate(newDate: Date) {
    this._vonDate = newDate;
    this.Von = moment(newDate).format(dayFormat) + ' 00:00:00';
  }
  public get vonDate(): Date {
    return this._vonDate;
  }

  @IndexedDBTypes.DataField('Date') private _bisDate: Date;
  public set bisDate(newDate: Date) {
    this._bisDate = newDate;
    this.Bis = moment(newDate).format(dayFormat) + ' 00:00:00';
  }
  public get bisDate(): Date {
    return this._bisDate;
  }

  constructor(data: Object) {
    super();
    this.changed = false;
    Object.assign(this, data);
    this.ArtReadable = ReadableAntragsArt.fromAntragsArt(this.Art);
    this.StatusReadable = ReadableAntragsStatus.fromAntragsStatus(this.Status);
    this.setTodayAsStartIfVonBisUndefinied();
    this.setDates(this.getVon(), this.getBis());
  }

  toDTO(): object {
    const result = { ...this };
    delete result.changed;
    delete result._vonDate;
    delete result._bisDate;
    delete result.ArtReadable;
    delete result._ArtReadable;
    delete result.StatusReadable;
    return result;
  }

  wasChanged(): boolean {
    return this.changed;
  }

  private setTodayAsStartIfVonBisUndefinied() {
    const datesNotSet = isNullOrUndefined(this.Von) && isNullOrUndefined(this.Bis);
    if (!datesNotSet) return;

    const today = new Date();
    this.vonDate = today;
    this.bisDate = today;
  }

  /**@description Sets the dates based on existing date information for better handling  */
  private setDates(von: string, bis: string) {
    const vonDate = moment(von, antragsDayFormat).toDate();
    const bisDate = moment(bis, antragsDayFormat).toDate();
    this.vonDate = vonDate;
    this.bisDate = bisDate;
  }

  public setChanged(changed: boolean) {
    this.changed = changed;
  }

  public setStatus(status: AntragsStatus) {
    this.Status = status;
  }

  public getGUID() {
    return this.Guid;
  }

  public getPNR() {
    return this.Pnr;
  }

  public getStatus(): AntragsStatus {
    return this.Status;
  }

  public set ArtReadable(newArt: ReadableAntragsArt) {
    this._ArtReadable = newArt;
    this.Art = ReadableAntragsArt.toAntragsArt(newArt);
  }
  public get ArtReadable(): ReadableAntragsArt {
    return this._ArtReadable;
  }

  public getInfo() {
    return this.Info;
  }

  public getIndex() {
    return this.Index;
  }

  public getVon() {
    return this.Von;
  }

  public getBis() {
    return this.Bis;
  }

  public getAntragTag() {
    return this.AntragTag;
  }

  public getVonDateString(): string {
    const vonDateString = this.getVon();
    const momentVon = moment(vonDateString, antragsDayFormat).format('DD.MM.YYYY');
    return momentVon;
  }

  public getBisDateString(): string {
    const bisDateString = this.getBis();
    const momentBis = moment(bisDateString, antragsDayFormat).format('DD.MM.YYYY');
    return momentBis;
  }

  static toString(): string {
    return 'Antrag';
  }
}
