import { BookingType } from '@app/utils/types';
import * as moment from 'moment';
import { IndexedDBTypes } from './dbType';

export interface TempBuchung {
  Pnr: string;
  /** Format YYYYMMDD */
  Tag: string;
  Stunden: number;
  Minuten: number;
  Sekunden: number;
  /** 0 Kommen, 1 Gehen */
  Art: string;
  Kennzeichen: string;
  Auftrag: string;
  Kunde: string;
  Taetigkeit: string;
  TaetigkeitBez?: string;
  Taetigkeitspaket: string;
  Grund: string;
  Kommentar1: string;
  Kommentar2: string;
  Kommentar3: string;
  Longitude: number;
  Latitude: number;
  Radius: number;
}

/**@description Extracts a comparable date information from a buchung */
export function tempBuchungToDate(tempBuchung: TempBuchung): Date {
  const tag = tempBuchung.Tag;
  let stunden = tempBuchung.Stunden.toString();
  stunden = stunden.length === 1 ? '0' + stunden : stunden;
  let minuten = tempBuchung.Minuten.toString();
  minuten = minuten.length === 1 ? '0' + minuten : minuten;
  let sekunden = tempBuchung.Sekunden.toString();
  sekunden = sekunden.length === 1 ? '0' + sekunden : sekunden;
  const dateFromBuchung = moment(tag + stunden + ':' + minuten + ':' + sekunden, 'YYYYMMDD hh:mm:ss').toDate();
  return dateFromBuchung;
}

export class BookingTime extends IndexedDBTypes.ChangetrackedDBType implements TempBuchung {
  public static StoreName = 'BookingTime';

  @IndexedDBTypes.KlassenName('BookingTime') private KlassenName: string;
  @IndexedDBTypes.IndexField('string') Pnr: string;
  @IndexedDBTypes.IndexField('string') Tag: string;
  @IndexedDBTypes.DataField('string') Stunden: number;
  @IndexedDBTypes.DataField('string') Minuten: number;
  @IndexedDBTypes.DataField('string') Sekunden: number;
  @IndexedDBTypes.DataField('string') Art: string;
  @IndexedDBTypes.DataField('string') Kennzeichen: string;
  @IndexedDBTypes.DataField('string') Auftrag: string;
  @IndexedDBTypes.DataField('string') Kunde: string;
  @IndexedDBTypes.DataField('string') Taetigkeit: string;
  @IndexedDBTypes.DataField('string') Taetigkeitspaket: string;
  @IndexedDBTypes.DataField('string') Grund: string;
  @IndexedDBTypes.DataField('string') Kommentar1: string;
  @IndexedDBTypes.DataField('string') Kommentar2: string;
  @IndexedDBTypes.DataField('string') Kommentar3: string;
  @IndexedDBTypes.DataField('string') Longitude: number;
  @IndexedDBTypes.DataField('string') Latitude: number;
  @IndexedDBTypes.DataField('string') Radius: number;

  @IndexedDBTypes.KeyDBField('string') AutoKey: string;

  getIndex(): string {
    return this.AutoKey;
  }

  wasChanged(): boolean {
    return true;
  }

  toDTO(): object {
    const tagAsDate = this.getTagAsDate();
    const dateString = moment(tagAsDate).format('DD.MM.YYYY 00:00:00');
    const result: BookingTime = { ...this, Tag: dateString };
    delete result.AutoKey;
    return result;
  }

  getDaytimeMS(): number {
    return this.Sekunden * 1000 + this.Minuten * 60 * 1000 + this.Stunden * 60 * 60 * 1000;
  }

  toDate(): Date {
    const result = moment(this.getTag(), 'YYYYMMDD');
    const hh = this.getStunden();
    const mm = this.getMinuten();
    const ss = this.getSekunden();
    return result.hours(hh).minutes(mm).seconds(ss).toDate();
  }

  static DateAsDatestring(date: Date): string {
    return moment(date).format('YYYYMMDD');
  }

  /**@description Returns if Minutecounter has changed since Booking*/
  minuteHasChanged(): boolean {
    if (!this.getTag()) {
      return true;
    }

    const buchungsZeit = this.toDate();
    const now = new Date();

    return (
      now.valueOf() > buchungsZeit.valueOf() &&
      (now.valueOf() - buchungsZeit.valueOf() > 60 * 1000 || buchungsZeit.getMinutes() < now.getMinutes())
    );
  }

  getTagAsDate(): Date {
    return moment(this.getTag(), 'YYYYMMDD').toDate();
  }

  setTag(Tag: string | Date): void {
    if (typeof Tag === 'string') this.Tag = Tag;
    else this.Tag = moment(Tag).format('YYYYMMDD');
  }

  getBookingType(): BookingType {
    if (this.Art === '1') return BookingType.GEHEN;
    if (!!this.Kennzeichen) return BookingType.FEHLZEIT;
    if (!!this.Auftrag || !!this.Kennzeichen) return BookingType.AUFTRAGSZEIT;
    if (this.Art === '0') return BookingType.ARBEITSZEIT;
    throw new Error('Unknown Bookingtype');
  }

  //#region Get/Set

  getTag(): string {
    return this.Tag;
  }

  getPnr(): string {
    return this.Pnr;
  }

  setPnr(Pnr: string): void {
    this.Pnr = Pnr;
  }

  getStunden(): number {
    return this.Stunden;
  }

  setStunden(Stunden: number): void {
    this.Stunden = Stunden;
  }

  getMinuten(): number {
    return this.Minuten;
  }

  setMinuten(Minuten: number): void {
    this.Minuten = Minuten;
  }

  getSekunden(): number {
    return this.Sekunden;
  }

  setSekunden(Sekunden: number): void {
    this.Sekunden = Sekunden;
  }

  getArt(): string {
    return this.Art;
  }

  setArt(Art: string): void {
    this.Art = Art;
  }

  getKennzeichen(): string {
    return this.Kennzeichen;
  }

  setKennzeichen(Kennzeichen: string): void {
    this.Kennzeichen = Kennzeichen;
  }

  getAuftrag(): string {
    return this.Auftrag;
  }

  setAuftrag(Auftrag: string): void {
    this.Auftrag = Auftrag;
  }

  getKunde(): string {
    return this.Kunde;
  }

  setKunde(Kunde: string): void {
    this.Kunde = Kunde;
  }

  getTaetigkeit(): string {
    return this.Taetigkeit;
  }

  setTaetigkeit(Taetigkeit: string): void {
    this.Taetigkeit = Taetigkeit;
  }

  getTaetigkeitspaket(): string {
    return this.Taetigkeitspaket;
  }

  setTaetigkeitspaket(Taetigkeitspaket: string): void {
    this.Taetigkeitspaket = Taetigkeitspaket;
  }

  getGrund(): string {
    return this.Grund;
  }

  setGrund(Grund: string): void {
    this.Grund = Grund;
  }

  getKommentar(): string {
    const comment1 = this.Kommentar1 || '';
    const comment2 = this.Kommentar2 || '';
    const comment3 = this.Kommentar3 || '';
    const comment = comment1 + comment2 + comment3;
    return comment;
  }

  getLongitude(): number {
    return this.Longitude;
  }

  setLongitude(Longitude: number): void {
    this.Longitude = Longitude;
  }

  getLatitude(): number {
    return this.Latitude;
  }

  setLatitude(Latitude: number): void {
    this.Latitude = Latitude;
  }

  getRadius(): number {
    return this.Radius;
  }

  setRadius(Radius: number): void {
    this.Radius = Radius;
  }

  //#endregion

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  static toString(): string {
    return 'BookingTime';
  }
}
