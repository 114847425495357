import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { ZEFBackendService } from './webservice-connection-services/zef-backend.service';
import { GlobalSettingService } from './settings/global-setting.service';
import { distinctUntilChanged, tap, switchMap, catchError, shareReplay, map, first } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { Employee } from '@entities/Employee';
import { UserSessionService } from './user-session-state.service';
import { taplog, getfirst } from '../utils/rxjsUtils';
import { MitarbeiterService } from '@pages/TabGroup/shared/ZEFMitarbeiter.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeLoginService {
  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly settingsService: GlobalSettingService,
    private readonly backendService: ZEFBackendService,
    private readonly userSession: UserSessionService,
    private readonly employeeService: MitarbeiterService
  ) {}

  async login(pnr: string, pw: string): Promise<boolean> {
    const callback = this.dialogService.ShowSpinner('Verbindungsaufbau...');
    let success = false;
    let response;
    try {
      response = await getfirst(this.backendService.login(pnr, pw));
      success = response && response.StatusCode === 1;
      await this.userSession.loggedIn.set(success);
      await this.userSession.setLoginDetails(pnr, pw);

      const storenames = await this.employeeService.Sync(true);
      success = success && storenames.length > 0;
    } finally {
      callback();
    }
    if (!success) return false;
    const name = response.Response.LoginDetails.Anzeige;
    await this.dialogService.ShowInformation('Erfolgreich eingeloggt.', `Willkommen, ${name}!`, 'Hallo!');
    return true;
  }

  async logout(force?: boolean) {
    if (!force) {
      if (!(await this.queryLogoutConfirmation())) return;
    }

    if (!(await this.serverLogout()) && !(await this.queryLogoutOnlyOffline())) return;

    try {
      const pnr = (await this.userSession.loginDetails.get())?.id;
      await this.userSession.lastUser.set(pnr);
      await this.userSession.setLoginDetails(null, null);
      await this.userSession.loggedIn.set(false);
    } finally {
      if (!force) {
        await this.dialogService.ShowInformation('Erfolgreich ausgeloggt.', `Auf Wiedersehen!`, 'Ok');
      }
      await this.router.navigate(['/login']);
    }
  }

  async queryLogoutConfirmation(): Promise<boolean> {
    return !(await this.dialogService.QueryConfirmation('Bestätigung', 'Benutzer abmelden?', 'Bleiben', 'Abmelden'));
  }

  async serverLogout(): Promise<boolean> {
    const promise = this.backendService
      .post<any>('Logout')
      .pipe(
        map(response => response.Success as boolean),
        this.dialogService.HandleDefaultErrors()
      )
      .toPromise();

    return await this.dialogService.ShowSpinnerWhile(promise, 'Serverlogout', 'Ressourcen werden freigegeben.');
  }

  async queryLogoutOnlyOffline(): Promise<boolean> {
    return !(await this.dialogService.QueryConfirmation(
      'Verbindung fehlgeschlagen!',
      'Belegte Serverressourcen konnten nicht freigegeben werden. Trotzdem ausloggen?',
      'Nein',
      'Ja'
    ));
  }
}
