import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//#region Angular Material
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatRippleModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
  MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

//#endregion
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { environment } from 'src/environments/environment';
import { GermanDateAdapter } from './GermanDateAdapter';
import { ControllerService } from './globalServices/controller.service';
import { AppRoutingModule } from './app-routing.module';

import { BSSIconComponent } from '@components/reuseable/bss-icon.component';

import { AuftragSelectorComponent } from '@components/reuseable/form-elements/selectors/auftrag-selector/auftrag-selector.component';
import { TaetigkeitSelectorComponent } from '@components/reuseable/form-elements/selectors/taetigkeit-selector/taetigkeit-selector.component';
import { MitarbeiterSelectorComponent } from '@components/reuseable/form-elements/selectors/mitarbeiter-selector/mitarbeiter-selector.component';
import { AbwesenheitsGrundSelectorComponent } from '@components/reuseable/form-elements/selectors/abwesenheitsgrund-selector/abwesenheitsgrund-selector.component';
import { KundenSelectorComponent } from '@components/reuseable/form-elements/selectors/kunden-selector/kunden-selector.component';
import { ToolbarComponent } from '@global-components/toolbar/toolbar.component';
import { BottomSheetFrameComponent } from '@components/reuseable/bottom-sheet-frame/bottom-sheet-frame.component';
import { QrcodeComponent } from '@global-components/qrcode/qrcode.component';
import { GenericDialogComponent } from '@components/global/dialog/dialog.component';
import { KartenComponent } from '@components/reuseable/karten/karten.component';

import { TermConverterService } from './globalServices/termConverter.service';

import { AppinfoComponent } from '@pages/appinfo/appinfo.component';
import { ImpressumComponent } from '@pages/impressum/impressum.component';
import { EinstellungenComponent } from '@pages/einstellungen/einstellungen.component';
import { MenuComponent } from '@pages/menu/menu.component';
import { LoginComponent } from '@pages/login/login.component';

import { TabMenuComponent } from '@pages/TabGroup/routed/tabMenu/tabMenu.component';

import { BuchungComponent } from '@pages/TabGroup/presentation/buchung/buchung.component';
import { AntragBuchenComponent } from '@pages/TabGroup/presentation/bottomsheet/antrag_buchen/antrag-buchen.component';
import { KommenErfassenComponent } from '@pages/TabGroup/presentation/bottomsheet/kommen-erfassen/kommen-erfassen.component';
import { GehenErfassenComponent } from '@pages/TabGroup/presentation/bottomsheet/gehen-erfassen/gehen-erfassen.component';
import { AuftragStartErfassenComponent } from '@pages/TabGroup/presentation/bottomsheet/auftrag-start-erfassen/auftrag-start-erfassen.component';
import { AuftragEndeErfassenComponent } from '@pages/TabGroup/presentation/bottomsheet/auftrag-ende-erfassen/auftrag-ende-erfassen.component';
import { AuftragMenuComponent } from '@pages/TabGroup/presentation/bottomsheet/auftrag-menu/auftrag-menu.component';
import { FehlzeitErfassenComponent } from '@pages/TabGroup/presentation/bottomsheet/fehlzeit-erfassen/fehlzeit-erfassen.component';
import { AuBescheinigungDatenErfassenComponent } from './pages/TabGroup/presentation/au-bescheinigung/au-bescheinigung-daten-erfassen/au-bescheinigung-daten-erfassen.component';
import { AuBescheinigungBildErfassenComponent } from './pages/TabGroup/presentation/au-bescheinigung/au-bescheinigung-bild-erfassen/au-bescheinigung-bild-erfassen.component';

import { SaldenComponent } from '@pages/TabGroup/presentation/salden/salden.component';
import { TeamComponent } from '@pages/TabGroup/presentation/team/team.component';
import { UebersichtComponent } from '@pages/TabGroup/presentation/uebersicht/uebersicht.component';
import { AntraegeComponent } from '@pages/TabGroup/presentation/antraege/antraege.component';
import { JournalComponent } from '@pages/TabGroup/presentation/buchung/journal/journal.component';
import { LetzteErfassungComponent } from '@pages/TabGroup/presentation/buchung/letzte-erfassung/letzte-erfassung.component';
import { GridTileComponent } from '@pages/TabGroup/presentation/buchung/buchungen-tile-grid/grid-tile/grid-tile.component';
import { CommentFieldComponent } from './components/reuseable/form-elements/comment-field/comment-field.component';
import { HttpClientWrapperProvider } from './globalServices/webservice-connection-services/httpclient-wrapper.service';
import { SpinnerComponent } from './components/reuseable/spinner/spinner.component';
import { BottomSheetButtonComponent } from './components/reuseable/bottom-sheet-button/bottom-sheet-button.component';
import { TeamSelectorTabsComponent } from './components/reuseable/team-selector-tabs/team-selector-tabs.component';
import { DateSelectorBandComponent } from './components/reuseable/form-elements/selectors/date-selector-band/date-selector-band.component';
import { AutocompleteInputComponent } from './components/base/form-components/autocomplete-input/autocomplete-input.component';
import { EntityAutocompleteInputComponent } from './components/reuseable/form-elements/selectors/EntityAutocompleteInputComponent';
import { TimepickerComponent } from './components/reuseable/timepicker/timepicker.component';
import { ManuellErfassenComponent } from './pages/TabGroup/presentation/bottomsheet/manuell-erfassen/manuell-erfassen.component';
import { TimePickerFieldComponent } from './components/reuseable/form-elements/time-picker-field/time-picker-field.component';
import { EditierenErfassenComponent } from './pages/TabGroup/presentation/bottomsheet/editieren-erfassen/editieren-erfassen.component';
import { BackgroundNoticeComponent } from './components/reuseable/background-notice/background-notice.component';
import { SaldenDetailsComponent } from './pages/TabGroup/presentation/salden/salden-details/salden-details.component';
import { AuBescheinigungComponent } from './pages/TabGroup/presentation/au-bescheinigung/au-bescheinigung.component';
import { TkennzeichenComponent } from './pages/TabGroup/presentation/tkennzeichen/tkennzeichen.component';
import { MonthPickerComponent } from './components/reuseable/monthpicker/monthpicker.component';
import { TKennzeichenBuchenComponent } from './pages/TabGroup/presentation/tkennzeichen/tkennzeichen-buchen/tkennzeichen-buchen.component';
import { StatusSelectorComponent } from '@components/reuseable/form-elements/selectors/status-selector/status-selector.component';

@NgModule({
  declarations: [
    AppComponent,
    BSSIconComponent,
    BuchungComponent,
    SaldenComponent,
    TeamComponent,
    UebersichtComponent,
    MenuComponent,
    AntraegeComponent,
    ToolbarComponent,
    MenuComponent,
    LoginComponent,
    QrcodeComponent,
    GenericDialogComponent,
    AppinfoComponent,
    ImpressumComponent,
    TabMenuComponent,
    EinstellungenComponent,
    JournalComponent,
    KartenComponent,
    AntragBuchenComponent,
    AuftragSelectorComponent,
    TaetigkeitSelectorComponent,
    MitarbeiterSelectorComponent,
    AbwesenheitsGrundSelectorComponent,
    KundenSelectorComponent,
    KommenErfassenComponent,
    GehenErfassenComponent,
    AuftragStartErfassenComponent,
    AuftragEndeErfassenComponent,
    AuftragMenuComponent,
    FehlzeitErfassenComponent,
    BottomSheetFrameComponent,
    LetzteErfassungComponent,
    AuBescheinigungDatenErfassenComponent,
    AuBescheinigungBildErfassenComponent,
    GridTileComponent,
    CommentFieldComponent,
    SpinnerComponent,
    BottomSheetButtonComponent,
    TeamSelectorTabsComponent,
    DateSelectorBandComponent,
    AutocompleteInputComponent,
    TimepickerComponent,
    MonthPickerComponent,
    ManuellErfassenComponent,
    TimePickerFieldComponent,
    EditierenErfassenComponent,
    BackgroundNoticeComponent,
    SaldenDetailsComponent,
    AuBescheinigungComponent,
    TkennzeichenComponent,
    TKennzeichenBuchenComponent,
    StatusSelectorComponent,
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    HttpClientModule,
    AngularSvgIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatRippleModule,
    MatMenuModule,
    MatTabsModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatGridListModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatStepperModule,
    MatDividerModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    ZXingScannerModule,
  ],
  providers: [
    ControllerService,
    TermConverterService,
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        ...MAT_NATIVE_DATE_FORMATS,
        display: {
          ...MAT_NATIVE_DATE_FORMATS.display,
          dateInput: { day: '2-digit', month: '2-digit', year: 'numeric' },
        },
      },
    },
    { provide: DateAdapter, useClass: GermanDateAdapter },
    HttpClientWrapperProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
