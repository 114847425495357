import { Injectable } from '@angular/core';

import { Observable, combineLatest, from } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { BaseError } from '@app/utils/ErrorHandling/Error';
import { GlobalSettingService } from '../settings/global-setting.service';
import { IConnectionInfo } from './Allocation';
import { AllocationManagerService } from './allocation-manager.service';
import { HttpRequest } from './httpclient-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionInfoCacheService {
  constructor(
    private readonly settings: GlobalSettingService,
    private readonly allocManager: AllocationManagerService
  ) {}

  readonly connectionInfoSetting = this.settings.createSetting<IConnectionInfo>('connectionInfo');

  readonly connectionInfo$ = this.connectionInfoSetting.value$;

  applyConnection(request: HttpRequest): Observable<[HttpRequest, IConnectionInfo]> {
    return this.connectionInfo$.pipe(
      first(),
      map(connectionInfo => {
        if (connectionInfo && connectionInfo.allocation) {
          const headers = {
            ...request.reqInit.headers,
            port: '' + connectionInfo.allocation.port,
          };
          const reqInit = { ...request.reqInit, headers };
          const targetPort = connectionInfo.allocation.hostPort ?? '3000';
          return [
            {
              reqInit,
              url: `https://${connectionInfo.allocation.hostname}:${targetPort}/Service.svc/${request.url}`,
            },
            connectionInfo,
          ];
        }

        if (connectionInfo && connectionInfo.directConnection) {
          return [
            {
              reqInit: request.reqInit,
              url: `https://${connectionInfo.directConnection.ip}:${connectionInfo.directConnection.port}/Service.svc/${request.url}`,
            },
            connectionInfo,
          ];
        }
        throw new BaseError('Missing connectionInfo');
      })
    );
  }

  async setConnectionInfo(connection: IConnectionInfo): Promise<void> {
    await this.connectionInfoSetting.set(connection);
    if (connection && connection.directConnection) await this.allocManager.allocationGuid.set(undefined);
  }

  refreshAllocation(): Observable<boolean> {
    const parameters = combineLatest([this.connectionInfo$, this.allocManager.allocationGuid.value$]).pipe(first());

    return parameters.pipe(
      switchMap(([, guid]) =>
        this.allocManager
          .brokerRequest(guid)
          .pipe(switchMap(allocation => from(this.setConnectionInfo({ allocation })).pipe(map(_ => !!allocation))))
      )
    );
  }
}
