<div id="salden-detail-card" class="detail-card">
  <a class="detail-card-text">Stand bis einschließlich {{ SaldenData.VorMonat }}</a>
  <a class="detail-card-value">{{ SaldenData.ZeitkontoDetailVormonat }}</a> <br /><br />
  <a class="detail-card-text">{{ SaldenData.Monat }}</a>
  <a class="detail-card-value"
    >{{ SaldenData.ZeitkontoDetailAktuell }} von {{ SaldenData.ZeitkontoDetailSollAktuell }}</a
  ><br /><br />
  <a class="detail-card-text">{{ NochZuLeistenLabelText() }}</a>
  <a class="detail-card-value">{{ SaldenData.ZeitkontoDetailNochZuLeisten }}</a> <br /><br />
  <a class="detail-card-text">Geplant (Zeitausgleich)</a>
  <a class="detail-card-value">{{ SaldenData.ZeitkontoDetailGeplant }}</a> <br />
  <hr />
  <a class="detail-card-text">Stand</a>
  <a class="detail-card-value">{{ SaldenData.ZeitkontoDetailStand }}</a>
</div>
