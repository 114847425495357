import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, mergeAll, shareReplay, switchMap, tap } from 'rxjs/operators';
import { IndexedDBTypes } from '@entities/dbType';

export interface IFilter {
  idFilter: string;
}

export abstract class SelectorStateService<TClass extends IndexedDBTypes.IndexedDBType, TFilter extends IFilter> {
  private readonly filterSubject = new BehaviorSubject<TFilter>(null);
  private readonly sourceSubject = new BehaviorSubject<Observable<TClass[]>>(null);

  public state$ = combineLatest([
    this.sourceSubject.asObservable().pipe(
      filter(obs => !!obs),
      switchMap(a => a)
    ),
    this.filterSubject.asObservable(),
  ]).pipe(
    map(([list, filterObj]) => this.ApplyFilter(list, filterObj)),
    shareReplay(1)
  );

  public ApplyFilter(list: TClass[], filterObj: TFilter): TClass[] {
    if (filterObj === null || filterObj === undefined) {
      return list;
    }
    const filterList: ((entity: TClass) => boolean)[] = this.createFilterList(filterObj);
    return filterList ? list.filter(entity => !filterList.some(filterFunc => !filterFunc(entity))) : list;
  }

  protected abstract createFilterList(filter: TFilter): ((entity: TClass) => boolean)[];

  protected setFilterSubject(filter: TFilter) {
    this.filterSubject.next(filter);
  }

  protected setSource(src: Observable<TClass[]>) {
    this.sourceSubject.next(src);
  }

  constructor() {}
}
