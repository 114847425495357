import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { A2hsService } from 'src/app/globalServices/a2hs.service';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/generated_version';
import { TermConverterService } from 'src/app/globalServices/termConverter.service';
import { SyncService } from '@app/globalServices/sync.service';
import { EmployeeLoginService } from '@app/globalServices/employee-login.service';
import { UserSessionInfo, UserSessionService } from '@app/globalServices/user-session-state.service';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
  link: string;
  backgroundImage: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  closeMenuUrl = 'assets/icons/close.svg';
  tiles: Tile[];
  showMenu = false;
  isProd = environment.configurationname === 'prod';
  versionsnummer = this.isProd
    ? VERSION.packageVersion
    : `${VERSION.packageVersion} | ${VERSION.hash} | ${VERSION.BuildTimestamp}`;
  @Output() closeMenuEvent = new EventEmitter();
  showAddToHome: boolean;

  constructor(
    private readonly globalSettingService: GlobalSettingService,
    private readonly router: Router,
    private readonly a2hsService: A2hsService,
    private readonly syncService: SyncService,
    private readonly loginService: EmployeeLoginService,
    private readonly termConverterService: TermConverterService,
    private readonly session: UserSessionService,
    private readonly bottomSheet: MatBottomSheet
  ) {}

  async ngOnInit() {
    this.showAddToHome = this.getShowAddToHome();
    // Initialisierung des Arrays mit allen Navigationskomponenten im Menu
    const loggedIn = await this.session.loggedIn.get();
    this.tiles = [
      {
        text: 'App-Info',
        cols: 1,
        rows: 1,
        color: 'transparent',
        link: 'appinfo',
        backgroundImage: 'assets/icons/info.svg',
      },
      {
        text: 'Datenschutz',
        cols: 1,
        rows: 1,
        color: 'transparent',
        link: 'datenschutz',
        backgroundImage: 'assets/icons/security.svg',
      },
      {
        text: 'Impressum',
        cols: 1,
        rows: 1,
        color: 'transparent',
        link: 'impressum',
        backgroundImage: 'assets/icons/impressum.svg',
      },
      {
        text: 'FAQ',
        cols: 1,
        rows: 1,
        color: 'transparent',
        link: 'faq',
        backgroundImage: 'assets/icons/faq.svg',
      },
    ];

    if (loggedIn === true) {
      this.tiles = [
        {
          text: 'Synchronisieren',
          cols: 1,
          rows: 1,
          color: 'transparent',
          link: 'synchronisieren',
          backgroundImage: 'assets/icons/sync.svg',
        },
        ...this.tiles,
        {
          text: 'Einstellungen',
          cols: 1,
          rows: 1,
          color: 'transparent',
          link: 'einstellungen',
          backgroundImage: 'assets/icons/settings.svg',
        },
        {
          text: 'Abmelden',
          cols: 1,
          rows: 1,
          color: 'transparent',
          link: 'abmelden',
          backgroundImage: 'assets/icons/logout.svg',
        },
      ];
    }
    this.showMenu = true;
  }

  /**@description Bestimmt ob Add To Home Button angezeigt wird: Allgemein nicht bei Ios und nur wenn nicht im Standalone Modus */
  getShowAddToHome(): boolean {
    const isStandAlone = window.matchMedia('(display-mode: standalone)').matches;
    const ios = this.termConverterService.isUserAgentIOS();
    const derefPrompt = this.a2hsService.hasDeferredEvent();
    if (ios || isStandAlone) {
      return false;
    }
    if (derefPrompt) {
      return true;
    }
    return false;
  }

  async handleClick(link: string) {
    this.closeMenuEvent.emit();
    switch (link) {
      case 'abmelden':
        await this.loginService.logout();
        break;

      case 'datenschutz':
        window.open('https://bluesolution.de/datenschutzerklaerung/', '_blank');
        break;

      case 'appinfo':
        window.open('https://bluesolution.de/topzeit/progressive_web_app/', '_blank');
        break;

      case 'synchronisieren':
        void this.syncService.syncData(true);
        break;

      case 'faq':
        window.open('https://bluesolution.de/topzeit/progressive_web_app/', '_blank');
        break;

      case 'einstellungen':
        void this.router.navigate(['/einstellungen']);
        break;

      case 'impressum':
        void this.router.navigate(['/impressum']);
        break;
    }
  }

  async closeMenu() {
    this.closeMenuEvent.emit();
  }

  arrayRemove(arr, value) {
    return arr.filter(element => {
      return element.text !== value;
    });
  }

  async gotoInstallApp() {
    await this.a2hsService.triggerInstall();
  }
}
