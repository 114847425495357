import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Injectable()
export class DateSelectorBandStateService {
  constructor() {}

  private readonly subject = new BehaviorSubject<Date>(new Date());

  readonly selection$ = this.subject.pipe(
    distinctUntilChanged(),
    shareReplay(1)
    // taplog('dateselection changed')
  );

  getSelected(): Date {
    return this.subject.value;
  }

  select(date: Date) {
    this.subject.next(date);
  }

  addDay(count: number = 1) {
    const nextDay = new Date(this.subject.value.getTime() + count * (1000 * 60 * 60 * 24));
    this.select(nextDay);
  }
}

export const DateSelectorBandStateServiceProvider = {
  provide: DateSelectorBandStateService,
};
