import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TabMenuComponent } from '@pages/TabGroup/routed/tabMenu/tabMenu.component';

/**@description Verhindert den Browserback/Forward indem es den state null null pusht - lässt aber durch den return true angular eignenes routing durch */
@Injectable()
export class TabMenuCanDeactivateGuard implements CanDeactivate<TabMenuComponent> {
  constructor() {}

  canDeactivate(
    component: TabMenuComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    history.pushState(null, null, location.href);
    return true;
  }
}
