import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/generated_version';

import { ToolbarComponent } from '@global-components/toolbar/toolbar.component';

@Component({
  selector: 'app-appinfo',
  templateUrl: './appinfo.component.html',
  styleUrls: ['./appinfo.component.css'],
})
export class AppinfoComponent implements OnInit {
  version =
    environment.configurationname === 'prod'
      ? VERSION.packageVersion
      : `${VERSION.packageVersion} | ${VERSION.hash} | ${VERSION.BuildTimestamp}`;

  constructor(private router: Router, private toolbarComponent: ToolbarComponent) {}

  ngOnInit() {}

  clickedClose() {
    void this.router.navigate(['/']);
  }
}
