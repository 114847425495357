<div class="employeeDisplay">{{ employee$ | async }}</div>

<ng-container *ngIf="qry$ | async as model" [ngSwitch]="model.status">
  <div *ngSwitchDefault class="spinner-container">
    <app-spinner></app-spinner>
  </div>

  <app-background-notice
    *ngSwitchCase="'error'"
    [image]="'assets/icons/info_white.png'"
    [message]="model.error['name']"
    [submessage]="model.error['message']"
  ></app-background-notice>

  <div *ngSwitchCase="'success'" class="cardContainer">
    <mat-card class="urlaubsCard">
      <a class="mat-card-text headline">Urlaubskonto</a> <br /><br />
      <br />
      <a class="mat-card-text">Urlaubsanspruch Kalenderjahr</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.JahresUrlaub }}</a> <br /><br />
      <a class="mat-card-text">Resturlaub Vorjahr</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.UrlaubVorjahr }}</a> <br /><br />
      <a class="mat-card-text">Urlaub genommen</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.UrlaubGenommen }}</a> <br /><br />
      <a class="mat-card-text">Urlaub geplant</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.UrlaubGeplant }}</a> <br />
      <hr />
      <a class="mat-card-text">Urlaub verbleibend</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.UrlaubVerbleibend }}</a>
    </mat-card>

    <mat-card class="zeitCard">
      <a class="mat-card-text headline">Zeitkonto</a> <br /><br /><br />
      <a class="mat-card-text">Stand bis Gestern</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.ZeitkontoGestern }}</a> <br /><br />
      <a class="mat-card-text">Stand Heute</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.ZeitkontoHeute }}</a
      ><br />
      <hr />
      <a class="mat-card-text">Stand Gesamt</a>
      <a class="mat-card-saldoAuswertungsText">{{ model.data.ZeitkontoStand }}</a
      ><br /><br />

      <button
        mat-button
        (click)="clickDetail()"
        class="mat-card-text headline buttonDetail"
        [ngClass]="{ isClicked: showDetail }"
      >
        Detailansicht
      </button>

      <app-salden-details [SaldenData]="model.data" *ngIf="showDetail"></app-salden-details>
    </mat-card>
  </div>
</ng-container>
