import { ControllerService } from '../../../globalServices/controller.service';
import { Injectable, Injector } from '@angular/core';
import { Antrag } from '@entities/Antrag';
import { Subject, Observable, of } from 'rxjs';
import { DatabaseService } from '@app/globalServices/database.service';
import { map, switchMapTo, switchMap, first, tap } from 'rxjs/operators';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { taplog } from '@app/utils/rxjsUtils';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';

export interface AntragMail {
  AntragIndex: string;
  Receiver: string;
}

@Injectable({
  providedIn: 'root',
})
export class AntraegeService extends StoreControllerServiceBase<Antrag> {
  protected GetStoreName(): string {
    return Antrag.StoreName;
  }

  protected entityConstructor(row): Antrag {
    return new Antrag(row);
  }

  public async Sync(emit?: boolean): Promise<string[]> {
    await this.controllerservice
      .sendStoreData(Antrag.StoreName, 'SetAntraege', raw => new Antrag(raw), emit)
      .pipe(
        tap(res => {
          res.forEach(async index => {
            //Erfolg des Mailversands ist irrelevant #4792
            await this.sendAntragMail(index);
          });
        })
      )
      .toPromise();

    await this.session.pnr$
      .pipe(
        first(),
        switchMap(pnr => this.controllerservice.reloadStore(this.GetStoreName(), `GetAntraegeForPnr/PNR/${pnr}`, emit))
      )
      .toPromise();

    return [Antrag.StoreName];
  }

  constructor(
    private readonly session: UserSessionService,
    private readonly controllerservice: ControllerService,
    private readonly dbService: DatabaseService,
    protected readonly injector: Injector,
    private readonly backendService: ZEFBackendService
  ) {
    super(injector);
  }

  userAntraege$(pnr: string) {
    return this.filteredStoreData$(pnr, 'Pnr');
  }

  readonly currentUserAntraege$ = this.session.pnr$.pipe(switchMap(pnr => this.userAntraege$(pnr)));

  /**
   * Inserte bestimmten Antrag in die Tabelle ZEFAntrag
   */
  async setAntrag(antrag: Antrag, emitRefresh?: boolean) {
    await this.dbService.setData(Antrag.StoreName, [antrag], emitRefresh);
  }

  /**
   * Schicke geflagten Inhalt aus der Tabelle ZEFAntrag zum Webservice
   */
  async uploadAntraegeData() {
    await this.controllerservice
      .sendStoreData(this.GetStoreName(), 'SetAntraege', raw => new Antrag(raw), true)
      .toPromise();
  }

  /**@description Vergleichsfunktion für die Anträge, sortiert nach Datum */
  compareAntraege(a: Antrag, b: Antrag) {
    const aVon = a.getVon();
    const currentDate = aVon.split(' ');
    const tagMonatJahr = currentDate[0].split('.');
    const tag = parseInt(tagMonatJahr[0], 0);
    const monat = parseInt(tagMonatJahr[1], 0) - 1;
    const jahr = parseInt(tagMonatJahr[2], 0);
    const aDate = new Date(jahr, monat, tag);
    const bVon = b.getVon();
    const currentDateB = bVon.split(' ');
    const tagMonatJahrB = currentDateB[0].split('.');
    const tagB = parseInt(tagMonatJahrB[0], 0);
    const monatB = parseInt(tagMonatJahrB[1], 0) - 1;
    const jahrB = parseInt(tagMonatJahrB[2], 0);
    const bDate = new Date(jahrB, monatB, tagB);
    if (aDate > bDate) {
      return -1;
    }
    if (aDate < bDate) {
      return 1;
    }
    return 0;
  }

  async sendAntragMail(index: string): Promise<boolean> {
    const antragMail: AntragMail = {
      AntragIndex: index,
      Receiver: 'Supervisor',
    };
    return this.backendService
      .post<any>('SendAntragMail', antragMail)
      .pipe(map(res => res.StatusCode === 1))
      .toPromise();
  }
}
