import { Component, OnInit } from '@angular/core';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { SyncService } from '@app/globalServices/sync.service';
import { TermConverterService } from '@app/globalServices/termConverter.service';
import { TimeService } from '@app/globalServices/time.service';
import { qMap } from '@app/utils/rxQueryUtils';
import {
  DateSelectorBandStateService,
  DateSelectorBandStateServiceProvider,
} from '@components/reuseable/form-elements/selectors/date-selector-band/date-selector-band.state.service';
import {
  TeamSelectorStateService,
  TeamSelectorStateServiceProvider,
} from '@components/reuseable/team-selector-tabs/team-selector.state.service';
import { Auswertung } from '@entities/Auswertung';
import { Fehlzeit } from '@entities/Fehlzeiten';
import { AuswertungService } from '@pages/TabGroup/shared/ZEFAuswertung.service';
import { FehlzeitenService } from '@pages/TabGroup/shared/ZEFFehlzeiten.service';
import { SaldenService } from '@stores/ZEFSalden.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

class AuswertungViewModel extends Auswertung {
  public readonly Title: string;
  constructor(auswertung: Auswertung, title: string) {
    super(auswertung);
    this.Title = title;
  }
}

export interface SaldoData {
  pnr: string;
  tag: string;
  istZeit: string;
  sollZeit: string;
  mehrarbeit: string;
  negative: boolean;
}

@Component({
  selector: 'app-uebersicht',
  templateUrl: './uebersicht.component.html',
  styleUrls: ['./uebersicht.component.scss'],
  providers: [TeamSelectorStateServiceProvider, DateSelectorBandStateServiceProvider],
})
export class UebersichtComponent implements OnInit {
  showKarteBoolean: boolean;
  latitude: string;
  longitude: string;

  readonly saldoDataSubject: BehaviorSubject<SaldoData> = new BehaviorSubject<SaldoData>(null);
  saldoData$ = this.saldoDataSubject.asObservable();

  constructor(
    private readonly auswertungsService: AuswertungService,
    readonly fehlzeiten: FehlzeitenService,
    private readonly termConverterService: TermConverterService,
    private readonly teamSelector: TeamSelectorStateService,
    private readonly dateSelector: DateSelectorBandStateService,
    private readonly settings: GlobalSettingService,
    private readonly saldenService: SaldenService,
    private readonly timeService: TimeService,
    private readonly syncService: SyncService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.syncService.sync([this.syncService.auswertungSync]);
  }

  readonly selectedPnr$ = this.teamSelector.selection$.pipe(map(employee => employee?.Pnr));

  readonly selectedDate$ = this.dateSelector.selection$;

  readonly auftragsAnzeige$ = this.settings.auftragsAnzeige.value$;
  readonly taetigkeitsAnzeige$ = this.settings.taetigkeitsAnzeige.value$;

  readonly dayOverview$ = combineLatest([this.selectedPnr$, this.selectedDate$, this.fehlzeiten.storeData$]).pipe(
    switchMap(([pnr, date, fehlzeitenList]) => {
      return this.auswertungsService.observeDayOverview$(pnr, date).pipe(
        qMap(res => res.sort((a, b) => (b.EndMin === -1 && b.EndStd === -1 ? -1 : 0))),
        qMap(auswertung => this.mapToViewModel(auswertung, fehlzeitenList))
      );
    })
  );

  readonly getSaldoData$: Observable<SaldoData> = combineLatest([this.selectedPnr$, this.selectedDate$]).pipe(
    switchMap(([pnr, date]) => {
      return this.saldenService.getSaldoForPnr$(pnr, date).pipe(
        map(saldo => {
          const saldoData: SaldoData = {
            pnr: saldo.Pnr,
            tag: saldo.Tag,
            sollZeit: this.termConverterService.minutesToHourMinutes(Math.floor(saldo.SollZeit)),
            istZeit: this.termConverterService.minutesToHourMinutes(Math.floor(saldo.IstZeit)),
            mehrarbeit:
              saldo.Mehrarbeit < 0
                ? '-' + this.termConverterService.minutesToHourMinutes(Math.floor(saldo.Mehrarbeit * -1))
                : this.termConverterService.minutesToHourMinutes(Math.floor(saldo.Mehrarbeit)),
            negative: saldo.Mehrarbeit < 0 && saldo.Mehrarbeit !== 0,
          };
          this.saldoDataSubject.next(saldoData);

          return saldoData;
        })
      );
    })
  );

  readonly validSaldoData$ = combineLatest([this.selectedDate$, this.saldoData$]).pipe(
    map(([date, saldo]) => {
      if (saldo === null) {
        return false;
      }
      const dateDay = this.timeService.getDateFromRawDate(date, true);
      return (
        this.timeService.getDateFromFormattedDate(dateDay).getTime() ===
        this.timeService.getDateFromFormattedDate(saldo.tag).getTime()
      );
    })
  );

  /**@description Prüft ob die Auswertung eine Tätigkeit besitzt - ist dies der Fall, wird sie angezeigt */
  hasTaetigkeit(auswertung: Auswertung): boolean {
    return !!auswertung?.getTaetigkeitBeschreibung();
  }

  getTimeString(row: AuswertungViewModel): string {
    const vonGerundet = this.termConverterService.minutesToHourMinutes(
      row.Vongerundet < 1440 ? row.Vongerundet : row.Vongerundet - 1440
    );
    let bisGerundet = this.termConverterService.minutesToHourMinutes(
      row.Bisgerundet < 1440 ? row.Bisgerundet : row.Bisgerundet - 1440
    );

    if (row.EndStd === -1 && row.EndMin === -1) {
      bisGerundet = 'Offen';
    }

    return vonGerundet + ' - ' + bisGerundet;
  }

  /**@description Scrollt in das jeweils geöffnete Panel */
  scrollInto(index): void {
    const header = document.getElementsByClassName('mat-expansion-panel-header');
    const correctHeader = header[index];
    correctHeader.scrollIntoView({ behavior: 'smooth' });
  }

  showMap(auswertung: Auswertung): void {
    this.latitude = auswertung.Latitude;
    this.longitude = auswertung.Longitude;
    this.showKarteBoolean = true;
  }

  closeMap(): void {
    this.showKarteBoolean = false;
  }

  private mapToViewModel(auswertungen: Auswertung[], fehlzeitenList: Fehlzeit[]): AuswertungViewModel[] {
    return auswertungen.map(auswertung => {
      let title = 'Arbeitszeit';
      if (auswertung.Kennzeichen)
        title =
          fehlzeitenList.find(fehlzeit => fehlzeit.Kennzeichen === auswertung.Kennzeichen)?.Beschreibung ||
          'Zeitbuchung(' + auswertung.Kennzeichen + ')';
      else if (auswertung.Auftrag) title = auswertung.Auftragbeschreibung || 'Auftrag ( ' + auswertung.Auftrag + ' )';
      return new AuswertungViewModel(auswertung, title);
    });
  }

  isFoldable(auswertung: Auswertung): boolean {
    return auswertung && (!!auswertung.getKommentar() || !!this.hasLocation(auswertung));
  }

  /**@description Wenn ein sinvoller Latitude Wert vortliegt, die Anzeige der Geolocation erlauben  */
  hasLocation(auswertung: Auswertung): boolean {
    if (!auswertung?.Latitude) {
      return false;
    }

    const latitude = parseFloat(auswertung.Latitude);
    if (latitude === 1 || latitude === 0) {
      return false;
    }
    return true;
  }
}
