import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { BuchungsBottomsheetComponent, EmployeePreselectionParameter } from '../BuchungsBottomsheetComponent';

export interface AuftragEndePreselectionParameter extends EmployeePreselectionParameter {
  AufNr: string;
  TaetNr: string;
}

@Component({
  selector: 'app-auftrag-ende-erfassen',
  templateUrl: './auftrag-ende-erfassen.component.html',
  styleUrls: ['./auftrag-ende-erfassen.component.scss'],
})
export class AuftragEndeErfassenComponent
  extends BuchungsBottomsheetComponent<EmployeePreselectionParameter>
  implements OnInit
{
  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  readonly TitleStyle: TitleStyle = {
    name: 'Auftrag beenden',
    color: 'black',
    fontweight: 'bold',
  };

  readonly SaveAuftragButton: ButtonStyle = {
    color: 'white',
    backgroundColor: 'rgb(18, 126, 198)',
    icon: 'assets/icons/briefcase_document.png',
    description: 'Auftrag beenden',
  };

  protected createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return formBuilder.group({
      mitarbeiter: ['', [Validators.required, Validators.minLength(1)]],
      kommentar: ['', Validators.nullValidator],
    });
  }
}
