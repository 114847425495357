<app-bottom-sheet-frame [Title]="TitleStyle.name">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <app-mitarbeiter-selector formControlName="mitarbeiter"></app-mitarbeiter-selector>
    <!-- <app-comment-field formControlName="kommentar"></app-comment-field> -->

    <app-bottom-sheet-button [Action]="submitButtonAction$ | async" [ButtonStyle]="SaveGehenButton"
      >send</app-bottom-sheet-button
    >
  </form>

  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel</app-bottom-sheet-button
  >
</app-bottom-sheet-frame>
