import { Injectable } from '@angular/core';
import { SelectorStateService, IFilter } from '@base-components/form-components/SelectorStateService';
import { Employee } from '@entities/Employee';
import { MitarbeiterService } from '@pages/TabGroup/shared/ZEFMitarbeiter.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

export interface ITeamSelectorFilter extends IFilter {}

@Injectable()
export class TeamSelectorStateService extends SelectorStateService<Employee, ITeamSelectorFilter> {
  constructor(private readonly employees: MitarbeiterService) {
    super();
    this.setSource(this.employees.currentUserSubordinates$);
  }

  private readonly subject = new BehaviorSubject<number>(0);
  readonly selection$ = this.state$.pipe(
    switchMap(employees =>
      this.subject.pipe(
        map(id => (employees.length < id ? (null as Employee) : employees[id])),
        shareReplay(1)
      )
    )
  );

  select(index: number) {
    this.subject.next(index);
  }

  protected createFilterList(filter: ITeamSelectorFilter): ((entity: Employee) => boolean)[] {
    return [];
  }
}

export const TeamSelectorStateServiceProvider = {
  provide: TeamSelectorStateService,
};
