import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { OnInit, Injector, Component } from '@angular/core';
import { ModalAction, ModalActionType, ModalQueryResult } from '@app/utils/types';
import { ComponentType } from '@angular/cdk/portal';
import { BaseModalComponent } from './BaseModalComponent';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';

@Component({ template: '{{"BaseBottomsheetComponent"}}' })
export abstract class BaseBottomsheetComponent<TParameter, TResult>
  extends BaseModalComponent<TParameter, TResult>
  implements OnInit
{
  public static async Show<TParameter, TResult>(
    bottomSheet: MatBottomSheet,
    component,
    config?: MatBottomSheetConfig<TParameter>
  ): Promise<ModalQueryResult<TResult>> {
    const ref = bottomSheet.open<
      ComponentType<BaseBottomsheetComponent<TParameter, TResult>>,
      TParameter,
      ModalQueryResult<TResult>
    >(component, { disableClose: true, ...config });

    let result = await ref.afterDismissed().toPromise();

    while (result?.Type === ModalActionType.RECALL) {
      if (!result.Recall) {
        const err = new Error('No Recall-Func to call');
        console.error(err);
        throw err;
      }
      result = await result.Recall();
    }

    return result;
  }

  protected readonly bottomSheet: MatBottomSheet;
  protected readonly Parameter = this.injector.get(MAT_BOTTOM_SHEET_DATA) as TParameter;
  //#region LifeCycle

  constructor(protected readonly injector: Injector) {
    super(injector);
    this.bottomSheet = this.injector.get(MatBottomSheet);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }

  protected Close(result: ModalQueryResult<TResult>) {
    this.bottomSheet.dismiss(result);
  }

  public Cancel() {
    this.Close({ Type: ModalActionType.CANCEL });
  }

  readonly closeButtonStyle: ButtonStyle = {
    color: 'rgb(187, 29, 72)',
    backgroundColor: 'white',
    icon: 'assets/icons/exit_red.png',
    description: 'Schließen',
  };

  readonly closeButtonAction: ModalAction = {
    Type: ModalActionType.CANCEL,
    Disabled: false,
    Execute: () => this.Cancel(),
  };

  //#endregion
}
