import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DatabaseService } from '@app/globalServices/database.service';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { SyncService } from '@app/globalServices/sync.service';
import { TermConverterService } from '@app/globalServices/termConverter.service';
import { getfirst, taplog } from '@app/utils/rxjsUtils';
import { qMap } from '@app/utils/rxQueryUtils';
import { BookingRight, BookingType } from '@app/utils/types';
import {
  DateSelectorBandStateService,
  DateSelectorBandStateServiceProvider,
} from '@components/reuseable/form-elements/selectors/date-selector-band/date-selector-band.state.service';
import {
  TeamSelectorStateService,
  TeamSelectorStateServiceProvider,
} from '@components/reuseable/team-selector-tabs/team-selector.state.service';
import { BookingTimeFrame } from '@entities/BookingTimeFrame';
import { Fehlzeit } from '@entities/Fehlzeiten';
import { MitarbeiterService } from '@stores/ZEFMitarbeiter.service';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuftraegeService } from '../../../shared/ZEFAuftraege.service';
import { FehlzeitenService } from '../../../shared/ZEFFehlzeiten.service';
import { StempelungService } from '../../../shared/ZEFStempelung.service';
import { TaetigkeitService } from '../../../shared/ZEFTaetigkeit.service';
import {
  EditErfassenParameter,
  EditierenErfassenComponent,
} from '../../bottomsheet/editieren-erfassen/editieren-erfassen.component';
import {
  ManuelErfassenParameter,
  ManuellErfassenComponent,
} from '../../bottomsheet/manuell-erfassen/manuell-erfassen.component';
import { StempelungViewModel } from '../ExtendedBookings';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss'],
  providers: [TeamSelectorStateServiceProvider, DateSelectorBandStateServiceProvider],
})
export class JournalComponent implements OnInit {
  constructor(
    private readonly dbService: DatabaseService,
    private readonly stempelungsService: StempelungService,
    private readonly taetigkeitsService: TaetigkeitService,
    private readonly AuftraegeService: AuftraegeService,
    private readonly fehlzeitenService: FehlzeitenService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly teamSelector: TeamSelectorStateService,
    private readonly dateSelector: DateSelectorBandStateService,
    private readonly settings: GlobalSettingService,
    private readonly termConverterService: TermConverterService,
    private readonly mitarbeiterService: MitarbeiterService,
    private readonly syncService: SyncService
  ) {}

  async ngOnInit() {
    const syncSteps = [this.syncService.employeesSync, this.syncService.auswertungSync];
    await this.syncService.sync(syncSteps);
  }

  private readonly selectedPnr$ = this.teamSelector.selection$.pipe(map(employee => employee.Pnr));
  private readonly selectedDate$ = this.dateSelector.selection$;

  readonly refresh$ = this.dbService.changes$.pipe(
    taplog('change unfiltered'),
    filter(changes => !!changes.find(change => change.storeName === BookingTimeFrame.StoreName)),
    map(changes => moment(new Date()).format('HHmmss')),
    taplog('change filtered')
  );

  readonly auftragsAnzeige$ = this.settings.auftragsAnzeige.value$;
  readonly taetigkeitsAnzeige$ = this.settings.taetigkeitsAnzeige.value$;

  readonly query$ = combineLatest([this.selectedPnr$, this.selectedDate$, this.fehlzeitenService.storeData$]).pipe(
    switchMap(([pnr, date, fehlzeiten]) =>
      this.stempelungsService.getDayOverview$(pnr, date).pipe(
        qMap(stemps => stemps.sort((a, b) => (a.BeginnStunden < b.EndStunden ? -1 : 1))),
        qMap(stemps =>
          stemps.map(stemp => new StempelungViewModel(stemp, this.bookingToTypedescription(stemp, fehlzeiten)))
        )
      )
    ),
    shareReplay(1)
  );

  getTimeString(row: StempelungViewModel) {
    const vonMinutes = row.BeginnStunden * 60 + row.BeginnMinuten;
    const bisMinutes = row.EndStunden * 60 + row.EndMinuten;

    var vonGerundet = this.termConverterService.minutesToHourMinutes(
      vonMinutes < 1440 ? vonMinutes : vonMinutes - 1440
    );
    var bisGerundet = this.termConverterService.minutesToHourMinutes(
      bisMinutes < 1440 ? bisMinutes : bisMinutes - 1440
    );

    if (row.EndStunden === -1 && row.EndMinuten === -1) {
      bisGerundet = 'Offen';
    }

    return vonGerundet + ' - ' + bisGerundet;
  }

  getTaetigkeitDescription$(bookingFrame: BookingTimeFrame): Observable<string> {
    if (!bookingFrame || !bookingFrame.TaetigkeitsNummer) return of('');
    return this.taetigkeitsService.getTaetigkeitDescription(bookingFrame.TaetigkeitsNummer);
  }

  getAuftragDescription$(bookingFrame: BookingTimeFrame): Observable<string> {
    if (!bookingFrame || !bookingFrame.AuftragsNummer) return of('');
    return this.AuftraegeService.getAuftragDescription(bookingFrame.AuftragsNummer);
  }

  getFehlzeitDescription$(bookingFrame: BookingTimeFrame): Observable<string> {
    if (!bookingFrame || !bookingFrame.Kennzeichen) return of('');
    return this.fehlzeitenService.mapToDescription(bookingFrame.Kennzeichen);
  }

  bookingToTypedescription(buchung: BookingTimeFrame, fehlzeiten: Fehlzeit[]): string {
    if (!buchung) return '';

    if (buchung.AuftragsNummer) {
      return `Auftrag (${buchung.AuftragsNummer})`;
    }
    if (buchung.Kennzeichen !== '') {
      const fehlzeit = fehlzeiten.find(f => f.Kennzeichen === buchung.Kennzeichen);
      if (!fehlzeit?.Beschreibung) return `Abwesenheit (${buchung.Kennzeichen})`;
      return fehlzeit.Beschreibung;
    }
    return 'Arbeitszeit';
  }

  async addBooking() {
    const selectedDay = this.dateSelector.getSelected();
    const existingStempelungenForDay = [...(await getfirst(this.query$))?.data];
    const selectedPnr = await getfirst(this.selectedPnr$);

    const bookingRight = await getfirst(
      this.mitarbeiterService.currentUserRights$.pipe(map(bookingRights => bookingRights.bookingRight))
    );
    const auftragRequired =
      bookingRight === BookingRight.BuchenMitAuftragserfassung ||
      bookingRight === BookingRight.BuchenMitAuftragserfassungUndTaetigkeiten;
    const preSelectBookingType = auftragRequired ? BookingType.AUFTRAGSZEIT : BookingType.ARBEITSZEIT;

    const data: ManuelErfassenParameter = {
      selectedDay,
      existingStempelungenForDay,
      selectedPnr,
      preSelectBookingType,
    };

    await ManuellErfassenComponent.ShowWith(this.bottomSheet, data);
  }

  async editBooking(stempelung: BookingTimeFrame) {
    const selectedDay = this.dateSelector.getSelected();
    const existingStempelungenForDay = [...(await getfirst(this.query$)).data];

    const preSelectBookingType = stempelung.toArt();

    const data: EditErfassenParameter = {
      selectedDay,
      existingStempelungenForDay,
      stempelung,
      preSelectBookingType,
    };

    await EditierenErfassenComponent.ShowWith(this.bottomSheet, data);
  }
}
