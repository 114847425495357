<div *ngIf="inputMode">
  <input
    matInput
    inputmode="numeric"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (keydown)="onKeyDown($event)"
    [(ngModel)]="selectedTime"
    class="inputField"
    #timePickerInput
  />
  <ul class="timeSlots">
    <li *ngFor="let possibleTimeSlot of timeSlots">
      <div class="timeSlot" (mousedown)="itemSelected(possibleTimeSlot)" [id]="possibleTimeSlot + 'Slot'">
        {{ possibleTimeSlot }} Uhr
      </div>
    </li>
  </ul>
</div>

<div class="inputField" *ngIf="!inputMode" (click)="switchToInputMode()">{{ getDisplayValueOrPlaceholder() }}</div>
