<app-bottom-sheet-frame [Title]="TitleStyle.name" [Actions]="[]" [StyleProvider$]="null">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="">
    <app-mitarbeiter-selector formControlName="mitarbeiter"></app-mitarbeiter-selector>
    <app-comment-field formControlName="kommentar"></app-comment-field>

    <app-bottom-sheet-button [Action]="submitButtonAction$ | async" [ButtonStyle]="SaveKommenButton"
      >send</app-bottom-sheet-button
    >
  </form>

  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel</app-bottom-sheet-button
  >
</app-bottom-sheet-frame>
