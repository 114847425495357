import { Component, OnInit, Injector } from '@angular/core';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BuchungsBottomsheetComponent, EmployeePreselectionParameter } from '../BuchungsBottomsheetComponent';
import { BookingTime } from '@entities/BookingTime';
import { BuchungTempService } from '@pages/TabGroup/shared/ZEFBuchung_Temp.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-gehen-erfassen',
  templateUrl: './gehen-erfassen.component.html',
  styleUrls: ['./gehen-erfassen.component.scss'],
})
export class GehenErfassenComponent
  extends BuchungsBottomsheetComponent<EmployeePreselectionParameter>
  implements OnInit
{
  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  TitleStyle: TitleStyle = {
    name: 'Gehen',
    color: 'black',
    fontweight: 'bold',
  };

  SaveGehenButton: ButtonStyle = {
    color: 'white',
    backgroundColor: 'rgb(187, 29, 72)',
    icon: 'assets/icons/inbox_out.png',
    description: 'Gehen buchen',
  };

  protected getDefaultBuchung() {
    // override booking type
    // '1' = end
    return { ...super.getDefaultBuchung(), Art: '1' };
  }

  protected createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return formBuilder.group({
      mitarbeiter: ['', [Validators.required, Validators.minLength(1)]],
      kommentar: ['', Validators.nullValidator],
    });
  }
}
