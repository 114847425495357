<!-- <div class='topZeile'>
  <a class='headline'>App-Info</a>
</div> -->
<div id="bodyContainer" class="bodyContainer">
  <div class="versionLogoContainer">
    <img class="logoClass" src="assets/icons/my_blueapp_zeiterfassung.svg" />
    <p class="versionHeadline">VERSION: {{ version }}</p>
  </div>
</div>
<!-- <button class='close' (click)='clickedClose()'></button> -->
<app-toolbar></app-toolbar>
