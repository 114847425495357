<app-bottom-sheet-frame [Title]="TitleStyle.name">
  <div class="auPictureFrame" id="auPictureFrame" (click)="FileUploader.click()">
    <img [src]="imageData$ | async" />

    <div class="placeholder" *ngIf="!(imageData$ | async)">
      <div>
        <mat-icon>camera_alt</mat-icon>
      </div>
      <div>Bild hochladen</div>
    </div>
  </div>

  <input #FileUploader hidden type="file" accept="image/jpeg, image/png" (change)="addFile($event)" />

  <app-bottom-sheet-button [Action]="DeleteFileButtonAction$ | async" [ButtonStyle]="DeleteFileButton"
    >send</app-bottom-sheet-button
  >

  <app-bottom-sheet-button [Action]="SaveButtonAction" [ButtonStyle]="SaveButton">send</app-bottom-sheet-button>

  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel</app-bottom-sheet-button
  >
</app-bottom-sheet-frame>
