import { Injectable, Injector } from '@angular/core';
import { LOCAL_SETTINGS_STORENAME } from '@entities/Setting';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { MILLISECONDS_PER_DAY } from '../time.service';
import { SettingService } from './SettingService';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingService extends SettingService {
  getTableName(): string {
    return LOCAL_SETTINGS_STORENAME;
  }

  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  // #region Einstellungen / Settings

  // Device / Session
  readonly deviceID = this.createReadonlySetting<string>('deviceID', uuidv4());
  readonly demoMode = this.createSetting<boolean>('demoMode', false);

  // Booking
  readonly gruppenBuchen = this.createSetting<boolean>('gruppenBuchungen', true);
  readonly showLastBookingForTeam = this.createSetting('lastBookingForTeam', false);

  readonly prefixPnr = this.createSetting('scannerPrefixPnr', 'P;');
  readonly prefixAuftrag = this.createSetting('scannerPrefixAuftrag', 'A;');
  readonly prefixTaetigkeit = this.createSetting('scannerPrefixTaetigkeit', 'T;');
  readonly prefixAbwesenheit = this.createSetting('scannerPrefixAbwesenheit', 'G;');

  readonly maxBookingTime = this.createSetting('maxBookingTime', 0);
  readonly longTapRight = this.createSetting('longTapRight', false);

  //#region Sync
  // due to circular dependency not located in sync-service

  readonly timeSyncRange = this.createSetting<number>('syncDateRange', 1);

  readonly syncTaetigkeitenAmount = this.createSetting<string>('syncTaetigkeitenAmount', '100');

  // send booking to backend after recording it
  readonly syncAfterBooking = this.createSetting<'always' | 'never' | 'query'>('syncAfterBooking', 'always');

  // range of days that are kept offline-available
  readonly syncDateRange$ = combineLatest([this.TimeService.currentDate$, this.timeSyncRange.value$]).pipe(
    map(([today, length]) => ({
      start: new Date(today.getTime() - length * MILLISECONDS_PER_DAY),
      end: today,
    }))
  );

  readonly currentAuswertungSyncRange = this.createSetting<[Date, Date]>('currentAuswertungSyncRange', null);

  readonly currentStempelungSyncRange = this.createSetting<[Date, Date]>('currentStempelungSyncRange', null);

  readonly auftragsSyncAb = this.createSetting<Date>('auftragsSyncAb', new Date(1990, 1, 1));

  readonly updateDone = this.createSetting<boolean>('updateDone', true);

  //#endregion

  //#region Selector-History

  readonly lastTaetigkeit = this.createSetting<string[]>('lastTaetigkeit', null);
  readonly lastAuftrag = this.createSetting<string>('lastAuftrag', null);
  readonly lastCustomer = this.createSetting<string>('lastCustomer', null);
  readonly lastFehlzeit = this.createSetting<string>('lastFehlzeit', null);
  readonly lastAuftragBookingTeam = this.createSetting<string[]>('lastAuftragBookingTeam', null);

  //#endregion

  //#region Anzeige
  readonly kundenAnzeige = this.createSetting<boolean>('kundenAnzeige', false);
  readonly auftragsAnzeige = this.createSetting<boolean>('auftragsAnzeige', false);
  readonly taetigkeitsAnzeige = this.createSetting<boolean>('taetigkeitsAnzeige', false);
  //#endregion

  //#endregion
}
