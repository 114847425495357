<div class="status-selector-container">
  <mat-form-field class="status-selector-form-field">
    <mat-label>{{ label }}</mat-label>
    <mat-select [(value)]="defaultOption" (selectionChange)="selectionChange.emit($event.value)">
      <div class="optionsDropDown">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</div>
