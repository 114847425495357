import { BookingType } from '@app/utils/types';
import { addLeadingZeroInTimevalue } from '@app/utils/utils';
import * as moment from 'moment';
import { TempBuchung } from './BookingTime';
import { IndexedDBTypes } from './dbType';

type CompareType = string | number;
const tryCompare = (a: CompareType, b: CompareType): number => {
  if (a === b) return 0;
  return a < b ? -1 : 1;
};

const BOOKINGTIMEFRAME_STORENAME = 'Stempelung';

export class BookingTimeFrame extends IndexedDBTypes.DbType {
  public static readonly StoreName = BOOKINGTIMEFRAME_STORENAME;

  @IndexedDBTypes.KlassenName(BOOKINGTIMEFRAME_STORENAME) private KlassenName = BOOKINGTIMEFRAME_STORENAME;
  @IndexedDBTypes.KeyDBField('string') Key: string;
  @IndexedDBTypes.IndexField('string') Pnr: string;

  @IndexedDBTypes.IndexField('string') Tag: string;

  @IndexedDBTypes.DataField('number') BeginnStunden: number;
  @IndexedDBTypes.DataField('number') BeginnMinuten: number;
  @IndexedDBTypes.DataField('number') BeginnSec: number;
  @IndexedDBTypes.DataField('number') EndStunden: number;
  @IndexedDBTypes.DataField('number') EndMinuten: number;
  @IndexedDBTypes.DataField('number') EndSec: number;

  @IndexedDBTypes.DataField('string') AuftragsNummer: string;
  @IndexedDBTypes.DataField('string') TaetigkeitsNummer: string;
  @IndexedDBTypes.DataField('string') TaetigkeitsPaket: string;
  @IndexedDBTypes.DataField('string') Kennzeichen: string;
  @IndexedDBTypes.DataField('string') Grund: string;
  @IndexedDBTypes.DataField('string') Kommentar1: string;
  @IndexedDBTypes.DataField('string') Kommentar2: string;
  @IndexedDBTypes.DataField('string') Kommentar3: string;

  @IndexedDBTypes.DataField('string') Longitude: string;
  @IndexedDBTypes.DataField('string') Latitude: string;
  @IndexedDBTypes.DataField('string') Radius: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  public getIndex() {
    return this.Key;
  }

  public getKommentar(): string {
    const comment1 = this.Kommentar1 || '';
    const comment2 = this.Kommentar2 || '';
    const comment3 = this.Kommentar3 || '';
    const comment = comment1 + comment2 + comment3;
    return comment;
  }

  public getBeginn() {
    if (this.BeginnMinuten === null || this.BeginnMinuten === undefined) {
      return;
    }
    let stunden = this.BeginnStunden.toString();
    let minuten = this.BeginnMinuten.toString();
    if (stunden.length < 2) {
      stunden = '0' + stunden;
    }
    if (minuten.length < 2) {
      minuten = '0' + minuten;
    }
    return stunden + ':' + minuten;
  }

  /**@param end If set to true it return the end instead of the beginning date */
  toDate(end = false): Date {
    const format = this.Tag.includes('.') ? 'DD.MM.YYYY' : 'YYYYMMDD'; // at some point the Tag seemed to be in dd.mm.yyyy - dirty fix
    const date = moment(this.Tag, format);
    const hh = end ? this.EndStunden : this.BeginnStunden;
    const mm = end ? this.EndMinuten : this.BeginnMinuten;
    const ss = end ? (this.EndSec ? this.EndSec : 0) : this.BeginnSec ? this.BeginnSec : 0;
    const result = date.hours(hh).minutes(mm).seconds(ss).toDate();
    return result;
  }

  /**@description Converts information to startDate and Enddate */
  toDates(): { startDate: Date; endDate: Date } {
    const startDateValue = this.toDate();
    const endDateValue = this.toDate(true);
    return { startDate: startDateValue, endDate: endDateValue };
  }

  public static DateAsDatestring(date: Date): string {
    return moment(date).format('YYYYMMDD');
  }

  public getEnde() {
    if (this.EndStunden === null || this.EndStunden === undefined) {
      return;
    }
    let stunden = this.EndStunden.toString();
    let minuten = this.EndMinuten.toString();
    if (stunden.length < 2) {
      stunden = '0' + stunden;
    }
    if (minuten.length < 2) {
      minuten = '0' + minuten;
    }
    const result = stunden + ':' + minuten;
    if (result === '-1:-1') {
      return 'Offen';
    }
    return result;
  }

  getDateYYYYMMDD(): string {
    if (this.Tag.length < 8) return '00000000';

    return (
      this.Tag[4] + this.Tag[5] + this.Tag[6] + this.Tag[7] + this.Tag[2] + this.Tag[3] + this.Tag[0] + this.Tag[1]
    );
  }

  compare(other: BookingTimeFrame): number {
    // compare tag
    const tagA = this.getDateYYYYMMDD();
    const tagB = other.getDateYYYYMMDD();
    const tag = tryCompare(tagA, tagB);
    if (tag !== 0) return tag;

    // compare begin
    if (this.toDate().getTime() > other.toDate().getTime()) return 1;
    if (this.toDate().getTime() < other.toDate().getTime()) return -1;

    // compare end
    if (this.getEnde() !== other.getEnde()) return this.getEnde() < other.getEnde() ? -1 : 1;

    if (this.Pnr !== other.Pnr) return this.Pnr < other.Pnr ? -1 : 1;
    if (this.Key !== other.Key) return this.Key < other.Key ? -1 : 1;

    return 0;
  }

  /**@description Connverts(extracts) the timepicker Values from a booking timeframe */
  toTimepickerValues(): { startTime: string; endTime: string } {
    const beginHours = addLeadingZeroInTimevalue(this.BeginnStunden);
    const beginMinutes = addLeadingZeroInTimevalue(this.BeginnMinuten);
    const startTimeValue = beginHours + ':' + beginMinutes;

    const endHours = addLeadingZeroInTimevalue(this.EndStunden);
    const endMinutes = addLeadingZeroInTimevalue(this.EndMinuten);
    const endTimeValue = endHours + ':' + endMinutes;

    return { startTime: startTimeValue, endTime: endTimeValue };
  }

  /**@description Extracts the art information from the booking */
  toArt(): BookingType {
    if (this.AuftragsNummer !== '' || this.TaetigkeitsNummer !== '') {
      return BookingType.AUFTRAGSZEIT;
    }
    if (this.Kennzeichen.toString() == 'P') {
      return BookingType.FEHLZEIT;
    }
    if (this.Kennzeichen !== '') {
      return BookingType.FEHLZEIT;
    }
    return BookingType.ARBEITSZEIT;
  }

  /**@description Converts a BookingTimeFrame to Tempbuchungen */
  toTempbuchungen(): { startBuchung: TempBuchung; endBuchung: TempBuchung } {
    const startBuchungValue: TempBuchung = {
      Pnr: this.Pnr,
      Tag: this.Tag,
      Stunden: this.BeginnStunden,
      Minuten: this.BeginnMinuten,
      Sekunden: this.BeginnSec,
      Art: '0',
      Kennzeichen: this.Kennzeichen,
      Auftrag: this.AuftragsNummer,
      Kunde: '',
      Taetigkeit: this.TaetigkeitsNummer,
      Taetigkeitspaket: this.TaetigkeitsPaket,
      Grund: this.Grund,
      Kommentar1: this.Kommentar1,
      Kommentar2: this.Kommentar2,
      Kommentar3: this.Kommentar3,
      Longitude: parseFloat(this.Longitude ?? '1'),
      Latitude: parseFloat(this.Latitude ?? '1'),
      Radius: 1000,
    };
    const endBuchungValue: TempBuchung = {
      Pnr: this.Pnr,
      Tag: this.Tag,
      Stunden: this.EndStunden,
      Minuten: this.EndMinuten,
      Sekunden: this.EndSec,
      Art: '1',
      Kennzeichen: this.Kennzeichen,
      Auftrag: this.AuftragsNummer,
      Kunde: '',
      Taetigkeit: this.TaetigkeitsNummer,
      Taetigkeitspaket: this.TaetigkeitsPaket,
      Grund: this.Grund,
      Kommentar1: this.Kommentar1,
      Kommentar2: this.Kommentar2,
      Kommentar3: this.Kommentar3,
      Longitude: parseFloat(this.Longitude ?? '1'),
      Latitude: parseFloat(this.Latitude ?? '1'),
      Radius: 1000,
    };
    return { startBuchung: startBuchungValue, endBuchung: endBuchungValue };
  }

  static toString(): string {
    return BOOKINGTIMEFRAME_STORENAME;
  }
}
