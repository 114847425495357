<ng-container *ngIf="selectType as type" [ngSwitch]="type">
  <!-- MULTI-SELECT -->
  <mat-form-field id="mitarbeiter-selector" *ngSwitchCase="'multi'" class="mitarbeiter-selector-wrapper">
    <mat-label>Mitarbeiter</mat-label>
    <mat-select [formControl]="employeeControl" multiple (selectionChange)="onSelect()" (blur)="onBlur()">
      <mat-select-trigger>
        {{ triggerLabel$ | async }}
        <span *ngIf="employeeControl.value?.length > 1" class="example-additional-selection">
          ( + {{ employeeControl.value.length - 1 }} {{ employeeControl.value?.length === 2 ? 'weiterer' : 'weitere' }})
        </span>
      </mat-select-trigger>

      <mat-option *ngFor="let mitarbeiter of options$ | async" [value]="mitarbeiter.Pnr">
        {{ mitarbeiter.getFullName() }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- SINGLE SELECT -->
  <mat-form-field id="mitarbeiter-selector" *ngSwitchDefault class="mitarbeiter-selector-wrapper">
    <mat-label>Mitarbeiter</mat-label>

    <mat-select [formControl]="employeeControl" (selectionChange)="onSelect()">
      <mat-select-trigger>
        {{ triggerLabel$ | async }}
      </mat-select-trigger>

      <mat-option *ngFor="let employee of options$ | async" [value]="employee.Pnr">
        {{ employee.getFullName() }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
