import { Injectable } from '@angular/core';
import { BookingType } from '@app/utils/types';
import { OpenBookingFrame } from '@pages/TabGroup/presentation/buchung/buchung.service';
import { Antrag } from '../database/db-types/Antrag';
import { FehlzeitFarben } from '../database/db-types/FehlzeitFarben';

/**
 * @class termConverter
 * Klasse mit welcher Hilfe sich verschiedene Strings/numbers/terms passend converten lassen
 * um diese weiter verarbeiten zu können
 */

@Injectable({
  providedIn: 'root',
})
export class TermConverterService {
  antragsArtenReadable = {
    Z: 'Zeitausgleich',
    G: 'Urlaub',
    H: 'Urlaub halber Tag',
    O: 'Sonderurlaub',
    S: 'Schule',
    K: 'Krankheit',
    R: 'Zeitkorrektur',
    '-1': 'Sonstiges',
  };
  antragsArtenWriteable = {
    Zeitausgleich: 'Z',
    Urlaub: 'G',
    'Urlaub halber Tag': 'H',
    Sonderurlaub: 'O',
    Zeitkorrektur: 'R',
  };
  antragsstatiReadable = {
    '0': 'offen',
    '1': 'in Bearbeitung',
    '2': 'genehmigt',
    '3': 'nicht genehmigt',
    '4': 'erledigt',
    '5': 'Stornierung gewünscht',
    '6': 'storniert',
  };
  antragsstatWriteable = {
    offen: '0',
    'in Bearbeitung': '1',
    genehmigt: '2',
    'nicht genehmigt': '3',
    erledigt: '4',
    'Stornierung gewünscht': '5',
    storniert: '6',
  };

  constructor() {}

  /**@description True wenn IosStandalone-Modus oder in QS(oder lokal) und vorher aufs Logo geclickt */
  getIosStandAloneOrQs(imageTrigger: boolean): boolean {
    const ios = this.isUserAgentIOS();
    const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
    const location = window.location.href;
    const manualImageMode = (location.includes('zeitqs') || location.includes('192.')) && imageTrigger;

    return (ios && isInStandaloneMode) || manualImageMode;
  }

  /**@description Gibt true zurück, wenn der userAgent auf iOs schließen lässt, falls ansonsten */
  isUserAgentIOS(): boolean {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('Macintosh')) {
      return true;
    }
    return false;
  }

  /**@description Nimmt ein Datum im Format *d.*m.yyyy und gibt es zurück in dd.mm.yyyy */
  getCleanDate(date: string) {
    const splittedDate = date.split('.');
    let tag = splittedDate[0];
    if (tag.length < 2) {
      tag = '0' + tag;
    }
    let monat = splittedDate[1];
    if (monat.length < 2) {
      monat = '0' + monat;
    }
    const jahr = splittedDate[2];
    return tag + '.' + monat + '.' + jahr;
  }

  /**Nimmt ein formatiertes Datum im Format 31.12.2001 00:00:00 und gibt ein date zurück */
  getDateFromFormattedDate(currentDateComplete: string): Date {
    // currentDateComplete kommt im Format: 01.12.2019 00:00:00 , deswegen splitten an leerzeichen, anschließend an punkten
    const currentDate = currentDateComplete.split(' ');
    const tagMonatJahr = currentDate[0].split('.');
    const tag = parseInt(tagMonatJahr[0], 0);
    // auch hier beachten, monat beginnt bei 0, daher einen abziehen
    const monat = parseInt(tagMonatJahr[1], 0) - 1;
    const jahr = parseInt(tagMonatJahr[2], 0);
    const newDate = new Date(jahr, monat, tag);
    return newDate;
  }

  /**@description Nimmt einen Tag im Format dd.mm.yyyy 00:00:00 und gibt ihn zurück in yyyymmdd */
  getSaldenCompliantDay(tag: string): string {
    const splittedTag = tag.split(' ');
    const splitAtPoints = splittedTag[0].split('.');
    return splitAtPoints[2] + splitAtPoints[1] + splitAtPoints[0];
  }

  /**
   * Parameter time in Minuten
   * Konvertiert eine übergebende Zeit im Format Hour:Minutes
   * zu einem String mit ergänzter 0 falls <10  LIEFERTE TEILWEISE UM EINE MINUTE VERSCHOBENE WERTE
   */
  public timeToString(time: number): number[] {
    const times = [];
    const hours1 = time / 60 / 60;
    const rhours1 = Math.floor(hours1);
    const minutes1 = (hours1 - rhours1) * 60;
    const rminutes1 = Math.floor(minutes1);
    const seconds1 = (minutes1 - rminutes1) * 60;
    const rseconds1 = Math.floor(seconds1);
    let strhours1 = rhours1 + '';
    let strminutes1 = rminutes1 + '';
    let strseconds1 = rseconds1 + '';

    if (rhours1 < 10 && rhours1 >= 0) {
      strhours1 = '0' + strhours1;
    }
    if (rminutes1 < 10 && rminutes1 >= 0) {
      strminutes1 = '0' + strminutes1;
    }
    if (rseconds1 < 10 && rseconds1 >= 0) {
      strseconds1 = '0' + strseconds1;
    }
    times[0] = strhours1;
    times[1] = strminutes1;
    times[2] = strseconds1;
    return times;
  }

  /**@description Nimmt einen Minutenwert und berechnet daraus den hh:mm Wert */
  minutesToHourMinutes(minutes: number): string {
    const stunden = Math.floor(minutes / 60);
    const minuten = minutes % 60;
    let returnStunden = stunden.toString();
    let reuturnMinuten = minuten.toString();
    if (stunden < 10) {
      returnStunden = '0' + returnStunden;
    }
    if (minuten < 10) {
      reuturnMinuten = '0' + reuturnMinuten;
    }
    return returnStunden + ':' + reuturnMinuten;
  }

  /**@description Baut eine Fehlermeldung zusammen für die jeweiligen Felder, die der User vergessen hat */
  public createErrorMessage(antrag: Antrag) {
    let message = '';
    let headline = 'Nicht alle Pflichtfelder ausgefüllt';
    const antragsArt = antrag.Art;
    if (!antragsArt) {
      message += 'Antragsart';
    }
    if (antrag.getVon() === 'Von') {
      if (message !== '') {
        message += ', ';
      }
      message += 'Startdatum';
    }
    if (antrag.getBis() === 'Bis') {
      if (message !== '') {
        message += ', ';
      }
      message += 'Enddatum';
    }
    let fehlt = ' fehlt.';
    if (message.includes(',')) {
      fehlt = ' fehlen.';
    }
    const kommata = message.match(/,/g);
    let haeufigkeit = -1;
    try {
      haeufigkeit = kommata.length;
    } catch {
      haeufigkeit = 0;
    }
    if (haeufigkeit === 1) {
      message = message.replace(',', ' und');
    }
    if (haeufigkeit === 2) {
      const replacement = ' und';
      message = message.replace(/,([^,]*)$/, replacement + '$1');
    }
    const vonDatum = this.getDateFromFormattedDate(antrag.getVon());
    const bisDatum = this.getDateFromFormattedDate(antrag.getBis());
    if (message === '' && vonDatum > bisDatum) {
      headline = 'Datumsfehler';
      message = 'Startdatum liegt hinter dem Enddatum.';
      fehlt = '';
    }
    return { message, fehlt, headline };
  }

  /**@description Wird zum färben von Antragsart verwendet*/
  public colorAntraege(antrag, farben: FehlzeitFarben) {
    let status;
    let color = { color: 'black' };
    status = antrag.getStatus();
    const antragsart = antrag.Art;
    const kennzeichen = this.antragsGrundToKennzeichen(antragsart);
    const wert = this.getColor('Abwesenheit', kennzeichen, farben, true);
    color = { color: wert };
    return color;
  }

  /**@description Wird zum färben von der Antragskategorie verwendet*/
  public colorAntragsKategorie(kategorie) {
    const status = kategorie.slice(8);
    let color;
    if (status === 'offen' || status === 'OFFEN') {
      color = { color: 'darkgrey' };
    }
    if (status === 'genehmigt' || status === 'GENEHMIGT') {
      color = { color: 'green' };
    }
    if (status === 'Abgelehnt' || status === 'NICHT GENEHMIGT') {
      color = { color: 'red' };
    }
    if (status === 'in Bearbeitung' || status === 'IN BEARBEITUNG') {
      color = { color: 'darkgoldenrod' };
    }
    if (status === 'Stornierung gewünscht' || status === 'STORN. GEW.') {
      color = { color: 'orange' };
    }
    if (status === 'erledigt' || status === 'ERLEDIGT') {
      color = { color: 'blue' };
    }
    if (status === 'nicht genehmigt' || status === 'ABGELEHNT') {
      color = { color: 'red' };
    }
    return color;
  }

  /**
   * @description Übersetzt die Art eines Antrags in sein jeweiliges Kürzel
   * @param grund Ausgeschriebener Grund einer Abwesenheit, bspw. Dienstgang
   */
  antragsGrundToKennzeichen(grund: string) {
    if (!grund) return 'U';

    switch (grund.toLowerCase()) {
      case 'zeitausgleich': {
        return 'Z';
      }
      case 'urlaub': {
        return 'G';
      }
      case 'urlaub halber tag': {
        return 'H';
      }
      case 'sonderurlaub': {
        return 'O';
      }
      case 'schule': {
        return 'S';
      }
      case 'krankheit': {
        return 'K';
      }
      case 'zeitkorrektur': {
        return 'R';
      }
      case 'dienstgang': {
        return 'D';
      }
      case 'pause': {
        return 'P';
      }
      case 'sonstiges': {
        return '-1';
      }
      default: {
        return 'U';
      }
    }
  }

  /**@description Nimmt eine Art und gibt darauf basierend einen Farbcode zurück */
  getColor(art: string, kennzeichen: string, farben: FehlzeitFarben, geplant: boolean): string {
    let color = '#009118';
    if (art === 'Auftrag') {
      color = '#127ec6';
    }
    if (art === 'Abwesenheit') {
      color = this.getAbwesenheitsColor(kennzeichen, geplant, farben);
    }
    return color;
  }

  /**
   * @description Holt auf Basis des Kennzeichens die Abwesenheitsfarbe (die von der Datenbank gelieferten Einträge sind dezimale Werte für Hexadezimal-Codierte
   * Farben; daher werden diese erst umgerechnet und dann mit Nullen aufgefüllt, damit sie als Hexdadezimalen Farbcode interpretiert werden können )
   */
  private getAbwesenheitsColor(kennzeichen: string, geplant: boolean, farben: FehlzeitFarben) {
    let color = '2315482';
    switch (kennzeichen) {
      case 'Z': {
        if (geplant) {
          color = farben.getZeitausgleichG();
        } else {
          color = farben.getZeitausgleich();
        }
        break;
      }
      case 'G': {
        if (geplant) {
          color = farben.getUrlaubG();
        } else {
          color = farben.getUrlaub();
        }
        break;
      }
      case 'H': {
        if (geplant) {
          color = farben.getUrlaubHalbG();
        } else {
          color = farben.getUrlaubHalb();
        }
        break;
      }
      case 'O': {
        if (geplant) {
          color = farben.getSonderurlaubG();
        } else {
          color = farben.getSonderurlaub();
        }
        break;
      }
      case 'S': {
        if (geplant) {
          color = farben.getSchuleG();
        } else {
          color = farben.getSchule();
        }
        break;
      }
      case 'K': {
        if (geplant) {
          color = farben.getKrankheitG();
        } else {
          color = farben.getKrankheit();
        }
        break;
      }
      case 'R': {
        if (geplant) {
          // korrektur
        } else {
          // korrektur
        }
        break;
      }
      case 'P': {
        color = farben.getPause();
        break;
      }
      case 'D': {
        if (geplant) {
          color = farben.getDienstgangG();
        } else {
          color = farben.getDienstgang();
        }
        break;
      }
      case 'X': {
        if (geplant) {
          color = farben.getFreieBuchung1G();
        } else {
          color = farben.getFreieBuchung1();
        }
        break;
      }
      case 'Y': {
        if (geplant) {
          color = farben.getFreieBuchung2G();
        } else {
          color = farben.getFreieBuchung2();
        }
        break;
      }
      case 'A': {
        if (geplant) {
          color = farben.getFreieBuchung3G();
        } else {
          color = farben.getFreieBuchung3();
        }
        break;
      }
      case 'B': {
        if (geplant) {
          color = farben.getFreieBuchung4G();
        } else {
          color = farben.getFreieBuchung4();
        }
        break;
      }
      case 'C': {
        if (geplant) {
          color = farben.getFreieBuchung5G();
        } else {
          color = farben.getFreieBuchung5();
        }
        break;
      }
      case 'E': {
        if (geplant) {
          color = farben.getFreieBuchung6G();
        } else {
          color = farben.getFreieBuchung6();
        }
        break;
      }
      case 'I': {
        if (geplant) {
          color = farben.getFreieBuchung7G();
        } else {
          color = farben.getFreieBuchung7();
        }
        break;
      }
    }
    const colorNumber = parseInt(color, 10);
    const colorHexcode = colorNumber.toString(16);
    const colorReturn = this.correctColor(colorHexcode);
    return colorReturn;
  }

  /**@description Berechnet, ob ein Ereignis geplant ist, also in der Zukunft liegt */
  calculateGeplant(now: Date, date: Date) {
    if (now < date) {
      return true;
    } else {
      return false;
    }
  }

  /**@description Füllt führende Nullen auf, tauscht anschließend RRGGBB zu BBGGRR da Delphis Farbmodell so ist, für korrekte HTML Darstellung */
  correctColor(colorReturn: string): string {
    let colorFilled = colorReturn;
    const length = colorReturn.length;
    const rest = 6 - length;
    for (let i = 0; i < rest; i++) {
      colorFilled = '0' + colorFilled;
    }
    const colorRR = colorFilled.substr(0, 2);
    const colorGG = colorFilled.substr(2, 2);
    const colorBB = colorFilled.substr(4, 2);
    const rgb = colorBB + colorGG + colorRR;
    return '#' + rgb;
  }

  /**@description Besorgt die Buchungskategorie: Kommen, Gehen, Auftrag oder Abwesend */
  bookingToBookingType(letzteBuchung: OpenBookingFrame): BookingType {
    if (!letzteBuchung) return BookingType.GEHEN;

    if (letzteBuchung.Art === '1') {
      return BookingType.GEHEN;
    }
    if (!!letzteBuchung.Kennzeichen) {
      return BookingType.FEHLZEIT;
    }
    if (letzteBuchung.Auftrag) {
      return BookingType.AUFTRAGSZEIT;
    }
    if (letzteBuchung) {
      return BookingType.ARBEITSZEIT;
    }
    return BookingType.GEHEN;
  }
}
