/**
 * //TODO:
 * 1.   db-types müssen "ihre eigenen Namen speichern" und
 *      auf diesen wird dann zugegriffen anstatt auf getClassName
 * 2.   weitere Funktionen in den Db-Types für die Abhängigkeiten
 *      müssen geschrieben werden
 * 3.   Ein Tagebuch soll noch einene "Schalter" on/off pro User bekommen
 * 4.   Alle Kommentare mit Tims Logger, derzeit noch "einfach so im Quellcode"
 * 5.   Bisschen mehr kommentieren
 */

export namespace IndexedDBTypes {
  export interface IInitializable {
    Init(params: any);
  }

  export interface IFieldAttributes {
    Name: string;
    TypeName: string;
    IsVisibleInGrid: boolean;
    IsEditable: boolean;
    // ..
  }

  export abstract class SourceClass {
    public static className;
  }

  export abstract class IndexedKeyValueStore extends SourceClass {
    // TODO
  }

  export interface IModelStore {
    STORE: { [className: string]: IModel };
  }

  export interface IModel {
    PK_NAME: string;
    FELDER: { [feldname: string]: IDBTableFieldAttribute };
  }

  export interface IColumnDef {
    headerName: string;
    field: string;
  }

  export interface IValidator {
    isValid(value: string): boolean;
  }

  export interface IFormDef {
    formFieldName: string;
    visible: boolean;
    bgString: string;
    default: string;
    validator: IValidator;
  }

  export abstract class DbType extends IndexedKeyValueStore {
    //#region static Table/Model-Definition

    public static MODEL: IModel;
    private static modelStoreSingleton: IModelStore = { STORE: {} };

    public static GetModelStore(): IModelStore {
      return DbType.modelStoreSingleton;
    }

    public static InitStore(db: any) {
      const className = this.toString();
      console.log('Index fuer ' + className + ' wird angelegt.');
      const modelstore = DbType.GetModelStore().STORE;
      const pkname = modelstore[className].PK_NAME;
      const felder = modelstore[className].FELDER;
      const autoIncrement = pkname === 'AutoKey';
      const store = db.createObjectStore(className, {
        keyPath: pkname,
        autoIncrement,
      });

      store.createIndex(pkname, pkname, {
        unique: true,
      });

      for (const feldName in felder) {
        if (felder[feldName].fieldIsIndex) {
          store.createIndex(feldName, feldName, {
            unique: felder[feldName].fieldIsUnique,
          });
        }
      }
    }

    public static GetFieldList(className = '', includePrimaryKey = false): IFieldAttributes[] {
      const result: IFieldAttributes[] = [];
      if (className === '') {
        className = this.toString();
      }
      const store = DbType.GetModelStore().STORE;

      if (includePrimaryKey) {
        const keyName = store[className].PK_NAME;
        result.push({
          Name: keyName,
          TypeName: 'string',
          IsVisibleInGrid: true,
          IsEditable: false,
        });
      }

      for (const feldName in store[className].FELDER) {
        result.push({
          Name: feldName,
          TypeName: store[className].FELDER[feldName].fieldType,
          IsVisibleInGrid: store[className].FELDER[feldName].fieldIsShownInGrid,
          IsEditable: store[className].FELDER[feldName].fieldCanBeEdited,
        });
      } // */

      return result;
    }

    // @className - Name der von DbType erbenden Klasse, deren Spalten-Definitionen berechnet werden sollen
    // @listOfOverrides - Liste an Spaltendefinitionen die anstelle der errechneten verwendet werden sollen
    //          (headerName === null => Col nicht anzeigen )
    // returns : Liste von Spaltendefinitionen
    public static GetColumnDefs(className = '', listOfOverrides: IColumnDef[] = null): IColumnDef[] {
      let result: IColumnDef[];
      if (listOfOverrides != null) {
        result = Object.assign([], listOfOverrides);
      } else {
        result = [];
      }
      // sammle Namen der ueberschriebenen Defs
      const overwrittenNames = result.map(colDef => colDef.field);

      // Zu ignorierende Columns rausloeschen
      result = result.filter(def => def.headerName != null);

      if (className === '') {
        className = this.toString();
      }

      DbType.GetFieldList(className, true).forEach(fieldAttribute => {
        // Defs fuer sichtbare & nicht ueberschriebene Cols adden
        if (fieldAttribute.IsVisibleInGrid && !(fieldAttribute.Name in overwrittenNames)) {
          result.push({
            field: fieldAttribute.Name,
            headerName: fieldAttribute.Name,
          });
        }
      });
      return result;
    }

    public static GetFormDefs(className = '', listOfOverrides: IFormDef[] = null): IFormDef[] {
      let result: IFormDef[];
      if (listOfOverrides != null) {
        result = Object.assign([], listOfOverrides);
      } else {
        result = [];
      }

      // sammle Namen der ueberschriebenen Defs
      const overwrittenNames = result.map(colDef => colDef.formFieldName);

      // Zu ignorierende Formfields rausloeschen
      result = result.filter(def => def.visible);

      if (className === '') {
        className = this.toString();
      }

      DbType.GetFieldList(className, true).forEach(fieldAttribute => {
        if (fieldAttribute.IsEditable && !(fieldAttribute.Name in overwrittenNames)) {
          result.push({
            formFieldName: fieldAttribute.Name,
            bgString: 'writeHere',
            visible: true,
            default: '',
            validator: null,
          });
        }
      });

      return result;
    }

    //#endregion

    public toDTO(): object {
      return this;
    }
  }

  export abstract class IndexedDBType extends DbType {
    abstract getIndex(): string;
  }

  export abstract class ChangetrackedDBType extends IndexedDBType {
    abstract wasChanged(): boolean;

    // abstract wasDeleted(): boolean;
  }

  export class DBField {
    public FIELD_TYPE: string;
    public Value: any;

    public constructor(type: string, value: any = null) {
      this.FIELD_TYPE = type;
      this.Value = value;
    }
  }

  export interface IDBTableFieldAttribute {
    fieldName: string;
    fieldType: string;
    fieldIsUnique: boolean;
    fieldIsIndex: boolean;
    fieldIsShownInGrid: boolean;
    fieldCanBeEdited: boolean;
  }

  export const KlassenName = name => KlName(name);
  const KlName = name =>
    function (target: Object, key: string | symbol) {
      SourceClass.className = name;
    };

  export const KeyDBField = (type = 'string') => aDBField(type, true, true, null, true);
  export const DataField = (type = 'string', isUnique = false, defaultValue = null) =>
    aDBField(type, isUnique, false, defaultValue, false, true, true);
  export const IndexField = (type = 'string', isUnique = false, defaultValue = null) =>
    aDBField(type, isUnique, false, defaultValue, true, true, true);

  const aDBField = (
    type = 'string',
    isUnique = false,
    isPrimaryKey = false,
    defaultValue = null,
    isIndex = false,
    isShown = true,
    isEditable = false
  ) =>
    function (target: Object, key: string | symbol) {
      const className = SourceClass.className;
      const store = DbType.GetModelStore().STORE;
      if ((<typeof DbType>target).MODEL === undefined) {
        (<typeof DbType>target).MODEL = { PK_NAME: null, FELDER: {} };
      }
      const model = (<typeof DbType>target).MODEL;

      if (store[className] === undefined) {
        store[className] = model;
      }

      if (<DbType>target) {
        if (isPrimaryKey === true && <typeof DbType>target) {
          model.PK_NAME = <string>key;
        } else {
          if (model.FELDER === undefined) {
            model.FELDER = {};
          }

          const attr: IDBTableFieldAttribute = {
            fieldName: <string>key,
            fieldType: type,
            fieldIsUnique: isUnique,
            fieldIsIndex: isIndex,
            fieldIsShownInGrid: isShown,
            fieldCanBeEdited: isEditable,
          };

          model.FELDER[<string>key] = attr;
        }
        if (defaultValue != null && type === defaultValue.constructor.name) {
          target[<string>key] = defaultValue;
        }
      } else {
        console.error('Invalid application of DataField-Decorator on non-DBType-Property');
      }
    };
}
