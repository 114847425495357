import { calcMinutenFromTime as calcMinutesFromTime, convertNumberToWochentag } from '@app/globalServices/time.service';

export class ZEFZeitmodell {
  private MontagVon: string;
  private MontagBis: string;
  private DienstagVon: string;
  private DienstagBis: string;
  private MittwochVon: string;
  private MittwochBis: string;
  private DonnerstagVon: string;
  private DonnerstagBis: string;
  private FreitagVon: string;
  private FreitagBis: string;
  private SamstagVon: string;
  private SamstagBis: string;
  private SonntagVon: string;
  private SonntagBis: string;

  /**@description Prüft, ob die Buchungsdaten innerhalb der Rahmenarbeitszeit liegen */
  isTimeInRahmenarbeitszeit(uhrzeitVon: string, uhrzeitBis: string, startDate: Date, endDate: Date): boolean {
    const startWochentag = convertNumberToWochentag(startDate.getDay());
    const endWochentag = convertNumberToWochentag(endDate.getDay());

    const startMinuten = calcMinutesFromTime(uhrzeitVon);
    const endMinuten = calcMinutesFromTime(uhrzeitBis);

    const rahmenStartVon = parseInt(this[startWochentag + 'Von'], 10);
    const rahmenStartBis = parseInt(this[startWochentag + 'Bis'], 10);

    const rahmenEndeVon = parseInt(this[endWochentag + 'Von'], 10);
    const rahmenEndeBis = parseInt(this[endWochentag + 'Bis'], 10);

    return (
      rahmenStartVon <= startMinuten &&
      startMinuten <= rahmenStartBis &&
      rahmenEndeVon <= endMinuten &&
      endMinuten <= rahmenEndeBis
    );
  }

  public getMontagVon(): string {
    return this.MontagVon;
  }

  public setMontagVon(MontagVon: string): void {
    this.MontagVon = MontagVon;
  }

  public getMontagBis(): string {
    return this.MontagBis;
  }

  public setMontagBis(MontagBis: string): void {
    this.MontagBis = MontagBis;
  }

  public getDienstagVon(): string {
    return this.DienstagVon;
  }

  public setDienstagVon(DienstagVon: string): void {
    this.DienstagVon = DienstagVon;
  }

  public getDienstagBis(): string {
    return this.DienstagBis;
  }

  public setDienstagBis(DienstagBis: string): void {
    this.DienstagBis = DienstagBis;
  }

  public getMittwochVon(): string {
    return this.MittwochVon;
  }

  public setMittwochVon(MittwochVon: string): void {
    this.MittwochVon = MittwochVon;
  }

  public getMittwochBis(): string {
    return this.MittwochBis;
  }

  public setMittwochBis(MittwochBis: string): void {
    this.MittwochBis = MittwochBis;
  }

  public getDonnerstagVon(): string {
    return this.DonnerstagVon;
  }

  public setDonnerstagVon(DonnerstagVon: string): void {
    this.DonnerstagVon = DonnerstagVon;
  }

  public getDonnerstagBis(): string {
    return this.DonnerstagBis;
  }

  public setDonnerstagBis(DonnerstagBis: string): void {
    this.DonnerstagBis = DonnerstagBis;
  }

  public getFreitagVon(): string {
    return this.FreitagVon;
  }

  public setFreitagVon(FreitagVon: string): void {
    this.FreitagVon = FreitagVon;
  }

  public getFreitagBis(): string {
    return this.FreitagBis;
  }

  public setFreitagBis(FreitagBis: string): void {
    this.FreitagBis = FreitagBis;
  }

  public getSamstagVon(): string {
    return this.SamstagVon;
  }

  public setSamstagVon(SamstagVon: string): void {
    this.SamstagVon = SamstagVon;
  }

  public getSamstagBis(): string {
    return this.SamstagBis;
  }

  public setSamstagBis(SamstagBis: string): void {
    this.SamstagBis = SamstagBis;
  }

  public getSonntagVon(): string {
    return this.SonntagVon;
  }

  public setSonntagVon(SonntagVon: string): void {
    this.SonntagVon = SonntagVon;
  }

  public getSonntagBis(): string {
    return this.SonntagBis;
  }

  public setSonntagBis(SonntagBis: string): void {
    this.SonntagBis = SonntagBis;
  }

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
