<app-bottom-sheet-frame [Title]="TitleStyle.name">
  <form [formGroup]="formGroup">
    <mat-form-field style="width: 100%">
      <label>Von: </label>
      <input formControlName="vonDatum" matInput [matDatepicker]="pickerVon" />
      <mat-datepicker-toggle matSuffix [for]="pickerVon"></mat-datepicker-toggle>
      <mat-datepicker #pickerVon></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <label>Bis: </label>
      <input formControlName="bisDatum" matInput [matDatepicker]="pickerBis" [min]="formGroup.value.vonDatum" />
      <mat-datepicker-toggle matSuffix [for]="pickerBis"></mat-datepicker-toggle>
      <mat-datepicker #pickerBis></mat-datepicker>
    </mat-form-field>

    <div class="error" *ngIf="!(checkDateFrameError$ | async)">
      Das <u>Von-Datum</u> darf nicht <br />auf das <u>Bis-Datum</u> folgen!
    </div>

    <app-comment-field formControlName="notiz"></app-comment-field>

    <app-bottom-sheet-button [Action]="ContinueButtonAction$ | async" [ButtonStyle]="ContinueButton"
      >send</app-bottom-sheet-button
    >
  </form>

  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel</app-bottom-sheet-button
  >
</app-bottom-sheet-frame>
