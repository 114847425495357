<div id="bodyContainer" class="bodyContainer">
  <mat-card class="logoContainer" (click)="hiddenFeature()">
    <img class="logoClass" src="assets/icons/my_blueapp_zeiterfassung.svg" />
  </mat-card>

  <!-- SYNC -->
  <mat-card>
    <h3 class="headline">Synchronisation</h3>
    <div class="miniContainer">
      <mat-slide-toggle id="sync-on-start-toggle" (change)="onChangeProgrammStart()" [checked]="programmStart"
        >Bei Programmstart</mat-slide-toggle
      >
    </div>
    <div class="miniContainer">
      <mat-slide-toggle id="sync-auftrag-toggle" (change)="onChangeAuftragSync()" [checked]="auftragSyncVorBuchung"
        >Auftragsdaten vor Buchung</mat-slide-toggle
      >
    </div>

    <mat-form-field class="syncAbContainer miniContainer">
      <label class="labelClass synchronisationAb">Aufträge synchronisieren ab: </label>
      <input
        id="sync-auftrag-range-input"
        [(ngModel)]="auftragStartDate"
        class="auftragInput"
        matInput
        [matDatepicker]="picker1"
        (dateInput)="safeAuftragAbholDatum()"
      />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <div class="miniContainer slider">
      <label *ngIf="syncTaetigkeitenAmount == 600" class="labelClass synchronisationAb"
        >Alle Tätigkeiten synchronisieren</label
      >
      <label *ngIf="syncTaetigkeitenAmount < 600" class="labelClass synchronisationAb"
        >Die letzten
        <span class="marked-text-value">[ {{ syncTaetigkeitenAmount }} ] </span>
        Tätigkeiten synchronisieren:</label
      >
      <mat-slider [min]="100" [step]="100" [max]="600">
        <input
          matSliderThumb
          (ngModelChange)="onChangeSyncTaetigkeitenAmount($event)"
          [(ngModel)]="syncTaetigkeitenAmount"
        />
      </mat-slider>
    </div>
  </mat-card>

  <!-- BUCHUNG -->
  <mat-card>
    <h3 class="headline">Buchung</h3>
    <div class="miniContainer">
      <mat-slide-toggle (change)="onChangeGruppenbuchungen()" [checked]="gruppenbuchungen" id="gruppenbuchungen-toggle"
        >Gruppenbuchungen</mat-slide-toggle
      >
    </div>
  </mat-card>

  <!-- ANZEIGE -->
  <mat-card>
    <h3 class="headline">Anzeige</h3>
    <div class="miniContainer">
      <mat-slide-toggle (change)="onChangeKundenAnzeige()" [checked]="kundenAnzeige" id="kundenAnzeige-toggle"
        >Vollen Kundennamen anzeigen</mat-slide-toggle
      >
    </div>

    <div class="miniContainer">
      <mat-slide-toggle (change)="onChangeAuftragsAnzeige()" [checked]="auftragsAnzeige" id="auftragsAnzeige-toggle"
        >Volle Auftragsbeschreibung anzeigen</mat-slide-toggle
      >
    </div>

    <div class="miniContainer">
      <mat-slide-toggle
        (change)="onChangeTaetigkeitsAnzeige()"
        [checked]="taetigkeitsAnzeige"
        id="taetigkeitsAnzeige-toggle"
        >Volle Tätigkeitsbeschreibung anzeigen</mat-slide-toggle
      >
    </div>
  </mat-card>

  <!-- BARCODE -->
  <mat-card>
    <h3 class="headline">Barcode Präfix</h3>
    <div class="barcode miniContainer">
      <label class="labelClass">Auftrag: </label
      ><input
        id="prefix-auftrag-input"
        matInput
        class="prefixInputs"
        [(ngModel)]="barcodePrefixAuftrag"
        (keyup)="onChangePrefix1()"
      />
      <label class="labelClass">Tätigkeit:</label>
      <input
        id="prefix-taetigkeit-input"
        matInput
        class="prefixInputs"
        [(ngModel)]="barcodePrefixTaetigkeit"
        (keyup)="onChangePrefix2()"
      />
      <label class="labelClass">Grund:</label>
      <input
        id="prefix-grund-input"
        matInput
        class="prefixInputs"
        [(ngModel)]="barcodePrefixAbwesenheit"
        (keyup)="onChangePrefix3()"
      />
    </div>
  </mat-card>

  <!-- Verbindungsinformationen -->
  <mat-card class="connectioninfo">
    <h3 class="headline">Verbindungsinformationen</h3>
    <div class="miniContainer" *ngIf="(connectionInfo$ | async)?.allocation as allocation; else noConnection">
      <label class="labelClass">Installations-ID: {{ allocation.guid }}</label>
      <label class="labelClass">Allokation: {{ allocation.hostname + ':' + allocation.port }}</label>
    </div>

    <ng-template #noConnection>
      <div class="miniContainer">
        <label class="labelClass">keine Verbindung bekannt</label>
      </div>
    </ng-template>
  </mat-card>
</div>
