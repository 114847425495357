import { Injectable, Injector } from '@angular/core';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { getfirst } from '@app/utils/rxjsUtils';
import { ObservableQuery, query } from '@app/utils/rxQueryUtils';
import { Auswertung } from '@entities/Auswertung';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ControllerService } from '../../../globalServices/controller.service';
import { TimeService } from '../../../globalServices/time.service';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { MitarbeiterService } from './ZEFMitarbeiter.service';

@Injectable({
  providedIn: 'root',
})
export class AuswertungService extends StoreControllerServiceBase<Auswertung> {
  protected GetStoreName(): string {
    return Auswertung.StoreName;
  }

  async Sync(emit?: boolean): Promise<string[]> {
    await this.setLongTapRight();
    await this.setMaxTimeBetweenBookings();
    await this.settings.timeSyncRange.set(1);
    const syncRange = await this.settings.timeSyncRange.get();
    const end = new Date(); // today
    const start = this.timeService.addDay(end, syncRange * -1);

    const result = await getfirst(this.getTeamAuswertung(start, end));

    if (result) {
      await this.controllerservice.resetStore(Auswertung.StoreName, result, emit).toPromise();

      await this.settings.currentAuswertungSyncRange.set([start, end]);
      return [Auswertung.StoreName];
    }
    return [];
  }

  protected entityConstructor(raw): Auswertung {
    return new Auswertung(raw);
  }

  constructor(
    private readonly timeService: TimeService,
    private readonly backend: ZEFBackendService,
    private readonly controllerservice: ControllerService,
    private readonly settings: GlobalSettingService,
    private readonly employee: MitarbeiterService,

    protected readonly injector: Injector
  ) {
    super(injector);
  }

  getTeamAuswertung(start: Date, end: Date): Observable<Auswertung[]> {
    return this.employee.currentUserSubordinates$.pipe(
      map(subordinates => subordinates.map(employee => employee.Pnr)),
      switchMap(pnrs => this.getAuswertung(pnrs, start, end))
    );
  }

  getAuswertung(pnrs: string[], start: Date, end: Date): Observable<Auswertung[]> {
    const startDate = this.timeService.getDateFromRawDate(start, true);
    const endDate = this.timeService.getDateFromRawDate(end, true);
    // const pnrString = pnrs.reduce((a, b) => `${a};${b}`);

    return this.backend
      .post<Auswertung[]>(`auswertung/team/${startDate}/${endDate}`, pnrs)
      .pipe(map(response => response.map(auswertung => new Auswertung(auswertung))));
  }

  getAuswertungDay(pnr: string, date: Date): ObservableQuery<Auswertung[]> {
    return query(`auswertung/team/${date}/${pnr}`, () => this.getAuswertung([pnr], date, date));
  }

  observeDayOverview$(pnr: string, date: Date): ObservableQuery<Auswertung[]> {
    return this.inCacheRange$(date).pipe(
      switchMap(
        inRange =>
          inRange
            ? this.observeCachedDayOverview$(pnr, date) // from db
            : this.getAuswertungDay(pnr, date) // from backend
      )
    );
  }

  private inCacheRange$(date: Date): Observable<boolean> {
    return this.settings.currentAuswertungSyncRange.value$.pipe(
      map(tuple => {
        if (!tuple) return false;

        const [start, end] = tuple;
        if (!start || !end) return false;

        return this.timeService.isBetween(start, end, date);
      })
    );
  }

  private observeCachedDayOverview$(pnr: string, date: Date): ObservableQuery<Auswertung[]> {
    const datestring = this.timeService.toDBCompliantDay(date);
    return query(`IDB>${Auswertung.StoreName}/${date}/${pnr}`, () =>
      this.filteredStoreData$(datestring, 'Tag').pipe(
        map(auswertungen => auswertungen.filter(auswertung => auswertung.Pnr === pnr))
      )
    );
  }

  async setMaxTimeBetweenBookings() {
    const maxTimeBetweenBookings = await this.backend.post<number>('GetMaxStempelzeit').pipe(first()).toPromise();
    await this.settings.maxBookingTime.set(maxTimeBetweenBookings);
  }
  async setLongTapRight() {
    const LongTapRight = await this.backend.post<boolean>('GetLongTapRight').pipe(first()).toPromise();
    await this.settings.longTapRight.set(LongTapRight);
  }
}
