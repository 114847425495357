<div>
  <div class="titel">Tageskennzeichen</div>

  <form [formGroup]="tkennzeichenForm">
    <mat-form-field class="fullWidth">
      <label>Datum: </label>
      <input formControlName="datum" matInput [matDatepicker]="pickerVon" />
      <mat-datepicker-toggle matSuffix [for]="pickerVon"></mat-datepicker-toggle>
      <mat-datepicker #pickerVon></mat-datepicker>
    </mat-form-field>

    <app-autocomplete-input
      formControlName="kennzeichen"
      [Parameters]="tkennzeichenParameters"
      [showFullLengthText]="true"
    >
      <ng-template #optionTemplate let-option
        >{{ option?.Kennzeichen }} | {{ option?.Beschreibung }} | {{ option?.Wert }}</ng-template
      >
    </app-autocomplete-input>
    <mat-form-field *ngIf="this.tkennzeichenForm.value.kennzeichen?.Changeable" class="fullWidth">
      <mat-label>TK-Wert: </mat-label>
      <input
        matInput
        type="number"
        formControlName="tkWert"
        [placeholder]="this.tkennzeichenForm.value.kennzeichen?.Wert"
      />
    </mat-form-field>
  </form>

  <app-bottom-sheet-button [Action]="SaveButtonAction$ | async" [ButtonStyle]="SaveButtonStyle$ | async"
    >send</app-bottom-sheet-button
  >
  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel</app-bottom-sheet-button
  >
</div>
