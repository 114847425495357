import { Component, ContentChild, ElementRef, forwardRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { OneOrMany } from '@app/utils/types';
import { BaseFormComponent } from '../BaseFormComponent';
import { getfirst, taplog } from '@app/utils/rxjsUtils';
import { median, median as medianObject } from '@app/utils/utils';

export interface AutocompleteParameters<T> {
  /**@description maps entity to filterable text for autocompletion*/
  toSearchText: (t: T) => string;

  /**@description maps entity to text displayed on selection */
  displayFunc: (t: T) => string;

  /**@description disables empty/null autoselect option */
  noEmptyOption?: boolean;

  /**@description background text for empty typeahead */
  Placeholder?: string;
  /**Observable aller möglichen Objekte für dieses Formfield, vgl. Datasource einer Devexpress Komponente */
  options$: Observable<T[]>;

  /**@description default entity preselected on init */
  preselection?: T;

  /**@description tries to map current typeahead-text to entity
   */
  inputAsSelection?: (inputText: string) => Promise<T>;
}

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteInputComponent<TEntity> extends BaseFormComponent<TEntity> implements OnInit {
  constructor() {
    super();
  }

  readonly autoCompleteControl = new UntypedFormControl();

  @Input() Parameters: AutocompleteParameters<TEntity>;
  @Input() showFullLengthText: boolean = false;

  @ContentChild('optionTemplate', { static: false })
  optionTemplate: TemplateRef<TEntity>;

  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  @ViewChild('matInput') matInput: ElementRef;

  protected readonly SelectionSubject = new BehaviorSubject<TEntity>(null);
  private readonly InputTextSubject = new BehaviorSubject<string>('');
  options$: Observable<TEntity[]>;

  async ngOnInit() {
    await super.ngOnInit();

    this.options$ = this.Parameters?.options$.pipe(switchMap(options => this.applyTextFilter(options)));

    this.applyPreselection();
  }

  private applyTextFilter(options: TEntity[]): Observable<TEntity[]> {
    return this.InputTextSubject.pipe(
      // taplog('filtering by'),
      map(txt => options.filter(opt => this.Parameters.toSearchText(opt).indexOf(txt?.toLowerCase()) >= 0))
    );
  }

  applyPreselection() {
    if (this.Parameters.preselection === null || this.Parameters.preselection === undefined) return;
    this.writeValue(this.Parameters.preselection);
  }

  protected getSelection$(): Observable<OneOrMany<TEntity>> {
    return this.SelectionSubject.asObservable();
  }

  writeValue(obj: TEntity): void {
    if ((obj as unknown as string) === '') return;
    this.SelectionSubject.next(obj);
    this.autoCompleteControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.registerSubscription(this.SelectionSubject.pipe(tap(entity => fn(entity))).subscribe());
  }

  protected onTouched = () => {};
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.autoCompleteControl.disable();
    } else {
      this.autoCompleteControl.enable();
    }
  }

  async onInput(text: string) {
    this.onTouched();
    this.InputTextSubject.next(text);
  }

  async onSelect(entity: TEntity) {
    await this.selectEntity(entity);
    this.matInput.nativeElement.blur();
  }

  async selectEntity(entity: TEntity) {
    if (entity === null) {
      await this.onInput('');
    }
    this.SelectionSubject.next(entity);
    this.onTouched();
  }

  async onBlur() {
    // notwendig fuer Validierung
    if (this.matAutocomplete.isOpen) return;
    if (typeof this.autoCompleteControl.value === 'string') {
      if (this.Parameters?.inputAsSelection) {
        const inputSelection = await this.Parameters?.inputAsSelection(this.autoCompleteControl.value);
        if (!inputSelection) await this.resetSelection();
        else this.writeValue(inputSelection);
      }
    }
  }

  async resetSelection() {
    const usePreselection = this.Parameters?.noEmptyOption && this.Parameters?.preselection !== undefined;

    this.autoCompleteControl.setValue(usePreselection ? this.Parameters?.preselection : '');
    await this.selectEntity(usePreselection ? this.Parameters?.preselection : null);
    this.InputTextSubject.next('');
  }
}
