<app-bottom-sheet-frame [Title]="TitleStyle().name">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <app-mitarbeiter-selector formControlName="mitarbeiter"></app-mitarbeiter-selector>

    <ng-container *ngIf="syncFinished$ | async">
      <app-autocomplete-input
        #kundeInput
        formControlName="kunde"
        [Parameters]="customerParameters"
        [showFullLengthText]="kundenAnzeige$ | async"
      >
        <ng-template #optionTemplate let-option>{{ option?.getBeschreibung() || option?.getNummer() }}</ng-template>
      </app-autocomplete-input>
      <app-autocomplete-input
        #auftragInput
        formControlName="auftrag"
        [Parameters]="auftragParameters"
        [showFullLengthText]="auftragsAnzeige$ | async"
      >
        <ng-template #optionTemplate let-option>
          {{
            option?.getNummer() && option?.getBeschreibung()
              ? option.getNummer() + ' | ' + option.getBeschreibung()
              : option?.getNummer() || option?.getBeschreibung()
          }}
        </ng-template>
      </app-autocomplete-input>
      <app-autocomplete-input
        #taetigkeitInput
        formControlName="taetigkeit"
        [Parameters]="taetigkeitParameters"
        [showFullLengthText]="taetigkeitsAnzeige$ | async"
      >
        <ng-template #optionTemplate let-option>
          {{
            option?.getNummer() && option?.getBeschreibung()
              ? option.getNummer() + ' | ' + option.getBeschreibung()
              : option?.getNummer() || option?.getBeschreibung()
          }}
        </ng-template>
      </app-autocomplete-input>
    </ng-container>

    <app-comment-field formControlName="kommentar"></app-comment-field>

    <app-bottom-sheet-button [Action]="submitButtonAction$ | async" [ButtonStyle]="SaveAuftragButton()"
      >send</app-bottom-sheet-button
    >
  </form>

  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel</app-bottom-sheet-button
  >
</app-bottom-sheet-frame>
