<div class="container">
  <div class="step" (click)="backwards()"><mat-icon class="icon">chevron_left</mat-icon></div>
  <div class="date" (click)="clickDate(picker)" *ngIf="validDate$ | async as validDate">
    <div class="month">{{ validDate.monthName }}</div>
    <div class="year">{{ validDate.year }}</div>
  </div>
  <div class="step" (click)="forwards()"><mat-icon class="icon">chevron_right</mat-icon></div>

  <div [hidden]="true">
    <mat-form-field>
      <mat-label>monthpicker</mat-label>
      <input matInput [(ngModel)]="date" [matDatepicker]="picker" (dateChange)="emitDateChange($event)" />
      <mat-hint>MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
      <mat-datepicker #picker [startView]="'year'" (monthSelected)="monthChanged($event, picker)"> </mat-datepicker>
    </mat-form-field>
  </div>
</div>
