import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { isNullOrUndefined } from '@app/utils/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class A2hsService {
  /**
   * Ob die App installiert ist.
   */
  private isInstalled = this.isLaunchedFromHomescreen();

  /**
   * Das gespeicherte beforeinstallprompt Event, was genutzt werden kann um die Installation zu triggern.
   */
  private deferredPromptEvent: any = null;

  constructor(private updates: SwUpdate) {}

  /**
   * Registriert die events für A2HS.
   * Die Funktion sollte so früh wie möglich gecallt werden.
   *
   */
  public initEventHandlers() {
    return new Promise<void>((resolve, reject) => {
      window.addEventListener('beforeinstallprompt', e => {
        // Verhindern, das alte Browserversionen den Prompt sofort zeigen.
        e.preventDefault();

        // Event für späteren Zeitpunkt abspeichern
        this.deferredPromptEvent = e;

        // this.logger.debug('beforeinstallprompt event wurde gespeichert.');

        resolve();
      });

      window.addEventListener('appinstalled', e => {
        // this.logger.info('App wurde installiert');
        this.isInstalled = true;
      });
    });
  }

  /**
   * Lässt den Service Worker einen Update-Check durchführen.
   * Dadurch wird
   */
  public async checkForUpdate() {
    if (environment.configurationname !== 'serve' && environment.runtimeEnvironment !== 'desktop') {
      await this.updates.checkForUpdate();
    } else {
      console.log('ServiceWorker nicht verfügbar beim Serven');
    }
  }

  /**
   * Registriert einen Listener für den Fall das der ServiceWorker ein Update
   * gefunden hat. Um das Update zu nutzen, muss dann ein reload der Seite ausgeführt werden.
   */
  public registerUpdateListener(updateCallback) {
    this.updates.available.subscribe(updateCallback);
  }

  /**
   * Prüft ob die App installiert wurde und vom Homescreen gestartet wurde.
   */
  public isLaunchedFromHomescreen(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  /**
   * Prüft ob das notwendige Event vorhanden ist, damit eine Installation getriggert werden kann.
   */
  public hasDeferredEvent(): boolean {
    return this.deferredPromptEvent != null;
  }

  /**
   * Triggert die Meldung für die Installation zum Homescreen. Der User kann akzeptieren
   * oder auch ablehnen.
   */
  public async triggerInstall() {
    // this.logger.debug('A2HS ausgelöst');

    // if (this.deferredPromptEvent == null) {
    //   // this.logger.error('Installation kann nicht getriggert werden, weil kein deferred prompt event vorhanden ist.');
    //   return;
    // }

    // triggers the install message
    if (isNullOrUndefined(this.deferredPromptEvent)) {
      return;
    }
    this.deferredPromptEvent.prompt();

    const choiceResult = await this.deferredPromptEvent.userChoice;
    if (choiceResult.outcome === 'accepted') {
      // this.logger.info('A2HS akzeptiert. Starte Installlation...');
      this.isInstalled = true;
    } else {
      // this.logger.info('A2HS abgelehnt. KEINE Installation.');
      this.isInstalled = false;
    }
    this.deferredPromptEvent = null;
  }
}
