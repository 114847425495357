import { Injectable } from '@angular/core';
import { MitarbeiterService } from '@stores/ZEFMitarbeiter.service';
import { first, map } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { GlobalSettingService } from './settings/global-setting.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  allowed: boolean;

  constructor(
    private readonly globalSettingService: GlobalSettingService,
    private readonly dialogService: DialogService,
    private readonly employeeService: MitarbeiterService
  ) {}

  getCurrentPosition(options = { timeout: 3000, enableHighAccuray: true }): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  /**@description Holt,wenn erlaubt und möglich, Latitude und Longitude - Ansonsten setzt es den Wert 1 */
  async getLatitudeAndLongitude(): Promise<{ latitude: number; longitude: number }> {
    this.allowed = await this.employeeService.currentUser$
      .pipe(
        map(user => user.GPSTracking),
        first()
      )
      .toPromise();
    if (navigator.geolocation && this.allowed) {
      try {
        const position = await this.getCurrentPosition();
        const coords = position.coords;
        const latitude = coords.latitude;
        const longitude = coords.longitude;
        return { latitude, longitude };
      } catch (error) {
        this.showPositionError();
        return { latitude: 1, longitude: 1 };
      }
    }
    return { latitude: 1, longitude: 1 };
  }

  /**@description Fehlermeldung wenn etwas bei der Positionsbestimmung schief lief */
  showPositionError() {
    void this.dialogService.ShowInformation(
      'Lokalisationsfehler',
      'Beim Versuch die Ortungsdaten zu bestimmen ist ein Fehler aufgetreten. ' +
        'Die Ortungsdaten wurden nicht korrekt an die Buchung übergeben. Die Buchung selbst war aber erfolgreich. '
    );
  }
}
