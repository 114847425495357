import { Observable, Subscription } from 'rxjs';
import { OnDestroy, Input, OnChanges, SimpleChanges, Component, OnInit } from '@angular/core';
import { ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { map } from 'rxjs/operators';
import { OneOrMany } from '@app/utils/types';

@Component({ template: 'BaseFormComponent' })
export abstract class BaseFormComponent<TValue> implements OnInit, OnDestroy, ControlValueAccessor {
  async ngOnInit() {}

  protected abstract getSelection$(): Observable<OneOrMany<TValue>>;

  protected selectedOrFirst$() {
    return this.getSelection$().pipe(
      map(selection =>
        Array.isArray(selection) && selection.length > 0 ? (selection[0] as TValue) : (selection as TValue)
      )
    );
  }

  private subSink: Subscription = new Subscription();

  protected registerSubscription(subscription: Subscription) {
    this.subSink.add(subscription);
  }

  //#region ControlValueAccessor

  abstract writeValue(obj: any): void;
  abstract registerOnChange(fn: any): void;
  abstract registerOnTouched(fn: any): void;
  abstract setDisabledState?(isDisabled: boolean): void;

  //#endregion

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
