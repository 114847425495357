import { Subscription } from 'rxjs';
import { OnInit, OnDestroy, Injector, Component } from '@angular/core';
import { ModalActionType, ModalQueryResult } from '@app/utils/types';

@Component({ template: '{{"BaseModalComponent"}}' })
export abstract class BaseModalComponent<TParameter, TResult> implements OnInit, OnDestroy {
  constructor(protected readonly injector: Injector) {}

  protected readonly Parameter: TParameter;

  async ngOnInit() {}

  protected abstract Close(result: ModalQueryResult<TResult>);

  private readonly subSink: Subscription = new Subscription();

  protected registerSubscription(sub: Subscription) {
    this.subSink.add(sub);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  protected Ok() {
    this.Close({ Type: ModalActionType.OK });
  }

  protected Cancel() {
    this.Close({ Type: ModalActionType.CANCEL });
  }

  protected Yes() {
    this.Close({ Type: ModalActionType.YES });
  }

  protected No() {
    this.Close({ Type: ModalActionType.NO });
  }

  protected Recall(recallFunc: () => Promise<ModalQueryResult<TResult>>) {
    this.Close({ Type: ModalActionType.RECALL, Recall: recallFunc });
  }
}
