import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css'],
})
export class ImpressumComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  clickedClose() {
    void this.router.navigate(['/menu4']);
  }
}
