<div class="grid-antraege">
  <div class="statusSelector">
    <app-status-selector
      [label]="'Antragsstatus'"
      [options]="antragsStati"
      (selectionChange)="selectedStatusSubject.next($event)"
    ></app-status-selector>
  </div>

  <div id="bodyContainer" class="bodyContainer">
    <mat-accordion class="myAccordion" *ngIf="antraege$ | async as antraege">
      <mat-expansion-panel
        (afterExpand)="scrollInto(i)"
        id="expPanel"
        class="mat-expansion-panel2 nthPanel"
        *ngFor="let antrag of antraege; let i = index"
      >
        <mat-expansion-panel-header id="header">
          <div class="antrag-list-element-header">
            <div class="antragsArt">
              <div>{{ antrag.ArtReadable }}</div>
              <div class="status">{{ antrag.StatusReadable }}</div>
            </div>
            <div class="antragsDatum">
              <a>{{ antrag.getVonDateString() }} - {{ antrag.getBisDateString() }}</a>
            </div>
          </div>
        </mat-expansion-panel-header>

        <mat-form-field class="fullWidth">
          <mat-select [disabled]="true" placeholder="Antragsart" [value]="antrag.ArtReadable">
            <mat-option *ngFor="let antragsArt of antragsArtenForSelecting" [value]="antragsArt">
              {{ antragsArt }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="fullWidth">
          <label>Von: </label>
          <input [(ngModel)]="antrag.vonDate" matInput [matDatepicker]="pickerVon" [disabled]="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerVon"></mat-datepicker-toggle>
          <mat-datepicker #pickerVon></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="fullWidth">
          <label>Bis: </label>
          <input [(ngModel)]="antrag.bisDate" matInput [matDatepicker]="pickerBis" [disabled]="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerBis"></mat-datepicker-toggle>
          <mat-datepicker #pickerBis></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="antrag.Text" class="fullWidth">
          <textarea [disabled]="true" matInput>{{ antrag.Text }}</textarea>
        </mat-form-field>

        <button
          [disabled]="antrag.StatusReadable == 'storniert' || antrag.StatusReadable == 'Stornierung gewünscht'"
          class="storniereCompleteAntrag"
          (click)="storniereCompleteAntrag(antrag)"
          mat-button
        >
          <mat-icon class="stornierIcon">remove_circle</mat-icon>
          <a>Antrag stornieren</a>
        </button>
      </mat-expansion-panel>

      <div *ngIf="antraege.length === 0" class="keinEintragGefundenContainer">
        <img class="handcardImage" src="assets/icons/hand_card_grey.png" />
        <br />
        <a class="antragsText1a">Es sind noch keine Anträge vorhanden</a> <br />
      </div>
    </mat-accordion>
  </div>
</div>

<button mat-fab class="addAntrag" (click)="openBottomSheet()">
  <mat-icon>add</mat-icon>
</button>
