import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Antrag, ReadableAntragsArt, ReadableAntragsStatus } from '@entities/Antrag';
import { AntraegeService } from 'src/app/pages/TabGroup/shared/ZEFAntraege.service';
import { AntragBuchenComponent } from '../bottomsheet/antrag_buchen/antrag-buchen.component';
import { DialogService } from '@app/globalServices/dialog.service';
import { SyncService } from '@app/globalServices/sync.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { taplog } from '@app/utils/rxjsUtils';

export type AntragsStatusFilter = 'alle' | ReadableAntragsStatus;

@Component({
  selector: 'app-antraege',
  templateUrl: './antraege.component.html',
  styleUrls: ['./antraege.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AntraegeComponent implements OnInit {
  antragsStati: AntragsStatusFilter[] = [
    'alle',
    'offen',
    'genehmigt',
    'in Bearbeitung',
    'nicht genehmigt',
    'erledigt',
    'Stornierung gewünscht',
    'storniert',
  ];

  antragsArtenForSelecting: ReadableAntragsArt[] = [
    'Urlaub',
    'Urlaub halber Tag',
    'Zeitausgleich',
    'Sonderurlaub',
    'Zeitkorrektur',
    'Dienstgang',
    'Schule',
    'Krankheit',
    'Sonstiges',
  ];

  statusFilter: AntragsStatusFilter = 'alle';
  readonly selectedStatusSubject = new BehaviorSubject<AntragsStatusFilter>('alle');
  readonly selectedStatusFilter$ = this.selectedStatusSubject.pipe(distinctUntilChanged());

  readonly antraege$ = combineLatest([this.selectedStatusFilter$, this.antraegeService.currentUserAntraege$]).pipe(
    map(([filter, antraege]) => {
      return antraege
        .filter(antrag => filter === 'alle' || antrag.StatusReadable === filter)
        .sort(this.antraegeService.compareAntraege);
    })
  );

  constructor(
    private readonly antraegeService: AntraegeService,
    private readonly dialogService: DialogService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly syncService: SyncService
  ) {}

  async ngOnInit() {}

  /**@description Öffnet die Option einen neuen Antrag zu erstellen */
  async openBottomSheet() {
    const bottomsheetRef = this.bottomSheet.open(AntragBuchenComponent);
    await bottomsheetRef.afterDismissed().toPromise();
  }

  /**@description Scrollt in das jeweils geöffnete Panel */
  scrollInto(index) {
    const header = document.getElementsByClassName('mat-expansion-panel-header');
    const correctHeader = header[index];
    correctHeader.scrollIntoView();
  }

  /**@description Setze den AntragsStatus auf "Stornierung gewünscht", die Flag auf 0(damit dieser Antrag neu hochgeladen wird) aktualisiere diesen Antrag in der Datenbank */
  async storniereCompleteAntrag(antrag: Antrag) {
    const behalten = await this.dialogService.QueryYesNo(
      'Achtung',
      'Möchten Sie diesen Antrag wirklich stornieren?',
      'Behalten',
      'Stornieren'
    );

    if (behalten) {
      return;
    }
    antrag.setStatus(5);
    antrag.setChanged(true);

    try {
      await this.antraegeService.setAntrag(antrag, true);
    } catch (error) {
      await this.dialogService.ShowError(error);
    }

    try {
      await this.refreshAntraege();
    } catch (error) {
      await this.dialogService.ShowError(error);
      await this.dialogService.ShowInformation(
        'Änderung gespeichert.',
        'Ihre Änderung wurde lokal zwischengespeichert.'
      );
    }
  }

  private async refreshAntraege() {
    const antragsSync = this.syncService.antraegeSync;
    await this.syncService.sync([antragsSync]);
  }
}
