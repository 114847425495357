<app-team-selector-tabs></app-team-selector-tabs>

<div class="journalContainer" id="journalContainer">
  <ng-container *ngIf="query$ | async as model" [ngSwitch]="model.status">
    <div *ngSwitchDefault class="spinner-container">
      <app-spinner></app-spinner>
    </div>

    <app-background-notice
      *ngSwitchCase="'error'"
      [image]="'assets/icons/info_white.png'"
      [message]="model.error['name']"
      [submessage]="model.error['message']"
    ></app-background-notice>

    <ng-container *ngSwitchCase="'success'">
      <app-background-notice
        *ngIf="model.data.length < 1"
        [image]="'assets/icons/history2_grey.png'"
        [message]="'Keine Buchungen'"
        [submessage]="'für diesen Tag vorhanden'"
      ></app-background-notice>

      <div *ngIf="model.data.length > 0" id="container" class="container">
        <div matRipple class="buchungsKarte" *ngFor="let stempelung of model.data" (click)="editBooking(stempelung)">
          <div class="artZeitContainer">
            <div class="artZeitContainerFlex">
              <div class="art">
                {{ stempelung.Zeitbezeichnung }}
              </div>
              <div class="stempelzeit">
                {{ getTimeString(stempelung) }}
              </div>
              <div
                *ngIf="stempelung.AuftragsNummer"
                [ngClass]="{ auftrag: true, fullLengthText: auftragsAnzeige$ | async }"
              >
                {{ getAuftragDescription$(stempelung) | async }}
              </div>
              <div
                *ngIf="stempelung.TaetigkeitsNummer"
                [ngClass]="{ taetigkeit: true, fullLengthText: taetigkeitsAnzeige$ | async }"
              >
                {{ getTaetigkeitDescription$(stempelung) | async }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<button mat-fab class="addBuchung" (click)="addBooking()">
  <mat-icon>add</mat-icon>
</button>

<app-date-selector-band></app-date-selector-band>
