import { Observable, of, throwError, timer, OperatorFunction } from 'rxjs';
import { switchMap, mergeMap, catchError, tap, first, concatMap, map, filter } from 'rxjs/operators';

/**@description Strategie-Generator für rxjs retryWhen
 * @param maxRetryAttempts Maximale Anzahl retries
 * @param duration Delay zwischen retries
 * @param retryCondition static Kondition für retry
 * @param errorCondition Prüfung ob Error Retry zulässt
 */
export const retryStrategy =
  ({
    maxRetryAttempts = 3,
    duration = 3000,
    retryCondition = () => true,
    errorCondition = () => true,
  }: {
    maxRetryAttempts?: number;
    duration?: number;
    retryCondition?: () => boolean | Observable<boolean>;
    errorCondition?: (error) => boolean;
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryCounter = i + 1;
        return of(errorCondition(error) && retryCounter <= maxRetryAttempts).pipe(
          switchMap(retry => {
            if (!retry) {
              return of(false);
            }
            const condition = retryCondition();
            let result: Observable<boolean>;
            if (typeof condition === 'boolean') {
              result = of(condition as boolean);
            } else {
              result = condition as Observable<boolean>;
            }
            return result;
          }),
          switchMap(retry => (retry ? timer(duration) : throwError(error)))
        );
      })
    );
  };

export const mapErrorTo = <T>(f: (err: any) => T): OperatorFunction<T, T> => {
  return src => src.pipe(catchError(err => of(f(err))));
};

export const switchMapErrorTo = <T>(f: (err: any) => Observable<T>): OperatorFunction<T, T> => {
  return src => src.pipe(catchError(err => f(err)));
};

export const errorAsFalse = mapErrorTo<boolean>(() => false);

export const taplog =
  <T>(title?: string) =>
  (src: Observable<T>) => {
    return title ? src.pipe(tap(value => console.log(title, value))) : src.pipe(tap(value => console.log(value)));
  };

export const getfirst = <T>(obs: Observable<T>): Promise<T> => {
  return obs.pipe(first()).toPromise();
};

export const afterValue =
  <T>(f: () => void, pos: number) =>
  (src: Observable<T>) => {
    return src.pipe(
      concatMap((val, index) => {
        return index === pos ? of(val).pipe(tap(f)) : of(val);
      })
    );
  };

export const afterFirst = <T>(f: () => void) => afterValue<T>(f, 0);

export const firstValueOrError =
  <T>(func: () => void) =>
  (src: Observable<T>) => {
    let exectuted = false;
    const limitedFunc = () => {
      if (!exectuted) {
        func();
        exectuted = true;
      }
    };

    return src.pipe<T, T>(
      catchError(err => {
        limitedFunc();
        return throwError(err);
      }),
      afterFirst(limitedFunc)
    );
  };

export const not$ = (src: Observable<boolean>): Observable<boolean> => {
  return src.pipe(map(bool => !bool));
};

export const isEmpty$ = <T>(src: Observable<T[]>): Observable<boolean> => {
  return src.pipe(map(arr => !arr || arr.length < 1));
};

/** @description if fFilter(t) do fTap(t) and filter out value */
export const splitOff = <T>(fFilter: (t: T) => boolean, fTap: (t: T) => void): OperatorFunction<T, T> => {
  return src =>
    src.pipe(
      tap(t => {
        if (fFilter(t)) fTap(t);
      }),
      filter(t => !fFilter(t))
    );
};
