import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-background-notice',
  templateUrl: './background-notice.component.html',
  styleUrls: ['./background-notice.component.scss'],
})
export class BackgroundNoticeComponent implements OnInit {
  @Input() image: string;
  @Input() message: string;
  @Input() submessage: string;

  constructor() {}

  ngOnInit() {}
}
