import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DialogService } from '@app/globalServices/dialog.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { DomainError, DomainErrorCode } from '@app/utils/ErrorHandling/DomainError';
import { MonthPickerFormat } from '@components/reuseable/monthpicker/monthpicker.component';
import { TKennzeichenBooking } from '@entities/TKennzeichen';
import { TKennzeichenService } from '@stores/ZEFTKennzeichen.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  TKennzeichenBuchenComponent,
  TKennzeichenBuchenParameter,
} from './tkennzeichen-buchen/tkennzeichen-buchen.component';

@Component({
  selector: 'app-tkennzeichen',
  templateUrl: './tkennzeichen.component.html',
  styleUrls: ['./tkennzeichen.component.scss'],
})
export class TkennzeichenComponent implements OnInit {
  constructor(
    private bottomSheet: MatBottomSheet,
    private tkennzeichenService: TKennzeichenService,
    private userSessionService: UserSessionService,
    private dialogService: DialogService
  ) {}

  selectedMonthSubject = new BehaviorSubject<MonthPickerFormat>(null);
  triggerSyncSubject = new BehaviorSubject<boolean>(null);
  bookedTKennzeichenSubject = new BehaviorSubject<TKennzeichenBooking[]>([]);

  bookedTKennzeichen$ = combineLatest([
    this.bookedTKennzeichenSubject.asObservable(),
    this.tkennzeichenService.getTKennzeichen$,
  ]).pipe(
    switchMap(async ([tkbookings, tkennzeichen]) => {
      tkbookings.map(
        async booking =>
          (booking.Beschreibung = tkennzeichen.find(tk => tk.Kennzeichen == booking.Kennzeichen)?.Beschreibung)
      );
      return tkbookings;
    }),
    map(tkbookings => {
      tkbookings.map(tk => {
        return (tk.Wert = tk.Wert?.split(/[,.]/).length == 1 ? tk.Wert + ',00' : tk.Wert.replace('.', ','));
      });

      return tkbookings;
    })
  );

  SyncSubscription: Subscription;

  readonly Sync$ = combineLatest([
    this.selectedMonthSubject.asObservable(),
    this.userSessionService.pnr$,
    this.triggerSyncSubject.asObservable(),
  ]).pipe(
    switchMap(async ([selectedDate, pnr]) => {
      const bookedTKennzeichen = await this.dialogService.ShowSpinnerWhile(
        this.tkennzeichenService.getTKennzeichenBookingForPnr(pnr, selectedDate.month, selectedDate.year),
        'Lade Tageskennzeichen'
      );

      this.bookedTKennzeichenSubject.next(bookedTKennzeichen);
    })
  );

  async editBooking(tkennzeichenBooking: TKennzeichenBooking) {
    const data: TKennzeichenBuchenParameter = {
      tkennzeichungBooked: tkennzeichenBooking,
      edit: true,
    };

    const bottomsheetRef = this.bottomSheet.open(TKennzeichenBuchenComponent, {
      data,
    });
    await bottomsheetRef.afterDismissed().toPromise();
    this.triggerSyncSubject.next(null);
  }

  async deleteBooking(tkennzeichenBooking: TKennzeichenBooking) {
    await this.dialogService
      .ShowSpinnerWhile(
        this.tkennzeichenService.deleteTKennzeichenBooking(tkennzeichenBooking),
        'Tageskennzeichen löschen',
        'Tageskennzeichen wird gelöscht!'
      )
      .then(async res => {
        if (res) {
          await this.dialogService.ShowInformation('Tageskennzeichen löschen', 'Tageskennzeichen wurde gelöscht!');
        }
      })
      .catch(async error => {
        if (error instanceof DomainError && error.ErrorCode === DomainErrorCode.MonthAlreadyClosed) {
          await this.dialogService.ShowError(error);
        } else {
          await this.dialogService.ShowInformation(
            'Fehler beim Löschen',
            'Ihr Tageskennzeichen konnte nicht gelöscht werden.'
          );
        }
      });
    this.triggerSyncSubject.next(null);
  }

  ngOnInit(): void {
    this.SyncSubscription = this.Sync$.subscribe();
  }

  ngOnDestroy(): void {
    this.SyncSubscription.unsubscribe();
  }

  pickedMonth(formattedDate: MonthPickerFormat) {
    this.selectedMonthSubject.next(formattedDate);
  }

  scrollInto(index) {
    // const header = document.getElementsByClassName(
    // 	'mat-expansion-panel-header'
    // );
    // const correctHeader = header[index];
    // correctHeader.scrollIntoView();
  }

  /**@description Öffnet die Option einen neuen Antrag zu erstellen */
  async openBottomSheet() {
    const bottomsheetRef = this.bottomSheet.open(TKennzeichenBuchenComponent);
    await bottomsheetRef.afterDismissed().toPromise();
    this.triggerSyncSubject.next(null);
  }
}
