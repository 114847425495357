import { Injectable } from '@angular/core';
import { ZEFBackendService } from './webservice-connection-services/zef-backend.service';
import { DatabaseService, EntityChangeEvent } from './database.service';
import { IndexedDBTypes } from '@entities/dbType';
import { map, switchMap, finalize, tap, filter, startWith, mapTo } from 'rxjs/operators';
import { Subject, Observable, from, of, merge } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControllerService {
  constructor(private readonly backendService: ZEFBackendService, private readonly dbService: DatabaseService) {}

  //#region Refresh

  private readonly storeRefreshSubject = new Subject<string[]>();
  private readonly refreshes$ = this.storeRefreshSubject.pipe();

  /**@description Meldet durchgeführte Aktualisierung/Synchronisierung eines Stores */
  storeRefresh$(storeNames: string[]): Observable<string[]> {
    return this.refreshes$.pipe(
      filter(namesEvent => {
        for (const name of namesEvent) {
          if (storeNames.includes(name)) return true;
        }
        return false;
      })
    );
  }

  /**@description filters store-update-events for given name, including(!) initial emit */
  observeStore$(observedStoreNames: string[]): Observable<string[]> {
    return this.storeRefresh$(observedStoreNames).pipe(startWith(observedStoreNames));
  }

  /**@description emits the given store-names in an store-update-event */
  reportSync(syncronizedStores: string[]) {
    if (syncronizedStores && syncronizedStores.length > 0) {
      this.storeRefreshSubject.next(syncronizedStores);
    }
  }

  //#endregion

  //#region Select map changes to key:value and where-filter

  /**@description loads data from db-index and re-load and emits it when store or entity with fitting key gets updated */
  filteredObservation$<T = any>(storeName: string, keyValue, keyName: string, ctor?: (row: any) => T): Observable<T[]> {
    const refresh$ = this.filteredRefresh$(storeName, keyName, keyValue).pipe(startWith());

    return refresh$.pipe(
      switchMap(_ => from(this.dbService.getData(storeName, keyValue, keyName))),
      map(arr => (ctor ? arr.map(row => ctor(row)) : arr))
    );
  }

  /**@description combines store-update event and filtered entity-change event */
  filteredRefresh$(storeName: string, keyValue, keyName: string): Observable<void> {
    const entityFilter = (entity): boolean => entity && entity[keyName] === keyValue;

    const changesFilter = (changes: EntityChangeEvent[]): boolean =>
      changes && !!changes.find(change => entityFilter(change.new) || entityFilter(change.old));

    const entityChangeObservation$ = this.dbService.changes$.pipe(
      filter(changes => !changes.find(change => change.storeName === storeName)),
      filter(changesFilter)
    );

    const storeObservation$ = this.observeStore$([storeName]).pipe();
    const combinedObservation$ = merge(entityChangeObservation$, storeObservation$).pipe(mapTo(null));

    return combinedObservation$;
  }

  //#endregion

  //#region Webservice

  // ################	Webservice Zugriff ###############################################################//

  /**
   * Hole neuste Data vom Webservice und speichere diese in der jeweiligen Tabelle
   **/
  public async refreshStore(storeName: string, url: string, emitRefresh?: boolean): Promise<void> {
    await this.backendService
      .post<any[]>(url)
      .pipe(
        switchMap(result => (result ? this.dbService.setData(storeName, result) : of())),
        finalize(() => {
          if (emitRefresh) this.storeRefreshSubject.next([storeName]);
        })
      )
      .toPromise();
  }

  sendStoreData<TDbType extends IndexedDBTypes.ChangetrackedDBType>(
    storeName: string,
    url: string,
    casting: (rawdata) => TDbType,
    emitRefresh?: boolean
  ): Observable<any> {
    return from(this.dbService.getData<TDbType>(storeName)).pipe(
      // db returns raw objects without functions
      map(rawSet => rawSet.map(casting)),
      // only changed entries
      map(entitySet => entitySet.filter(row => row.wasChanged())),
      // cancel if empty
      filter(entitySet => entitySet.length > 0),
      // cleanup
      map(entitySet => ({
        entitySet,
        storeData: entitySet.map(row => row.toDTO()),
      })),
      // send
      switchMap(({ storeData }) => this.backendService.post(url, storeData).pipe(map(response => response))),
      // clear store
      switchMap(response =>
        !!response
          ? from(this.dbService.clearStore(storeName)).pipe(
              mapTo(response) // TODO response => response.body für neue endpoints
            )
          : of(response)
      )
    );
  }

  reloadStore(storeName: string, url: string, emitRefresh?: boolean): Observable<void> {
    return this.backendService.post<any[]>(url).pipe(
      map(result => result || []),
      switchMap(resultSet => this.resetStore(storeName, resultSet, emitRefresh))
    );
  }

  /** @description clears store and sets content*/
  resetStore(storeName: string, storeData: any[], emitRefresh?: boolean): Observable<void> {
    return from(this.dbService.clearStore(storeName)).pipe(
      switchMap(_ => this.dbService.setData(storeName, storeData || [])),
      tap(_ => {
        if (emitRefresh) this.storeRefreshSubject.next([storeName]);
      })
    );
  }

  replaceIndex(
    storeName: string,
    keyValue: any,
    key: string,
    storeData: any[],
    emitRefresh?: boolean
  ): Observable<void> {
    return from(this.dbService.deleteData(storeName, keyValue, key)).pipe(
      switchMap(_ => this.dbService.setData(storeName, storeData)),
      tap(_ => {
        if (emitRefresh) this.storeRefreshSubject.next([storeName]);
      })
    );
  }

  //#endregion
}
