import { Injectable, Injector } from '@angular/core';
import { ControllerService } from '@app/globalServices/controller.service';
import { DatabaseService } from '@app/globalServices/database.service';
import { DialogService } from '@app/globalServices/dialog.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { DomainError, DomainErrorCode } from '@app/utils/ErrorHandling/DomainError';
import { Auswertung } from '@entities/Auswertung';
import { BookingTime, TempBuchung, tempBuchungToDate } from '@entities/BookingTime';
import { from, interval, Observable, of } from 'rxjs';
import { map, mapTo, mergeAll, switchMap } from 'rxjs/operators';
import { BookingCollision, StempelungViewModel } from '../presentation/buchung/ExtendedBookings';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { MitarbeiterService } from './ZEFMitarbeiter.service';

@Injectable({
  providedIn: 'root',
})
export class BuchungTempService extends StoreControllerServiceBase<BookingTime> {
  protected GetStoreName(): string {
    return BookingTime.StoreName;
  }

  protected entityConstructor(raw): BookingTime {
    return new BookingTime(raw);
  }

  constructor(
    private readonly controllerservice: ControllerService,
    private readonly dbService: DatabaseService,
    private readonly backendService: ZEFBackendService,
    private readonly session: UserSessionService,
    private readonly employeeStore: MitarbeiterService,
    private readonly dialogService: DialogService,
    protected readonly injector: Injector
  ) {
    super(injector);
  }

  /**@description latest bookings for all employees */
  private readonly latestBookings$ = this.employeeStore.currentUserSubordinates$.pipe(
    map(employees => employees.map(employee => employee.Pnr).map(pnr => this.mostRecentForPnr$(pnr))),
    switchMap(observations => from(observations)),
    mergeAll()
  );

  /** latest booking for current user */
  private readonly latestCurrentUserBooking$ = this.session.pnr$.pipe(
    // taplog('session.pnr$'),
    switchMap(pnr => this.mostRecentForPnr$(pnr))
    // taplog('latestCurrentUserBooking$ '),
  );

  readonly latestBooking$ = /* this.globalSettingService.showLastBookingForTeam.value$ */ of(false).pipe(
    // taplog('booking for team'),
    switchMap(forTeam => (forTeam ? this.latestBookings$ : this.latestCurrentUserBooking$))
    // taplog('latest booking')
  );

  public mostRecentForPnr$(pnr: string): Observable<BookingTime> {
    return interval(1000).pipe(
      switchMap(_ => this.filteredStoreData$(pnr, 'Pnr')),
      map(bookings => bookings.sort(this.compareBookings)[bookings.length - 1])
    );
  }

  private compareBookings(buchungA: BookingTime, buchungB: BookingTime): number {
    const buchungToDateValue = (buchung: BookingTime) =>
      new Date(
        buchung.getTag() +
          '  ' +
          ('' + buchung.getStunden()).padStart(2, '0') +
          ':' +
          ('' + buchung.getMinuten()).padStart(2, '0') +
          ':' +
          ('' + buchung.getSekunden()).padStart(2, '0')
      ).valueOf();

    return buchungToDateValue(buchungA) - buchungToDateValue(buchungB);
  }

  /**
   * @description Lade die Buchung_Temp Data hoch, falls dieses geklappt hat cleare die Auswertung und ersetze sie mit der Antwort. Cleare die Buchung_temp
   * (mit Ausnahme des letzten Eintrags !___der angemeldeten PNR___!)
   * - In der neusten Version ist die Antwort des Webservices direkt eine Auswertung und wird auch so verwertet
   */
  async Sync(emit?: boolean): Promise<string[]> {
    try {
      return await this.controllerservice
        .sendStoreData<BookingTime>(BookingTime.StoreName, 'SetBuchungenPWA', raw => new BookingTime(raw), emit)
        .pipe(
          map<object[][], Auswertung[][]>(response =>
            !!response ? response.map(arr => (!!arr ? arr.map(row => new Auswertung(row)) : null)) : null
          ),
          // taplog('returned Auswertungen2'),
          switchMap(content =>
            !!content
              ? this.controllerservice
                  .resetStore(Auswertung.StoreName, content, emit)
                  .pipe(mapTo([Auswertung.StoreName]))
              : of([] as string[])
          ),
          switchMap(names =>
            from(this.dbService.clearStore(BookingTime.StoreName)).pipe(mapTo([BookingTime.StoreName, ...names]))
          )
        )
        .toPromise();
    } catch (error) {
      if (error instanceof DomainError && error.ErrorCode === DomainErrorCode.MonthAlreadyClosed) {
        await this.dialogService.ShowError(error);
        return await this.controllerservice
          .resetStore(Auswertung.StoreName, [], emit)
          .pipe(
            mapTo([Auswertung.StoreName]),
            switchMap(names =>
              from(this.dbService.clearStore(BookingTime.StoreName)).pipe(mapTo([BookingTime.StoreName, ...names]))
            )
          )
          .toPromise();
      } else {
        throw error;
      }
    }
  }

  /**
   * Gib eine Temp_Buchung mit bestimmter pnr aus der Tabelle zurück
   */
  async getBooking(pnr: string): Promise<BookingTime[]> {
    return (await this.dbService.getData(BookingTime.StoreName, pnr, 'Pnr')).map(element => new BookingTime(element));
  }

  async saveBookings(bookings: BookingTime[]) {
    await this.dbService.setData(BookingTime.StoreName, bookings, true);
  }

  /**@description Löscht die angegebene Buchung */
  async deleteBuchung(key: string): Promise<boolean> {
    const url = 'DeleteBuchung/INDEX/' + key;
    try {
      const succces = await this.backendService
        .post<any>(url)
        .pipe(map(response => response['Success']))
        .toPromise();
      return succces;
    } catch (error) {
      if (error instanceof DomainError && error.ErrorCode === DomainErrorCode.MonthAlreadyClosed) {
        await this.dialogService.ShowError(error);
        return false;
      } else {
        throw error;
      }
    }
  }

  /**@description Extracts an array of all bookingcollisions 0f an intended journalbooking with all currently existing bookings for that day */
  getBookingCollisions(
    startBooking: TempBuchung,
    endbooking: TempBuchung,
    dayBookings: StempelungViewModel[]
  ): BookingCollision[] {
    const incomingStartDate = tempBuchungToDate(startBooking);
    const incomingEndDate = tempBuchungToDate(endbooking);
    const bookingCollisions: BookingCollision[] = [];
    for (const booking of dayBookings) {
      const bookingDates = booking.toDates();
      const bookingStartDate = bookingDates.startDate;
      const bookingEndDate = bookingDates.endDate;
      const isOpenBooking = booking.EndStunden === -1 && booking.EndMinuten === -1;
      const overlapTypeValue = getOverlapType(
        bookingStartDate,
        incomingStartDate,
        bookingEndDate,
        incomingEndDate,
        isOpenBooking
      );
      bookingCollisions.push(new BookingCollision(booking, overlapTypeValue));
    }
    return bookingCollisions;
  }

  /**@description Resolves the general booking conflict by deleting the conflicted bookings and creating new ones, taking the incoming booking as leading for the rest */
  async generateResolvingBookings(
    incomingStartBuchung: TempBuchung,
    incomingEndBuchung: TempBuchung,
    collisions: BookingCollision[]
  ) {
    const conflictResolvingBookings: TempBuchung[] = [];
    for (const conflict of collisions) {
      const collisionType = conflict.overlapType;
      const tempBuchungenFromConflict1 = conflict.toTempbuchungen();
      const tempBuchungenFromConflict2 = conflict.toTempbuchungen();
      const success = await this.deleteBuchung(conflict.Key);
      if (!success) return [];
      switch (collisionType) {
        case 'overwriteBooking': {
          conflictResolvingBookings.push(incomingStartBuchung);
          conflictResolvingBookings.push(incomingEndBuchung);
          break;
        }
        case 'punchHoleInBooking': {
          tempBuchungenFromConflict2.startBuchung = {
            ...tempBuchungenFromConflict1.startBuchung,
            Stunden: incomingEndBuchung.Stunden,
            Minuten: incomingEndBuchung.Minuten,
            Sekunden: incomingEndBuchung.Sekunden,
          };
          conflictResolvingBookings.push(tempBuchungenFromConflict1.startBuchung);
          conflictResolvingBookings.push(incomingStartBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict2.startBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict2.endBuchung);
          break;
        }
        case 'overlapStart': {
          tempBuchungenFromConflict1.startBuchung = {
            ...tempBuchungenFromConflict1.startBuchung,
            Stunden: incomingEndBuchung.Stunden,
            Minuten: incomingEndBuchung.Minuten,
            Sekunden: incomingEndBuchung.Sekunden,
          };
          conflictResolvingBookings.push(incomingStartBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict1.startBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict1.endBuchung);
          break;
        }
        case 'overlapEnding': {
          tempBuchungenFromConflict1.endBuchung = {
            ...tempBuchungenFromConflict1.endBuchung,
            Stunden: incomingStartBuchung.Stunden,
            Minuten: incomingStartBuchung.Minuten,
            Sekunden: incomingEndBuchung.Sekunden,
          };
          conflictResolvingBookings.push(tempBuchungenFromConflict1.startBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict1.endBuchung);
          conflictResolvingBookings.push(incomingStartBuchung);
          conflictResolvingBookings.push(incomingEndBuchung);
          break;
        }
        case 'startAsEnd': {
          conflictResolvingBookings.push(tempBuchungenFromConflict1.startBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict1.endBuchung);
          conflictResolvingBookings.push(incomingStartBuchung);
          conflictResolvingBookings.push(incomingEndBuchung);
          break;
        }
        case 'endAsStart': {
          conflictResolvingBookings.push(incomingStartBuchung);
          conflictResolvingBookings.push(incomingEndBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict1.startBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict1.endBuchung);
          break;
        }
        case 'none': {
          conflictResolvingBookings.push(tempBuchungenFromConflict1.startBuchung);
          conflictResolvingBookings.push(tempBuchungenFromConflict1.endBuchung);
          break;
        }
      }
    }
    // doppelte filtern
    return conflictResolvingBookings.filter(
      (conflict, index, self) =>
        index ===
        self.findIndex(
          t =>
            t.Pnr === conflict.Pnr &&
            t.Tag === conflict.Tag &&
            t.Stunden === conflict.Stunden &&
            t.Minuten === conflict.Minuten &&
            t.Sekunden === conflict.Sekunden &&
            t.Art === conflict.Art &&
            t.Kennzeichen === conflict.Kennzeichen &&
            t.Auftrag === conflict.Auftrag &&
            t.Kunde === conflict.Kunde &&
            t.Taetigkeit === conflict.Taetigkeit &&
            t.Taetigkeitspaket === conflict.Taetigkeitspaket &&
            t.Grund === conflict.Grund &&
            t.Kommentar1 === conflict.Kommentar1 &&
            t.Kommentar2 === conflict.Kommentar2 &&
            t.Kommentar3 === conflict.Kommentar3 &&
            t.Longitude === conflict.Longitude &&
            t.Latitude === conflict.Latitude &&
            t.Radius === conflict.Radius
        )
    );
  }
}

export type overlapType =
  | 'none'
  | 'overlapStart'
  | 'overlapEnding'
  | 'punchHoleInBooking'
  | 'overwriteBooking'
  | 'startAsEnd'
  | 'endAsStart';

/**
 * @description Checks what kind of overlap happens between a plannend and a existing booking
 */
function getOverlapType(
  existingStartDate: Date,
  incomingStartDate: Date,
  existingEndDate: Date,
  incomingEndDate: Date,
  openBooking: boolean
): overlapType {
  if (openBooking) {
    return existingStartDate < incomingEndDate ? 'overwriteBooking' : 'none';
  }
  const startInBookingFrame = existingStartDate < incomingStartDate && incomingStartDate < existingEndDate;
  const endInBookingFrame = existingStartDate < incomingEndDate && incomingEndDate < existingEndDate;

  const overwriteBooking = incomingStartDate <= existingStartDate && existingEndDate <= incomingEndDate;

  const startAsEnd = incomingStartDate.valueOf() === existingEndDate.valueOf();
  const endAsStart = incomingEndDate.valueOf() === existingStartDate.valueOf();

  if (overwriteBooking) return 'overwriteBooking';
  if (startInBookingFrame && endInBookingFrame) return 'punchHoleInBooking';
  if (startInBookingFrame) return 'overlapEnding';
  if (endInBookingFrame) return 'overlapStart';
  if (startAsEnd) return 'startAsEnd';
  if (endAsStart) return 'endAsStart';
  return 'none';
}
