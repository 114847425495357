<!-- regulärer scan -->

<div class="scanner-overlay">
  <zxing-scanner
    [autofocusEnabled]="true"
    [device]="desiredDevice"
    (camerasFound)="camerasFoundHandler($event)"
    (scanSuccess)="handleQrContent($event)"
    [formats]="['QR_CODE', 'CODE_128']"
    [enable]="scanning"
  >
  </zxing-scanner>
  <button class="switch-camera-button" mat-button>
    <img [src]="'assets/icons/camera_switch.svg'" class="switch-camera-icon" (click)="switchBetweenDevices()" />
  </button>

  <button class="read-image-button" (click)="uploader.click()" mat-button>Image-Datei wählen</button>

  <button *ngIf="scanning" class="cancel-scan-button" (click)="stopScanning()" mat-button>
    <mat-icon class="cancel-icon">remove_circle</mat-icon>
    <a>Abbrechen</a>
  </button>

  <input hidden id="uploader" #uploader type="file" accept="image/jpeg, image/png" (change)="processQrImage($event)" />
</div>
