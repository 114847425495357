import { Component, Input, OnInit } from '@angular/core';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';

export interface TileState {
  color?: string;
  bcolor: string;
  textsize?: number;
  disabled?: boolean;
  text: string;
  iconSrc: string;
  clickEvent: () => any;
  longtap?: boolean;
}

@Component({
  selector: 'app-grid-tile',
  templateUrl: './grid-tile.component.html',
  styleUrls: ['./grid-tile.component.scss'],
})
export class GridTileComponent implements OnInit {
  @Input() state: TileState;
  longTapTimeout;

  constructor(private settings: GlobalSettingService) {}

  ngOnInit() {}

  onClick() {
    if (this.state && !this.state.disabled && this.state.clickEvent) {
      this.state.clickEvent();
    }
  }

  onTouchStart() {
    clearTimeout(this.longTapTimeout);
    this.longTapTimeout = setTimeout(async () => {
      if (this.state.longtap && (await this.settings.longTapRight.get())) {
        this.state.clickEvent();
      }
    }, 1000);
  }

  onTouchEnd() {
    clearTimeout(this.longTapTimeout);
  }
}
