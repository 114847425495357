<div class="container">
  <div class="monthPicker">
    <app-monthpicker (formattedDate)="pickedMonth($event)"></app-monthpicker>
  </div>

  <div class="bookedTageskennzeichen">
    <mat-accordion class="bookedTageskennzeichenAccordion">
      <mat-expansion-panel
        (afterExpand)="scrollInto(i)"
        class="mat-expansion-panel2 nthPanel"
        *ngFor="let booking of bookedTKennzeichen$ | async; let i = index"
      >
        <mat-expansion-panel-header>
          <div class="bookedTageskennzeichen-list-element-header">
            <div class="datum">
              {{ booking?.Datum }}
            </div>
            <div class="kennzeichen">
              {{ booking?.Kennzeichen }}
            </div>
            <div class="wert">
              {{ booking?.Wert }}
            </div>
          </div>
        </mat-expansion-panel-header>

        Beschreibung:
        <div class="beschreibung">
          {{ booking?.Beschreibung }}
        </div>

        <button class="editBooking" (click)="editBooking(booking)" mat-button>
          <mat-icon class="icon">edit</mat-icon>
          <a>Tageskennzeichen bearbeiten</a>
        </button>

        <button class="deleteBooking" (click)="deleteBooking(booking)" mat-button>
          <mat-icon class="icon">remove_circle</mat-icon>
          <a>Tageskennzeichen löschen</a>
        </button>
      </mat-expansion-panel>

      <div *ngIf="(bookedTKennzeichen$ | async)?.length === 0" class="keinEintragGefundenContainer">
        <img class="handcardImage" src="assets/icons/tkennzeichen.png" />
        <br />
        <a class="keineEintraegeText">Es sind noch keine Tageskennzeichen vorhanden</a> <br />
      </div>
    </mat-accordion>
  </div>
</div>

<button mat-fab class="addBooking" (click)="openBottomSheet()">
  <mat-icon>add</mat-icon>
</button>
