<app-bottom-sheet-frame [Title]="TitleStyle.name">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <app-autocomplete-input formControlName="typeSelection" [Parameters]="artParameters">
      <ng-template #optionTemplate let-art>{{ art[0] + art.substr(1, art.length - 1).toLowerCase() }}</ng-template>
    </app-autocomplete-input>

    <app-time-picker-field [label]="'Startzeit für ' + getFormattedSelectedStart()" formControlName="timepickerStart">
    </app-time-picker-field>

    <app-time-picker-field
      [label]="'Endzeit für ' + getFormattedCalculatedEnd()"
      [warnLabel]="warnLabel"
      formControlName="timepickerEnd"
    >
    </app-time-picker-field>

    <app-mitarbeiter-selector
      formControlName="mitarbeiter"
      [disableGruppenbuchen]="true"
      [takeDefault]="false"
    ></app-mitarbeiter-selector>

    <app-autocomplete-input
      *ngIf="(selectedBookingFrameType | async) == 'FEHLZEIT'"
      formControlName="abwesenheitsgrund"
      [Parameters]="abwesenheitsParameters"
    >
      <ng-template #optionTemplate let-option>{{ option?.getBeschreibung() || option?.getKennzeichen() }}</ng-template>
    </app-autocomplete-input>

    <ng-container *ngIf="(selectedBookingFrameType | async) == 'AUFTRAGSZEIT'">
      <app-autocomplete-input
        formControlName="kunde"
        [Parameters]="customerParameters"
        [showFullLengthText]="kundenAnzeige$ | async"
      >
        <ng-template #optionTemplate let-option>{{ option?.getBeschreibung() || option?.getNummer() }}</ng-template>
      </app-autocomplete-input>

      <app-autocomplete-input
        formControlName="auftrag"
        [Parameters]="auftragParameters"
        [showFullLengthText]="auftragsAnzeige$ | async"
      >
        <ng-template #optionTemplate let-option>
          {{
            option?.getNummer() && option?.getBeschreibung()
              ? option.getNummer() + ' | ' + option.getBeschreibung()
              : option?.getNummer() || option?.getBeschreibung()
          }}
        </ng-template>
      </app-autocomplete-input>

      <app-autocomplete-input
        formControlName="taetigkeit"
        [Parameters]="taetigkeitParameters"
        [showFullLengthText]="taetigkeitsAnzeige$ | async"
      >
        <ng-template #optionTemplate let-option>
          {{
            option?.getNummer() && option?.getBeschreibung()
              ? option.getNummer() + ' | ' + option.getBeschreibung()
              : option?.getNummer() || option?.getBeschreibung()
          }}
        </ng-template>
      </app-autocomplete-input>
    </ng-container>

    <app-comment-field formControlName="kommentar"></app-comment-field>
  </form>

  <app-bottom-sheet-button [Action]="editButtonAction$ | async" [ButtonStyle]="editButtonStyle"
    >send
  </app-bottom-sheet-button>

  <app-bottom-sheet-button [Action]="deleteButtonAction" [ButtonStyle]="deleteButtonStyle"
    >cancel
  </app-bottom-sheet-button>

  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel
  </app-bottom-sheet-button>
</app-bottom-sheet-frame>
