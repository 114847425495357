import { Injectable, Injector } from '@angular/core';
import { DatabaseService } from '@app/globalServices/database.service';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { TermConverterService } from '@app/globalServices/termConverter.service';
import { TimeService } from '@app/globalServices/time.service';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { getfirst } from '@app/utils/rxjsUtils';
import { ObservableQuery, query } from '@app/utils/rxQueryUtils';
import { BookingTimeFrame } from '@entities/BookingTimeFrame';
import * as moment from 'moment';
import { from, interval, Observable } from 'rxjs';
import { combineAll, first, map, startWith, switchMap } from 'rxjs/operators';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { MitarbeiterService } from './ZEFMitarbeiter.service';

@Injectable({
  providedIn: 'root',
})
export class StempelungService extends StoreControllerServiceBase<BookingTimeFrame> {
  protected GetStoreName(): string {
    return BookingTimeFrame.StoreName;
  }

  protected entityConstructor(entity): BookingTimeFrame {
    return new BookingTimeFrame(entity);
  }

  public async Sync(emit?: boolean): Promise<string[]> {
    await this.settings.timeSyncRange.set(1);
    const range = await getfirst(this.settings.syncDateRange$);

    const startDate = moment(range.start).format('DD.MM.YYYY');
    const endDate = moment(range.end).format('DD.MM.YYYY');
    const pnrs = await getfirst(
      this.mitarbeiterService.currentUserSubordinates$.pipe(
        first(),
        map(employees => employees.map(employee => employee.Pnr))
      )
    );

    const stemps = await this.backend
      .post<BookingTimeFrame[]>(`GetTeamStempelungen/FROM/${startDate}/UNTIL/${endDate}`, pnrs)
      .toPromise();

    await this.CentralController.resetStore(BookingTimeFrame.StoreName, stemps, emit).toPromise();

    await this.settings.currentStempelungSyncRange.set([range.start, range.end]);

    return [BookingTimeFrame.StoreName];
  }

  constructor(
    private readonly timeService: TimeService,
    private readonly converter: TermConverterService,
    private readonly settings: GlobalSettingService,

    private readonly dbService: DatabaseService,
    private readonly backend: ZEFBackendService,

    private readonly mitarbeiterService: MitarbeiterService,
    protected readonly injector: Injector
  ) {
    super(injector);
  }

  /**
   * @description Refresht die Stempelung für den angegebenen Tag (auch aller Unterarbeiter), cleart optional die bestehende Tabelle
   * @param all Auch die Stempelungen der Unterarbeiter eines Vorarbeiters werden geholt
   */
  async refreshStempelung(datum: string) {
    const employees = await getfirst(this.mitarbeiterService.currentUserSubordinates$);
    const urls = employees.map(employee => `GetLiveStempelungForPnr/DATUM/${datum}/PNR/${employee.Pnr}`);
    const queries = urls.map(url => this.backend.get<BookingTimeFrame[]>(url).toPromise());
    const responses = await Promise.all(queries);
    const flatList = ([] as BookingTimeFrame[]).concat(...responses);
    await this.CentralController.resetStore(BookingTimeFrame.StoreName, flatList).toPromise();
  }

  async refreshTeamStempelung(datum: string, pnr: string) {
    await this.CentralController.reloadStore(
      'ZEFStempelung',
      `GetTeamStempelung/DATUM/${datum}/PNR/${pnr}`
    ).toPromise();
  }

  getDayOverview$(pnr: string, date: Date): ObservableQuery<BookingTimeFrame[]> {
    const datestring = moment(date).format('DDMMYYYY');
    const result = this.inCacheRange$(date).pipe(
      switchMap(inRange =>
        inRange
          ? this.observeDayOverview$(pnr, date)
          : this.backend.post<BookingTimeFrame[]>(`GetLiveStempelungForPnr/DATUM/${datestring}/PNR/${pnr}`)
      ),
      (obs: Observable<BookingTimeFrame[]>) => query('getDayOverview$:' + datestring, () => obs)
    );
    return result;
  }

  private inCacheRange$(date: Date): Observable<boolean> {
    return this.settings.currentStempelungSyncRange.value$.pipe(
      map(tuple => {
        if (!tuple) return false;

        const [start, end] = tuple;
        if (!start || !end) return false;

        return this.timeService.isBetween(start, end, date);
      })
    );
  }

  observeDayOverview$(pnr: string, date: Date): Observable<BookingTimeFrame[]> {
    const dateString = BookingTimeFrame.DateAsDatestring(date);
    return this.filteredStoreData$(pnr, 'Pnr').pipe(map(stemps => stemps.filter(stemp => stemp.Tag === dateString)));
  }

  //#region Most recent bookingFrame

  /**@description maps Pnrs of Subordinates to Observables of their last bookingframe */
  readonly mostRecent$ = this.mitarbeiterService.currentUserSubordinates$.pipe(
    map(employees => employees.map(employee => employee.Pnr).map(pnr => this.mostRecentForPnr$(pnr))),
    switchMap(observations => from(observations)),
    combineAll()
    // taplog('most recent stempelung for subordinates')
  );

  /**@description refresh now-Timestamp every 5sec */
  private readonly nowRefresh = interval(5000).pipe(
    startWith(-1),
    map(_ => new Date())
  );

  /**@description Selects store-content for todays and yesterdays content for the given employee-id,
   * then maps it to the most recent.
   */
  mostRecentForPnr$(pnr: string): Observable<BookingTimeFrame> {
    return this.timeService.lastTwoDays$.pipe(
      map(dates => dates.map(date => moment(date).format('YYYYMMDD'))),
      // taplog('datepair for recent stemps'),

      // selects all bookings of the given employee-number, for today and yesterday
      switchMap(dates =>
        this.filteredStoreData$(pnr, 'Pnr').pipe(
          // taplog('unfiltered stemps for most recent'),
          map(
            frames => frames.filter(bookingframe => dates.includes(bookingframe.Tag))
            // .filter((bookingframe) => bookingframe.EndStunden === -1)
          )
          // taplog('Filtered stemps for most recent')
        )
      ),
      switchMap(bookingframes => this.selectMostRecent$(bookingframes))
      // taplog('most recent booking frame')
    );
  }

  private selectMostRecent$(bookingframes: BookingTimeFrame[]): Observable<BookingTimeFrame> {
    return this.nowRefresh.pipe(
      map(now => this.buildBookingFrameReducer(now)),
      map(reducer => {
        if (bookingframes.length < 1) return null;
        if (bookingframes.length < 2) return bookingframes[0];

        const result = bookingframes.reduce(reducer);
        return result;
      })
    );
  }

  /**@description Builds a reducer-func that reduces to the most recent booking not in the future of given time */
  private buildBookingFrameReducer =
    (date: Date) =>
    (bookingFrameA: BookingTimeFrame, bookingFrameB: BookingTimeFrame): BookingTimeFrame => {
      if (!bookingFrameA) return bookingFrameB;
      if (!bookingFrameB) return bookingFrameA;

      const isInTheFuture = (bookingFrame: BookingTimeFrame): boolean => {
        const result = bookingFrame?.Tag === BookingTimeFrame.DateAsDatestring(date) && bookingFrame?.toDate() >= date;
        return result;
      };

      if (isInTheFuture(bookingFrameA)) {
        if (isInTheFuture(bookingFrameB)) {
          return null;
        }
        return bookingFrameB;
      }

      if (bookingFrameA.compare(bookingFrameB) < 0) return bookingFrameB;

      return bookingFrameA;
    };

  //#endregion
}
