import { Component, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { Observable } from 'rxjs';
import { ModalActionType, ModalQueryResult } from '@app/utils/types';
import { BaseDialogComponent } from '@base-components/BaseDialogComponent';

export interface DialogButtonData {
  buttonText: string;
  buttonType: ModalActionType;
}

export interface DialogData {
  title$?: Observable<string>;
  title?: string;

  description$?: Observable<string>;
  description?: string;

  showSpinner?: boolean;
  progress$?: Observable<number>;

  actions?: DialogButtonData[];
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class GenericDialogComponent extends BaseDialogComponent<DialogData, void> {
  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  buttonClick(event, actionType: ModalActionType) {
    this.Close({ Type: actionType });
  }

  buttonClass(actionType: ModalActionType): string {
    switch (actionType) {
      case ModalActionType.OK: {
        return 'button-ok';
      }
      case ModalActionType.CANCEL: {
        return 'button-cancel';
      }
      case ModalActionType.YES: {
        return 'button-yes';
      }
      case ModalActionType.NO: {
        return 'button-no';
      }
      default: {
        return 'button-ok';
      }
    }
  }
}
