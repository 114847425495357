import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { getfirst } from '@app/utils/rxjsUtils';
import { BookingRight, BookingType } from '@app/utils/types';
import { addLeadingZeroInDateValue, isDayChange } from '@app/utils/utils';
import { AutocompleteParameters } from '@base-components/form-components/autocomplete-input/autocomplete-input.component';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { TimepickerTime } from '@components/reuseable/timepicker/timepicker.component';
import { Auftrag } from '@entities/Auftrag';
import { TempBuchung } from '@entities/BookingTime';
import { Kunden } from '@entities/Customer';
import { IndexedDBTypes } from '@entities/dbType';
import { Employee } from '@entities/Employee';
import { Fehlzeit } from '@entities/Fehlzeiten';
import { Taetigkeit } from '@entities/Taetigkeit';
import { AuftraegeService } from '@stores/ZEFAuftraege.service';
import { FehlzeitenService } from '@stores/ZEFFehlzeiten.service';
import { KundenService } from '@stores/ZEFKunden.service';
import { MitarbeiterService } from '@stores/ZEFMitarbeiter.service';
import { TaetigkeitService } from '@stores/ZEFTaetigkeit.service';
import { ZeitmodelleService } from '@stores/zeitmodelle.service';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { BookingCollision, JournalKeyData, StempelungViewModel } from '../buchung/ExtendedBookings';
import { BuchungsBottomsheetComponent } from './BuchungsBottomsheetComponent';
import { SaveButtonStyle } from './manuell-erfassen/SaveButtonStyle';

@Component({ template: '{{"JournalDialogComponent"}}' })
export abstract class JournalDialogComponent<TParameter extends JournalKeyData>
  extends BuchungsBottomsheetComponent<TParameter>
  implements OnInit
{
  //#region Injector

  protected readonly customers = this.injector.get<KundenService>(KundenService);

  protected readonly AuftraegeService = this.injector.get<AuftraegeService>(AuftraegeService);

  protected readonly taetigkeiten = this.injector.get<TaetigkeitService>(TaetigkeitService);

  protected readonly fehlzeiten = this.injector.get<FehlzeitenService>(FehlzeitenService);

  protected readonly zeitmodell = this.injector.get<ZeitmodelleService>(ZeitmodelleService);

  protected readonly mitarbeiter = this.injector.get<MitarbeiterService>(MitarbeiterService);

  //#endregion

  selectedStart: Date;
  calculatedEnd: Date;
  warnLabel = false;

  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
    this.listenForFormGroupChanges();
  }

  currentAuftrag: BehaviorSubject<Auftrag> = new BehaviorSubject(null);
  currentAuftrag$: Observable<Auftrag> = this.currentAuftrag.asObservable().pipe(distinctUntilChanged());

  async onSubmit() {
    const valid = await this.checkValidTimeframe();
    if (!valid) {
      await this.DialogService.ShowInformation(
        'Fehler',
        'Der Buchungszeitraum liegt außerhalb der erlaubten Zeit. Die Buchung wird nicht durchgeführt',
        'Ok'
      );
      return;
    }
    const tempBuchungen = await this.extractBookingObject();
    if (tempBuchungen.length > 1) {
      const startBooking = tempBuchungen[0];
      const endBooking = tempBuchungen[1];
      const collisions = await this.getCollisionsForDay(startBooking, endBooking);
      if (collisions.filter(collision => collision.overlapType !== 'none').length !== 0) {
        const askToResolve = collisions.some(
          collision => !['startAsEnd', 'endAsStart', 'none'].includes(collision.overlapType)
        );
        await this.offerPossibleConflictresolution(startBooking, endBooking, collisions, askToResolve);
        return;
      }
    }
    await super.onSubmit();
  }

  /**@description Offers the user to resolve a conflicting booking */
  private async offerPossibleConflictresolution(
    startBooking: TempBuchung,
    endBooking: TempBuchung,
    collisions: BookingCollision[],
    askToResolve: Boolean = true
  ) {
    if (askToResolve) {
      const proceed = await this.DialogService.QueryYesNo(
        'Achtung - Überschneidung',
        'Soll die neue Buchung trotzdem eingefügt werden? (überschneidende Buchungen werden dabei angepasst)',
        'Ja',
        'Abbruch'
      );
      if (!proceed) return;
    }
    const resolvingBookings = await this.BookingService.generateResolvingBookings(startBooking, endBooking, collisions);
    if (resolvingBookings?.length === 0) {
      // no resolvedBookings means, that the deletion process for atleast one conflict failed
      const syncOnError = await this.DialogService.QueryConfirmation(
        'Löschen fehlgeschlagen',
        'Eine Buchung die zur Konfliktlösung entfernt werden muss, konnte nicht gelöscht werden. Synchronisieren Sie neu und versuchen Sie es erneut. Jetzt Synchronisieren ?',
        'Synchronsieren',
        'Nicht Synchronsieren'
      );
      if (syncOnError) {
        await this.SyncService.syncData();
      }
    }
    await this.BuchungService.saveBookings(resolvingBookings);
  }

  protected async getCollisionsForDay(startbooking: TempBuchung, endbooking: TempBuchung): Promise<BookingCollision[]> {
    const result = this.BookingService.getBookingCollisions(startbooking, endbooking, this.existingStempelungenForDay);
    return result;
  }

  get existingStempelungenForDay(): StempelungViewModel[] {
    return this.Data.existingStempelungenForDay;
  }

  readonly selectedBookingFrameType = new BehaviorSubject<BookingType>(BookingType.ARBEITSZEIT);

  protected getSelectedEntityIndex(
    field: 'mitarbeiter' | 'auftrag' | 'taetigkeit' | 'abwesenheitsgrund' | 'kunde'
  ): string {
    const currentFieldValue = this.formGroup.value[field];
    if (currentFieldValue === '') return '';
    const currentEntityIndex = (currentFieldValue as IndexedDBTypes.IndexedDBType)?.getIndex();
    return currentEntityIndex || '';
  }

  protected createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    const result = formBuilder.group({
      typeSelection: ['', [Validators.required]],
      timepickerStart: ['', [Validators.pattern('[0-2][0-9]:[0-5][0-9]')]],
      timepickerEnd: ['', [Validators.nullValidator]],
      mitarbeiter: ['', [Validators.required, Validators.minLength(1)]],
      abwesenheitsgrund: ['', [Validators.nullValidator]],
      kunde: ['', Validators.nullValidator],
      auftrag: ['', Validators.nullValidator],
      taetigkeit: ['', Validators.nullValidator],
      kommentar: ['', Validators.maxLength(750)],
    });
    return result;
  }

  /**@description Sets the employee which was seelected in the teamseleciton */
  protected async setSelectedEmployee(pnr: string): Promise<void> {
    const stempEmployee = await getfirst(
      this.Employees.storeData$.pipe(map(employees => employees.find(employee => employee.Pnr === pnr)))
    );
    this.formGroup.patchValue({ mitarbeiter: stempEmployee });
  }

  /**@description Checks if the booking is allowed within the requested timeframe */
  async checkValidTimeframe(): Promise<boolean> {
    const employee = this.formGroup.value.mitarbeiter as Employee;
    const currentPermission = employee.Journal;
    if (currentPermission === '1') {
      // user can book without validation
      return true;
    }

    //#region => extract into getter
    const startTime = this.formGroup.value.timepickerStart;
    const endTime = this.formGroup.value.timepickerEnd;
    const startDay = this.selectedStart;

    const dateOfSelectedDay = moment(startDay, 'DD.MM.YYYY').toDate();
    const dateForZeitmodell = moment(startDay, 'DD.MM.YYYY').format('DDMMYYYY');
    const startTimeDate = moment(startDay + ' ' + startTime, 'DD.MM.YYYY HH:mm').toDate();
    const endTimeDate = moment(startDay + ' ' + endTime, 'DD.MM.YYYY HH:mm').toDate();

    const dateOfEndDay = new Date(dateOfSelectedDay);
    const dayAdjustment = endTimeDate < startTimeDate ? 1 : 0;
    const possibleExtraDay = dateOfSelectedDay.getDate() + dayAdjustment;
    dateOfEndDay.setDate(possibleExtraDay);

    //#endregion

    const rahmenZeiten = await this.zeitmodell.getZeitmodell(employee.Pnr, dateForZeitmodell);
    const valid = rahmenZeiten.isTimeInRahmenarbeitszeit(startTime, endTime, dateOfSelectedDay, dateOfEndDay);
    return valid;
  }

  readonly artOptions$ = combineLatest([
    of([BookingType.ARBEITSZEIT, BookingType.AUFTRAGSZEIT, BookingType.FEHLZEIT]),
    this.mitarbeiter.currentUserRights$.pipe(map(right => right?.bookingRight)),
  ]).pipe(
    map(([bookingFrameTypes, bookingRight]) => {
      if (
        bookingRight === BookingRight.BuchenMitAuftragserfassung ||
        bookingRight === BookingRight.BuchenMitAuftragserfassungUndTaetigkeiten
      ) {
        bookingFrameTypes = bookingFrameTypes.filter(b => b != BookingType.ARBEITSZEIT);
      } else if (bookingRight === BookingRight.BuchenOhneAuftragserfassung)
        bookingFrameTypes = bookingFrameTypes.filter(b => b != BookingType.AUFTRAGSZEIT);
      return bookingFrameTypes;
    })
  );

  readonly artParameters: AutocompleteParameters<BookingType> = {
    displayFunc: (art: BookingType) => (art ? art[0] + art.substr(1, art.length - 1).toLowerCase() : ''),
    toSearchText: (art: BookingType) => art.toLowerCase(),
    Placeholder: 'Buchungsart',
    options$: this.artOptions$,
    noEmptyOption: true,
    inputAsSelection: (inputText: string) =>
      getfirst(this.artOptions$.pipe(map(frameTypes => frameTypes.find(frameType => inputText === frameType)))),
  };

  readonly customerParameters: AutocompleteParameters<Kunden> = {
    displayFunc: (customer: Kunden) => (customer ? customer?.getBeschreibung() || customer?.getNummer() : ''),
    toSearchText: (customer: Kunden) => (customer?.getBeschreibung() + customer?.getNummer())?.toLowerCase(),
    Placeholder: 'Kunde',
    options$: this.customers.storeData$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.customers.storeData$.pipe(
          map(customers =>
            customers.find(
              customer =>
                inputText === (customer?.getBeschreibung() || customer?.getNummer()) ||
                inputText === customer?.getNummer()
            )
          )
        )
      ),
  };

  readonly auftragFilter$ = this.formGroup.controls.kunde.valueChanges
    .pipe(startWith(this.formGroup.value.kunde))
    .pipe(
      map(
        kunde => (auftrag: Auftrag) =>
          !kunde || auftrag.getKundenNummer() === (kunde as Kunden).getNummer() || auftrag.getKundenNummer() === ''
      )
    );
  readonly auftragOptions$ = combineLatest([this.auftragFilter$, this.AuftraegeService.storeData$]).pipe(
    map(([auftragFilter, auftragListe]) => (auftragFilter ? auftragListe.filter(auftragFilter) : auftragListe))
  );

  readonly auftragParameters: AutocompleteParameters<Auftrag> = {
    displayFunc: (auftrag: Auftrag) => (auftrag ? auftrag?.getBeschreibung() || auftrag?.getNummer() : ''),
    toSearchText: (auftrag: Auftrag) => (auftrag?.getNummer() + auftrag?.getBeschreibung())?.toLowerCase(),
    Placeholder: 'Auftrag',
    options$: this.auftragOptions$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.auftragOptions$.pipe(
          map(auftraege =>
            auftraege.find(
              auftrag =>
                inputText === (auftrag?.getBeschreibung() || auftrag?.getNummer()) || inputText === auftrag?.getNummer()
            )
          )
        )
      ),
  };

  readonly taetigkeitFromAutrag$: Observable<Taetigkeit[]> = this.currentAuftrag$.pipe(
    switchMap(auftrag => (auftrag ? this.TaetigkeitService.getTaetigkeitFromAuftrag(auftrag.getNummer()) : of([])))
  );

  readonly taetigkeitOptions$: Observable<Taetigkeit[]> = combineLatest([
    this.taetigkeitFromAutrag$,
    this.taetigkeiten.storeData$,
  ]).pipe(
    map(([taetigkeitFromAuftrag, taetigkeitListe]) => {
      const set = new Set();
      const filteredArr = [...taetigkeitFromAuftrag, ...taetigkeitListe].filter(obj => {
        const isPresentInSet = set.has(obj.getNummer());
        set.add(obj.getNummer());
        return !isPresentInSet;
      });
      return filteredArr;
    }),
    shareReplay(1)
  );

  readonly taetigkeitParameters: AutocompleteParameters<Taetigkeit> = {
    displayFunc: (taetigkeit: Taetigkeit) =>
      taetigkeit ? taetigkeit?.getBeschreibung() || taetigkeit?.getNummer() : '',
    toSearchText: (taetigkeit: Taetigkeit) => (taetigkeit?.getNummer() + taetigkeit?.getBeschreibung())?.toLowerCase(),
    Placeholder: 'Taetigkeit',
    options$: this.taetigkeitOptions$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.taetigkeitOptions$.pipe(
          map(taetigkeiten =>
            taetigkeiten.find(
              taetigkeit =>
                inputText === (taetigkeit?.getBeschreibung() || taetigkeit?.getNummer()) ||
                inputText === taetigkeit?.getNummer()
            )
          )
        )
      ),
  };

  readonly abwesenheitsParameters: AutocompleteParameters<Fehlzeit> = {
    displayFunc: (fehlzeit: Fehlzeit) => (fehlzeit ? fehlzeit?.getBeschreibung() || fehlzeit?.getKennzeichen() : ''),
    toSearchText: (fehlzeit: Fehlzeit) => fehlzeit?.getBeschreibung().toLowerCase(),
    Placeholder: 'Abwesenheitsgrund',
    options$: this.fehlzeiten.buchbareFehlzeiten$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.fehlzeiten.buchbareFehlzeiten$.pipe(
          map(fehlzeiten =>
            fehlzeiten.find(fehlzeit => inputText === (fehlzeit?.getBeschreibung() || fehlzeit?.getKennzeichen()))
          )
        )
      ),
  };

  protected getDefaultBuchung() {
    return {
      ...super.getDefaultBuchung(),
      Kennzeichen: (this.formGroup.controls.abwesenheitsgrund.value as Fehlzeit)?.Kennzeichen,
    };
  }

  protected readBuchungFromForm() {
    return {
      ...super.readBuchungFromForm(),
      Auftrag:
        this.formGroup.controls.auftrag.value === ''
          ? ''
          : (this.formGroup.controls.auftrag.value as Auftrag)?.getNummer(),
      Taetigkeit:
        this.formGroup.controls.taetigkeit.value === ''
          ? ''
          : (this.formGroup.controls.taetigkeit.value as Taetigkeit)?.getNummer(),
      Kunde:
        this.formGroup.controls.kunde.value === '' ? '' : (this.formGroup.controls.kunde.value as Kunden)?.getNummer(),
    };
  }

  /**
   * @description Override for manuell-erfassen: Extracts two bookings based on the picked timevalues
   * @returns [startbooking,endbooking]
   */
  protected async extractBookingObject(): Promise<TempBuchung[]> {
    const baseBookings = await super.extractBookingObject();

    const baseBooking = baseBookings[0];
    const selectedDay = moment(this.selectedStart).format('YYYYMMDD');
    baseBooking.Tag = selectedDay;
    const startingTime = new TimepickerTime(this.formGroup.value.timepickerStart);
    const endTime = new TimepickerTime(
      this.formGroup.value.timepickerEnd == 'Uhrzeit wählen' ? '-1:-1' : this.formGroup.value.timepickerEnd
    );
    const endday = this.calculateEndDay(startingTime, endTime, baseBooking);
    const startBookingValue: TempBuchung = {
      ...baseBooking,
      Art: '0',
      Stunden: startingTime.getHours(),
      Minuten: startingTime.getMinutes(),
      Sekunden: startingTime.getSeconds(),
    };

    // offene Gehen Buchung muss nicht gespeichert werden, geschieht beim Verarbeiten der Buchungen automatisch
    if (endTime.getHours() !== -1 && endTime.getMinutes() !== -1) {
      const endBookingValue: TempBuchung = {
        ...baseBooking,
        Art: '1',
        Stunden: endTime.getHours(),
        Minuten: endTime.getMinutes(),
        Sekunden: endTime.getSeconds(),
        Tag: endday,
      };
      return [startBookingValue, endBookingValue];
    } else {
      return [startBookingValue];
    }
  }

  /**
   * @description Calulates the right day (the current or the day after) based on the fact whether the startingtime is bigger then the endtime or not
   * @returns Tag in YYYYMMDD
   */
  protected calculateEndDay(startingTime: TimepickerTime, endTime: TimepickerTime, baseBooking: TempBuchung): string {
    const startPoint = moment(startingTime.value, 'hh:mm').toDate();
    const endPoint = moment(endTime.value, 'hh:mm').toDate();
    const startDay = moment(baseBooking.Tag, 'YYYYMMDD').toDate();
    const newDay = endPoint < startPoint && endTime.value !== '-1:-1';
    const dayAdjustment = newDay ? 1 : 0;
    startDay.setDate(startDay.getDate() + dayAdjustment);
    return moment(startDay).format('YYYYMMDD');
  }

  protected listenForFormGroupChanges() {
    //Buchungsart
    const bookingTypeChangedSubscription = this.formGroup.controls.typeSelection.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(newType => this.bookingTypeChanged(newType));

    //Start
    const startChangedSubscription = this.formGroup.controls.timepickerStart.valueChanges.subscribe(newStart => {
      this.timeChanged(newStart, null);
    });

    //Ende
    const endChangedSubscription = this.formGroup.controls.timepickerEnd.valueChanges.subscribe(newEnd => {
      this.timeChanged(null, newEnd);
    });

    //Auftrag
    const auftragChangedSubscription = this.formGroup.controls.auftrag.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(auftrag => {
        this.currentAuftrag.next(auftrag);
      });

    //Register Subscriptions
    this.registerSubscription(bookingTypeChangedSubscription);
    this.registerSubscription(auftragChangedSubscription);
    this.registerSubscription(startChangedSubscription);
    this.registerSubscription(endChangedSubscription);
  }

  /**@description Reacts on change of bookingtype and sets labels and colors accordingly */
  protected async bookingTypeChanged(bookingType: BookingType) {
    if (!bookingType) bookingType = BookingType.ARBEITSZEIT;
    this.selectedBookingFrameType.next(bookingType);
    this.resetBookingtypeSpecificFields();
    const title = bookingType.substr(0, 1).toUpperCase() + bookingType.substr(1, bookingType.length - 1).toLowerCase();
    this.TitleStyle.name = title;
    this.SaveManualBuchungStyle.setButtonStyle(bookingType);

    const bookingRight: BookingRight = await getfirst(
      this.mitarbeiter.currentUserRights$.pipe(map(rights => rights.bookingRight))
    );
    await this.changeValidators(bookingType, bookingRight);
    await this.disableFormControls(bookingType, bookingRight);

    if ((bookingType = BookingType.AUFTRAGSZEIT)) {
      this.SyncService.sync(await this.SyncService.getAuftragsSyncSteps());
    }
  }

  /**@description Whenever the type of a booking changes, the set values for another type need a reset */
  protected resetBookingtypeSpecificFields() {
    this.formGroup.patchValue({ auftrag: '', kunde: '', taetigkeit: '', kennzeichen: '' });
  }

  protected listenForTimeChanges() {
    const startChangedSubscription = this.formGroup.controls.timepickerStart.valueChanges.subscribe(newStart => {
      this.timeChanged(newStart, null);
    });
    this.registerSubscription(startChangedSubscription);

    const endChangedSubscription = this.formGroup.controls.timepickerEnd.valueChanges.subscribe(newEnd => {
      this.timeChanged(null, newEnd);
    });
    this.registerSubscription(endChangedSubscription);
  }

  /**@description Reacts on change of endTime and might change the calculated endday accordingly */
  protected timeChanged(newStart?: string, newEnd?: string) {
    this.warnLabel = false;
    this.calculatedEnd = this.selectedStart;
    const startTime = newStart || this.formGroup.value.timepickerStart;
    const endTime = newEnd || this.formGroup.value.timepickerEnd;
    if (startTime === '' || endTime === '') return;

    const dayChange = isDayChange(startTime, endTime);
    if (dayChange) {
      const nextDay = moment(this.selectedStart, 'DD.MM.YYYY').toDate();
      const addADay = nextDay.getDate() + 1;
      nextDay.setDate(addADay);
      this.calculatedEnd = nextDay;
      this.warnLabel = true;
    }
  }

  TitleStyle: TitleStyle = {
    name: 'Arbeitszeit',
    color: 'black',
    fontweight: 'bold',
  };

  SaveManualBuchungStyle = new SaveButtonStyle(BookingType.ARBEITSZEIT);

  protected async changeValidators(bookingType: BookingType, bookingRight: BookingRight) {
    var abwesenheitsgrundValidators = [Validators.nullValidator];
    var auftragValidators = [Validators.nullValidator];
    var taetigkeitValidators = [Validators.nullValidator];

    switch (bookingType) {
      case BookingType.AUFTRAGSZEIT: {
        auftragValidators = [Validators.required];

        if (bookingRight === BookingRight.BuchenMitAuftragserfassungUndTaetigkeiten)
          taetigkeitValidators = [Validators.required];

        break;
      }
      case BookingType.FEHLZEIT: {
        abwesenheitsgrundValidators = [Validators.required];
        break;
      }
    }

    this.formGroup.controls.abwesenheitsgrund.setValidators(abwesenheitsgrundValidators);
    this.formGroup.controls.auftrag.setValidators(auftragValidators);
    this.formGroup.controls.taetigkeit.setValidators(taetigkeitValidators);
  }

  async disableFormControls(bookingType: BookingType, bookingRight: BookingRight) {
    this.formGroup.controls.auftrag.enable();
    this.formGroup.controls.taetigkeit.enable();
    switch (bookingType) {
      case BookingType.AUFTRAGSZEIT: {
        if (bookingRight === BookingRight.BuchenOhneAuftragserfassung) {
          this.formGroup.controls.auftrag.disable();
          this.formGroup.controls.taetigkeit.disable();
        }
      }
    }
  }

  getFormattedSelectedStart(): string {
    return addLeadingZeroInDateValue(this.selectedStart);
  }

  getFormattedCalculatedEnd(): string {
    return addLeadingZeroInDateValue(this.calculatedEnd);
  }
}
