import { ControllerService } from '../../../globalServices/controller.service';
import { Injectable, Injector, OnInit } from '@angular/core';
import { TermConverterService } from '../../../globalServices/termConverter.service';
import { Kunden } from '@entities/Customer';
import { DatabaseService } from '@app/globalServices/database.service';
import { ISyncable, StoreControllerServiceBase } from './StoreControllerServiceBase';

@Injectable({
  providedIn: 'root',
})
export class KundenService extends StoreControllerServiceBase<Kunden> {
  protected GetStoreName(): string {
    return Kunden.StoreName;
  }

  protected entityConstructor(data) {
    return new Kunden(data);
  }

  constructor(
    private readonly controllerservice: ControllerService,
    private readonly dbService: DatabaseService,
    protected readonly injector: Injector
  ) {
    super(injector);
  }

  async Sync(emit?: boolean): Promise<string[]> {
    await this.controllerservice.reloadStore(Kunden.StoreName, 'GetKunden', emit).toPromise();
    return [Kunden.StoreName];
  }

  async getKunden() {
    return (await this.dbService.getData(Kunden.StoreName)).map(element => new Kunden(element));
  }
}
