import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PWAUpdateService } from '@app/globalServices/pwaUpdate.service';
import { SyncService } from '@app/globalServices/sync.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { getfirst } from '@app/utils/rxjsUtils';

/**
 * @class Menu1
 * In dieser Klasse wird das Kachelmenue verwaltet und je nach Klick auf
 * eine bestimmte Kachel in die jeweilige Componente geroutet.
 */
@Component({
  selector: 'app-tab-menu',
  templateUrl: './tabMenu.component.html',
  styleUrls: ['./tabMenu.component.scss'],
})
export class TabMenuComponent implements OnInit {
  selectedTabIndex = 0;

  constructor(
    private readonly router: Router,
    private readonly userSessionService: UserSessionService,
    private readonly syncService: SyncService,
    private readonly pwaUpdateService: PWAUpdateService
  ) {}

  async ngOnInit(): Promise<void> {
    let loggedIn = false;
    try {
      loggedIn = await this.userSessionService.loggedIn.get();
    } catch (err) {
      console.error(err);
    }
    if (!loggedIn) {
      await this.router.navigate(['/login']);
      return;
    }

    history.pushState(null, null, location.href);

    window.addEventListener('load', () => {
      window.history.pushState({}, '');
    });

    window.addEventListener('popstate', () => {
      window.history.pushState({}, '');
    });

    const firstSyncAfterLogin = await this.syncService.querySyncAfterLogin.get();

    const updateAvailable = await getfirst(this.pwaUpdateService.updateAvailable$);

    if (firstSyncAfterLogin && !updateAvailable) {
      this.syncService.triggerSync('offline');
    }
  }

  /**@description Wenn ein Nutzer auf Team oder Übersicht klickt, wird dann erst die jeweilige Komponente gerendert, damit auch definitiv alle Daten da sind */
  onSelectedIndexChange(index: number): void {
    this.selectedTabIndex = index;

    const header = document.getElementsByClassName('mdc-tab mat-mdc-tab');
    const correctHeader = header[index];
    correctHeader.scrollIntoView();
  }
}
