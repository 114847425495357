import { Component, Input } from '@angular/core';
import { ModalAction, ModalActionType } from '@app/utils/types';
import { ButtonStyle } from './ButtonStyle';

@Component({
  selector: 'app-bottom-sheet-button',
  templateUrl: './bottom-sheet-button.component.html',
  styleUrls: ['./bottom-sheet-button.component.scss'],
})
export class BottomSheetButtonComponent {
  @Input() Action: ModalAction;
  @Input() ButtonStyle: ButtonStyle;

  bgColor() {
    const get = () => {
      if (this.Action?.Disabled) return 'gray';
      return (this.ButtonStyle || this.Default).backgroundColor;
    };
    const result = get();
    return result;
  }

  color(): string {
    if (this.Action?.Disabled) return 'white';
    return (this.ButtonStyle || this.Default).color;
  }

  public readonly Default: ButtonStyle = {
    color: 'white',
    backgroundColor: 'green',
    icon: null,
    description: 'Beschreibung',
  };

  public readonly DefaultAction: ModalAction = {
    Type: ModalActionType.OK,
    Disabled: false,
    Execute: () => {},
  };

  constructor() {}
}
