import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { getMonthName } from '@app/utils/dateTimeUtils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class MonthpickerDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }
}

export interface MonthPickerFormat {
  month: number;
  monthName: string;
  year: number;
}

@Component({
  selector: 'app-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MonthpickerDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class MonthPickerComponent implements OnInit {
  date: Date = new Date();

  validDateSubject = new BehaviorSubject<Date>(this.date);
  validDate$: Observable<MonthPickerFormat> = this.validDateSubject.asObservable().pipe(
    map(date => {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const monthPickerObject = {
        month: month,
        monthName: getMonthName(month),
        year: year,
      };
      this.formattedDate.emit(monthPickerObject);
      return monthPickerObject;
    })
  );

  @Output() formattedDate = new EventEmitter<MonthPickerFormat>();

  constructor() {}

  ngOnInit(): void {
    this.validDateSubject.next(this.date);
  }

  backwards() {
    this.date.setMonth(this.date.getMonth() - 1);
    this.validDateSubject.next(this.date);
  }

  forwards() {
    this.date.setMonth(this.date.getMonth() + 1);
    this.validDateSubject.next(this.date);
  }

  clickDate(datePicker: any) {
    datePicker.open();
  }

  public emitDateChange(event): void {
    this.validDateSubject.next(event.value);
  }

  public monthChanged(value: any, datePicker: any): void {
    this.date = value;
    this.validDateSubject.next(this.date);
    datePicker.close();
  }
}
