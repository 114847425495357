import { IndexedDBTypes } from './dbType';

const EMPLOYEE_STORE_NAME = 'Mitarbeiter';

export interface EmployeeRights {
  foreman: boolean;
  journalUse: boolean;
  journalLevel: string;
  bookingRight: number;
}

export class Employee extends IndexedDBTypes.DbType {
  public static StoreName = EMPLOYEE_STORE_NAME;

  @IndexedDBTypes.KlassenName(EMPLOYEE_STORE_NAME) private KlassenName = EMPLOYEE_STORE_NAME;
  @IndexedDBTypes.KeyDBField('string') Pnr: string;
  @IndexedDBTypes.IndexField('string') Vorname: string;
  @IndexedDBTypes.IndexField('string') Nachname: string;
  // @IndexedDBTypes.DataField('string') private Kennwort: string;
  @IndexedDBTypes.DataField('string') Karte: string;
  @IndexedDBTypes.DataField('string') Pnrs: string[];
  @IndexedDBTypes.DataField('string') Journal: string;
  @IndexedDBTypes.DataField('number') Vorarbeiter: boolean;
  @IndexedDBTypes.DataField('') BuchungsRecht: number;
  @IndexedDBTypes.DataField('bool') GPSTracking: boolean;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  //#region Rights

  public getRightsObject(): EmployeeRights {
    return {
      foreman: this.Vorarbeiter,
      journalUse: this.hasJournalRight(),
      journalLevel: this.Journal,
      bookingRight: this.BuchungsRecht,
    };
  }

  public hasJournalRight(): boolean {
    try {
      const level = parseInt(this.Journal, 10);
      return level > 0;
    } catch (error) {
      return false;
    }
  }
  //#endregion

  //#region Get/Set

  public getIndex(): string {
    return this.Pnr;
  }

  public getFullName(): string {
    return `${this.Vorname} ${this.Nachname}`;
  }

  //#endregion

  removeDuplicates(pnrTemp: string[]): string[] {
    const final = [];
    for (const pnrs of pnrTemp) {
      if (!final.includes(pnrs)) {
        final.push(pnrs);
      }
    }
    return final;
  }

  static toString(): string {
    return EMPLOYEE_STORE_NAME;
  }

  public getGPSTracking(): boolean {
    return this.GPSTracking;
  }
}
