import * as moment from 'moment';

export function generateInterval(
  stepsInMin: number = 15,
  formatString: string = 'HH:mm',
  startTimeInMin: number = 0
): string[] {
  const result = [];
  const now = moment(moment.now());
  const today = now.format('dd');
  let current = now.hour(0).minute(startTimeInMin);

  while (current.format('dd') === today) {
    result.push(current.format(formatString));
    current = current.add(stepsInMin, 'minutes');
  }
  return result;
}

export function getMonthName(monthNumber: number): string {
  var date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);

  var locale = 'de-DE',
    monthName = date.toLocaleString(locale, { month: 'long' });

  return monthName;
}
