<div
  class="title bottom-sheet-frame-item-title"
  [ngStyle]="{
    color: Title || DefaultTitle.color,
    'font-weight': Title || DefaultTitle.fontweight
  }"
>
  {{ Title || DefaultTitle.name }}
</div>
<div class="content bottom-sheet-frame-item-content">
  <ng-content></ng-content>
</div>

<app-bottom-sheet-button *ngFor="let action of Actions" [Action]="action"> </app-bottom-sheet-button>
