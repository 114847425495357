<ng-container *ngIf="!!Parameters">
  <mat-form-field mat-list-item>
    <input
      [formControl]="autoCompleteControl"
      #matInput
      type="text"
      aria-label="AutocompleteSelect"
      matInput
      [matAutocomplete]="auto"
      (blur)="onBlur()"
      (input)="onInput($event.target.value)"
    />

    <mat-label>{{ Parameters?.Placeholder || 'Placeholder' }}</mat-label>
    <mat-autocomplete
      #autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onSelect($event.option.value)"
      [displayWith]="Parameters?.displayFunc"
      [panelWidth]="'calc(100% - 70px)'"
    >
      <div class="autocomplete">
        <ng-container *ngIf="options$ | async as options">
          <mat-option *ngIf="!Parameters?.noEmptyOption" [value]="null" class="clearValue">
            - Auswahl leeren -
            <ng-container
              *ngTemplateOutlet="optionTemplate || defaultOptionDisplayTemplate; context: { $implicit: null }"
            ></ng-container>
          </mat-option>
          <mat-option *ngFor="let option of options" [value]="option" [ngClass]="{ option: true }">
            <div [ngClass]="{ text: true, fullLengthText: showFullLengthText }">
              <ng-container
                *ngTemplateOutlet="optionTemplate || defaultOptionDisplayTemplate; context: { $implicit: option }"
              ></ng-container>
            </div>
          </mat-option>
          <div [ngClass]="{ optionEnd: true, optionEndFull: options.length > 3 }"></div>
        </ng-container>
      </div>
    </mat-autocomplete>
  </mat-form-field>

  <ng-template #defaultOptionDisplayTemplate let-option>{{ Parameters?.displayFunc(option) }}</ng-template>
</ng-container>
