import { IndexedDBTypes } from './dbType';

export class Auswertung extends IndexedDBTypes.DbType {
  public static StoreName = 'Auswertung';

  @IndexedDBTypes.KlassenName('Auswertung') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') Key: string;
  @IndexedDBTypes.IndexField('string') Pnr: string;
  @IndexedDBTypes.IndexField('string') Tag: string;

  @IndexedDBTypes.DataField('string') BeginStd: number;
  @IndexedDBTypes.DataField('string') BeginMin: number;

  @IndexedDBTypes.DataField('string') EndStd: number;
  @IndexedDBTypes.DataField('string') EndMin: number;

  @IndexedDBTypes.DataField('string') Auftrag: string;
  @IndexedDBTypes.DataField('string') Auftragbeschreibung: string;
  @IndexedDBTypes.DataField('string') Taetigkeit: string;
  @IndexedDBTypes.DataField('string') Taetigkeitbeschreibung: string;

  @IndexedDBTypes.DataField('string') Kommentar1: string;
  @IndexedDBTypes.DataField('string') Kommentar2: string;
  @IndexedDBTypes.DataField('string') Kommentar3: string;
  @IndexedDBTypes.DataField('string') Kennzeichen: string;

  @IndexedDBTypes.DataField('string') Longitude: string; // DTO
  @IndexedDBTypes.DataField('string') Latitude: string; // DTO
  @IndexedDBTypes.DataField('string') Grund: string; // DTO

  @IndexedDBTypes.DataField('string') Vongerundet: number;
  @IndexedDBTypes.DataField('string') Bisgerundet: number;

  // @IndexedDBTypes.DataField('string') Pause: string;
  @IndexedDBTypes.DataField('string') Zeit: string;
  @IndexedDBTypes.DataField('string') Sollzeit: string;
  @IndexedDBTypes.DataField('string') Sollpause: string;
  // @IndexedDBTypes.DataField('string') Taetigkeitpaket: string;
  // @IndexedDBTypes.DataField('string') Radius: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  public getBeginn() {
    return this.BeginStd * 60 + this.BeginMin;
  }

  public getEnde() {
    return this.EndStd < 0 ? -1 : this.EndStd * 60 + this.EndMin;
  }

  public getKz() {
    return this.Kennzeichen;
  }

  public getGrund() {
    return this.Grund;
  }

  public getAuftragBeschreibung() {
    return this.Auftragbeschreibung;
  }

  public getTaetigkeitBeschreibung() {
    return this.Taetigkeitbeschreibung;
  }

  public getAuftrag() {
    return this.Auftrag;
  }

  public getSollZeit() {
    return this.Sollzeit;
  }

  public getSollPause() {
    return this.Sollpause;
  }

  public getZeit() {
    return this.Zeit;
  }

  public getPnr() {
    return this.Pnr;
  }

  public getKommentar() {
    const comment1 = this.Kommentar1 || '';
    const comment2 = this.Kommentar2 || '';
    const comment3 = this.Kommentar3 || '';
    const comment = comment1 + comment2 + comment3;
    return comment;
  }

  public getTag() {
    return this.Tag;
  }

  public getLongitude() {
    return this.Longitude;
  }

  public getLatitude() {
    return this.Latitude;
  }

  static toString(): string {
    return 'Auswertung';
  }
}
