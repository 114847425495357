import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';

import { AppComponent } from './app.component';

import { TabMenuCanDeactivateGuard } from './tab-menu-can-deactivate-guard.service';
import { TabMenuComponent } from '@pages/TabGroup/routed/tabMenu/tabMenu.component';

import { UebersichtComponent } from '@pages/TabGroup/presentation/uebersicht/uebersicht.component';
import { AntraegeComponent } from '@pages/TabGroup/presentation/antraege/antraege.component';
import { SaldenComponent } from '@pages/TabGroup/presentation/salden/salden.component';
import { TeamComponent } from '@pages/TabGroup/presentation/team/team.component';
import { BuchungComponent } from '@pages/TabGroup/presentation/buchung/buchung.component';

import { LoginComponent } from '@pages/login/login.component';
import { AppinfoComponent } from '@pages/appinfo/appinfo.component';
import { ImpressumComponent } from '@pages/impressum/impressum.component';
import { EinstellungenComponent } from '@pages/einstellungen/einstellungen.component';
import { JournalComponent } from '@pages/TabGroup/presentation/buchung/journal/journal.component';

const routes: Route[] = [
  { path: 'login', component: LoginComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'appinfo', component: AppinfoComponent },
  {
    path: 'tabgroup',
    data: { title: false, depth: 1 },
    component: TabMenuComponent,
    canDeactivate: [TabMenuCanDeactivateGuard],
  },
  // { path: 'uebersicht',         component: UebersichtComponent },
  // { path: 'antraege',    component: AntraegeComponent},
  // { path: 'salden',    data: {title: false, depth: 2},  component: SaldenComponent},
  // { path: 'buchung',  data: {subcategory: 'buchung', depth: 2},   component: BuchungComponent},
  // { path: 'team', component: TeamComponent},
  // { path: 'menu', component: MenuComponent},
  { path: 'einstellungen', component: EinstellungenComponent },
  { path: 'journal', component: JournalComponent },
  { path: 'antraege', component: AntraegeComponent },

  { path: '', redirectTo: 'tabgroup', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  exports: [RouterModule],
  imports: [CommonModule, RouterModule.forRoot(routes, {})],
  declarations: [],
  providers: [TabMenuCanDeactivateGuard],
})
export class AppRoutingModule {}
