import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, group, query, style, animate, stagger, state } from '@angular/animations';
import { Location } from '@angular/common';
import { TimeService } from 'src/app/globalServices/time.service';
import { interval, Subscription } from 'rxjs';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    // trigger('popOverState', [
    //   state('show', style({
    //     transform: 'translateY(-94vh)'
    //   })),
    //   state('hide',   style({
    //     transform: 'translateY(100vh)'
    //   })),
    //   transition('show => hide', animate('500ms ease-out')),
    //   transition('hide => show', animate('500ms ease-in'))
    // ])
  ],
})
export class ToolbarComponent implements OnInit {
  showHomeButton = false;
  showIcon = true;
  showSpacer = true;
  subscription: Subscription;
  title = 'zeit:erfassung';
  mPunkt = [
    { color: 'white', text: 'Einstellungen' },
    { color: '#d44848', text: 'Einstellungen' },
    { color: 'white', text: 'Credits' },
    { color: '#d44848', text: 'XXX' },
    { color: 'white', text: 'XXX' },
    { color: '#d44848', text: 'Ausloggen' },
  ];
  show = false;
  showMenueButton1 = false;
  showMenueButton2 = true;
  showMenueButton3 = false;
  showToolbarTitle = false;
  showToolbar = false;
  showImage1 = true;
  showBurgerMenu = true;
  burgerMenueImage = 'assets/icons/burger_menu.svg';
  nurLogoUrl = 'assets/icons/topzeit_Logo-512x512.png';
  image1Url = 'assets/icons/my_blueapp_zeiterfassung.svg';
  image2Url = 'assets/icons/Impressum.png';
  image3Url = 'assets/icons/App_Info.png';
  imageBack = 'assets/icons/ArrowLeft.png';
  time;
  date;
  background;
  showMenu: boolean;
  toggleMenu: boolean = false;
  inMenu = false;
  showBack = true;
  nurLogo = false;
  loginScenario = [false, true, false, true, true, 'white', false, false, 'Login', true, true];
  defaultScenario = [false, true, false, false, false, 'white', true, false, 'login', false, false];
  subMenuScenario = [false, false, false, true, true, 'white', false, true, 'menu', false, false];
  tabMenuScenario = [true, false, false, false, true, 'white', true, false, 'tabMenu', true, false];

  constructor(
    private router: Router,
    private location: Location,

    private timeService: TimeService,
    private session: UserSessionService,
    private globalSettingService: GlobalSettingService
  ) {
    router.events.subscribe(val => {
      const route = location.path();
      let scenario;
      if (route === '/') {
        scenario = this.defaultScenario;
      } else if (route === '/login') {
        scenario = this.loginScenario;
      } else if (route === '/appinfo') {
        scenario = this.subMenuScenario;
        this.subMenuScenario[8] = 'Appinfo';
      } else if (route === '/impressum') {
        scenario = this.subMenuScenario;
        this.subMenuScenario[8] = 'Impressum';
      } else if (route === '/einstellungen') {
        scenario = this.subMenuScenario;
        this.subMenuScenario[8] = 'Einstellungen';
      } else if (route === '/journal') {
        scenario = this.subMenuScenario;
        this.subMenuScenario[8] = 'Journal';
        this.subMenuScenario[9] = true;
      } else {
        scenario = this.tabMenuScenario;
      }

      this.showMenueButton1 = scenario[0];
      this.showMenueButton2 = scenario[1];
      this.showMenueButton3 = scenario[2];
      this.showToolbarTitle = scenario[3];
      this.showToolbar = scenario[4];
      this.background = scenario[5];
      this.showImage1 = scenario[6];
      this.showBack = scenario[7];
      this.title = scenario[8];
      this.showBurgerMenu = scenario[9];
      this.nurLogo = scenario[10];
    });
  }

  ngOnInit() {}

  async goHome() {
    await this.router.navigate(['/tabgroup']);
  }

  get stateName() {
    return this.show ? 'show' : 'hide';
  }

  openMenu() {
    this.toggleMenu = true;
  }

  closeMenu() {
    this.toggleMenu = false;
  }

  async backClicked() {
    const loggedIn = await this.session.loggedIn.get();
    if (!loggedIn) {
      await this.router.navigate(['/login']);
      return;
    }
    if (this.router.url !== '/tabgroup') {
      await this.router.navigate(['/tabgroup']);
    }
  }
}
