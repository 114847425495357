import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Setting } from '@entities/Setting';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { ControllerService } from './controller.service';
import { DatabaseService } from './database.service';
import { DialogService } from './dialog.service';
import { GlobalSettingService } from './settings/global-setting.service';
import { SyncService } from './sync.service';
import { UserSessionService } from './user-session-state.service';

@Injectable({
  providedIn: 'root',
})
export class PWAUpdateService implements OnDestroy {
  private updateTimerSubscription: Subscription;
  private updateReminder: Subscription;

  constructor(
    private updates: SwUpdate,
    private dialogService: DialogService,
    private syncService: SyncService,
    private session: UserSessionService,
    private dbService: DatabaseService,
    private settingsService: GlobalSettingService
  ) {}

  checkForUpdates$ = this.session.loggedIn.value$.pipe(
    switchMap(async res => {
      if (res) {
        await this.periodicCheck(5);
      }
    })
  );

  updateAvailableSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  updateAvailable$ = this.updateAvailableSubject.asObservable();

  ngOnDestroy(): void {
    this.updateTimerSubscription?.unsubscribe();
    this.updateReminder?.unsubscribe();
  }

  /**@description Prüft alle N Minuten ob ein Update der PWA vorliegt , löst dann Userinteraktion aus
   */
  async periodicCheck(timeIntervallInMinutes: number): Promise<void> {
    console.log('Update-Check started');
    const timeIntervallMilliseconds = timeIntervallInMinutes * 60 * 1000;
    void this.intervallCheckUpdate(timeIntervallMilliseconds);
    const everyNHours$ = interval(timeIntervallMilliseconds);
    this.updateTimerSubscription = everyNHours$.subscribe(async () => {
      await this.intervallCheckUpdate(timeIntervallMilliseconds);
    });
  }

  /**
   * Registriert einen Listener für den Fall das der ServiceWorker ein Update
   * gefunden hat. Um das Update zu nutzen, muss dann ein reload der Seite ausgeführt werden.
   */
  private async intervallCheckUpdate(reminderIntervallMinutes: number): Promise<void> {
    console.log('Check for Update!');
    const updateAvailableSubscription = await this.updates.checkForUpdate();
    console.log('Update-Check finished!');
    if (updateAvailableSubscription) {
      console.log('PWA Update available');
      void this.askUserForUpdate(reminderIntervallMinutes);
    }
  }

  /**@description Öffnet den Dialog um den User über das Update zu benachrichtigen  */
  private async askUserForUpdate(reminderIntervallMinutes: number) {
    const updateInfoText =
      'Es liegt ein Update für Ihre my blue:app zeit:erfassung vor. Das Update setzt die aktuellste Version der topzeit voraus. Wollen Sie das Update jetzt durchführen?';
    const confirmation = await this.dialogService.QueryYesNo('Update verfügbar', updateInfoText);
    if (confirmation === true && navigator.onLine) {
      this.updateReminder?.unsubscribe();
      await this.doUpdateRoutine();
      return;
    }
    if (confirmation === true && !navigator.onLine) {
      await this.dialogService.ShowInformation(
        'Update fehlgeschlagen',
        'Das Update kann nur bei bestehender Internetverbindung durchgeführt werden!'
      );
      this.openUpdateReminder(reminderIntervallMinutes);
      return;
    }
    if (confirmation === false) {
      this.openUpdateReminder(reminderIntervallMinutes);
      return;
    }
  }

  /**@description Öffnet periodisch die Erinnerung des Updatedialogs */
  private openUpdateReminder(reminderIntervallMinutes: number): void {
    const reminderIntervall = interval(reminderIntervallMinutes);
    this.updateReminder = reminderIntervall.subscribe(() => void this.remindUserUntilUpdate());
  }

  private async remindUserUntilUpdate(): Promise<void> {
    const updateInfoText =
      'Es liegt ein Update für Ihre my blue:app zeit:erfassung vor. Das Update setzt die aktuellste Version der topzeit voraus. Wollen Sie das Update jetzt durchführen?';
    const confirmation = await this.dialogService.QueryYesNo('Update verfügbar', updateInfoText);

    if (confirmation === true && navigator.onLine) {
      this.updateReminder?.unsubscribe();
      await this.doUpdateRoutine();
    }
  }

  /**@description Pusht alle zu pushenden Daten, meldete die Geräte für Lizenz und Push ab und führt dann das Update durch */
  public async doUpdateRoutine(): Promise<void> {
    try {
      await this.syncService.syncData(false);
      console.log('synced Data');
      await this.dbService.clearAllStores();
      console.log('cleared Stores');
      await this.updates.activateUpdate();
      console.log('activatedUpdate');
      await this.settingsService.updateDone.set(false);
      console.log('set Flag');
      window.location.reload();
    } catch (error) {
      await this.dialogService.ShowError(error);
    }
  }

  /**@description Nach dem Update des PWA-Codes wird die Settings-Tabelle zwischengespeichert. Dann wird die indexedDb verworfen und neu angelegt.
   * Die Settings-Tabelle wird wieder gefüllt und der User wieder eingeloggt.
   */
  public async startUpdateProcess(): Promise<void> {
    console.log('Update wird durchgeführt');
    const conservedData = await this.dbService.getData(Setting.StoreName);
    await this.dbService.rebuildDB();
    await this.dbService.setData(Setting.StoreName, conservedData);
    await this.syncService.syncData(false);
  }
}
