import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
export const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor() {}

  getDayTimeInMS(date: Date): number {
    const now = date.getTime();
    return now % MILLISECONDS_PER_DAY;
  }

  /**@description Gibt an, ob zwei Daten am selben Tag sind */
  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  addDay(date: Date, count: number = 1): Date {
    return new Date(date.getTime() + count * MILLISECONDS_PER_DAY);
  }

  /**@description Berechnet die Differenz von zwei Daten in Tagen, wenn kein startingDate angegeben wird, wird das aktuelle Datum genutzt */
  calculateDayDifference(endDate: Date, startingDate?: Date): number {
    let startDate = new Date();
    if (startingDate) {
      startDate = startingDate;
    }
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    const differenz = endDate.getTime() - startDate.getTime();
    const differenzTage = differenz / MILLISECONDS_PER_DAY;
    return differenzTage;
  }

  /**@description Returns if Minutecounter has changed since Booking*/
  minuteHasChanged(date: Date): boolean {
    if (!date) {
      return true;
    }

    const now = new Date();
    return (
      now.valueOf() > date.valueOf() &&
      (now.valueOf() - date.valueOf() > 60 * 1000 || date.getMinutes() < now.getMinutes())
    );
  }

  /**Nimmt ein formatiertes Datum im Format 31.12.2001 00:00:00 und gibt ein date zurück */
  getDateFromFormattedDate(currentDateComplete: string): Date {
    // currentDateComplete kommt im Format: 01.12.2019 00:00:00 , deswegen splitten an leerzeichen, anschließend an punkten
    const currentDate = currentDateComplete.split(' ');
    const tagMonatJahr = currentDate[0].split('.');
    const tag = parseInt(tagMonatJahr[0], 0);
    // auch hier beachten, monat beginnt bei 0, daher einen abziehen
    const monat = parseInt(tagMonatJahr[1], 0) - 1;
    const jahr = parseInt(tagMonatJahr[2], 0);
    const newDate = new Date(jahr, monat, tag);
    return newDate;
  }

  public getNowDateString(withoutTime?: boolean): string {
    return this.getDateFromRawDate(new Date(), withoutTime);
  }

  readonly currentDate$ = of(new Date());

  readonly lastTwoDays$ = this.currentDate$.pipe(map<Date, [Date, Date]>(today => [this.addDay(today, -1), today]));

  readonly currentMonth$ = this.currentDate$.pipe(
    map(today => this.getAktuellenMonat()),
    distinctUntilChanged()
  );

  readonly lastMonth$ = this.currentDate$.pipe(
    map(today => this.getAktuellenMonat(-1)),
    distinctUntilChanged()
  );

  isBetween(start: Date, end: Date, selected: Date): boolean {
    const startDate = Math.floor(start.getTime() / MILLISECONDS_PER_DAY);
    const endDate = Math.floor(end.getTime() / MILLISECONDS_PER_DAY);
    const selectedDate = Math.floor(selected.getTime() / MILLISECONDS_PER_DAY);

    const b4End = selectedDate <= endDate;
    const afterStart = startDate <= selectedDate;
    const result = b4End && afterStart;
    return result;
  }

  /**
   * @description Nimmt ein Date und gibt das passende Datum zurück im Format 31.12.2001 00:00:00
   * @param date - Das Datum
   * @param withoutTime - Optional, wenn true gesetzt, wird die zeit am Ende weggelassen
   */
  public getDateFromRawDate(date: Date, withoutTime?: boolean): string {
    let tag = date.getDate().toString();
    if (tag.length === 1) {
      tag = '0' + tag;
    }
    // die getMonth Methode beginnt bei 0 ...
    let monat = (date.getMonth() + 1).toString();
    if (monat.length === 1) {
      monat = '0' + monat;
    }
    const jahr = date.getFullYear();
    let time = ' 00:00:00';
    if (withoutTime) {
      time = '';
    }
    const datum = tag + '.' + monat + '.' + jahr + time;
    return datum;
  }

  /**@description Nimmt einen Tag im Format dd.mm.yyyy 00:00:00  und gibt ihn zurück in ddmmyyyy */
  toDBCompliantDay(date: string | Date): string {
    let datestring: string;
    if (typeof date !== 'string') datestring = this.getDateFromRawDate(date);
    else datestring = date;

    const splittedTag = datestring.split(' ');
    const splitAtPoints = splittedTag[0].split('.');
    return splitAtPoints[2] + splitAtPoints[1] + splitAtPoints[0];
  }

  public getTimeFromMatDatePicker(timestamp) {
    const date = new Date(timestamp);
    const month = '' + date.getMonth() + 1;
    const date1 = '' + date.getDate();
    const year = '' + date.getFullYear();
    return date1 + '.' + month + '.' + year + ' 00:00:00';
  }

  calcStundenMinuten(zeitMinuten: string | number): string {
    let minuten: number = typeof zeitMinuten === 'string' ? parseInt(zeitMinuten, 10) : zeitMinuten;
    let sign = '';
    if (minuten < 0) {
      sign = '-';
    }
    minuten = Math.abs(minuten);
    const stunden = Math.floor(minuten / 60);
    const rest = minuten % 60;
    let startNull = '';
    if (rest.toString().length < 2) {
      startNull = '0';
    }
    return sign + stunden + ':' + startNull + rest;
  }

  /**@description Validiert das Format und die Werte einer Uhrzeit */
  validateUhrzeit(uhrzeit: any): boolean {
    const uhrzeitArray = uhrzeit.split(':');
    const stunden = parseInt(uhrzeitArray[0], 10);
    const minuten = parseInt(uhrzeitArray[1], 10);
    if (uhrzeitArray.length !== 2) {
      return false;
    }
    if (stunden < 0 || stunden > 23 || isNaN(stunden)) {
      return false;
    }
    if (minuten < 0 || minuten > 59 || isNaN(minuten)) {
      return false;
    }
    return true;
  }

  /**@description Guckt ob die Buchung die in der IDB liegt die aktuellste ist oder die letzte der Auswertung neuer ist */
  getLetzteZeitpunkt(timestampZEFBeginn: string, timestampZEFEnde: string, timestampIDB: string): string {
    if (this.isTimeStampBiggerOrEqual(timestampIDB, timestampZEFEnde)) {
      return 'idb';
    } else {
      return 'zef';
    }
  }

  /**@description Gibt true zurück, wenn timestamp1 größer timestamp2 ist, false wenn 2 größer gleich 1 ist */
  isTimeStampBiggerOrEqual(timestamp1: string, timestamp2: string): boolean {
    if (timestamp1.startsWith('-')) {
      return true;
    }
    if (timestamp2.startsWith('-')) {
      return false;
    }
    const timestamp1Array = timestamp1.split(':');
    const timestamp2Array = timestamp2.split(':');
    const timestamp1Stunden = parseInt(timestamp1Array[0], 10);
    const timestamp1Minuten = parseInt(timestamp1Array[1], 10);
    const timestamp2Stunden = parseInt(timestamp2Array[0], 10);
    const timestamp2Minuten = parseInt(timestamp2Array[1], 10);
    const timeStamp1Gesammtminuten = 60 * timestamp1Stunden + timestamp1Minuten;
    const timeStamp2Gesammtminuten = 60 * timestamp2Stunden + timestamp2Minuten;
    if (timeStamp1Gesammtminuten > timeStamp2Gesammtminuten) {
      return true;
    } else {
      return false;
    }
  }

  /**@description Gibt ein Datum als String basierend auf der CurrentTimeTOADd zurück */
  currentTimeToAddToDatum(currentTimeToAdd: number): string {
    const today = new Date();
    const correctDay = today.setDate(today.getDate() + currentTimeToAdd);
    const date = this.getDateFromRawDate(new Date(correctDay), true);
    const returnDateArray = date.split('.');
    return returnDateArray[2] + returnDateArray[1] + returnDateArray[0];
  }

  /**@description Nimmt ein dbDatum im Format yyyy.mm.dd und gibt es im Format dd.mm.yy zurück */
  datumToyyyymmdd(dbDate: string): string {
    if ((dbDate?.length || 0) < 8) return '00000000';
    return dbDate[4] + dbDate[5] + dbDate[6] + dbDate[7] + dbDate[2] + dbDate[3] + dbDate[0] + dbDate[1];
  }

  getAktuellenMonat(offset: number = 0): string {
    const jetzt = new Date();
    const monat = ((jetzt.getMonth() + 12 + (offset % 12)) % 12) + 1;
    switch (monat) {
      case 1: {
        return 'Januar';
      }
      case 2: {
        return 'Februar';
      }
      case 3: {
        return 'März';
      }
      case 4: {
        return 'April';
      }
      case 5: {
        return 'Mai';
      }
      case 6: {
        return 'Juni';
      }
      case 7: {
        return 'Juli';
      }
      case 8: {
        return 'August';
      }
      case 9: {
        return 'September';
      }
      case 10: {
        return 'Oktober';
      }
      case 11: {
        return 'November';
      }
      case 12: {
        return 'Dezember';
      }
    }
  }
}

/**@description Konvertiert eine Nummer in einen Wochentag, dabei ist 0=Sonntag, 1=Montag ... */
export function convertNumberToWochentag(tag: number): string {
  switch (tag) {
    case 0: {
      return 'Sonntag';
    }
    case 1: {
      return 'Montag';
    }
    case 2: {
      return 'Dienstag';
    }
    case 3: {
      return 'Mittwoch';
    }
    case 4: {
      return 'Donnerstag';
    }
    case 5: {
      return 'Freitag';
    }
    case 6: {
      return 'Samstag';
    }
  }
}

/**@description Nimmt eine Uhrzeit im Format hh:mm (aber auch h:m) und gibt die Gesammtminuten seit 0Uhr zurück( 600 für 10Uhr ) */
export function calcMinutenFromTime(time: string): number {
  const stundenMinuten = time.split(':');
  const stunden = parseInt(stundenMinuten[0], 10);
  const minuten = parseInt(stundenMinuten[1], 10);
  return stunden * 60 + minuten;
}

/**@description Formatiert ein Date in eine Uhrzeit vom Format (hh:mm) */
export function getTimeFormatForTimePicker(date: Date): string {
  const hours = date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString();
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();
  return hours + ':' + minutes;
}
