import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DialogService } from '@app/globalServices/dialog.service';
import { TimeService } from '@app/globalServices/time.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { DomainError, DomainErrorCode } from '@app/utils/ErrorHandling/DomainError';
import { getfirst } from '@app/utils/rxjsUtils';
import { ModalAction, ModalActionType } from '@app/utils/types';
import { shortenString } from '@app/utils/utils';
import { BaseBottomsheetComponent } from '@base-components/BaseBottomsheetComponent';
import { AutocompleteParameters } from '@base-components/form-components/autocomplete-input/autocomplete-input.component';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { TKennzeichen, TKennzeichenBooking } from '@entities/TKennzeichen';
import { TKennzeichenService } from '@stores/ZEFTKennzeichen.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';

export class TKennzeichenBuchenParameter {
  tkennzeichungBooked: TKennzeichenBooking;
  edit: boolean;
}

@Component({
  selector: 'app-tkennzeichen-buchen',
  templateUrl: './tkennzeichen-buchen.component.html',
  styleUrls: ['./tkennzeichen-buchen.component.scss'],
})
export class TKennzeichenBuchenComponent extends BaseBottomsheetComponent<void, TKennzeichen> implements OnInit {
  constructor(
    protected readonly injector: Injector,
    private readonly tkennzeichenService: TKennzeichenService,
    private readonly userSessionService: UserSessionService,
    private readonly dialogService: DialogService,
    private readonly timeService: TimeService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: TKennzeichenBuchenParameter
  ) {
    super(injector);
  }

  tkennzeichenForSelecting$ = this.tkennzeichenService.getTKennzeichen$;

  editActiveSubject = new BehaviorSubject<boolean>(false);
  editActive$ = this.editActiveSubject.asObservable();

  async ngOnInit() {
    if (this.data) {
      this.editActiveSubject.next(this.data.edit);
      this.tkennzeichenForm.controls.datum.disable();
      this.tkennzeichenForm.controls.datum.setValue(
        this.timeService.getDateFromFormattedDate(this.data.tkennzeichungBooked.Datum)
      );
      this.tkennzeichenForm.controls.kennzeichen.setValue(
        await this.tkennzeichenService.getTKennzeichenFromKennzeichen(this.data.tkennzeichungBooked.Kennzeichen)
      );
    } else {
      this.editActiveSubject.next(false);
    }
  }

  tkennzeichenForm: UntypedFormGroup = new UntypedFormGroup({
    kennzeichen: new UntypedFormControl(''),
    datum: new UntypedFormControl(new Date()),
    tkWert: new UntypedFormControl(),
  });

  readonly disableSubmit$ = combineLatest([this.tkennzeichenForm.valueChanges, this.editActive$]).pipe(
    startWith([null, false]),
    map(([values, editActive]) => {
      return editActive ? false : !values?.datum || !values?.kennzeichen;
    })
  );

  readonly SaveButtonAction$: Observable<ModalAction> = combineLatest([this.disableSubmit$, this.editActive$]).pipe(
    map(([disableSubmit, editActive]) => {
      return {
        Type: ModalActionType.OK,
        Disabled: disableSubmit,
        Execute: () => this.save(editActive),
      };
    })
  );

  readonly SaveButtonStyle$: Observable<ButtonStyle> = this.editActive$.pipe(
    map(editActive => {
      return {
        color: 'white',
        backgroundColor: editActive ? 'rgb(209, 91, 0)' : '#009118',
        icon: 'assets/icons/paper_jet2.png',
        description: editActive ? 'Tageskennzeichen überschreiben' : 'Tageskennzeichen buchen',
      };
    })
  );

  async save(edit?: boolean) {
    const tkennzeichenBooking = new TKennzeichenBooking({
      Datum: this.tkennzeichenForm.getRawValue().datum,
      Kennzeichen: this.tkennzeichenForm.value.kennzeichen.Kennzeichen,
      Pnr: await this.userSessionService.pnr$.pipe(first()).toPromise(),
      Wert: this.tkennzeichenForm.value.tkWert ?? this.tkennzeichenForm.value.kennzeichen.Wert,
    });
    try {
      if (edit) {
        await this.dialogService.ShowSpinnerWhile(
          this.tkennzeichenService.updateTKennzeichenBooking(tkennzeichenBooking),
          'Tageskennzeichen buchen',
          'Ihr Tageskennzeichen wird überschrieben.'
        );
      } else {
        await this.dialogService.ShowSpinnerWhile(
          this.tkennzeichenService.setTKennzeichenBooking(tkennzeichenBooking),
          'Tageskennzeichen buchen',
          'Ihr Tageskennzeichen wird gebucht.'
        );
      }
    } catch (error) {
      if (error instanceof DomainError) {
        await this.dialogService.ShowError(error);
        if (error.ErrorCode == DomainErrorCode.AlreadyExists) {
          if (
            await this.dialogService.QueryYesNo('Doppelte Buchung', 'Soll das Tageskennzeichen überschrieben werden?')
          ) {
            this.save(true);
          }
        }
      } else {
        await this.dialogService.ShowInformation(
          'Fehler beim Buchen',
          'Ihr Tageskennzeichen konnte nicht gebucht werden.'
        );
      }
    }
    await this.bottomSheet.dismiss();
  }

  readonly tkennzeichenParameters: AutocompleteParameters<TKennzeichen> = {
    displayFunc: (tkennzeichen: TKennzeichen) =>
      tkennzeichen
        ? shortenString(tkennzeichen?.Kennzeichen, 10) +
          ' | ' +
          shortenString(tkennzeichen?.Beschreibung, 10) +
          ' | ' +
          tkennzeichen?.Wert
        : '',
    toSearchText: (tkennzeichen: TKennzeichen) =>
      (tkennzeichen?.Kennzeichen + tkennzeichen?.Beschreibung)?.toLowerCase(),
    Placeholder: 'Kennzeichen',
    options$: this.tkennzeichenForSelecting$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.tkennzeichenForSelecting$.pipe(
          map(tkennzeichen =>
            tkennzeichen.find(
              tk => inputText === (tk?.Beschreibung || tk?.Kennzeichen) || inputText === tk?.Kennzeichen
            )
          )
        )
      ),
    noEmptyOption: true,
  };
}
