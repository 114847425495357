import { Injector } from '@angular/core';
import { concat, from, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, publish, shareReplay } from 'rxjs/operators';
import { DatabaseService } from '../database.service';
import { TimeService } from '../time.service';

export interface ReadonlySetting<T> {
  readonly value$: Observable<T>;
  readonly get: () => Promise<T>;
}

export interface Setting<T> extends ReadonlySetting<T> {
  readonly set: (value: T) => Promise<void>;
}

export abstract class SettingService {
  abstract getTableName(): string;

  constructor(protected readonly injector: Injector) {}
  protected readonly DatabaseService: DatabaseService = this.injector.get<DatabaseService>(DatabaseService);
  protected readonly TimeService: TimeService = this.injector.get<TimeService>(TimeService);

  public createReadonlySetting<T>(key: string, initValue?: T, defaultValue?: T): ReadonlySetting<T> {
    return {
      value$: this.observeSetting$<T>(key, initValue, defaultValue),
      get: async () => await this.getData<T>(key, initValue, defaultValue),
    };
  }

  public createSetting<T>(key: string, initValue?: T, defaultValue?: T): Setting<T> {
    return {
      ...this.createReadonlySetting(key, initValue, defaultValue),
      set: this.setterFunction<T>(key),
    };
  }

  public setterFunction<T>(key: string): (object: T) => Promise<void> {
    return async (object: T) => {
      await this.setData<T>(key, object);
    };
  }

  //#region private
  private readonly change$ = this.DatabaseService.changes$.pipe(
    map(changes => changes.filter(change => change.storeName === this.getTableName())),
    filter(changes => changes.length > 0),
    map(changes =>
      changes.map(change => ({
        key: change.old?.key || change.new?.key,
        value: change.new?.value,
      }))
    ),
    concatMap(changes => from(changes))
  );

  private async setData<T>(key: string, object: T): Promise<void> {
    await this.DatabaseService.setData(this.getTableName(), [{ key, value: object }], true);
  }

  private async getData<T>(key: string, initValue?: any, defaultValue?: any): Promise<T> {
    const data = await this.DatabaseService.getData(this.getTableName(), key);
    let result: T;
    if (data?.[0]?.value !== undefined) {
      return data[0].value;
    }

    if (initValue !== undefined) {
      await this.DatabaseService.setData(this.getTableName(), [{ key, value: initValue }]);
      result = initValue;
      return result;
    }

    if (defaultValue !== undefined) {
      result = defaultValue;
      return result;
    }
    result = undefined;
    return result;
  }

  private observeSetting$<T>(key: string, initValue?: any, defaultValue?: any): Observable<T> {
    const result = from(this.getData<T>(key, initValue, defaultValue)).pipe(
      publish(load$ => concat(load$, this.changeByKey$<T>(key))),
      distinctUntilChanged(),
      shareReplay(1)
    );
    result.subscribe().unsubscribe();
    return result;
  }

  private changeByKey$<T>(keyString: string): Observable<T> {
    return this.change$.pipe(
      filter(change => change.key === keyString),
      map(change => change.value as T)
    );
  }
}
