import { IndexedDBTypes } from './dbType';

export class Auftrag extends IndexedDBTypes.IndexedDBType {
  public static StoreName = 'Auftrag';

  @IndexedDBTypes.KlassenName('Auftrag') private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private Nummer: string;
  @IndexedDBTypes.DataField('string') private Beschreibung: string;
  @IndexedDBTypes.DataField('string') private LangBeschreibung: string;
  @IndexedDBTypes.DataField('string') private KundenName: string;
  @IndexedDBTypes.DataField('string') private KundenNummer: string;
  @IndexedDBTypes.DataField('string') private Erledigt: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  getIndex(): string {
    return this.getNummer();
  }

  public getNummer() {
    return this.Nummer;
  }

  public getBeschreibung() {
    return this.Beschreibung;
  }

  public getLangBeschreibung() {
    return this.LangBeschreibung;
  }

  public getKundenName() {
    return this.KundenName;
  }

  public getKundenNummer() {
    return this.KundenNummer;
  }

  public getErledigt() {
    return this.Erledigt;
  }

  static toString(): string {
    return 'Auftrag';
  }
}
