<div class="grid-au-bescheinigung">
  <div class="statusSelector">
    <app-status-selector
      [label]="'AU-Bescheinigungsstatus'"
      [options]="AUBescheinigungStati"
      (selectionChange)="selectedStatusSubject.next($event)"
    ></app-status-selector>
  </div>

  <div id="bodyContainer" class="bodyContainer">
    <mat-accordion class="myAccordion" *ngIf="auBescheinigungen$ | async as auBescheinigungen">
      <mat-expansion-panel
        (afterExpand)="scrollInto(i)"
        id="expPanel"
        class="mat-expansion-panel2 nthPanel"
        *ngFor="let auBescheinigung of auBescheinigungen; let i = index"
      >
        <mat-expansion-panel-header id="header">
          <div class="auBescheinigung-list-element-header">
            <div class="auBescheinigungArt">
              <div>{{ auBescheinigung.getReadableStatus() }}</div>
            </div>
            <div class="auBescheinigungDatum">
              <a>{{ auBescheinigung.getVonDateString() }} - {{ auBescheinigung.getBisDateString() }}</a>
            </div>
          </div>
        </mat-expansion-panel-header>

        <mat-form-field class="fullWidth">
          <label>Von: </label>
          <input [(ngModel)]="auBescheinigung.vonDate" matInput [matDatepicker]="pickerVon" [disabled]="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerVon"></mat-datepicker-toggle>
          <mat-datepicker #pickerVon></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="fullWidth">
          <label>Bis: </label>
          <input [(ngModel)]="auBescheinigung.bisDate" matInput [matDatepicker]="pickerBis" [disabled]="true" />
          <mat-datepicker-toggle matSuffix [for]="pickerBis"></mat-datepicker-toggle>
          <mat-datepicker #pickerBis></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="auBescheinigung.Notiz" class="fullWidth">
          <textarea [disabled]="true" matInput>{{ auBescheinigung.Notiz }}</textarea>
        </mat-form-field>
      </mat-expansion-panel>

      <div *ngIf="auBescheinigungen.length === 0" class="keinEintragGefundenContainer">
        <img class="handcardImage" src="assets/icons/doctor2.png" />
        <br />
        <a class="auBescheinigungText1a">Es sind noch keine AU-Bescheinigungen vorhanden</a> <br />
      </div>
    </mat-accordion>
  </div>
</div>

<button mat-fab class="addAUBescheinigung" (click)="openBottomSheet()">
  <mat-icon>add</mat-icon>
</button>
