import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DialogService } from '@app/globalServices/dialog.service';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { SyncStep } from '@app/globalServices/sync.service';
import { getfirst } from '@app/utils/rxjsUtils';
import { AuftragDialogType, BookingRight, ModalAction, ModalActionType } from '@app/utils/types';
import { isNullOrUndefined } from '@app/utils/utils';
import { AutocompleteParameters } from '@base-components/form-components/autocomplete-input/autocomplete-input.component';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { Auftrag } from '@entities/Auftrag';
import { Kunden } from '@entities/Customer';
import { Fehlzeit } from '@entities/Fehlzeiten';
import { Taetigkeit } from '@entities/Taetigkeit';
import { AuftraegeService } from '@pages/TabGroup/shared/ZEFAuftraege.service';
import { KundenService } from '@pages/TabGroup/shared/ZEFKunden.service';
import { TaetigkeitService } from '@pages/TabGroup/shared/ZEFTaetigkeit.service';
import { FehlzeitenService } from '@stores/ZEFFehlzeiten.service';
import { MitarbeiterService } from '@stores/ZEFMitarbeiter.service';
import { ZeitmodelleService } from '@stores/zeitmodelle.service';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, first, map, startWith, switchMap } from 'rxjs/operators';
import { BuchungService } from '../../buchung/buchung.service';
import { BuchungsBottomsheetComponent, EmployeePreselectionParameter } from '../BuchungsBottomsheetComponent';

export interface AuftragStartPreselectionParameter extends EmployeePreselectionParameter {
  AufNr: string;
  TaetNr: string;
  CustomerNr: string;
  AuftragDialogType: AuftragDialogType;
}

@Component({
  selector: 'app-auftrag-start-erfassen',
  templateUrl: './auftrag-start-erfassen.component.html',
  styleUrls: ['./auftrag-start-erfassen.component.scss'],
})
export class AuftragStartErfassenComponent
  extends BuchungsBottomsheetComponent<AuftragStartPreselectionParameter>
  implements OnInit
{
  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  public static async ShowWith(bottomSheet: MatBottomSheet, data: AuftragStartPreselectionParameter) {
    return await super.Show<AuftragStartPreselectionParameter, void>(bottomSheet, AuftragStartErfassenComponent, {
      data,
    });
  }

  protected readonly customers = this.injector.get<KundenService>(KundenService);

  protected readonly auftraege = this.injector.get<AuftraegeService>(AuftraegeService);

  protected readonly taetigkeiten = this.injector.get<TaetigkeitService>(TaetigkeitService);

  protected readonly fehlzeiten = this.injector.get<FehlzeitenService>(FehlzeitenService);

  protected readonly zeitmodell = this.injector.get<ZeitmodelleService>(ZeitmodelleService);

  protected readonly buchungsService = this.injector.get<BuchungService>(BuchungService);

  protected readonly dialog = this.injector.get<DialogService>(DialogService);

  protected readonly mitarbeiter = this.injector.get<MitarbeiterService>(MitarbeiterService);

  protected readonly settings = this.injector.get<GlobalSettingService>(GlobalSettingService);

  readonly kundenAnzeige$ = this.settings.kundenAnzeige.value$;
  readonly auftragsAnzeige$ = this.settings.auftragsAnzeige.value$;
  readonly taetigkeitsAnzeige$ = this.settings.taetigkeitsAnzeige.value$;

  @ViewChild('kundeInput') kundeInput;
  @ViewChild('auftragInput') auftragInput;
  @ViewChild('taetigkeitInput') taetigkeitInput;

  TitleStyle(): TitleStyle {
    switch (this.Data.AuftragDialogType) {
      case AuftragDialogType.AuftragStarten:
        return {
          name: 'Auftrag starten',
          color: 'black',
          fontweight: 'bold',
        };
      case AuftragDialogType.AuftragWechseln:
        return {
          name: 'Auftrag wechseln',
          color: 'black',
          fontweight: 'bold',
        };
      case AuftragDialogType.TaetigkeitWechseln:
        return {
          name: 'Taetigkeit wechseln',
          color: 'black',
          fontweight: 'bold',
        };
    }
  }

  SaveAuftragButton(): ButtonStyle {
    switch (this.Data.AuftragDialogType) {
      case AuftragDialogType.AuftragStarten:
        return {
          color: 'white',
          backgroundColor: 'rgb(18, 126, 198)',
          icon: 'assets/icons/briefcase_document.png',
          description: 'Auftrag starten',
        };
      case AuftragDialogType.AuftragWechseln:
        return {
          color: 'white',
          backgroundColor: 'rgb(18, 126, 198)',
          icon: 'assets/icons/briefcase_document.png',
          description: 'Auftrag wechseln',
        };
      case AuftragDialogType.TaetigkeitWechseln:
        return {
          color: 'white',
          backgroundColor: 'rgb(18, 126, 198)',
          icon: 'assets/icons/briefcase_document.png',
          description: 'Tätigkeit wechseln',
        };
    }
  }

  readonly submitButtonAction$: Observable<ModalAction> = this.disableSubmit$.pipe(
    map(res => {
      return {
        Type: ModalActionType.OK,
        Disabled: res,
        Execute: async () => this.bottomSheet.dismiss(),
      };
    })
  );

  //#region Sync

  protected async getSyncSteps(): Promise<SyncStep[]> {
    const result = [...(await super.getSyncSteps()), ...(await this.getAuftragSyncSteps())];
    return result;
  }

  protected async getAuftragSyncSteps(): Promise<SyncStep[]> {
    const auftragSync = await this.SyncService.syncAuftragVorBuchung.get();
    if (!auftragSync) return [];

    const result = [...(await this.SyncService.getAuftragsSyncSteps())];

    return result;
  }

  //#endregion

  //#region Form

  protected createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    const result = formBuilder.group({
      mitarbeiter: ['', [Validators.required, Validators.minLength(1)]],
      kunde: ['', Validators.nullValidator],
      auftrag: ['', Validators.required],
      taetigkeit: [{ value: '', disabled: true }, Validators.nullValidator],
      kommentar: ['', Validators.maxLength(750)],
    });

    return result;
  }

  protected readBuchungFromForm() {
    return {
      ...super.readBuchungFromForm(),
      Auftrag: this.accesBookingValue(this.formGroup.value.auftrag, 'Auftrag'),
      Kunde: this.accesBookingValue(this.formGroup.value.kunde, 'Kunden'),
      Taetigkeit: this.accesBookingValue(this.formGroup.value.taetigkeit, 'Taetigkeit'),
      TaetigkeitBez: this.accesBookingValue(this.formGroup.value.taetigkeit, 'TaetigkeitBez'),
    };
  }

  readonly customerParameters: AutocompleteParameters<Kunden> = {
    displayFunc: (customer: Kunden) => (customer ? customer?.getBeschreibung() || customer?.getNummer() : ''),
    toSearchText: (customer: Kunden) => (customer?.getBeschreibung() + customer?.getNummer())?.toLowerCase(),
    Placeholder: 'Kunde',
    options$: this.customers.storeData$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.customers.storeData$.pipe(
          map(customers =>
            customers.find(
              customer =>
                inputText === (customer?.getBeschreibung() || customer?.getNummer()) ||
                inputText === customer?.getNummer()
            )
          )
        )
      ),
  };

  readonly auftragFilter$ = this.formGroup.controls.kunde.valueChanges
    .pipe(startWith(this.formGroup.value.kunde))
    .pipe(
      map(
        kunde => (auftrag: Auftrag) =>
          !kunde || auftrag.getKundenNummer() === (kunde as Kunden).getNummer() || auftrag.getKundenNummer() === ''
      )
    );

  readonly auftragOptions$ = combineLatest([this.auftragFilter$, this.auftraege.storeData$]).pipe(
    map(([auftragFilter, auftragListe]) => (auftragFilter ? auftragListe.filter(auftragFilter) : auftragListe))
  );

  readonly auftragParameters: AutocompleteParameters<Auftrag> = {
    displayFunc: (auftrag: Auftrag) => (auftrag ? auftrag?.getBeschreibung() || auftrag?.getNummer() : ''),
    toSearchText: (auftrag: Auftrag) => (auftrag?.getNummer() + auftrag?.getBeschreibung())?.toLowerCase(),
    Placeholder: 'Auftrag',
    options$: this.auftragOptions$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.auftragOptions$.pipe(
          map(auftraege =>
            auftraege.find(
              auftrag =>
                inputText === (auftrag?.getBeschreibung() || auftrag?.getNummer()) || inputText === auftrag?.getNummer()
            )
          )
        )
      ),
  };

  readonly auftragValueChanged$ = this.formGroup.controls.auftrag.valueChanges.pipe<Taetigkeit>(distinctUntilChanged());

  readonly taetigkeitFromAutrag$ = this.auftragValueChanged$.pipe(
    switchMap(value =>
      value ? this.TaetigkeitService.getTaetigkeitFromAuftrag(value.getNummer()) : of(<Taetigkeit[]>[])
    )
  );

  reactOnAuftragChanges() {
    const sub = this.auftragValueChanged$.subscribe({
      next: value => {
        value ? this.formGroup.controls.taetigkeit.enable() : this.formGroup.controls.taetigkeit.disable();
      },
    });
    this.registerSubscription(sub);
  }

  readonly taetigkeitOptions$ = combineLatest([this.taetigkeitFromAutrag$, this.taetigkeiten.storeData$]).pipe(
    map(([taetigkeitFromAuftrag, taetigkeitListe]) => {
      const set = new Set();
      const filteredArr = [...taetigkeitFromAuftrag, ...taetigkeitListe].filter(obj => {
        const isPresentInSet = set.has(obj.getNummer());
        set.add(obj.getNummer());
        return !isPresentInSet;
      });
      return filteredArr;
    })
  );

  readonly taetigkeitParameters: AutocompleteParameters<Taetigkeit> = {
    displayFunc: (taetigkeit: Taetigkeit) =>
      taetigkeit ? taetigkeit?.getBeschreibung() || taetigkeit?.getNummer() : '',
    toSearchText: (taetigkeit: Taetigkeit) => (taetigkeit?.getNummer() + taetigkeit?.getBeschreibung())?.toLowerCase(),
    Placeholder: 'Taetigkeit',
    options$: this.taetigkeitOptions$,
    inputAsSelection: (inputText: string) =>
      getfirst(
        this.taetigkeitOptions$.pipe(
          map(taetigkeiten =>
            taetigkeiten.find(
              taetigkeit =>
                inputText === (taetigkeit?.getBeschreibung() || taetigkeit?.getNummer()) ||
                inputText === taetigkeit?.getNummer()
            )
          )
        )
      ),
  };
  //#endregion

  async ngOnInit() {
    await super.ngOnInit();
    this.reactOnKundenChanges();
    this.reactOnAuftragChanges();
    await this.setFormGroupValidators();
    await this.initializeDialog(this.Data);
  }

  async initializeDialog(data: AuftragStartPreselectionParameter) {
    const auftrag = await this.auftraege.firstInFilteredStoreData$(data.AufNr, 'Nummer').pipe(first()).toPromise();
    const taetigkeit =
      data.TaetNr !== ''
        ? await this.taetigkeiten.firstInFilteredStoreData$(data.TaetNr, 'Nummer').pipe(first()).toPromise()
        : '';

    switch (data.AuftragDialogType) {
      case AuftragDialogType.AuftragStarten:
        this.formGroup.controls.auftrag.setValue(auftrag);
        break;
      case AuftragDialogType.AuftragWechseln:
        this.formGroup.controls.mitarbeiter.setValue(data.Pnrs);
        this.formGroup.controls.auftrag.setValue(auftrag);
        this.formGroup.controls.taetigkeit.setValue(taetigkeit);
        break;
      case AuftragDialogType.TaetigkeitWechseln:
        this.formGroup.controls.mitarbeiter.setValue(data.Pnrs);
        this.formGroup.controls.auftrag.setValue(auftrag);
        this.formGroup.controls.taetigkeit.setValue(taetigkeit);
        this.kundeInput.setDisabledState(true);
        this.auftragInput.setDisabledState(true);
        break;
    }
  }

  /**@description Reagiert auf den Valuechange der Auftragsform und setzt gegebenenfalls den Kunden zurück */
  reactOnKundenChanges() {
    const sub = this.formGroup.controls.kunde.valueChanges.subscribe({
      next: value => {
        const nextKunde = value as Kunden;
        if (isNullOrUndefined(nextKunde)) {
          return;
        }
        const kundenNummer = nextKunde.getNummer();
        let currentAuftrag = this.formGroup.value.auftrag;
        if (isNullOrUndefined(currentAuftrag) || currentAuftrag === '') {
          return;
        }
        currentAuftrag = currentAuftrag as Auftrag;
        const kundenNummerAuftrag = currentAuftrag.getKundenNummer();
        if (kundenNummerAuftrag === '') {
          return;
        }
        const kundenNummerMatches = kundenNummer === kundenNummerAuftrag;
        if (!kundenNummerMatches) {
          this.resetAuftraege();
        }
      },
      error: errorValue => {
        console.log(errorValue);
      },
    });
    this.registerSubscription(sub);
  }

  resetAuftraege() {
    this.formGroup.controls.auftrag.setValue(null);
  }

  /**@description Reads from each formfield the part of the information critical for a booking */
  accesBookingValue(source: any, typeOfSource: 'Auftrag' | 'Kunden' | 'Taetigkeit' | 'Fehlzeit' | 'TaetigkeitBez') {
    if (source === '' || isNullOrUndefined(source)) {
      return '';
    }
    switch (typeOfSource) {
      case 'Auftrag':
        return (source as Auftrag).getNummer();
      case 'Kunden':
        return (source as Kunden).getNummer();
      case 'Taetigkeit':
        return (source as Taetigkeit).getNummer();
      case 'TaetigkeitBez':
        return (source as Taetigkeit).getBeschreibung();
      case 'Fehlzeit':
        return (source as Fehlzeit).getKennzeichen();
    }
  }

  async setFormGroupValidators() {
    const bookingRight: BookingRight = await this.mitarbeiter.currentUserRights$
      .pipe(
        first(),
        map(rights => rights.bookingRight)
      )
      .toPromise();
    const validators = bookingRight == 3 ? [Validators.nullValidator, Validators.required] : [Validators.nullValidator];
    this.formGroup.controls['taetigkeit'].setValidators(validators);
  }
}
