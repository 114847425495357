export class TKennzeichen {
  public Kennzeichen: string;
  public Beschreibung: string;
  public Wert: string;
  public Changeable: boolean;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  getIndex(): string {
    return this.getKennzeichen();
  }

  public getKennzeichen() {
    return this.Kennzeichen;
  }

  public getBeschreibung() {
    return this.Beschreibung;
  }

  public getWert() {
    return this.Wert;
  }
}

export class TKennzeichenBooking {
  public Nummer: string;
  public Datum: string;
  public Pnr: string;
  public Kennzeichen: string;
  public Beschreibung: string;
  public Wert: string;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  getIndex(): string {
    return this.Nummer;
  }

  public getPnr() {
    return this.Pnr;
  }

  public getDatum() {
    return this.Datum;
  }

  public getKennzeichen() {
    return this.Kennzeichen;
  }

  public getWert() {
    return this.Wert;
  }
}
