import { ValidationError } from './ErrorHandling/Error';

export enum ModalActionType {
  OK,
  CANCEL,
  YES,
  NO,
  RECALL,
  DELETE,
  CHANGE,
}

export interface ModalAction {
  Type: ModalActionType;
  Disabled?: boolean;
  Execute: (event) => void;
}

export interface ModalQueryResult<TResult> {
  Type: ModalActionType;
  Data?: TResult;
  Recall?: () => Promise<ModalQueryResult<TResult>>;
}

//#region Validation

export type ValidationResult = true | ValidationError[];

export type Validator<T> = (object: T) => Promise<ValidationResult>;

//#endregion

export type OneOrMany<T> = T | T[];

export interface BaseEnumFunctions<T> {
  contains: (value: string) => boolean;
  values: T[];
  tryCast: (value: string) => { success: boolean; result: T };
  type: T;
}

export type TypeExtenderFunction<T, TExtended extends BaseEnumFunctions<T>> = (
  valueSet: Set<T>,
  baseType: BaseEnumFunctions<T>
) => TExtended;

export const StringEnumClass =
  <StringLiteral extends string>(...values: StringLiteral[]) =>
  <TTypeExtension extends BaseEnumFunctions<StringLiteral>>(
    extender: TypeExtenderFunction<StringLiteral, TTypeExtension>
  ) => {
    Object.freeze(values);
    const valueSet: Set<string> = new Set(values);

    const contains = (value: string): value is StringLiteral => {
      return valueSet.has(value);
    };

    const tryCast = (value: string): { success: boolean; result: StringLiteral } => {
      if (valueSet.has(value)) return { success: true, result: value as StringLiteral };
      return { success: false, result: undefined };
    };

    const baseType: BaseEnumFunctions<StringLiteral> = { contains, values, tryCast, type: undefined };

    const unionNamespace = { ...extender(valueSet as Set<StringLiteral>, baseType) };
    return Object.freeze(unionNamespace as typeof unionNamespace & { type: StringLiteral });
  };

export const SmartEnum = <StringLiteral extends string>(...values: StringLiteral[]) =>
  StringEnumClass(...values)((_, type) => type);

export enum AuftragDialogType {
  AuftragStarten,
  AuftragWechseln,
  TaetigkeitWechseln,
}

export enum BookingRight {
  BuchenOhneEinschraenkung,
  BuchenOhneAuftragserfassung,
  BuchenMitAuftragserfassung,
  BuchenMitAuftragserfassungUndTaetigkeiten,
}

export enum BookingType {
  GEHEN = 'GEHEN',
  ARBEITSZEIT = 'ARBEITSZEIT',
  AUFTRAGSZEIT = 'AUFTRAGSZEIT',
  FEHLZEIT = 'FEHLZEIT',
}
