import { Injectable } from '@angular/core';
import { Database } from '@app/database/database';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

export interface EntityChangeEvent {
  storeName: string;
  old: { key: any; value: any };
  new: { key: any; value: any };
}

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor() {}

  private readonly database = new Database();

  private readonly changeSubject = new Subject<EntityChangeEvent[]>();

  /**@description Meldet inserts/deletes ([table/storename, id, value])*/
  readonly changes$ = this.changeSubject.pipe(
    // taplog('DB-Changes'),
    share()
  );

  public async initDatabase() {
    await this.database.setDbVersion();
  }

  /**
   * Data aus bestimmter Tabelle holen
   * Option1: nur storeName => gibt kompletten Tabellen Inhalt zurück
   * Option2: storeName, keyValue => gibt Inhalt mit bestimmter KeyValue zurück
   * Option3: storeName, keyValue, key => gibt Inhalt mit bestimmter KeyValue zurück, wobei mit key
   * 			angegeben kann welche Spalte nun mein Index ist
   **/
  public async getData<TType>(storeName: string, keyValue?: any, key?: string): Promise<any[]> {
    if (key && keyValue) {
      return (await this.database.getAllSpecificData<TType>(storeName, key, keyValue)) as any;
    } else {
      if (keyValue) {
        return await this.database.getAllSpecificDataWithKey(storeName, keyValue);
      }
      return (await this.database.getAllData<TType>(storeName)) as any;
    }
  }

  /**@description Löscht Daten in storeName bei denen der key Eintrag dem keyvalue entspricht */
  public async deleteData(storeName: string, keyValue, key: string): Promise<void> {
    if (keyValue !== null && keyValue !== undefined) {
      await this.database.deleteSpecificData(storeName, key, keyValue);
    } else {
      await this.database.deleteData(storeName, key);
    }
  }

  /**
   * Data in bestimmte Tabelle schreiben
   */
  public async setData(storeName, data: any[], emitRefresh?: boolean): Promise<void> {
    await this.database.insert(storeName, data);
    if (emitRefresh) {
      const changes = data.map<EntityChangeEvent>(document => ({
        storeName,
        old: null,
        new: document,
      }));
      this.changeSubject.next(changes);
    }
  }

  /**
   * Kompletten Inhalt aus bestimmter Tabelle löschen
   */
  public async clearStore(storeName: string): Promise<void> {
    await this.database.clearStore(storeName);
  }

  public async clearAllStores() {
    await this.database.clearAllStores();
  }

  public async setDbVersion() {
    await this.database.setDbVersion();
  }

  public async rebuildDB() {
    const dbVersion = this.database.dbVersion;
    this.database.deleteOldIDB();
    this.database.initStores(dbVersion);
  }
}
