import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { CodedError } from './Error';

export enum DomainErrorCode {
  InvalidFormat = 10,
  ParameterWrong = 11,

  AlreadyExists = 12,
  NotAllowed = 13,
  NotFound = 14,
  LicLimitReached = 17,
  MonthAlreadyClosed = 18,
  PWANotEnabled = 22,
  PWANoAccess = 25,

  LicenseLocked = 703,

  AuthIdUnknown = 500,
  AuthHashInvalid = 501,

  TenantBlocked = 600,
  TenantUnknown = 604,
}

export enum DomainErrorDetailCode {
  Default = 0,
}

export const IsKnownErrorCode = (code: number, detail?: boolean): boolean => {
  if (detail) return Object.values(DomainErrorDetailCode).includes(code);
  return Object.values(DomainErrorCode).includes(code);
};

export class DomainError extends CodedError {
  public readonly DetailCode: number;
  public readonly Message: string;

  constructor(errorCode: number = -1, detailCode: number = -1, message?: string, name?: string) {
    super(message, errorCode);
    this.DetailCode = detailCode;
    this.Message = message;
    if (name !== undefined) this.name = name;
  }
}
