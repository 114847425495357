import { Component, SkipSelf } from '@angular/core';
import { TermConverterService } from '@app/globalServices/termConverter.service';
import { AuftraegeService } from '@app/pages/TabGroup/shared/ZEFAuftraege.service';
import { TaetigkeitService } from '@app/pages/TabGroup/shared/ZEFTaetigkeit.service';
import { BookingType } from '@app/utils/types';
import { FehlzeitenService } from '@pages/TabGroup/shared/ZEFFehlzeiten.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, interval, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { BuchungService, OpenBookingFrame } from '../buchung.service';

@Component({
  selector: 'app-letzte-erfassung',
  templateUrl: './letzte-erfassung.component.html',
  styleUrls: ['./letzte-erfassung.component.scss'],
})
export class LetzteErfassungComponent {
  constructor(
    private readonly converter: TermConverterService,
    @SkipSelf() private readonly buchungsService: BuchungService,
    private readonly AuftraegeService: AuftraegeService,
    private readonly taetigkeitsService: TaetigkeitService,
    private readonly fehlzeitenService: FehlzeitenService
  ) {}

  private readonly timeRefresh$ = interval(1000);

  private readonly lastBooking$ = this.buchungsService.mostRecentOpenBooking$.pipe(
    // taplog('buchungsService.mostRecentOpenBooking$'),
    shareReplay(1)
  );

  private readonly lastBookedTaetigkeitIdSubject = new BehaviorSubject<string>(null);

  private readonly lastTaetigkeitBeschreibungSubject = new BehaviorSubject<string>(null);
  private readonly lastTaetigkeitBeschreibung$ = this.lastTaetigkeitBeschreibungSubject.asObservable();

  private readonly bookingAndType$ = this.lastBooking$.pipe(
    map(booking => ({
      booking,
      type: this.converter.bookingToBookingType(booking),
    }))
  );
  private readonly bookingType$ = this.bookingAndType$.pipe(map(({ booking, type }) => type));

  readonly hasBooking$ = this.bookingType$.pipe(
    map(type => type !== 'GEHEN'),
    distinctUntilChanged()
  );

  readonly color$ = this.bookingType$.pipe(map(type => this.bookingTypeToColor(type)));

  readonly bookingDescription$ = this.bookingAndType$.pipe(
    switchMap(({ booking, type }) =>
      combineLatest([
        this.bookingTypeToTitle(booking, type).pipe(map(title => (title && title.length > 40 ? title.substr(0, 40) + '... ' : title || ''))),
        this.bookingTypeToSubTitle(booking, type).pipe(map(title => (title && title.length > 40 ? title.substr(0, 40) + '... ' : title || ''))),
      ])
    ),
    map(([title, subtitle]) => {
      return { title: title, subtitle: subtitle };
    }),
    distinctUntilChanged()
  );

  /**@description timestamp of last booking */
  readonly bookingTimestamp$ = this.lastBooking$.pipe(map(booking => this.bookingToTimestamp(booking)));
  /**@description a counter counting up the duration since last booking */
  readonly counter$ = this.lastBooking$.pipe(switchMap(booking => this.bookingToCounter(booking)));

  readonly currentTime$ = this.timeRefresh$.pipe(
    startWith(-1),
    map(_ => moment(new Date()).format('HH:mm'))
  );

  private bookingToCounter(booking: OpenBookingFrame): Observable<string> {
    if (!booking) return of('');

    return this.timeRefresh$.pipe(
      startWith(-1),
      map(trigger => new Date()),
      map(now => this.bookingToTimespan(booking, now))
    );
  }

  /**@description maps booking and timestamp to timespan*/
  private bookingToTimespan(booking: OpenBookingFrame, now: Date): string {
    const nowTime = now.getTime();
    const bookingTime = booking.Date.getTime();

    const diffInSec = Math.floor((nowTime - bookingTime) / 1000);
    const hh = Math.floor(diffInSec / 3600);
    const mm = Math.floor((diffInSec - hh * 3600) / 60);

    const ss = diffInSec - hh * 3600 - mm * 60;
    return this.pad(hh) + ':' + this.pad(mm) + ':' + this.pad(ss);
  }

  /**@description Mapt Buchung auf Zeitpunkt als string */
  private bookingToTimestamp(booking: OpenBookingFrame): string {
    if (!booking) return '';
    return this.pad(booking.Date.getHours()) + ':' + this.pad(booking.Date.getMinutes());
  }

  private bookingTypeToColor(typ: BookingType): string {
    switch (typ) {
      case BookingType.GEHEN: {
        return '#004080'; // '#bb1d48' Gehen-Rot;
      }
      case BookingType.FEHLZEIT: {
        return '#da542d';
      }
      case BookingType.AUFTRAGSZEIT: {
        return '#127ec6';
      }
      case BookingType.ARBEITSZEIT: {
        return '#009118';
      }
      default: {
        return '#009118';
      }
    }
  }

  private bookingTypeToTitle(booking: OpenBookingFrame, type: BookingType): Observable<string> {
    if (type === BookingType.GEHEN) {
      return of(null);
    }
    if (type === BookingType.FEHLZEIT) {
      if (booking.Kennzeichen)
        return this.fehlzeitenService.firstInStoreData$(fehlzeit => fehlzeit.Kennzeichen === booking.Kennzeichen).pipe(map(fehlzeit => fehlzeit.Beschreibung));
      return of('Abwesend');
    }
    if (type === BookingType.ARBEITSZEIT) {
      return of('Arbeitszeit');
    }
    if (type === BookingType.AUFTRAGSZEIT) {
      const auftragId = booking.Auftrag;
      return this.AuftraegeService.getAuftragDescription(auftragId);
    }
    throw new Error('unknown Bookingtype');
  }

  private bookingTypeToSubTitle(booking: OpenBookingFrame, type: BookingType): Observable<string> {
    if (type === BookingType.AUFTRAGSZEIT) {
      const lastBookedTaetigkeitId = this.lastBookedTaetigkeitIdSubject.getValue();

      return lastBookedTaetigkeitId != booking.Taetigkeit
        ? this.getTaetigkeitBeschreibung(booking.Taetigkeit, booking.Auftrag).pipe(tap(description => this.lastTaetigkeitBeschreibungSubject.next(description)))
        : this.lastTaetigkeitBeschreibung$;
    }
    return of('');
  }

  getTaetigkeitBeschreibung(taetigkeitId: string, auftragId?: string) {
    this.lastBookedTaetigkeitIdSubject.next(taetigkeitId);

    return this.taetigkeitsService.getTaetigkeitDescription(taetigkeitId).pipe(
      switchMap(description => {
        return description ? of(description) : this.taetigkeitsService.getTaetigkeitFromAuftragById(auftragId, taetigkeitId);
      })
    );
  }

  /**@description Pads '0' */
  private pad(num): string {
    let result = '' + num;
    while (result.length < 2) {
      result = '0' + result;
    }
    return result;
  }
}
