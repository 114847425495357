<mat-form-field class="example-full-width">
  <mat-label *ngIf="text?.length < 750">Kommentar</mat-label>
  <mat-label class="colorRed" *ngIf="text?.length > 749">Kommentar (maximale Kommentarlänge erreicht)</mat-label>
  <textarea
    [formControl]="commentControl"
    matInput
    (input)="inputChange($event.target.value)"
    maxlength="750"
  ></textarea>
</mat-form-field>
