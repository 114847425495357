import { IndexedDBTypes } from './dbType';

const TAETIGKEIT_STORENAME = 'Taetigkeit';

export class Taetigkeit extends IndexedDBTypes.IndexedDBType {
  public static StoreName = TAETIGKEIT_STORENAME;

  @IndexedDBTypes.KlassenName(TAETIGKEIT_STORENAME) private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private Nummer: string;
  @IndexedDBTypes.IndexField('string') private Auftrag: string;
  @IndexedDBTypes.DataField('string') private Paket: string;
  @IndexedDBTypes.DataField('string') private Beschreibung: string;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  getIndex(): string {
    return this.getNummer();
  }

  public getNummer() {
    return this.Nummer;
  }

  public getAuftrag() {
    return this.Auftrag;
  }

  public getPaket() {
    return this.Paket;
  }

  public getBeschreibung() {
    return this.Beschreibung;
  }
  static toString(): string {
    return TAETIGKEIT_STORENAME;
  }
}
