<app-team-selector-tabs></app-team-selector-tabs>

<div id="bodyContainer" class="bodyContainer">
  <ng-container *ngIf="dayOverview$ | async as model" [ngSwitch]="model.status">
    <div *ngSwitchDefault class="spinner-container">
      <app-spinner></app-spinner>
    </div>

    <app-background-notice
      *ngSwitchCase="'error'"
      [image]="'assets/icons/info_white.png'"
      [message]="model.error['name']"
      [submessage]="model.error['message']"
    ></app-background-notice>

    <div *ngSwitchCase="'success'" id="timeframe-container" class="timeframe-container">
      <app-background-notice
        *ngIf="model.data.length < 1"
        [image]="'assets/icons/history2_grey.png'"
        [message]="'Keine Buchungen'"
        [submessage]="'für diesen Tag vorhanden'"
      ></app-background-notice>

      <div *ngIf="model.data && model.data.length > 0">
        <mat-accordion *ngFor="let row of model.data; let i = index">
          <mat-expansion-panel
            (afterExpand)="scrollInto(i)"
            class="mat-expansion-panel"
            [disabled]="!isFoldable(row)"
            [expanded]="false"
          >
            <mat-expansion-panel-header class="mat-expansion-panel-header">
              <div class="mat-content-rewrite">
                <div class="mat-panel-text-frame">
                  <div
                    [ngClass]="{ 'mat-panel-text': true, fullLengthText: auftragsAnzeige$ | async }"
                    style="font-weight: bold"
                  >
                    {{ row.Title }}
                  </div>
                  <div
                    *ngIf="hasTaetigkeit(row)"
                    [ngClass]="{ 'mat-panel-text': true, fullLengthText: taetigkeitsAnzeige$ | async }"
                  >
                    {{ row.getTaetigkeitBeschreibung() }}
                  </div>
                </div>
                <div class="mat-panel-zeit">
                  {{ getTimeString(row) }}
                </div>
              </div>
            </mat-expansion-panel-header>

            <textarea disabled="true" rows="4" style="resize: none" class="kommentar">{{
              row.getKommentar()
            }}</textarea>
            <button mat-button class="kartenButton" *ngIf="hasLocation(row)" (click)="showMap(row)">
              Geolokalisierung anzeigen
            </button>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-container>
</div>

<div class="saldo-container">
  <div class="summe-icon-container">
    <mat-icon class="summe-icon">functions</mat-icon>
  </div>
  <div class="saldo-description">
    <div>Ist-Zeit</div>
    <div>Soll-Zeit</div>
    <div>Saldo</div>
  </div>
  <div class="saldo-time-container" *ngIf="getSaldoData$ | async as saldoData">
    <ng-container *ngIf="!(validSaldoData$ | async)" class="spinner-container">
      <app-spinner></app-spinner>
    </ng-container>
    <div style="text-align: right" *ngIf="validSaldoData$ | async">
      <div>{{ saldoData.istZeit }}</div>
      <div>{{ saldoData.sollZeit }}</div>
      <div [ngClass]="{ 'negative-saldo': saldoData.negative }">{{ saldoData.mehrarbeit }}</div>
    </div>
  </div>
</div>

<app-date-selector-band></app-date-selector-band>

<app-karten *ngIf="showKarteBoolean" (closeEvent)="closeMap()" [latitude]="latitude" [longitude]="longitude">
</app-karten>
