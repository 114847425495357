import { IndexedDBTypes } from './dbType';

const SALDEN_STORENAME = 'Salden';

export class Salden extends IndexedDBTypes.DbType {
  static StoreName = SALDEN_STORENAME;

  @IndexedDBTypes.KlassenName(SALDEN_STORENAME) private KlassenName = SALDEN_STORENAME;
  @IndexedDBTypes.KeyDBField('string') public PNR: string;
  @IndexedDBTypes.DataField('number') private UrlaubJahr: number;
  @IndexedDBTypes.DataField('number') private UrlaubVorjahr: number;
  @IndexedDBTypes.DataField('number') private UrlaubGenommen: number;
  @IndexedDBTypes.DataField('number') private UrlaubGeplant: number;
  @IndexedDBTypes.DataField('number') private UrlaubVerbleibend: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoGestern: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoHeute: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoStand: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoDetailVormonat: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoDetailAktuell: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoDetailSollAktuell: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoDetailNochZuLeisten: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoDetailStand: number;
  @IndexedDBTypes.DataField('number') private ZeitkontoDetailGeplant: number;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  public getJahresUrlaub() {
    return this.UrlaubJahr;
  }

  public getUrlaubVorjahr() {
    return this.UrlaubVorjahr;
  }

  public getUrlaubGenommen() {
    return this.UrlaubGenommen;
  }

  public getUrlaubGeplant() {
    return this.UrlaubGeplant;
  }

  public getUrlaubVerbleibend() {
    return this.UrlaubVerbleibend;
  }

  public getZeitkontoGestern() {
    return this.ZeitkontoGestern;
  }

  public getZeitkontoHeute() {
    return this.ZeitkontoHeute;
  }

  public getZeitkontoStand() {
    return this.ZeitkontoStand;
  }

  public getZeitkontoDetailVormonat() {
    return this.ZeitkontoDetailVormonat;
  }

  public getZeitkontoDetailSollAktuell() {
    return this.ZeitkontoDetailSollAktuell;
  }

  public getZeitkontoDetailNochZuLeisten() {
    return this.ZeitkontoDetailNochZuLeisten;
  }

  public getZeitkontoDetailAktuell() {
    return this.ZeitkontoDetailAktuell;
  }

  public getZeitkontoDetailStand() {
    return this.ZeitkontoDetailStand;
  }

  public getZeitkontoDetailGeplant() {
    return this.ZeitkontoDetailGeplant;
  }

  static toString(): string {
    return SALDEN_STORENAME;
  }
}

export class Saldo {
  Pnr: string;
  Tag: string;
  IstZeit: number;
  SollZeit: number;
  Pause: number;
  Mehrarbeit: number;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
