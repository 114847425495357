import { ControllerService } from '../../../globalServices/controller.service';
import { Injectable, Injector } from '@angular/core';
import { TermConverterService } from '../../../globalServices/termConverter.service';
import { Salden, Saldo } from '@entities/Salden';
import { TimeService } from 'src/app/globalServices/time.service';
import { Observable } from 'rxjs';
import { DatabaseService } from '@app/globalServices/database.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { MitarbeiterService } from './ZEFMitarbeiter.service';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { map, switchMap, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SaldenService extends StoreControllerServiceBase<Salden> {
  protected GetStoreName(): string {
    return Salden.StoreName;
  }

  protected entityConstructor(row): Salden {
    return new Salden(row);
  }

  constructor(
    private readonly controllerservice: ControllerService,
    private readonly dbService: DatabaseService,
    private readonly timeService: TimeService,
    private readonly session: UserSessionService,
    private readonly termConverterService: TermConverterService,
    private readonly backend: ZEFBackendService,
    private readonly employees: MitarbeiterService,
    protected readonly injector: Injector
  ) {
    super(injector);
  }

  async Sync(emit?: boolean): Promise<string[]> {
    const date = this.timeService.getNowDateString(true);

    const salden = await this.session.pnr$
      .pipe(
        first(),
        switchMap(pnr => this.backend.post<Salden[]>(`GetTeamSalden/DATUM/${date}`, [pnr]))
      )
      .toPromise();
    if (!!salden && salden.length > 0) {
      await this.controllerservice.resetStore(Salden.StoreName, salden, emit).toPromise();
      return [Salden.StoreName];
    }
    return [];
  }

  saldoByPnr$(pnr: string): Observable<Salden> {
    return this.firstInStoreData$(saldo => saldo.PNR === pnr);
  }

  /**@description Holt das heutige Datum und wandelt es in das korrekte Format für den Webservice um */
  public getTodayAsSaldenDBCompliantDate() {
    const heuteDate = new Date();
    const heute = this.timeService.getDateFromRawDate(heuteDate, false);
    const heuteDb = this.termConverterService.getSaldenCompliantDay(heute);
    return heuteDb;
  }

  public getSaldoForPnr$(pnr: string, date: Date): Observable<Saldo> {
    const rawDate = this.timeService.getDateFromRawDate(date, true);
    return this.backend.post<Saldo>(`GetSaldenForPnr/PNR/${pnr}/DATUM/${rawDate}`);
  }
}
