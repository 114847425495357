<div class="mapContainer">
  <div id="map" class="map"></div>

  <div>
    <a class="overlay" id="stempelungsPosition" target="_blank">Stempelung</a>
    <div id="marker" title="Marker"></div>
  </div>

  <button class="close" (click)="close()">X</button>
</div>
