import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ModalAction, ModalActionType } from '@app/utils/types';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AUBescheinigung } from '@entities/AUBescheinigung';
import { AUBescheinigungService } from '@stores/ZEFAUBescheinigung.service';
import { BaseBottomsheetComponent } from '@base-components/BaseBottomsheetComponent';
import { AuBescheinigungDatenErfassenParameter } from '../au-bescheinigung-daten-erfassen/au-bescheinigung-daten-erfassen.component';
import { resizeImage, convertBlobtoBase64 } from '@app/utils/fileUtils';
import { DialogService } from '@app/globalServices/dialog.service';

@Component({
  selector: 'app-au-bescheinigung-bild-erfassen',
  templateUrl: './au-bescheinigung-bild-erfassen.component.html',
  styleUrls: ['./au-bescheinigung-bild-erfassen.component.scss'],
})
export class AuBescheinigungBildErfassenComponent
  extends BaseBottomsheetComponent<AuBescheinigungDatenErfassenParameter, void>
  implements OnInit
{
  readonly AUBescheinigungSubject: BehaviorSubject<AUBescheinigung> = new BehaviorSubject(null);
  readonly ImageSubject: BehaviorSubject<File> = new BehaviorSubject(null);

  @ViewChild('FileUploader') fileUploader;

  constructor(
    protected readonly injector: Injector,
    protected readonly auBescheinigungService: AUBescheinigungService,
    protected readonly dialogService: DialogService
  ) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();
  }

  public static async ShowWith(bottomSheet: MatBottomSheet, data: AuBescheinigungDatenErfassenParameter) {
    return await super.Show<AuBescheinigungDatenErfassenParameter, void>(
      bottomSheet,
      AuBescheinigungBildErfassenComponent,
      { data }
    );
  }

  readonly TitleStyle: TitleStyle = {
    name: 'AU-Bescheinigung',
    color: 'black',
    fontweight: 'bold',
  };

  readonly DeleteFileButton: ButtonStyle = {
    color: 'white',
    backgroundColor: 'rgb(187, 29, 72)',
    icon: 'assets/icons/garbage_can.png',
    description: 'Bild entfernen',
  };

  readonly DeleteFileButtonAction$: Observable<ModalAction> = this.ImageSubject.pipe(
    map(image => {
      return {
        Type: ModalActionType.OK,
        Disabled: !image,
        Execute: () => this.clearUpload(),
      };
    })
  );

  readonly SaveButton: ButtonStyle = {
    color: 'white',
    backgroundColor: '#009118',
    icon: 'assets/icons/paper_jet2.png',
    description: 'Bestätigen',
  };

  readonly SaveButtonAction: ModalAction = {
    Type: ModalActionType.OK,
    Disabled: false,
    Execute: () => this.uploadAUBescheinigung(),
  };

  async addFile(event) {
    const file = event.target.files[0];
    if (!file) return;
    this.ImageSubject.next(file);
  }

  /**@description AU direkt hochladen, nicht erst in der iDB speichern */
  async uploadAUBescheinigung() {
    const file = this.ImageSubject.getValue();

    const data = new AUBescheinigung({
      Pnr: this.Parameter.mitarbeiter,
      VonDatum: this.Parameter.vonDatum,
      BisDatum: this.Parameter.bisDatum,
      Status: '0',
      FileName: file ? file['name'] : '',
      FileData: file ? await resizeImage(file, 1080, 1080).then(res => convertBlobtoBase64(res)) : null,
      Notiz: this.Parameter.notiz,
    });

    await this.dialogService.ShowSpinnerWhile(
      this.auBescheinigungService.uploadAUBescheinigung(data),
      'Upload',
      'Ihre AU-Bescheinigung wird hochgeladen'
    );

    await this.auBescheinigungService.Sync(true);
    this.bottomSheet.dismiss();
  }

  private clearUpload() {
    this.fileUploader.nativeElement.value = null;
    this.ImageSubject.next(null);
    this.AUBescheinigungSubject.next(null);
  }

  readonly imageData$ = this.ImageSubject.pipe(
    switchMap(async image => {
      if (!image) return;
      const size = document.getElementById('auPictureFrame').clientHeight;
      return await resizeImage(image, size, size).then(imageBlob => convertBlobtoBase64(imageBlob));
    })
  );
}
