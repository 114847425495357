import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { getfirst } from '@app/utils/rxjsUtils';
import { OneOrMany } from '@app/utils/types';
import { EntitySelectorFormComponent } from '@base-components/form-components/EntitySelectorFormComponent';
import { Employee } from '@entities/Employee';
import { MitarbeiterService } from '@pages/TabGroup/shared/ZEFMitarbeiter.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

type SelectType = 'multi' | 'single' | 'none';

@Component({
  selector: 'app-mitarbeiter-selector',
  templateUrl: './mitarbeiter-selector.component.html',
  styleUrls: ['./mitarbeiter-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MitarbeiterSelectorComponent),
      multi: true,
    },
  ],
})
export class MitarbeiterSelectorComponent
  extends EntitySelectorFormComponent<Employee>
  implements OnDestroy, OnInit, ControlValueAccessor
{
  constructor(
    private readonly employeeService: MitarbeiterService,
    private readonly globalSettings: GlobalSettingService
  ) {
    super();
  }

  /**If set to true on single booking mode a default will be chosen */
  @Input() takeDefault = true;

  /**If set to true SelectType single is forced */
  @Input() disableGruppenbuchen = false;

  readonly options$ = this.employeeService.currentUserSubordinates$;

  readonly employeeControl = new UntypedFormControl();

  private readonly selectionSubject = new BehaviorSubject<OneOrMany<Employee>>(this.employeeControl.value);

  protected getSelection$(): Observable<OneOrMany<Employee>> {
    return this.selectionSubject.pipe();
  }

  readonly triggerLabel$ = this.selectedOrFirst$().pipe(
    // taplog('pnr-selection2'),
    map(employee => (!employee ? '' : `${employee?.Pnr} - ${employee?.getFullName()}`))
  );

  selectType: SelectType;
  readonly defaultEmployee$ = this.options$.pipe(
    map(employees => (employees && employees.length > 0 ? employees[0] : undefined))
  );

  async ngOnInit() {
    this.registerSubscription(
      this.employeeControl.valueChanges
        .pipe(
          switchMap(pnrs => {
            if (Array.isArray(pnrs)) {
              return combineLatest(
                (pnrs as string[]).map(pnr => this.employeeService.firstInStoreData$(e => e.Pnr === pnr))
              ).pipe(map(employees => employees as OneOrMany<Employee>));
            }
            return this.employeeService
              .firstInStoreData$(e => e.Pnr === (pnrs as string))
              .pipe(map(employee => employee as OneOrMany<Employee>));
          }),
          distinctUntilChanged()
        )
        .subscribe(change => {
          this.selectionSubject.next(change);
          this.onChange(change);
        })
    );

    const hasMultipleEmployees = await getfirst(
      this.options$.pipe(map(employees => employees && employees.length > 1))
    );

    if (!hasMultipleEmployees) this.selectType = 'none';
    else {
      const gruppenBuchenEnabled = (await this.globalSettings.gruppenBuchen.get()) && !this.disableGruppenbuchen;
      this.selectType = gruppenBuchenEnabled ? 'multi' : 'single';
    }

    await this.initDefaultSelection(this.selectType);
  }

  private async initDefaultSelection(selectType: SelectType) {
    if (selectType === 'none') {
      const defaultEmployee = await getfirst(this.defaultEmployee$);
      this.employeeControl.setValue(defaultEmployee.Pnr);
      this.employeeControl.disable();
    }

    if (selectType === 'single' && this.takeDefault) {
      const defaultEmployee = await getfirst(this.defaultEmployee$);
      this.employeeControl.setValue(defaultEmployee.Pnr);
    }

    if (selectType === 'multi' && this.takeDefault) {
      const pnrs = await this.employeeService.recentlyBookedEmployees.get();
      this.employeeControl.setValue(pnrs);
    }
  }

  onSelect() {
    this.onTouched();
  }

  onBlur() {
    // notwendig fuer Validierung
    if (!this.employeeControl.value || this.employeeControl.value.length === 0) {
      this.selectionSubject.next(null);
      this.onChange(null);
    }
  }

  //#region ControlValueAccessor

  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(pnrOrEmployee: string | Employee): void {
    const valueIsMitarbeiter = pnrOrEmployee instanceof Employee;
    let pnr = pnrOrEmployee;
    if (valueIsMitarbeiter) {
      pnr = (pnrOrEmployee as Employee).Pnr;
    }
    this.employeeControl.setValue(pnr);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) this.employeeControl.disable();
    else this.employeeControl.enable();
  }

  get value() {
    return this.employeeControl.value;
  }

  set value(val) {
    if (val === undefined || val === null) {
      this.value = undefined;
      return;
    }

    let toSet: string | string[];

    if (Array.isArray(val)) {
      if (this.selectType === 'multi') {
        toSet = (val as Employee[]).map(empl => empl.Pnr);
      } else {
        const arr = val as Employee[];
        toSet = arr.length > 0 ? arr[0].Pnr : null;
      }
    } else {
      if (this.selectType !== 'multi') toSet = (val as Employee).Pnr;
      else toSet = [(val as Employee).Pnr];
    }

    this.employeeControl.setValue(toSet);
  }

  //#endregion
}
