import { Component, Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { TimeService } from '@app/globalServices/time.service';
import { ValidationError } from '@app/utils/ErrorHandling/Error';
import { AuftragDialogType, BookingRight, BookingType, ModalActionType, Validator } from '@app/utils/types';
import { QrcodeComponent } from '@global-components/qrcode/qrcode.component';
import { MitarbeiterService } from '@pages/TabGroup/shared/ZEFMitarbeiter.service';
import { BehaviorSubject, Observable, combineLatest, from, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TermConverterService } from 'src/app/globalServices/termConverter.service';
import { AuftragEndeErfassenComponent } from '../bottomsheet/auftrag-ende-erfassen/auftrag-ende-erfassen.component';
import { AuftragMenuComponent } from '../bottomsheet/auftrag-menu/auftrag-menu.component';
import {
  AuftragStartErfassenComponent,
  AuftragStartPreselectionParameter,
} from '../bottomsheet/auftrag-start-erfassen/auftrag-start-erfassen.component';
import { FehlzeitErfassenComponent } from '../bottomsheet/fehlzeit-erfassen/fehlzeit-erfassen.component';
import { GehenErfassenComponent } from '../bottomsheet/gehen-erfassen/gehen-erfassen.component';
import { KommenErfassenComponent } from '../bottomsheet/kommen-erfassen/kommen-erfassen.component';
import { ArbeitsplanBookingParameter, BuchungService, OpenBookingFrame } from './buchung.service';
import { TileState } from './buchungen-tile-grid/grid-tile/grid-tile.component';

export interface TileStateBase {
  isForeman: boolean;
  hasJournal: boolean;
  booking: OpenBookingFrame;
  isBetweenTimeRange: boolean;
  bookingType: BookingType;
  scannerEnabled: boolean;
  bookingRight: BookingRight;
}

/**
 * @class Buchung
 * In dieser Klasse werden die verschiedenen Buchungen (-Kommen-Gehen-Auftrag-AuftragEnde-Abwesend)
 * verwaltet und deren Layout passend gesetzt.
 */
@Component({
  selector: 'app-buchung',
  templateUrl: './buchung.component.html',
  styleUrls: ['./buchung.component.scss'],
})
@Injectable()
export class BuchungComponent {
  constructor(
    private readonly router: Router,
    private readonly bottomSheet: MatBottomSheet,
    private readonly dialog: MatDialog,

    private readonly termConverterService: TermConverterService,
    private readonly timeService: TimeService,

    private readonly settingsService: GlobalSettingService,
    private readonly buchungService: BuchungService,
    private readonly mitarbeiterService: MitarbeiterService
  ) {}

  readonly isForeman$ = this.mitarbeiterService.currentUserRights$.pipe(
    map(employee => employee?.foreman),
    distinctUntilChanged()
  );

  readonly journalRight$ = this.mitarbeiterService.currentUserRights$.pipe(
    map(employee => employee?.journalUse),
    distinctUntilChanged()
  );

  private readonly mostRecentBookingType$ = this.buchungService.mostRecentOpenBooking$.pipe(
    map(booking => this.termConverterService.bookingToBookingType(booking))
  );

  readonly bookingRight$ = this.mitarbeiterService.currentUserRights$.pipe(
    map(rights => rights?.bookingRight),
    distinctUntilChanged()
  );

  //#region Scanner

  pictureMode = this.termConverterService.getIosStandAloneOrQs(false);
  imageTrigger = false;

  private readonly scannerSubject = new BehaviorSubject<boolean>(false);
  public readonly scannerEnabled$ = of(true); // TODO nach Beta - this.scannerSubject.pipe(distinctUntilChanged() );

  /**@description Schaltet imageTrigger um - Function für die Automatisierung von Marc im QS */
  imageTriggerFunction(): void {
    this.imageTrigger = !this.imageTrigger;
    this.pictureMode = this.termConverterService.getIosStandAloneOrQs(this.imageTrigger);
  }

  async scanArbeitsplan(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/require-await
    const validator: Validator<string> = async (s: string) =>
      !!s ? true : [new ValidationError('Invalider QRCode-Inhalt', 'Formatfehler')];
    const result = await QrcodeComponent.ScanQRCode(this.dialog, validator);
    if (result.Type !== ModalActionType.OK) return;
    let param: ArbeitsplanBookingParameter = { auftragNummer: '', taetigkeitNummer: '' };
    try {
      // neue QR-Code Variante, noch nicht in den QR-Codes berücksichtigt
      param = JSON.parse(result.Data) as ArbeitsplanBookingParameter;
    } catch (_) {
      // alte QR-Code Variante
      const splitted = result.Data.split(';');
      param.auftragNummer = splitted[1];
      param.taetigkeitNummer = splitted[3];
    }
    if (
      param.auftragNummer !== null &&
      param.auftragNummer !== '' &&
      param.taetigkeitNummer !== null &&
      param.taetigkeitNummer !== ''
    )
      await this.buchungService.bookArbeitsPlan(param);
  }

  scanStop(): void {
    this.scannerSubject.next(false);
  }

  //#endregion

  //#region Tilestates$

  /** @description Synchronises dependencies for tiles to prevent singular popup */
  private readonly tileStateBase$: Observable<TileStateBase> = combineLatest([
    this.isForeman$,
    this.journalRight$,
    this.buchungService.mostRecentOpenBooking$,
    this.buchungService.isBetweenBookingTimeRange$,
    this.mostRecentBookingType$,
    this.scannerEnabled$,
    this.bookingRight$,
  ]).pipe(
    map(([isForeman, hasJournal, booking, isBetweenTimeRange, bookingType, scannerEnabled, bookingRight]) => ({
      isForeman,
      hasJournal,
      booking,
      isBetweenTimeRange,
      bookingType,
      scannerEnabled,
      bookingRight,
    }))
  );

  public readonly kommenTileState$: Observable<TileState> = this.tileStateBase$.pipe(
    map(params => {
      const auftragActive = params.bookingType === BookingType.AUFTRAGSZEIT;
      const auftragRequired = [
        BookingRight.BuchenMitAuftragserfassung,
        BookingRight.BuchenMitAuftragserfassungUndTaetigkeiten,
      ].includes(params.bookingRight);
      const disabled =
        (params.bookingType === BookingType.ARBEITSZEIT && params?.isBetweenTimeRange && !params.isForeman) ||
        (auftragRequired && !params.isForeman && !auftragActive) ||
        (auftragRequired && params.isForeman);

      return {
        text: auftragActive && !params.isForeman ? 'Auftrag beenden' : 'Kommen',
        disabled,
        bcolor: auftragActive && !params.isForeman ? '#127ec6' : '#009118',
        clickEvent: auftragActive && !params.isForeman ? () => this.onAuftragEndeClick() : () => this.onKommenClick(),
        iconSrc:
          auftragActive && !params.isForeman ? 'assets/icons/briefcase_document.png' : 'assets/icons/inbox_into.png',
        longtap: true,
      };
    })
  );

  public readonly gehenTileState$: Observable<TileState> = this.tileStateBase$.pipe(
    map(params => ({
      text: 'Gehen',
      disabled: (params.bookingType === BookingType.GEHEN || !params?.isBetweenTimeRange) && !params.isForeman,
      bcolor: '#bb1d48',
      clickEvent: () => this.onGehenClick(),
      iconSrc: 'assets/icons/inbox_out.png',
      longtap: true,
    }))
  );

  public readonly auftragTileState$: Observable<TileState> = this.tileStateBase$.pipe(
    map(params => ({
      text: params.isForeman
        ? 'Auftrag'
        : params.bookingType === BookingType.AUFTRAGSZEIT
        ? 'Auftrag bearbeiten'
        : 'Auftrag starten',
      disabled: params.bookingRight === BookingRight.BuchenOhneAuftragserfassung,
      bcolor: '#127ec6',
      clickEvent:
        params.bookingType === BookingType.AUFTRAGSZEIT || params.isForeman
          ? () => this.onAuftragBearbeitenClick()
          : async () => this.onAuftragStartClick(),
      iconSrc: 'assets/icons/briefcase_document.png',
    }))
  );

  public readonly fehlzeitTileState$: Observable<TileState> = this.tileStateBase$.pipe(
    map(_ => ({
      text: 'Abwesend',
      bcolor: '#da542d',
      clickEvent: () => this.onFehlzeitClick(),
      iconSrc: 'assets/icons/cup.png',
    }))
  );

  public readonly journalTileState$: Observable<TileState> = this.tileStateBase$.pipe(
    map(params => ({
      text: 'Journal',
      disabled: !params.hasJournal,
      bcolor: '#613cbc',
      clickEvent: () => this.onJournalClick(),
      iconSrc: 'assets/icons/edit.svg',
    }))
  );

  public readonly scannerTileState$: Observable<TileState> = this.tileStateBase$.pipe(
    map(params => ({
      text: 'Buchungsscan',
      disabled: !params.scannerEnabled,
      bcolor: '#004080',
      clickEvent: () => this.onScanClick(),
      iconSrc: 'assets/icons/scan.svg',
    }))
  );

  //#endregion

  //#region Clickevents

  onKommenClick(): void {
    const config: MatBottomSheetConfig<void> = { disableClose: true };
    this.bottomSheet.open(KommenErfassenComponent, { data: from([config]) });
  }

  onGehenClick(): void {
    const config: MatBottomSheetConfig<void> = { disableClose: true };
    this.bottomSheet.open(GehenErfassenComponent, { data: from([config]) });
  }

  onFehlzeitClick(): void {
    const config: MatBottomSheetConfig<void> = { disableClose: true };
    this.bottomSheet.open(FehlzeitErfassenComponent, { data: from([config]) });
  }

  async onAuftragStartClick(): Promise<void> {
    const lastAuftrag = await this.settingsService.lastAuftrag.get();
    const data: AuftragStartPreselectionParameter = {
      Pnrs: [],
      AufNr: lastAuftrag ? lastAuftrag : '',
      TaetNr: '',
      CustomerNr: '',
      AuftragDialogType: AuftragDialogType.AuftragStarten,
    };
    await AuftragStartErfassenComponent.ShowWith(this.bottomSheet, data);
  }

  onAuftragEndeClick(): void {
    const config: MatBottomSheetConfig<void> = { disableClose: true };
    this.bottomSheet.open(AuftragEndeErfassenComponent, {
      data: from([config]),
    });
  }

  onAuftragBearbeitenClick(): void {
    const config: MatBottomSheetConfig<void> = { disableClose: true };
    this.bottomSheet.open(AuftragMenuComponent, {
      data: from([config]),
    });
  }

  onScanClick(): void {
    void this.scanArbeitsplan();
  }

  onJournalClick(): void {
    void this.router.navigate(['/journal']);
  }

  //#endregion
}
