import { ControllerService } from '../../../globalServices/controller.service';
import { Injectable, Injector } from '@angular/core';
import { Fehlzeit } from '@entities/Fehlzeiten';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FehlzeitenService extends StoreControllerServiceBase<Fehlzeit> {
  protected GetStoreName(): string {
    return Fehlzeit.StoreName;
  }

  constructor(private readonly controllerservice: ControllerService, protected readonly injector: Injector) {
    super(injector);
  }

  protected entityConstructor(entity) {
    return new Fehlzeit(entity);
  }

  public buchbareFehlzeiten$ = this.storeData$.pipe(map(fehlzeiten => fehlzeiten.filter(fehlzeit => fehlzeit.Buchbar)));

  async Sync(emit?: boolean): Promise<string[]> {
    await this.controllerservice.reloadStore(Fehlzeit.StoreName, 'GetAbwesenheitsGruende', emit).toPromise();
    return [Fehlzeit.StoreName];
  }

  mapToDescription(id: string): Observable<string> {
    return this.storeData$.pipe(
      map(data => data.find(fehlzeit => fehlzeit.Kennzeichen === id)),
      map(fehlzeit => fehlzeit?.Beschreibung)
    );
  }
}
