import { IndexedDBTypes } from './dbType';
import { StringEnumClass } from '@app/utils/types';
import * as moment from 'moment';

//#region AUBescheinigungStatus

export type AUBescheinigungStatus = 0 | 1 | 2;

export const ReadableAUBescheinigungStatus = StringEnumClass(
  'offen',
  'akzeptiert',
  'zurückgewiesen'
)((valueSet, baseClass) => ({
  ...baseClass,

  toAUBescheinigungStatus: (readable: typeof baseClass.type): AUBescheinigungStatus => {
    const result = (baseClass.values as Array<string>).indexOf(readable);
    if (result < 0 || result > 2) throw new TypeError(`"${readable}" not in ReadableAUBescheinigungStatus`);
    return result as AUBescheinigungStatus;
  },

  fromAUBescheinigungStatus: (status: AUBescheinigungStatus): typeof baseClass.type => {
    return baseClass.values[status];
  },
}));
export type ReadableAUBescheinigungStatus = typeof ReadableAUBescheinigungStatus.type;

//#endregion

const auDayFormat = 'DD.MM.YYYY hh:mm:ss';
const dayFormat = 'DD.MM.YYYY';

const AUBescheinigung_STORENAME = 'AUBescheinigung';

export class AUBescheinigung extends IndexedDBTypes.ChangetrackedDBType {
  public static StoreName = AUBescheinigung_STORENAME;

  @IndexedDBTypes.KlassenName(AUBescheinigung_STORENAME) private KlassenName = AUBescheinigung_STORENAME;
  @IndexedDBTypes.IndexField('string') private Pnr: string;
  @IndexedDBTypes.DataField('string') private VonDatum: string;
  @IndexedDBTypes.DataField('string') private BisDatum: string;
  @IndexedDBTypes.DataField('string') private Status: AUBescheinigungStatus;
  @IndexedDBTypes.DataField('string') private FileName?: string;
  @IndexedDBTypes.DataField('string') private FileData?: string;
  @IndexedDBTypes.DataField('string') private Notiz: string;

  @IndexedDBTypes.IndexField('string') private changed: boolean;
  @IndexedDBTypes.KeyDBField('string') AutoKey: string;

  @IndexedDBTypes.DataField('Date') private _vonDate: Date;
  public set vonDate(newDate: Date) {
    this._vonDate = newDate;
  }
  public get vonDate(): Date {
    return this._vonDate;
  }

  @IndexedDBTypes.DataField('Date') private _bisDate: Date;
  public set bisDate(newDate: Date) {
    this._bisDate = newDate;
  }
  public get bisDate(): Date {
    return this._bisDate;
  }

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    this.changed = false;
    Object.assign(this, data);
    this.setDates(this.getVonDatum(), this.getBisDatum());
  }

  toDTO(): object {
    const result = { ...this };
    delete result.changed;
    delete result.AutoKey;
    delete result._vonDate;
    delete result._bisDate;
    return result;
  }

  public wasChanged(): boolean {
    return this.changed;
  }

  public setChanged(changed: boolean) {
    this.changed = changed;
  }

  public getIndex(): string {
    return this.AutoKey;
  }

  public getPnr() {
    return this.Pnr;
  }

  public getVonDatum() {
    return this.VonDatum;
  }

  public getBisDatum() {
    return this.BisDatum;
  }

  public getStatus(): AUBescheinigungStatus {
    return this.Status;
  }

  public getReadableStatus(): ReadableAUBescheinigungStatus {
    return ReadableAUBescheinigungStatus.fromAUBescheinigungStatus(this.Status);
  }

  public getFileName() {
    return this.FileName;
  }

  public getFileData() {
    return this.FileData;
  }

  public getNotiz() {
    return this.Notiz;
  }

  public getVonDateString(): string {
    const vonDateString = this.getVonDatum();
    const momentVon = moment(vonDateString, auDayFormat).format('DD.MM.YYYY');
    return momentVon;
  }

  public getBisDateString(): string {
    const bisDateString = this.getBisDatum();
    const momentBis = moment(bisDateString, auDayFormat).format('DD.MM.YYYY');
    return momentBis;
  }

  /**@description Sets the dates based on existing date information for better handling  */
  private setDates(von: string, bis: string) {
    const vonDate = moment(von, auDayFormat).toDate();
    const bisDate = moment(bis, auDayFormat).toDate();
    this.vonDate = vonDate;
    this.bisDate = bisDate;
  }

  static toString(): string {
    return AUBescheinigung_STORENAME;
  }
}
