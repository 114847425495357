import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CryptoService } from '../Crypto.service';
import { UserSessionInfo, UserSessionService } from '../user-session-state.service';
import { IConnectionInfo } from './Allocation';
import { HttpRequest } from './httpclient-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class BackendAuthenticationService {
  constructor(private readonly userService: UserSessionService, private readonly cryptoService: CryptoService) {}

  /**@description Ergänzt Authorization und User-Header */
  authenticateRequest(
    request: HttpRequest,
    connectionInfo: IConnectionInfo,
    urlExtension: string
  ): Observable<HttpRequest> {
    return this.userService.loginDetails.value$.pipe(
      first(),
      map(loginDetails => this.authenticateEmployeeRequest(request, urlExtension, connectionInfo, loginDetails))
    );
  }

  /**@description Ergänzt erste Login-Request um Auth-Header */
  authenticateLoginRequest(
    request: HttpRequest,
    connectionInfo: IConnectionInfo,
    urlExtension: string,
    pnr: string,
    pw: string
  ): Observable<HttpRequest> {
    const [hash, salt] = this.cryptoService.hashUnsalted(pw);
    const sessionInfo: UserSessionInfo = { id: pnr, hash, salt, type: '' };
    return of(this.authenticateEmployeeRequest(request, urlExtension, connectionInfo, sessionInfo));
  }

  private authenticateEmployeeRequest(
    request: HttpRequest,
    urlExtension: string,
    connectionInfo: IConnectionInfo,
    sessionInfo: UserSessionInfo
  ): HttpRequest {
    if (!sessionInfo) {
      return request;
    }
    const { id: pnr, hash, salt } = sessionInfo;

    const authUrl = this.extractUrl(connectionInfo) + urlExtension;
    const authHash = this.cryptoService.hash(authUrl, hash);

    const headers = {
      ...request.reqInit.headers,
      EmployeeID: encodeURIComponent(pnr),
      EmployeeUserAuth: authHash,
      Salt: salt,
    };
    return { url: request.url, reqInit: { ...request.reqInit, headers } };
  }

  /**@description Baut Url die als Auth-Grundlage verwendet wird;
   * wichtig für Verbindung via Relay-Proxy, da Relay-Port != Proxy-Port */
  private extractUrl(connectionInfo: IConnectionInfo): string {
    if (!connectionInfo) throw new Error('No Url to authenticate');
    if (connectionInfo.allocation)
      return `https://${connectionInfo.allocation.ip}:${connectionInfo.allocation.port}/Service.svc/`;
    if (connectionInfo.directConnection)
      return `https://${connectionInfo.directConnection.ip}:${connectionInfo.directConnection.port}/Service.svc/`;
  }
}
