import { IndexedDBTypes } from './dbType';

export const LOCAL_SETTINGS_STORENAME = 'LocalSettings';

export class Setting extends IndexedDBTypes.DbType {
  static StoreName = LOCAL_SETTINGS_STORENAME;

  @IndexedDBTypes.KlassenName(LOCAL_SETTINGS_STORENAME) private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private key: string;
  @IndexedDBTypes.DataField('object') private value: any;

  // Konstruktor setzt beim initialisieren die übergebene Data mit Object.assign
  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  public getKey() {
    return this.key;
  }

  public getValue() {
    return this.value;
  }

  static toString(): string {
    return LOCAL_SETTINGS_STORENAME;
  }
}
