import { Component, Injector, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { BookingType } from '@app/utils/types';
import { ZEFZeitmodell } from '@entities/ZEFZeitmodell';
import { JournalKeyData } from '../../buchung/ExtendedBookings';
import { JournalDialogComponent } from '../JournalDialogComponent';

export interface ManuelErfassenParameter extends JournalKeyData {
  selectedPnr: string;
  preSelectBookingType: BookingType;
}

@Component({
  selector: 'app-manuell-erfassen',
  templateUrl: './manuell-erfassen.component.html',
  styleUrls: ['./manuell-erfassen.component.scss'],
})
export class ManuellErfassenComponent extends JournalDialogComponent<ManuelErfassenParameter> implements OnInit {
  public static async ShowWith(bottomSheet: MatBottomSheet, data: ManuelErfassenParameter) {
    return await super.Show<ManuelErfassenParameter, void>(bottomSheet, ManuellErfassenComponent, { data });
  }

  rahmenZeitModell: ZEFZeitmodell;

  constructor(protected readonly injector: Injector) {
    super(injector);
    this.selectedStart = this.Data.selectedDay;
    this.calculatedEnd = this.selectedStart;
  }

  protected readonly settings = this.injector.get<GlobalSettingService>(GlobalSettingService);

  readonly kundenAnzeige$ = this.settings.kundenAnzeige.value$;
  readonly auftragsAnzeige$ = this.settings.auftragsAnzeige.value$;
  readonly taetigkeitsAnzeige$ = this.settings.taetigkeitsAnzeige.value$;

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    await this.setSelectedEmployee(this.Data.selectedPnr);
    this.formGroup.patchValue({ typeSelection: this.Data.preSelectBookingType });
  }

  protected readBuchungFromForm() {
    const buchungsData = {
      ...super.readBuchungFromForm(),
      Auftrag: this.getSelectedEntityIndex('auftrag'),
      Kunde: this.getSelectedEntityIndex('kunde'),
      Taetigkeit: this.getSelectedEntityIndex('taetigkeit'),
      Kennzeichen: this.getSelectedEntityIndex('abwesenheitsgrund'),
      Kommentar1: this.formGroup.value.kommentar || '',
    };
    return buchungsData;
  }
}
