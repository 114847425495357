<div class="stempelContainer">
  <div class="kachelContainer buchung-tile-grid">
    <app-letzte-erfassung></app-letzte-erfassung>

    <app-grid-tile [state]="kommenTileState$ | async" style="grid-area: kommen"></app-grid-tile>
    <app-grid-tile [state]="gehenTileState$ | async" style="grid-area: gehen"></app-grid-tile>
    <app-grid-tile [state]="auftragTileState$ | async" style="grid-area: auftrag"></app-grid-tile>
    <app-grid-tile [state]="fehlzeitTileState$ | async" style="grid-area: fehlzeit"></app-grid-tile>
    <app-grid-tile [state]="scannerTileState$ | async" style="grid-area: scan"></app-grid-tile>
    <app-grid-tile [state]="journalTileState$ | async" style="grid-area: journal"></app-grid-tile>
  </div>
</div>

<!-- <app-qrcode *ngIf="this.scannerEnabled$ | async" [pictureMode]='pictureMode' (stopEvent)='scanStop($event)'></app-qrcode> -->
