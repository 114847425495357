import { Injectable, Injector } from '@angular/core';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { AUBescheinigung } from '@entities/AUBescheinigung';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';
import { DatabaseService } from '@app/globalServices/database.service';
import { switchMap, first, map } from 'rxjs/operators';
import { ControllerService } from '../../../globalServices/controller.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { DialogService } from '@app/globalServices/dialog.service';

export interface AUBescheinigungMail {
  AUBescheinigungIndex: string;
  Receiver: string;
}

@Injectable({
  providedIn: 'root',
})
export class AUBescheinigungService extends StoreControllerServiceBase<AUBescheinigung> {
  constructor(
    protected readonly injector: Injector,
    private readonly session: UserSessionService,
    private readonly backendService: ZEFBackendService,
    private readonly controllerservice: ControllerService,
    private readonly dbService: DatabaseService,
    private readonly dialogService: DialogService
  ) {
    super(injector);
  }

  protected GetStoreName(): string {
    return AUBescheinigung.StoreName;
  }

  protected entityConstructor(row): AUBescheinigung {
    return new AUBescheinigung(row);
  }

  public async Sync(emit?: boolean): Promise<string[]> {
    /*await this.controllerservice
			.sendStoreData(AUBescheinigung.StoreName, 'SetAUBescheinigungen', (raw) => new AUBescheinigung(raw), emit)
			.toPromise();*/

    await this.session.pnr$
      .pipe(
        first(),
        switchMap(pnr =>
          this.controllerservice.reloadStore(this.GetStoreName(), `GetAUBescheinigungenForPnr/PNR/${pnr}`, emit)
        )
      )
      .toPromise();

    return [AUBescheinigung.StoreName];
  }

  async uploadAUBescheinigung(auBescheinigung: AUBescheinigung) {
    return await this.backendService
      .post<any>('SetAUBescheinigung', auBescheinigung)
      .pipe(
        map(response => response.Success as boolean),
        this.dialogService.HandleDefaultErrors()
      )
      .toPromise();
  }

  async setAUBescheinigung(bescheinigung: AUBescheinigung, emitRefresh?: boolean) {
    await this.dbService.setData(AUBescheinigung.StoreName, [bescheinigung], emitRefresh);
  }

  userAUBescheinigungen$(pnr: string) {
    return this.filteredStoreData$(pnr, 'Pnr');
  }

  readonly currentUserAUBescheinigungen$ = this.session.pnr$.pipe(switchMap(pnr => this.userAUBescheinigungen$(pnr)));

  async sendAUBescheinigungMail(auBescheinigungIndex) {
    const auBescheinigungMail: AUBescheinigungMail = {
      AUBescheinigungIndex: auBescheinigungIndex,
      Receiver: 'Supervisor',
    };
    return await this.backendService.post('SendAUBescheinigungMail', auBescheinigungMail).toPromise();
  }

  /**@description Vergleichsfunktion für die AUBescheinigungen, sortiert nach Datum */
  compareAUBescheinigungen(a: AUBescheinigung, b: AUBescheinigung) {
    const aVon = a.getVonDatum();
    const currentDate = aVon.split(' ');
    const tagMonatJahr = currentDate[0].split('.');
    const tag = parseInt(tagMonatJahr[0], 0);
    const monat = parseInt(tagMonatJahr[1], 0) - 1;
    const jahr = parseInt(tagMonatJahr[2], 0);
    const aDate = new Date(jahr, monat, tag);
    const bVon = b.getVonDatum();
    const currentDateB = bVon.split(' ');
    const tagMonatJahrB = currentDateB[0].split('.');
    const tagB = parseInt(tagMonatJahrB[0], 0);
    const monatB = parseInt(tagMonatJahrB[1], 0) - 1;
    const jahrB = parseInt(tagMonatJahrB[2], 0);
    const bDate = new Date(jahrB, monatB, tagB);
    if (aDate > bDate) {
      return -1;
    }
    if (aDate < bDate) {
      return 1;
    }
    return 0;
  }
}
