import { Injectable } from '@angular/core';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { ZEFZeitmodell } from '@entities/ZEFZeitmodell';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ZeitmodelleService {
  constructor(private readonly backendService: ZEFBackendService) {}

  /**@param datum Format: ddmmyyyy */
  async getZeitmodell(pnr: string, datum: string): Promise<ZEFZeitmodell> {
    return await this.backendService
      .post<any>('GetZeitmodell/PNR/' + pnr + '/DATUM/' + datum)
      .pipe(map(response => new ZEFZeitmodell(response)))
      .toPromise();
  }
}
