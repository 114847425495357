import { AfterViewChecked, AfterViewInit, Component, Input, OnInit } from '@angular/core';

export interface SaldenDetails {
  ZeitkontoDetailVormonat: string;
  ZeitkontoDetailAktuell: string;
  ZeitkontoDetailSollAktuell: string;
  ZeitkontoDetailNochZuLeisten: string;
  ZeitkontoDetailStand: string;
  ZeitkontoDetailGeplant: string;

  Monat: string;
  VorMonat: string;
}

@Component({
  selector: 'app-salden-details',
  templateUrl: './salden-details.component.html',
  styleUrls: ['./salden-details.component.scss'],
})
export class SaldenDetailsComponent implements AfterViewInit {
  @Input() SaldenData: SaldenDetails;

  constructor() {}
  ngAfterViewInit(): void {
    const element = document.getElementById('salden-detail-card');

    element.scrollIntoView({ behavior: 'smooth' });
  }

  NochZuLeistenLabelText(): string {
    if (this.SaldenData?.ZeitkontoDetailNochZuLeisten?.startsWith('-')) return 'Noch zu leisten';
    else return 'Geleistet (Überstunden)';
  }
}
