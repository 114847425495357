<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

<mat-toolbar *ngIf="showToolbar" [style.background]="background" class="toolbar" id="toolbar">
  <img (click)="backClicked()" *ngIf="showBack" class="imageBack" [src]="imageBack" />
  <img [src]="nurLogoUrl" *ngIf="nurLogo" class="nurLogoCss" />
  <table class="toolbarTitleTable">
    <div *ngIf="showImage1" class="image1container"><img class="image1" [src]="image1Url" /></div>
    <a *ngIf="showToolbarTitle" class="toolbarTitle">{{ title }}</a>
  </table>
  <img [src]="burgerMenueImage" *ngIf="showBurgerMenu" class="burgerMenueButton" (click)="openMenu()" />
</mat-toolbar>

<app-menu *ngIf="toggleMenu" (closeMenuEvent)="closeMenu()"></app-menu>
