import { BaseFormComponent } from './BaseFormComponent';
import { Component } from '@angular/core';

@Component({ template: '{{"BaseTextfieldComponent"}}' })
export abstract class BaseTextfieldComponent extends BaseFormComponent<string> {
  // abstract writeValue(obj: any): void ;
  // abstract registerOnChange(fn: any): void;
  // abstract registerOnTouched(fn: any): void;
  // abstract setDisabledState?(isDisabled: boolean): void;
}
