import { BookingTimeFrame } from '@entities/BookingTimeFrame';
import { overlapType } from '@stores/ZEFBuchung_Temp.service';

export class StempelungViewModel extends BookingTimeFrame {
  readonly Zeitbezeichnung: string;
  constructor(stemp: BookingTimeFrame, bez: string) {
    super(stemp);
    this.Zeitbezeichnung = bez;
  }
}

export class BookingCollision extends StempelungViewModel {
  readonly overlapType: overlapType;
  constructor(stemp: StempelungViewModel, overlapTypeValue: overlapType) {
    super(stemp, stemp.Zeitbezeichnung);
    this.overlapType = overlapTypeValue;
  }
}

export interface StempelungMetadata {
  TaetigkeitsDescription: string;
  AuftragsDescription: string;
  FehlzeitDescription: string;
}

export interface JournalKeyData {
  existingStempelungenForDay: StempelungViewModel[];
  selectedDay: Date;

  // selectedPnr?: string;
  // stempelung: BookingTimeFrame;
}
