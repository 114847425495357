import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControllerService } from '@app/globalServices/controller.service';
import { DialogService } from '@app/globalServices/dialog.service';
import { SyncService, SyncStep } from '@app/globalServices/sync.service';
import { ObservableQuery, qMap, query } from '@app/utils/rxQueryUtils';
import { Salden } from '@entities/Salden';
import { MitarbeiterService } from '@stores/ZEFMitarbeiter.service';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TimeService } from 'src/app/globalServices/time.service';
import { SaldenService } from 'src/app/pages/TabGroup/shared/ZEFSalden.service';
import { SaldenDetails } from './salden-details/salden-details.component';

export interface SaldenViewModel extends SaldenDetails {
  JahresUrlaub: string;
  UrlaubVorjahr: string;
  UrlaubGenommen: string;
  UrlaubGeplant: string;
  UrlaubVerbleibend: string;

  ZeitkontoGestern: string;
  ZeitkontoHeute: string;
  ZeitkontoStand: string;
}

@Component({
  selector: 'app-salden',
  templateUrl: './salden.component.html',
  styleUrls: ['./salden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaldenComponent implements OnInit {
  showDetail = false;

  constructor(
    private readonly saldenService: SaldenService,
    private readonly controllerService: ControllerService,
    private readonly timeService: TimeService,
    private readonly dialogService: DialogService,
    private readonly syncService: SyncService,
    private readonly employeeService: MitarbeiterService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.Sync();
  }

  readonly employee$ = this.employeeService.currentUser$.pipe(
    map(employee => {
      return employee.Vorname + ' ' + employee.Nachname;
    })
  );

  private readonly saldenQry$: ObservableQuery<Salden> = this.employeeService.currentUser$.pipe(
    map(employee => employee.Pnr),
    switchMap(pnr =>
      query('salden:' + pnr, () => this.saldenService.firstInStoreData$(saldenList => saldenList.PNR === pnr))
    )
  );

  private readonly month$ = this.timeService.currentMonth$.pipe();
  private readonly lastMonth$ = this.timeService.lastMonth$.pipe();

  readonly qry$: ObservableQuery<SaldenViewModel> = combineLatest([this.month$, this.lastMonth$]).pipe(
    switchMap(([monat, vormonat]) =>
      this.saldenQry$.pipe(
        map(qrystate => {
          if (qrystate.status === 'success' && !qrystate.data) {
            const result = {
              status: 'error',
              error: new Error('Bitte synchronisieren sie Ihre Daten.'),
            };
            result.error.name = 'Unbekannter Datensatz';
            return result;
          }
          return qrystate;
        }),
        qMap(salden => this.mapToViewModel(salden, monat, vormonat))
      )
    )
  );

  mapToViewModel(salden: Salden, monat: string, vormonat: string): SaldenViewModel {
    return {
      JahresUrlaub: salden.getJahresUrlaub().toString(),
      UrlaubVorjahr: salden.getUrlaubVorjahr().toString(),
      UrlaubGenommen: salden.getUrlaubGenommen().toString(),
      UrlaubGeplant: salden.getUrlaubGeplant().toString(),
      UrlaubVerbleibend: salden.getUrlaubVerbleibend().toString(),

      ZeitkontoGestern: this.formatMinutenZuStunden(salden.getZeitkontoGestern()),
      ZeitkontoHeute: this.formatMinutenZuStunden(salden.getZeitkontoHeute()),
      ZeitkontoStand: this.formatMinutenZuStunden(salden.getZeitkontoStand()),

      ZeitkontoDetailVormonat: this.formatMinutenZuStunden(salden.getZeitkontoDetailVormonat()),
      ZeitkontoDetailAktuell: this.formatMinutenZuStunden(salden.getZeitkontoDetailAktuell()),
      ZeitkontoDetailSollAktuell: this.formatMinutenZuStunden(salden.getZeitkontoDetailSollAktuell()),
      ZeitkontoDetailNochZuLeisten: this.formatMinutenZuStunden(salden.getZeitkontoDetailNochZuLeisten()),
      ZeitkontoDetailStand: this.formatMinutenZuStunden(salden.getZeitkontoDetailStand()),
      ZeitkontoDetailGeplant: this.formatMinutenZuStunden(salden.getZeitkontoDetailGeplant()),

      Monat: monat,
      VorMonat: vormonat,
    };
  }

  formatMinutenZuStunden(s: string | number): string {
    return this.timeService.calcStundenMinuten(s);
  }

  //#endregion

  //#region Sync

  protected async Sync(): Promise<void> {
    const steps = await this.getSaldenSyncSteps();
    await this.syncService.sync(steps);
  }

  protected async getSaldenSyncSteps(): Promise<SyncStep[]> {
    return [...(await this.syncService.getSaldenSyncSteps())];
  }

  //#endregion

  clickDetail() {
    this.showDetail = !this.showDetail;
  }
}
