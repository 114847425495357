import { Component, Injector, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { getfirst } from '@app/utils/rxjsUtils';
import { BookingType, ModalAction, ModalActionType } from '@app/utils/types';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { Auftrag } from '@entities/Auftrag';
import { BookingTimeFrame } from '@entities/BookingTimeFrame';
import { Kunden } from '@entities/Customer';
import { Fehlzeit } from '@entities/Fehlzeiten';
import { Taetigkeit } from '@entities/Taetigkeit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JournalKeyData } from '../../buchung/ExtendedBookings';
import { JournalDialogComponent } from '../JournalDialogComponent';

export interface EditErfassenParameter extends JournalKeyData {
  stempelung: BookingTimeFrame;
  preSelectBookingType: BookingType;
}

@Component({
  selector: 'app-editieren-erfassen',
  templateUrl: './editieren-erfassen.component.html',
  styleUrls: ['./editieren-erfassen.component.scss'],
})
export class EditierenErfassenComponent extends JournalDialogComponent<EditErfassenParameter> implements OnInit {
  readonly selectedBooking: BookingTimeFrame;

  public static async ShowWith(bottomSheet: MatBottomSheet, data: EditErfassenParameter) {
    return await super.Show<EditErfassenParameter, void>(bottomSheet, EditierenErfassenComponent, { data });
  }

  constructor(protected readonly injector: Injector) {
    super(injector);
    this.selectedStart = this.Data.selectedDay;
    this.calculatedEnd = this.selectedStart;
    this.selectedBooking = this.Data.stempelung;
    this.artParameters.preselection = this.selectedBooking.toArt();
  }

  TitleStyle: TitleStyle = {
    name: 'Kommen',
    color: 'black',
    fontweight: 'bold',
  };

  protected readonly settings = this.injector.get<GlobalSettingService>(GlobalSettingService);

  readonly kundenAnzeige$ = this.settings.kundenAnzeige.value$;
  readonly auftragsAnzeige$ = this.settings.auftragsAnzeige.value$;
  readonly taetigkeitsAnzeige$ = this.settings.taetigkeitsAnzeige.value$;

  async ngOnInit() {
    await super.ngOnInit();
    await this.parseFormfieldValues(this.selectedBooking);
  }

  /**@description Parses the booking information for editing/deleting */
  async parseFormfieldValues(selectedBooking: BookingTimeFrame) {
    const art = this.Data.preSelectBookingType;
    this.formGroup.patchValue({ typeSelection: art });
    switch (art) {
      case BookingType.AUFTRAGSZEIT: {
        const assignment: Auftrag = await getfirst(
          this.AuftraegeService.firstInStoreData$(auftrag => auftrag.getNummer() === selectedBooking.AuftragsNummer)
        );
        this.formGroup.patchValue({ auftrag: assignment });
        const customer: Kunden = await getfirst(
          this.customers.firstInStoreData$(
            searchCustomer => searchCustomer.getNummer() === assignment?.getKundenNummer()
          )
        );
        this.formGroup.patchValue({ kunde: customer });
        const activity: Taetigkeit = await getfirst(
          this.taetigkeitOptions$.pipe(
            map(taetigkeiten => taetigkeiten?.find(t => t.getNummer() === selectedBooking.TaetigkeitsNummer))
          )
        );
        this.formGroup.patchValue({ taetigkeit: activity });
        break;
      }
      case BookingType.FEHLZEIT: {
        const reason: Fehlzeit = await getfirst(
          this.fehlzeiten.firstInStoreData$(fehlzeit => fehlzeit.getKennzeichen() === selectedBooking.Kennzeichen)
        );
        this.formGroup.patchValue({ abwesenheitsgrund: reason });
        break;
      }
    }
    const timePickerValues = selectedBooking.toTimepickerValues();
    this.formGroup.patchValue({ timepickerStart: timePickerValues.startTime });
    this.formGroup.patchValue({ timepickerEnd: timePickerValues.endTime });
    await this.setSelectedEmployee(selectedBooking.Pnr);
    this.formGroup.patchValue({ kommentar: selectedBooking.Kommentar1 });
  }

  readonly deleteButtonAction: ModalAction = {
    Type: ModalActionType.DELETE,
    Disabled: false,
    Execute: async () => {
      const cancel = await this.DialogService.QueryYesNo(
        'Sind Sie sicher?',
        'Buchung wirklich löschen?',
        'Nein',
        'Löschen'
      );
      if (cancel) return;
      await this.deleteBooking(this.selectedBooking);
      this.bottomSheet.dismiss();
    },
  };

  readonly editButtonAction$: Observable<ModalAction> = this.disableSubmit$.pipe(
    map<boolean, ModalAction>(disable => ({
      Type: ModalActionType.OK,
      Disabled: disable,
      Execute: async () => {
        try {
          const noChanges = this.checkForChanges();
          if (noChanges) {
            await this.DialogService.ShowInformation(
              'Achtung',
              'Sie haben keine Änderungen an der Buchung vorgenommen. Die Buchung wird nicht durchgeführt',
              'Ok'
            );
            return;
          }

          const newBookingValid = await this.checkValidTimeframe();
          if (!newBookingValid) {
            await this.DialogService.ShowInformation(
              'Fehler',
              'Der Buchungszeitraum der neuen Buchung liegt außerhalb der erlaubten Zeit. Die Buchung wird nicht durchgeführt',
              'Ok'
            );
            return;
          }

          this.setSecondsForBooking();
          await this.onSubmit();
          this.bottomSheet.dismiss();
        } catch (error) {
          await this.DialogService.ShowError(error);
        }
      },
    }))
  );

  /**@description Deletes the booking, does a sync afterwards */
  private async deleteBooking(selectedBooking: BookingTimeFrame, skipSync = false) {
    const success = await this.BookingService.deleteBuchung(selectedBooking.Key);
    if (!success) return;

    if (skipSync) return;

    const offLineDialog = async () =>
      this.DialogService.ShowInformation('Offline', 'Bitte stellen Sie Ihre Verbindung wieder her.');
    await this.BuchungService.doSyncAfterBooking(offLineDialog);
  }

  /**@description Checks if any changes have been made to the booking */
  private checkForChanges(): boolean {
    const { timepickerStart, timepickerEnd, kommentar, auftrag, taetigkeit, abwesenheitsgrund } = this.formGroup.value;

    const sameTime =
      this.selectedBooking.BeginnStunden === Number(timepickerStart.split(':')[0]) &&
      this.selectedBooking.BeginnMinuten === Number(timepickerStart.split(':')[1]) &&
      this.selectedBooking.EndStunden === Number(timepickerEnd.split(':')[0]) &&
      this.selectedBooking.EndMinuten === Number(timepickerEnd.split(':')[1]);
    const sameKommentar = this.selectedBooking.Kommentar1 === kommentar;
    const sameAuftrag = this.selectedBooking.AuftragsNummer === (auftrag?.Nummer ?? '');
    const sameTaetigkeit = this.selectedBooking.TaetigkeitsNummer === (taetigkeit?.Nummer ?? '');
    const sameAbwesendheitsgrund = this.selectedBooking.Kennzeichen === (abwesenheitsgrund?.Kennzeichen ?? '');

    return sameTime && sameKommentar && sameAuftrag && sameTaetigkeit && sameAbwesendheitsgrund;
  }

  /**@description Sets seconds for Booking if no change has been made to the time values */
  private setSecondsForBooking(): void {
    const { timepickerStart, timepickerEnd } = this.formGroup.value;

    const sameStart =
      this.selectedBooking.BeginnStunden === Number(timepickerStart.split(':')[0]) &&
      this.selectedBooking.BeginnMinuten === Number(timepickerStart.split(':')[1]);
    const sameEnd =
      this.selectedBooking.EndStunden === Number(timepickerEnd.split(':')[0]) &&
      this.selectedBooking.EndMinuten === Number(timepickerEnd.split(':')[1]);

    const newStart = sameStart ? `${timepickerStart}:${this.selectedBooking.BeginnSec}` : timepickerStart;
    const newEnd = sameEnd ? `${timepickerEnd}:${this.selectedBooking.EndSec}` : timepickerEnd;

    this.formGroup.patchValue({ timepickerStart: newStart, timepickerEnd: newEnd });
  }

  //#endregion

  readonly editButtonStyle: ButtonStyle = {
    color: 'white',
    backgroundColor: '#D15B00',
    icon: null,
    description: 'Buchung ändern',
  };

  readonly deleteButtonStyle: ButtonStyle = {
    color: 'white',
    backgroundColor: 'red',
    icon: null,
    description: 'Buchung löschen',
  };
}
