import { Subscription, Subject } from 'rxjs';
import { OnDestroy, Directive } from '@angular/core';

@Directive()
export abstract class BaseDirective implements OnDestroy {
  private readonly subscriptionCollection = new Subscription();
  protected readonly destroy$ = new Subject();

  async ngOnDestroy() {
    this.subscriptionCollection.unsubscribe();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  protected registerSubscription(sub: Subscription) {
    this.subscriptionCollection.add(sub);
  }
}
