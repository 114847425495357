<div class="container">
  <mat-tab-group
    mat-stretch-tabs="false"
    headerPosition="below"
    class="tab-group"
    [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="onSelectedIndexChange($event)"
  >
    <mat-tab label="Buchung">
      <ng-template mat-tab-label>
        <div class="grid-container">
          <div class="grid-image-icon"><bss-icon [color]="'var(--bss-blue)'" class="img"></bss-icon></div>
          <div class="grid-label"><a class="buchung">Buchung</a></div>
        </div>
      </ng-template>

      <div class="tab-content" *ngIf="selectedTabIndex === 0">
        <app-buchung></app-buchung>
      </div>
    </mat-tab>

    <mat-tab label="T-Kennzeichen">
      <ng-template mat-tab-label>
        <div class="grid-container">
          <div class="grid-image"><img src="assets/icons/tkennzeichen.png" /></div>
          <div class="grid-label"><a class="t-kennzeichen">T-Kennzeichen</a></div>
        </div>
      </ng-template>

      <div class="tab-content" *ngIf="selectedTabIndex === 1">
        <app-tkennzeichen></app-tkennzeichen>
      </div>
    </mat-tab>

    <mat-tab label="Uebersicht">
      <ng-template mat-tab-label>
        <div class="grid-container">
          <div class="grid-image"><img src="assets/icons/history2.png" /></div>
          <div class="grid-label"><a class="uebersicht">Übersicht</a></div>
        </div>
      </ng-template>

      <div class="tab-content" *ngIf="selectedTabIndex === 2">
        <app-uebersicht></app-uebersicht>
      </div>
    </mat-tab>

    <mat-tab label="Salden">
      <ng-template mat-tab-label>
        <div class="grid-container">
          <div class="grid-image"><img src="assets/icons/information.png" /></div>
          <div class="grid-label"><a class="salden">Salden</a></div>
        </div>
      </ng-template>

      <div class="tab-content" *ngIf="selectedTabIndex === 3">
        <app-salden></app-salden>
      </div>
    </mat-tab>

    <mat-tab label="Antraege">
      <ng-template mat-tab-label>
        <div class="grid-container">
          <div class="grid-image"><img src="assets/icons/hand_card.png" /></div>
          <div class="grid-label"><a class="antraege">Anträge</a></div>
        </div>
      </ng-template>

      <div class="tab-content" *ngIf="selectedTabIndex === 4">
        <app-antraege></app-antraege>
      </div>
    </mat-tab>

    <mat-tab label="AU-Bescheinigung">
      <ng-template mat-tab-label>
        <div class="grid-container">
          <div class="grid-image"><img src="assets/icons/doctor2.png" /></div>
          <div class="grid-label"><a>AU-Bescheinigung</a></div>
        </div>
      </ng-template>

      <div class="tab-content" *ngIf="selectedTabIndex === 5">
        <app-au-bescheinigung></app-au-bescheinigung>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
