import { IndexedDBTypes } from './dbType';

const CUSTOMER_STORE_NAME = 'Kunden';

export class Kunden extends IndexedDBTypes.IndexedDBType {
  public static StoreName = CUSTOMER_STORE_NAME;

  @IndexedDBTypes.KlassenName(CUSTOMER_STORE_NAME) private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') private Kundennummer: string;
  @IndexedDBTypes.IndexField('string') private Kundenname: string;

  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  getIndex(): string {
    return this.getNummer();
  }

  public getNummer() {
    return this.Kundennummer;
  }

  public getBeschreibung() {
    return this.Kundenname;
  }

  static toString(): string {
    return CUSTOMER_STORE_NAME;
  }
}
