<div class="letzteBuchungContainer" [style.background]="color$ | async" [style.border-color]="color$ | async">
  <div *ngIf="hasBooking$ | async" class="booking-layout">
    <div class="component-title">Letzte Buchung</div>
    <div class="booking-text">
      <div class="title-text">
        {{ (bookingDescription$ | async)?.title }}
      </div>
      <div class="title-text" *ngIf="(bookingDescription$ | async)?.subtitle">
        {{ (bookingDescription$ | async)?.subtitle }}
      </div>
    </div>
    <div class="textCounterZeit">{{ bookingTimestamp$ | async }} | {{ counter$ | async }}</div>
  </div>

  <div *ngIf="!(hasBooking$ | async)" class="empty-layout">
    <div class="timer-text">{{ currentTime$ | async }}</div>
  </div>
</div>
