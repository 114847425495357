import { IndexedDBTypes } from './dbType';

export class Fehlzeit extends IndexedDBTypes.DbType {
  public static StoreName = 'Fehlzeit';

  @IndexedDBTypes.KlassenName('Fehlzeit')
  private KlassenName: string;
  @IndexedDBTypes.KeyDBField('string') public Kennzeichen: string;
  @IndexedDBTypes.DataField('string') public Beschreibung: string;
  @IndexedDBTypes.DataField('boolean') public Buchbar: boolean;

  constructor(data: Object) {
    super();
    Object.assign(this, data);
  }

  getIndex(): string {
    return this.getKennzeichen();
  }

  public getKennzeichen() {
    return this.Kennzeichen;
  }

  public getBeschreibung() {
    return this.Beschreibung;
  }

  public getBuchBar() {
    return this.Buchbar;
  }

  static toString(): string {
    return 'Fehlzeit';
  }
}
