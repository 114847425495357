import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DialogService } from '@app/globalServices/dialog.service';
import { LocationService } from '@app/globalServices/location.service';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { SyncService, SyncStep } from '@app/globalServices/sync.service';
import { TimeService } from '@app/globalServices/time.service';
import { ModalAction, ModalActionType, OneOrMany } from '@app/utils/types';
import { BaseBottomsheetComponent } from '@base-components/BaseBottomsheetComponent';
import { BookingTime, TempBuchung } from '@entities/BookingTime';
import { Employee } from '@entities/Employee';
import { BuchungTempService } from '@pages/TabGroup/shared/ZEFBuchung_Temp.service';
import { MitarbeiterService } from '@pages/TabGroup/shared/ZEFMitarbeiter.service';
import { AuftraegeService } from '@stores/ZEFAuftraege.service';
import { KundenService } from '@stores/ZEFKunden.service';
import { TaetigkeitService } from '@stores/ZEFTaetigkeit.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { BuchungService } from '../buchung/buchung.service';

export interface EmployeePreselectionParameter {
  Pnrs: string[];
}

@Component({ template: '{{"BaseBottomsheetComponent"}}' })
export abstract class BuchungsBottomsheetComponent<TParam>
  extends BaseBottomsheetComponent<TParam, void>
  implements OnInit
{
  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  protected readonly Data: TParam = this.injector.get<TParam>(MAT_BOTTOM_SHEET_DATA);

  protected readonly SyncService = this.injector.get<SyncService>(SyncService);
  protected readonly Settings = this.injector.get<GlobalSettingService>(GlobalSettingService);
  protected readonly Employees = this.injector.get<MitarbeiterService>(MitarbeiterService);

  protected readonly DialogService = this.injector.get<DialogService>(DialogService);
  protected readonly TimeService = this.injector.get<TimeService>(TimeService);
  protected readonly LocationService = this.injector.get<LocationService>(LocationService);

  protected readonly BookingService = this.injector.get<BuchungTempService>(BuchungTempService);
  protected readonly BuchungService = this.injector.get<BuchungService>(BuchungService);

  protected readonly AuftraegeService = this.injector.get<AuftraegeService>(AuftraegeService);

  protected readonly TaetigkeitService = this.injector.get<TaetigkeitService>(TaetigkeitService);

  protected readonly CustomerService = this.injector.get<KundenService>(KundenService);

  private readonly formBuilder = this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
  readonly formGroup = this.createFormGroup(this.formBuilder);

  validationErrors() {
    if (!this.formGroup.errors) return;
    return Object.keys(this.formGroup.errors).map(key => JSON.stringify(this.formGroup.errors[key]));
  }

  protected abstract createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup;

  private syncFinishedSubject = new BehaviorSubject<boolean>(false);
  readonly syncFinished$ = this.syncFinishedSubject.asObservable();

  async ngOnInit() {
    this.syncFinishedSubject.next(false);
    await this.Sync();
    this.syncFinishedSubject.next(true);
  }

  //#region Sync

  protected async Sync(): Promise<void> {
    const steps = await this.getSyncSteps();
    await this.SyncService.sync(steps);
  }

  protected async getSyncSteps(): Promise<SyncStep[]> {
    const employeeSync = await this.SyncService.syncPersonalVorBuchung.get();

    if (!employeeSync) return [];

    return [...(await this.SyncService.getEmployeeSyncSteps())];
  }

  //#endregion

  //#region Buchen

  async onSubmit() {
    const bookings = await this.extractBookingObject();

    await this.BuchungService.saveBookings(bookings);
  }

  protected async extractBookingObject(): Promise<TempBuchung[]> {
    const employeeSelection: OneOrMany<Employee> = this.formGroup.value.mitarbeiter;
    let employees = [];
    if (employeeSelection && Array.isArray(employeeSelection)) {
      employees = employeeSelection as Employee[];
      await this.Employees.recentlyBookedEmployees.set(employees.map(e => e.Pnr));
    } else if (employeeSelection && typeof employeeSelection === 'object') employees = [employeeSelection as Employee];

    const defaultBuchung = this.getDefaultBuchung();
    const buchungFromForm = this.readBuchungFromForm();
    await this.setLastAuftragBookingInLocalSettings(employees, buchungFromForm);
    const commentFields = this.extractComments();
    const tempBuchungData: TempBuchung = {
      ...this.partOfComplete(defaultBuchung),
      ...this.partOfComplete(buchungFromForm),
      ...this.partOfComplete(commentFields),
      ...this.partOfComplete(await this.getLocationData()),
      ...this.partOfComplete(this.getTimeData()),
      Pnr: 'placeholder',
    };

    const result = employees.map(employee => ({
      ...tempBuchungData,
      Pnr: employee.Pnr,
    }));
    return result;
  }

  /** Impliziert welche Felder von TempBuchung gesetzt sind um mehrere Partials typsicher kombinieren zu können */
  partOfComplete = <T extends Partial<TempBuchung>>(t: T) => t;

  protected readBuchungFromForm() {
    return {
      Auftrag: '',
      Kunde: '',
      Taetigkeit: '',
      TaetigkeitBez: '',
      Grund: '',
    };
  }

  protected getDefaultBuchung() {
    return {
      Art: '0',
      Kennzeichen: '',
      Taetigkeitspaket: '',
    };
  }

  protected extractComments(): { Kommentar1: string; Kommentar2: string; Kommentar3: string } {
    const completeComment = this.formGroup.value.kommentar as string;
    return {
      Kommentar1: completeComment?.substring(0, 249) || '',
      Kommentar2: completeComment?.substring(250, 499) || '',
      Kommentar3: completeComment?.substring(500) || '',
    };
  }

  protected getTimeData() {
    const now = new Date();
    const date = BookingTime.DateAsDatestring(now);
    const result = {
      Tag: date,
      Stunden: now.getHours(),
      Minuten: now.getMinutes(),
      Sekunden: now.getSeconds(),
    };
    return result;
  }

  protected async getLocationData() {
    const gpsPos = await this.LocationService.getLatitudeAndLongitude();
    return {
      Radius: 1000, // default
      Longitude: gpsPos.longitude,
      Latitude: gpsPos.latitude,
    };
  }

  //#endregion

  //#region UI

  /**
   * @param formControlArray - Array of FormControls
   * e.g. [FormControl, FormControl, ...]
   * @description Returns 'true' if a value inside of a FormControl is null or undefined
   */
  protected checkFormControlsNullOrUndefinded$(formControls: AbstractControl[]): Observable<boolean> {
    return this.formGroup.valueChanges.pipe(
      map(_ => {
        for (const formControl of formControls) {
          if (!formControl.value) return true;
        }
        return false;
      })
    );
  }

  readonly disableSubmit$ = this.formGroup.statusChanges.pipe(
    map(status => status as string),
    startWith<string>(this.formGroup.status),
    map(status => !['VALID', 'DISABLED'].includes(status)),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly submitButtonAction$: Observable<ModalAction> = this.disableSubmit$.pipe(
    map<boolean, ModalAction>(disable => ({
      Type: ModalActionType.OK,
      Disabled: disable,
      Execute: () => this.bottomSheet.dismiss(),
    }))
  );

  async setLastAuftragBookingInLocalSettings(employees, booking) {
    if (booking.Auftrag) {
      const pnrs: string[] = [];
      employees.map(employee => pnrs.push(employee['Pnr']));
      const employeePromise = this.Settings.lastAuftragBookingTeam.set(pnrs);
      const auftragPromise = this.Settings.lastAuftrag.set(booking.Auftrag);
      const taetigkeitPromise = this.Settings.lastTaetigkeit.set([booking.Taetigkeit, booking.TaetigkeitBez]);
      const customerPromise = this.Settings.lastCustomer.set(booking.Kunde);

      await Promise.all([employeePromise, auftragPromise, taetigkeitPromise, customerPromise]);
    }
  }

  //#endregion
}
