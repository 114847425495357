import { Injectable, Injector } from '@angular/core';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { isNullOrUndefined } from '@app/utils/utils';
import { Taetigkeit } from '@entities/Taetigkeit';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { ControllerService } from '../../../globalServices/controller.service';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';

@Injectable({
  providedIn: 'root',
})
export class TaetigkeitService extends StoreControllerServiceBase<Taetigkeit> {
  //#region StoreControllerServiceBase

  protected GetStoreName(): string {
    return Taetigkeit.StoreName;
  }

  async Sync(): Promise<string[]> {
    await combineLatest([this.session.pnr$, this.globalSettings.syncTaetigkeitenAmount.value$])
      .pipe(
        first(),
        switchMap(([pnr, amount]) =>
          this.controllerservice.reloadStore(this.GetStoreName(), `GetTaetigkeitenForPnr/PNR/${pnr}/AMOUNT/${amount}`)
        )
      )
      .toPromise();

    return [this.GetStoreName()];
  }

  protected entityConstructor(element) {
    return new Taetigkeit(element);
  }

  //#endregion

  constructor(
    private readonly controllerservice: ControllerService,
    protected readonly injector: Injector,
    private readonly session: UserSessionService,
    private readonly backend: ZEFBackendService,
    private readonly globalSettings: GlobalSettingService
  ) {
    super(injector);
  }

  /**@description Nimmt die Taetigkeitssnummer und gibt den Text dazu zurück */
  getTaetigkeitDescription(taetigkeitsId: string): Observable<string> {
    return this.firstInStoreData$(taet => taet.getNummer() === taetigkeitsId).pipe(
      map(taet => (!taet ? '' : taet.getBeschreibung() ? taet.getBeschreibung() : taetigkeitsId))
    );
  }

  async getTaetigkeitFromAuftrag(sAuftragsNr: string): Promise<Taetigkeit[]> {
    const taetigkeiten = await this.globalSettings.syncTaetigkeitenAmount.value$
      .pipe(
        first(),
        switchMap(amount =>
          this.backend.post<Taetigkeit[]>(`GetTaetigkeitenForAuftrag`, {
            sAuftragNr: sAuftragsNr,
            sAmount: amount,
          })
        ),
        catchError(_ => {
          return [];
        })
      )
      .toPromise();

    return taetigkeiten?.map(t => new Taetigkeit(t)) ?? [];
  }

  getTaetigkeitFromAuftragById(sAuftragNr: string, sTaetigkeitNr: string): Observable<string> {
    return from(this.getTaetigkeitFromAuftrag(sAuftragNr)).pipe(
      map(taetigkeiten => taetigkeiten.find(t => t.getNummer() == sTaetigkeitNr)?.getBeschreibung()),
      switchMap(description => combineLatest([of(description), this.globalSettings.lastTaetigkeit.value$])),
      map(([description, lastTaetigkeit]) => (!isNullOrUndefined(description) ? description : lastTaetigkeit[1]))
    );
  }
}
