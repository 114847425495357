/**
 * @description Konvertiert einen Blob zu einem base64-String
 */
export function convertBlobtoBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const dataUrl = <string>reader.result;
      resolve(dataUrl);
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
}

/**
 * @description Verändert die Größe eines Bildes anhand der mitgebenen Parameter
 * @param file - Das Image, welches verkleinert/vergrößert werden soll
 * @param maxWidth - Die maximale Breite, die das Bild haben darf
 * @param maxHeight - Die maximale Höhe, die das Bild haben darf
 */
export function resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const width = image.width;
      const height = image.height;

      if (width <= maxWidth && height <= maxHeight) {
        resolve(file);
      }

      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }

      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas.getContext('2d');

      context.drawImage(image, 0, 0, newWidth, newHeight);

      canvas.toBlob(resolve, file.type);
    };
    image.onerror = reject;
  });
}
