<div class="maincontainer">
  <div class="titel">{{ antragForm.controls['art'].value }}</div>
  <br />
  <div class="dates">{{ getDateRange$ | async }}</div>

  <form [formGroup]="antragForm">
    <div class="fullWidth statusSelector">
      <app-status-selector
        [label]="'Antragsart'"
        [options]="antragsArtenForSelecting"
        (selectionChange)="setAntragArt($event)"
      ></app-status-selector>
    </div>

    <mat-form-field class="fullWidth">
      <label *ngIf="!(isZeitkorrektur$ | async)">Von: </label>
      <label *ngIf="isZeitkorrektur$ | async">Datum: </label>
      <input class="dateInput" formControlName="vonDate" matInput [matDatepicker]="pickerVon" />
      <mat-datepicker-toggle matSuffix [for]="pickerVon"></mat-datepicker-toggle>
      <mat-datepicker #pickerVon></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="fullWidth" *ngIf="!(isZeitkorrektur$ | async)">
      <label>Bis: </label>
      <input class="dateInput" formControlName="bisDate" matInput [matDatepicker]="pickerBis" />
      <mat-datepicker-toggle matSuffix [for]="pickerBis"></mat-datepicker-toggle>
      <mat-datepicker #pickerBis></mat-datepicker>
    </mat-form-field>

    <app-time-picker-field [hidden]="!(isZeitkorrektur$ | async)" [label]="'Von'" formControlName="vonTime">
    </app-time-picker-field>

    <mat-error *ngIf="!(isValidTimeRange$ | async)">
      Die <u>Von-Zeit</u> darf nicht auf die <u>Bis-Zeit</u> folgen!
    </mat-error>

    <app-time-picker-field [hidden]="!(isZeitkorrektur$ | async)" [label]="'Bis'" formControlName="bisTime">
    </app-time-picker-field>

    <mat-form-field class="fullWidth">
      <label class="comment">Kommentar: </label>
      <textarea matInput maxlength="90" [placeholder]="" formControlName="kommentar"></textarea>
    </mat-form-field>
  </form>

  <app-bottom-sheet-button [Action]="SaveButtonAction$ | async" [ButtonStyle]="SaveButton"
    >send</app-bottom-sheet-button
  >
  <app-bottom-sheet-button [Action]="closeButtonAction" [ButtonStyle]="closeButtonStyle"
    >cancel</app-bottom-sheet-button
  >
</div>
