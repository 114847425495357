import { Injectable } from '@angular/core';
import * as CryptoJs from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  constructor() {}

  private encryptPasswort(password: string) {
    const hash = CryptoJs.PBKDF2(password, CryptoJs.enc.Utf8.parse('bssblue123'), {
      iterations: 100,
      keySize: 16,
      hasher: CryptoJs.algo.SHA256,
    });
    return hash.toString(CryptoJs.enc.Base64);
  }

  /**@returns [hash, salt]*/
  public hashUnsalted(payload: string): [string, string] {
    const salt = CryptoJs.lib.WordArray.random(128 / 8).toString(CryptoJs.enc.Base64);
    console.log('generated salt', salt);
    return [this.hash(payload, salt), salt];
  }

  public hash(payload: string, salt: string, algo: 'SHA256' | 'SHA512' = 'SHA256'): string {
    let hash = null;
    if (algo === 'SHA256') hash = CryptoJs.HmacSHA256(payload, salt);
    if (algo === 'SHA512') hash = CryptoJs.HmacSHA512(payload, salt);
    if (hash === null) {
      console.error(algo, hash);
      throw new Error('unknown crypto algo');
    }

    return CryptoJs.enc.Base64.stringify(hash);
  }

  public EncodeBase64(payload: string): string {
    let encodedWord = CryptoJs.enc.Utf8.parse(payload);
    let encoded = CryptoJs.enc.Base64.stringify(encodedWord);
    return encoded;
  }

  public DecodeBase64(encrypted: string): string {
    let decodedWord = CryptoJs.enc.Base64.parse(encrypted);
    let decoded = CryptoJs.enc.Utf8.stringify(decodedWord);
    return decoded;
  }
}
