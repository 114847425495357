import { Injectable } from '@angular/core';
import { DialogService } from '@app/globalServices/dialog.service';
import { LocationService } from '@app/globalServices/location.service';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { SyncService } from '@app/globalServices/sync.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { BookingTime, TempBuchung } from '@entities/BookingTime';
import { BookingTimeFrame } from '@entities/BookingTimeFrame';
import { BuchungTempService } from '@pages/TabGroup/shared/ZEFBuchung_Temp.service';
import { StempelungService } from '@pages/TabGroup/shared/ZEFStempelung.service';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';

export interface OpenBookingFrame {
  PNR: string;
  Date: Date;
  Kennzeichen: string;
  Grund: string;
  Auftrag: string;
  Taetigkeit: string;
  Art: string;
}

export const BookingFrameToOBF = (stemp: BookingTimeFrame): OpenBookingFrame => {
  if (!stemp) return null;

  return {
    PNR: stemp.Pnr,
    Date: stemp.toDate(),
    Kennzeichen: stemp.Kennzeichen,
    Grund: stemp.Grund,
    Auftrag: stemp.AuftragsNummer,
    Taetigkeit: stemp.TaetigkeitsNummer,
    Art: stemp.EndStunden === -1 ? '0' : '1',
  };
};

export const BookingToOBF = (booking: BookingTime): OpenBookingFrame => {
  if (!booking) return null;

  return {
    PNR: booking.getPnr(),
    Date: booking.toDate(),
    Kennzeichen: booking.getKennzeichen(),
    Grund: booking.getGrund(),
    Auftrag: booking.getAuftrag(),
    Taetigkeit: booking.getTaetigkeit(),
    Art: booking.getArt(),
  };
};

export interface ArbeitsplanBookingParameter {
  auftragNummer: string;
  taetigkeitNummer: string;
}

@Injectable({ providedIn: 'root' })
export class BuchungService {
  constructor(
    private readonly locationService: LocationService,
    private readonly bookingsFrameStore: StempelungService,
    private readonly bookingStore: BuchungTempService,
    private readonly settingsService: GlobalSettingService,
    private readonly syncService: SyncService,
    private readonly userSessionService: UserSessionService,
    private readonly dialogService: DialogService
  ) {}

  private readonly selectedPnr = new BehaviorSubject<string>(null);

  readonly pnr$ = merge(this.userSessionService.pnr$, this.selectedPnr.asObservable()).pipe(
    filter(pnr => pnr !== null),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private mostRecentBookingFrameForPnr$(pnrObs: Observable<string>): Observable<OpenBookingFrame> {
    return pnrObs.pipe(
      switchMap(pnr => this.bookingsFrameStore.mostRecentForPnr$(pnr)),
      map(booking => BookingFrameToOBF(booking))
    );
  }

  private readonly mostRecentBooking$: Observable<OpenBookingFrame> = this.bookingStore.latestBooking$.pipe(
    // taplog('bookingStore.latestBooking$'),
    map(booking => BookingToOBF(booking))
    // taplog('lastBooking asdf $')
  );

  mostRecentOpenBookingForPnr$(pnr: Observable<string>): Observable<OpenBookingFrame> {
    return combineLatest([this.mostRecentBooking$, this.mostRecentBookingFrameForPnr$(pnr)]).pipe(
      map(([booking, bookingFrame]) =>
        this.compareOpenBookingFrame(booking, bookingFrame) < 0 ? bookingFrame : booking
      ),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  readonly mostRecentOpenBooking$: Observable<OpenBookingFrame> = this.mostRecentOpenBookingForPnr$(this.pnr$);

  private compareOpenBookingFrame(bookingA: OpenBookingFrame, bookingB: OpenBookingFrame): number {
    if (!bookingA) {
      if (!bookingB) return 0;
      return -1;
    }
    if (!bookingB) return 1;

    return bookingA.Date.valueOf() - bookingB.Date.valueOf() < 0 ? -1 : 1;
  }

  setPnr(pnr: string): void {
    this.selectedPnr.next(pnr);
  }

  async bookArbeitsPlan(param: ArbeitsplanBookingParameter): Promise<BookingTime[]> {
    const pnrs = [(await this.userSessionService.loginDetails.get()).id];

    const now = new Date();
    const date = BookingTime.DateAsDatestring(now);

    const gpsPos = await this.locationService.getLatitudeAndLongitude();

    const bookings: BookingTime[] = pnrs.map(pnr => {
      const result: TempBuchung = {
        Pnr: pnr,
        Tag: date,
        Stunden: now.getHours(),
        Minuten: now.getMinutes(),
        Sekunden: now.getSeconds(),
        Art: '0',
        Auftrag: param.auftragNummer,
        Taetigkeit: param.taetigkeitNummer,
        Kunde: '',
        Grund: '',
        Kommentar1: '',
        Kennzeichen: '',
        Taetigkeitspaket: '',
        Kommentar2: '',
        Kommentar3: '',
        Radius: 1000, // default
        Longitude: gpsPos.longitude,
        Latitude: gpsPos.latitude,
      };

      return new BookingTime(result);
    });

    await this.saveBookings(bookings);

    return bookings;
  }

  /**@description Saves Tempbuchungen */
  async saveBookings(bookingList: TempBuchung[]): Promise<void> {
    const bookings = bookingList.map(buchung => new BookingTime(buchung));

    if (bookings.length < 1) return;

    const offLineDialog = async (): Promise<void> => {
      await this.dialogService.ShowInformation(
        'Offline',
        'Zeit wurde offline erfasst und wird bis zur Übertragung zwischengespeichert.'
      );
    };

    const syncAfterBooking = await this.settingsService.syncAfterBooking.get();
    if (syncAfterBooking === 'never') return;
    if (
      syncAfterBooking === 'query' &&
      !(await this.dialogService.QueryYesNo('Buchungen senden', 'Jetzt Zeiten übertragen?'))
    ) {
      await offLineDialog();
      return;
    }

    await this.bookingStore.saveBookings(bookings);

    await this.doSyncAfterBooking(offLineDialog);
  }

  /**@description Synchronizes after change in booking data*/
  async doSyncAfterBooking(offLineDialog: () => Promise<void>): Promise<void> {
    if (navigator.onLine) {
      const result = await this.syncService.syncBookings();

      if (result === 'offline') {
        await offLineDialog();
        return;
      }
      if (result === 'sent') {
        await this.dialogService.ShowInformation(
          'Offline',
          'Zeiten wurden übertragen, andere Zeitdaten konnten jedoch nicht aktualisiert werden.'
        );
        return;
      }
    } else {
      await offLineDialog();
    }
  }

  /**@description Deletes the booking, does a sync afterwards */
  async deleteBookingRoutine(selectedBooking: BookingTimeFrame, skipSync = false): Promise<boolean> {
    const success = await this.bookingStore.deleteBuchung(selectedBooking.Key);
    if (!success) {
      const syncOnError = await this.dialogService.QueryConfirmation(
        'Löschen fehlgeschlagen',
        'Das Löschen der ursprünglichen Buchung ist fehlgeschlagen. Synchronisieren Sie neu und versuchen Sie es erneut. Jetzt Synchronisieren?',
        'Synchronsieren',
        'Nicht Synchronsieren'
      );
      if (syncOnError) {
        await this.syncService.syncData();
      }
      return false;
    }
    if (skipSync) return true;
    const offLineDialog = async (): Promise<void> => {
      await this.dialogService.ShowInformation(
        'Offline',
        'Zeit wurde offline erfasst und wird bis zur Übertragung zwischengespeichert.'
      );
    };
    await this.doSyncAfterBooking(offLineDialog);
    return true;
  }

  /**@description Returns last values of an "Auftrag"-booking  */
  readonly lastBooking$ = combineLatest([
    this.settingsService.lastAuftragBookingTeam.value$,
    this.settingsService.lastAuftrag.value$,
    this.settingsService.lastTaetigkeit.value$,
    this.settingsService.lastCustomer.value$,
  ]).pipe(
    map(([lastAuftragBookingTeam, lastAuftrag, lastTaetigkeit, lastCustomer]) => {
      return {
        Employees: lastAuftragBookingTeam ? lastAuftragBookingTeam : [],
        Auftrag: lastAuftrag ? lastAuftrag : '',
        Taetigkeit: lastTaetigkeit ? lastTaetigkeit : '',
        Customer: lastCustomer ? lastCustomer : '',
      };
    })
  );

  isBetweenBookingTimeRange$ = combineLatest([
    this.mostRecentOpenBooking$,
    this.settingsService.maxBookingTime.value$,
  ]).pipe(
    map(([booking, maxTimeBetweenBookings]) => {
      const maxDate = new Date();
      maxDate.setTime(booking?.Date.getTime() + maxTimeBetweenBookings * 60 * 1000);

      const now = new Date().getTime();
      const maxTime = maxDate.getTime();
      return now <= maxTime;
    })
  );
}
