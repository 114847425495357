import { OnInit, Injector, Component } from '@angular/core';
import { ModalActionType, ModalQueryResult } from '@app/utils/types';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { BaseModalComponent } from './BaseModalComponent';

@Component({ template: '{{"BaseDialogComponent"}}' })
export abstract class BaseDialogComponent<TParameter, TResult>
  extends BaseModalComponent<TParameter, TResult>
  implements OnInit
{
  public static async Show<TParameter, TResult>(dialog: MatDialog, component, config?: MatDialogConfig<TParameter>) {
    const ref = dialog.open<
      ComponentType<BaseDialogComponent<TParameter, TResult>>,
      TParameter,
      ModalQueryResult<TResult>
    >(component, { disableClose: true, ...config });

    let result = await ref.afterClosed().toPromise();

    while (result.Type === ModalActionType.RECALL) result = await result.Recall();

    return result;
  }

  protected readonly DialogRef: MatDialogRef<BaseDialogComponent<TParameter, TResult>, ModalQueryResult<TResult>> =
    this.injector.get(MatDialogRef);

  protected readonly Dialog: MatDialog;
  readonly Parameter: TParameter;

  //#region LifeCycle

  constructor(protected readonly injector: Injector) {
    super(injector);
    this.Dialog = this.injector.get(MatDialog);
    this.Parameter = this.injector.get(MAT_DIALOG_DATA) as TParameter;
  }

  async ngOnInit() {
    await super.ngOnInit();
  }

  //#endregion

  protected Close(result: ModalQueryResult<TResult>) {
    this.DialogRef.close(result);
  }
}
