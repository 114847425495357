<div *ngIf="noMethodSelected$ | async" class="decisionContainer">
  <div class="logoContainer">
    <img (click)="toggleImageLogin()" class="logoImage" src="assets/images/loginLogo.svg" />
  </div>

  <div class="decisionButtons">
    <button
      *ngIf="lastUserLoginEnabled$ | async"
      (click)="this.selectLoginMethod('lastUser')"
      class="manuellEinloggenButton"
      mat-button
    >
      Letzten Nutzer anmelden
    </button>

    <button
      *ngIf="manualLoginEnabled$ | async"
      (click)="this.selectLoginMethod('manual')"
      class="manuellEinloggenButton"
      mat-button
    >
      Manuell anmelden
    </button>

    <button
      *ngIf="localhostLoginEnabled$ | async"
      (click)="this.selectLoginMethod('localhost')"
      class="manuellEinloggenButton"
      mat-button
    >
      lokal anmelden
    </button>

    <button
      *ngIf="lanLoginEnabled$ | async"
      (click)="this.selectLoginMethod('lan')"
      class="manuellEinloggenButton"
      mat-button
    >
      im Netzwerk suchen
    </button>

    <button *ngIf="demoEnabled" (click)="demoLogin()" class="manuellEinloggenButton" mat-button>Demo</button>

    <button *ngIf="installEnabled" (click)="triggerInstall()" class="manuellEinloggenButton" mat-button>
      Installieren
    </button>

    <button
      *ngIf="qrScanEnabled$ | async"
      (click)="this.selectLoginMethod('qrscan')"
      class="qrScanBtn"
      mat-raised-button
    >
      QR-Code scannen
    </button>

    <button
      *ngIf="lanLoginEnabled$ | async"
      (click)="this.selectLoginMethod('lan')"
      class="qrScanBtn"
      mat-raised-button
    >
      Im Netzwerk anmelden
    </button>
  </div>
</div>

<div *ngIf="formMethodSelected$ | async" class="loginContainer" id="manuellLoginContainer">
  <div class="userNameAndPassword">
    <mat-form-field class="userfield" *ngIf="showGuidField$ | async">
      <input
        #guidfield
        matInput
        placeholder="Verbindungs-ID"
        [(ngModel)]="guid"
        [disabled]="disableGuidField$ | async"
      />
    </mat-form-field>

    <mat-form-field class="userfield" *ngIf="showAddressField$ | async">
      <input #ipfield matInput placeholder="IP-Adresse" [(ngModel)]="ipaddress" />
    </mat-form-field>

    <mat-form-field class="userfield" *ngIf="showPortField$ | async">
      <input #portfield matInput placeholder="Port" [(ngModel)]="port" />
    </mat-form-field>

    <mat-form-field class="userfield" *ngIf="showMandantSelector$ | async">
      <input
        #mandantfield
        matInput
        placeholder="Mandant-Name"
        [(ngModel)]="mandant"
        [disabled]="disableMandantField$ | async"
      />
    </mat-form-field>

    <mat-form-field class="userfield" *ngIf="showPnrField$ | async">
      <input #userfield matInput placeholder="Personalnummer" [(ngModel)]="pnr" [disabled]="disablePnrField$ | async" />
    </mat-form-field>

    <mat-form-field class="passwordfield" *ngIf="showPwField$ | async">
      <input
        #passwordfield
        matInput
        placeholder="Passwort"
        [(ngModel)]="passwort"
        [type]="hidePasswort ? 'password' : 'text'"
        (keyup.enter)="parameterLogin()"
      />
      <mat-icon matSuffix (click)="hidePasswort = !hidePasswort">{{
        hidePasswort ? 'visibility_off' : 'visibility'
      }}</mat-icon>
    </mat-form-field>
  </div>

  <div class="decisionContainer2">
    <button class="loginButton" (click)="parameterLogin()" mat-button>Login</button>
    <button (click)="this.selectLoginMethod('none')" class="loginAbbrechenButton" mat-button>Abbrechen</button>
  </div>
</div>
