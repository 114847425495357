import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnInit, forwardRef, OnDestroy, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms';
import { BaseTextfieldComponent } from '@base-components/form-components/BaseTextfieldComponent';

@Component({
  selector: 'app-time-picker-field',
  templateUrl: './time-picker-field.component.html',
  styleUrls: ['./time-picker-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimePickerFieldComponent),
      multi: true,
    },
  ],
})
export class TimePickerFieldComponent
  extends BaseTextfieldComponent
  implements OnDestroy, ControlValueAccessor, Validator
{
  constructor() {
    super();
  }
  @Input() label = 'Uhrzeit';
  @Input() warnLabel = false;
  public text;

  _selectedTimeString: string;
  get selectedTimeString(): string {
    return this._selectedTimeString;
  }
  set selectedTimeString(value: string) {
    this._selectedTimeString = value;
    this.inputChange(value);
    this.timePickerControl.setValue(value);
  }

  readonly timePickerControl = new UntypedFormControl();

  private readonly textSubject = new BehaviorSubject<string>(undefined);
  protected getSelection$() {
    return this.textSubject.asObservable();
  }

  public disabled: boolean;
  private onChange: any = () => {};
  private onTouched: any = () => {};

  inputChange(value: string) {
    this.text = value;
    this.onChange(this.text);
    this.onTouched();
    this._selectedTimeString = value;
  }

  //#region ControlValueAccessor

  writeValue(value): void {
    this.text = value;
    this._selectedTimeString = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value() {
    return this.text;
  }

  set value(val) {
    if (val) {
      this.text = val;
      this.onChange(this.text);
    }
  }

  //#endregion

  //#region Validator

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {}

  //#endregion
}
