<div
  matRipple
  *ngIf="state"
  [style.background]="state.disabled ? '#d0d0d0' : state.bcolor"
  [style.color]="state.color ? state.color : 'white'"
  [ngClass]="state.disabled ? '' : 'shadowed-tile'"
  class="tile-layout"
  [ngClass]="state.disabled ? '' : 'pointer-cursor'"
  (click)="onClick()"
  (touchstart)="onTouchStart()"
  (touchend)="onTouchEnd()"
>
  <img class="tile-icon" [src]="state.iconSrc" />
  <h1 class="tile-text">{{ state.text }}</h1>
</div>
