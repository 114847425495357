import { Component, Injector, OnInit } from '@angular/core';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { AuftragDialogType, BookingType, ModalAction, ModalActionType } from '@app/utils/types';
import { BaseBottomsheetComponent } from '@base-components/BaseBottomsheetComponent';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { MitarbeiterService } from '@stores/ZEFMitarbeiter.service';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { TermConverterService } from 'src/app/globalServices/termConverter.service';
import { BuchungService } from '../../buchung/buchung.service';
import { AuftragEndeErfassenComponent } from '../auftrag-ende-erfassen/auftrag-ende-erfassen.component';
import {
  AuftragStartErfassenComponent,
  AuftragStartPreselectionParameter,
} from '../auftrag-start-erfassen/auftrag-start-erfassen.component';

@Component({
  selector: 'app-auftrag-menu',
  templateUrl: './auftrag-menu.component.html',
  styleUrls: ['./auftrag-menu.component.scss'],
})
export class AuftragMenuComponent extends BaseBottomsheetComponent<void, void> implements OnInit {
  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  protected readonly settingService = this.injector.get<GlobalSettingService>(GlobalSettingService);

  protected readonly buchungService = this.injector.get<BuchungService>(BuchungService);

  protected readonly termConverterService = this.injector.get<TermConverterService>(TermConverterService);

  protected readonly employeeService = this.injector.get<MitarbeiterService>(MitarbeiterService);

  readonly selectedPnrs$ = this.employeeService.currentUserSubordinates$.pipe(
    map(employees => employees.map(employee => employee.Pnr))
  );

  private readonly openTeamBookings$ = this.selectedPnrs$.pipe(
    switchMap(pnrs => combineLatest(pnrs.map(pnr => this.buchungService.mostRecentOpenBookingForPnr$(of(pnr))))),
    shareReplay(1)
  );

  private readonly openBookingForAuftragExists$ = this.openTeamBookings$.pipe(
    map(bookings =>
      bookings.some(booking => this.termConverterService.bookingToBookingType(booking) === BookingType.AUFTRAGSZEIT)
    )
  );

  private readonly employeeWithoutAuftragExists$ = this.openTeamBookings$.pipe(
    map(bookings =>
      bookings.some(booking => this.termConverterService.bookingToBookingType(booking) !== BookingType.AUFTRAGSZEIT)
    )
  );

  readonly isForeman$ = this.employeeService.currentUserRights$.pipe(
    map(employee => employee?.foreman),
    distinctUntilChanged()
  );

  readonly taetigkeitWechselns$ = this.openBookingForAuftragExists$;
  readonly auftragStarten$ = this.employeeWithoutAuftragExists$;
  readonly auftragWechseln$ = this.openBookingForAuftragExists$;
  readonly auftragBeenden$ = this.openBookingForAuftragExists$;

  getButtonStyle(type: string): ButtonStyle {
    return {
      color: 'white',
      backgroundColor: '#127ec6',
      icon: 'assets/icons/briefcase_document.png',
      description: type,
    };
  }

  readonly onTaetigkeitWechselnClick$: Observable<ModalAction> = this.buchungService.lastBooking$.pipe(
    map(lastBooking => {
      const data: AuftragStartPreselectionParameter = {
        Pnrs: lastBooking?.Employees ? lastBooking.Employees : [],
        AufNr: lastBooking?.Auftrag ? lastBooking.Auftrag : '',
        TaetNr: lastBooking?.Taetigkeit[0] ? lastBooking.Taetigkeit[0] : '',
        CustomerNr: lastBooking?.Customer ? lastBooking.Customer : '',
        AuftragDialogType: AuftragDialogType.TaetigkeitWechseln,
      };

      return {
        Type: ModalActionType.RECALL,
        Disabled: false,
        Execute: () => AuftragStartErfassenComponent.ShowWith(this.bottomSheet, data),
      };
    })
  );

  readonly onAuftragStartClick$: Observable<ModalAction> = this.buchungService.lastBooking$.pipe(
    map(lastBooking => {
      const data: AuftragStartPreselectionParameter = {
        Pnrs: [],
        AufNr: lastBooking?.Auftrag ? lastBooking.Auftrag : '',
        TaetNr: '',
        CustomerNr: '',
        AuftragDialogType: AuftragDialogType.AuftragStarten,
      };

      return {
        Type: ModalActionType.RECALL,
        Disabled: false,
        Execute: () => AuftragStartErfassenComponent.ShowWith(this.bottomSheet, data),
      };
    })
  );

  readonly onAuftragWechselnClick$: Observable<ModalAction> = this.buchungService.lastBooking$.pipe(
    map(lastBooking => {
      const data: AuftragStartPreselectionParameter = {
        Pnrs: lastBooking?.Employees ? lastBooking.Employees : [],
        AufNr: lastBooking?.Auftrag ? lastBooking.Auftrag : '',
        TaetNr: lastBooking?.Taetigkeit[0] ? lastBooking.Taetigkeit[0] : '',
        CustomerNr: lastBooking?.Customer ? lastBooking.Customer : '',
        AuftragDialogType: AuftragDialogType.AuftragWechseln,
      };

      return {
        Type: ModalActionType.RECALL,
        Disabled: false,
        Execute: () => AuftragStartErfassenComponent.ShowWith(this.bottomSheet, data),
      };
    })
  );

  onAuftragBeendenClick() {
    return {
      Type: ModalActionType.RECALL,
      Disabled: false,
      Execute: () => this.bottomSheet.open(AuftragEndeErfassenComponent),
    };
  }
}
