import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomainError } from '@app/utils/ErrorHandling/DomainError';
import { BaseError, CodedError, HttpConnectionError } from '@app/utils/ErrorHandling/Error';
import { ModalActionType, ModalQueryResult, ValidationResult } from '@app/utils/types';
import { DialogData, GenericDialogComponent } from '@components/global/dialog/dialog.component';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorAsFalse } from '../utils/rxjsUtils';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  /**@description typisierter Standardzugriff für  Material und DialogComponent*/
  private openDialog(dlgData: DialogData): MatDialogRef<GenericDialogComponent, ModalQueryResult<void>> {
    return this.dialog.open<GenericDialogComponent, DialogData, ModalQueryResult<void>>(GenericDialogComponent, {
      disableClose: true,
      data: dlgData,
    });
  }

  /**@description Ja / Nein Abfragedialog */
  public async QueryYesNo(
    title?: string,
    question?: string,
    yesButtonText?: string,
    noButtonText?: string
  ): Promise<boolean> {
    const data: DialogData = {
      title: title || 'Abfrage',
      description: question || '',
      actions: [
        { buttonText: yesButtonText || 'Ja', buttonType: ModalActionType.YES },
        { buttonText: noButtonText || 'Nein', buttonType: ModalActionType.NO },
      ],
    };
    const ref = this.openDialog(data);
    const action = await ref.afterClosed().toPromise();

    return action.Type === ModalActionType.YES;
  }

  public async QueryConfirmation(
    title?: string,
    question?: string,
    yesButtonText?: string,
    cancelButtonText?: string
  ): Promise<boolean> {
    const data: DialogData = {
      title: title || 'Bestätigen',
      description: question || '',
      actions: [
        {
          buttonText: yesButtonText || 'Bestätigen',
          buttonType: ModalActionType.YES,
        },
        {
          buttonText: cancelButtonText || 'Abbrechen',
          buttonType: ModalActionType.CANCEL,
        },
      ],
    };
    const ref = this.openDialog(data);
    return (await ref.afterClosed().toPromise())?.Type === ModalActionType.YES;
  }

  public ShowError(error: Error): Promise<void> {
    let errorMessage = error.message;
    if ((error as HttpConnectionError).response) {
      const httpError = error as HttpConnectionError;
      const endpoint = httpError.response.url.split('Service.svc/')[1];
      errorMessage = '[' + endpoint + ']\n' + errorMessage;
    }
    return this.ShowInformation(error.name, errorMessage);
  }

  public async ShowInformation(title?: string, message?: string, buttonTxt?: string): Promise<void> {
    const data: DialogData = {
      title: title || 'Error',
      description: message || 'Ein Fehler ist aufgetreten.',
      actions: [{ buttonText: buttonTxt || 'Ok', buttonType: ModalActionType.OK }],
    };

    const ref = this.openDialog(data);
    await ref.afterClosed().toPromise();
  }

  public async QuerySaveChanges(title?: string, question?: string): Promise<ModalActionType> {
    const data: DialogData = {
      title: title || 'Änderungen speichern?',
      description: question || 'Es sind noch ungespeicherte Änderungen vorhanden.',
      actions: [
        { buttonText: 'Speichern', buttonType: ModalActionType.YES },
        { buttonText: 'Abbrechen', buttonType: ModalActionType.CANCEL },
        { buttonText: 'Verwerfen', buttonType: ModalActionType.NO },
      ],
    };

    const ref = this.openDialog(data);
    return (await ref.afterClosed().toPromise())?.Type;
  }

  public ShowSpinner(
    title?: string | Observable<string>,
    description?: string | Observable<string>,
    progress$?: Observable<number>
  ): () => void {
    const data: DialogData = {
      title: 'Vorgang läuft',
      showSpinner: true,
      progress$: progress$,
    };

    if (typeof description === 'string') data.description = description;
    else data.description$ = description;

    if (typeof title === 'string') data.title = title;
    else data.title$ = title;

    const ref = this.openDialog(data);

    return () => ref.close();
  }

  async ShowSpinnerWhile<T>(promise: Promise<T>, title?: string, description?: string): Promise<T> {
    const close = this.ShowSpinner(title, description);
    try {
      return await promise;
    } finally {
      close();
    }
  }

  async ShowValidationResult(validation: ValidationResult): Promise<void> {
    if (!Array.isArray(validation)) return;
    const message = validation.map(error => error.message).reduce((a, b) => `${a}\n${b}`);
    await this.ShowInformation('Formatfehler', message);
  }

  /**@description Zeigt Dialog mit aufkommendem Fehler */
  ShowOnError<T>(): (src: Observable<T>) => Observable<T> {
    return src =>
      src.pipe(
        catchError(err => {
          console.log('Showing error', err);

          return this.ShowError(err).then(_ => {
            console.log('done showing error', err);

            throw err;
          });
        })
      );
  }

  /**@description Zeigt bei Fehler Dialog mit Fehler und mapt ihn als false weiter */
  HandleDefaultErrors(): OperatorFunction<boolean, boolean> {
    return src => src.pipe(this.ShowOnError(), errorAsFalse);
  }

  ErrorHandler<TError extends BaseError, T>(
    errorType,
    errorFilter: (err: TError) => boolean,
    title?: string,
    text?: string,
    errorMap?: (err: TError) => T
  ): OperatorFunction<T, T> {
    const safeErrorMap = errorMap || (err => null);
    return src =>
      src.pipe(
        catchError((err, caught) => {
          if (err instanceof errorType && errorFilter(err)) {
            if (!!title) return this.ShowInformation(title, text).then(_ => safeErrorMap(err));
            else return this.ShowError(err).then(_ => safeErrorMap(err));
          }
          return throwError(err);
        })
      );
  }

  /**
   * @description erstellt Handler-Operator, der mit Nummerncode versehene Errors(CodedErrors) mit angegebenem Code catcht, anzeigt und in T (zb false/bool als failstate) wandelt
   *  */
  CodedErrorHandler<TError extends CodedError, T>(
    errorType,
    code: number,
    errorMap: (err: TError) => T,
    title?: string,
    text?: string
  ): OperatorFunction<T, T> {
    return src =>
      src.pipe(this.ErrorHandler<TError, T>(errorType, err => err.ErrorCode === code, title, text, errorMap));
  }

  /**
   * @description erstellt Handler-Operator, der DomainErrors catcht, anzeigt und ggf in T (zb false/boolean als failstate) wandelt
   *  */
  readonly DomainErrorHandler = <T>(
    code: number,
    title?: string,
    text?: string,
    errorMap?: (err: DomainError) => T,
    detailCode: number = 0
  ): OperatorFunction<T, T> => {
    return src =>
      src.pipe(
        this.ErrorHandler<DomainError, T>(
          DomainError,
          err =>
            err instanceof DomainError &&
            (err as DomainError).ErrorCode === code &&
            (err as DomainError).DetailCode === detailCode,
          title,
          text,
          errorMap
        )
      );
  };
}
