import { Injector } from '@angular/core';
import { switchMap, map, shareReplay, first, last, tap } from 'rxjs/operators';
import { ControllerService } from '../../../globalServices/controller.service';
import { Observable } from 'rxjs';
import { DatabaseService } from '@app/globalServices/database.service';
import { taplog } from '@app/utils/rxjsUtils';
import { query, QueryOutput } from 'rx-query';
import { ObservableQuery, mapToQuery } from '@app/utils/rxQueryUtils';

export interface ISyncable {
  Sync(emit?: boolean): Promise<string[]>;
}

export abstract class StoreControllerServiceBase<TStoreEntity> implements ISyncable {
  //#region abstract

  /**@description synchronises storedata and returns names of updated stores */
  public abstract Sync(emit?: boolean): Promise<string[]>;

  protected abstract GetStoreName(): string;

  //#endregion

  constructor(protected readonly injector: Injector) {}

  protected readonly CentralController = this.injector.get<ControllerService>(ControllerService);
  protected readonly DB = this.injector.get<DatabaseService>(DatabaseService);

  /**@description names of stores (*this*) is depending on */
  protected GetObservedStoreNames(): string[] {
    return [];
  }

  protected readonly refresh$ = this.getRefresh();

  /**@description *overrideable* refresh-event for complete reload */
  protected getRefresh(): Observable<string[]> {
    return this.CentralController.observeStore$([this.GetStoreName(), ...this.GetObservedStoreNames()]);
  }

  /**@description *overridable* constructor-reference for TStoreEntity
   * used to construct entity from raw database-*object*  */
  protected entityConstructor(row): TStoreEntity {
    return row as TStoreEntity; // pls override
  }

  protected async LoadData(): Promise<TStoreEntity[]> {
    const rawData = await this.DB.getData(this.GetStoreName());
    return rawData.map(row => this.entityConstructor(row));
  }

  /** @description complete store-content cached in-memory(!) when used
   * >> use .filteredStoreData$(..) for larger stores!!
   */
  public readonly storeData$ = this.refresh$.pipe(
    switchMap(_ => this.LoadData()),
    shareReplay(1)
  );

  public async getStoreData(): Promise<TStoreEntity[]> {
    return await this.LoadData();
  }

  /**@description Findfirst in InMemory-Storedata
   * Usage Trigger InMemory-Storage in storeData$
   */
  firstInStoreData$(where: (entity: TStoreEntity) => boolean): Observable<TStoreEntity> {
    return this.storeData$.pipe(map(output => output?.find(entity => where(entity))));
  }

  /**@description Observable Query on IDB-Index */
  filteredStoreData$(keyValue, keyName: string): Observable<TStoreEntity[]> {
    return this.CentralController.filteredObservation$(this.GetStoreName(), keyValue, keyName).pipe(
      map(entities => entities.map(entityData => this.entityConstructor(entityData)))
    );
  }

  /**@description Returns the first value of the filtered StoreData */
  firstInFilteredStoreData$(keyValue, keyName: string): Observable<TStoreEntity> {
    return this.filteredStoreData$(keyValue, keyName).pipe(map(filteredStoreData => filteredStoreData.pop()));
  }
}
