import { Injectable, Injector } from '@angular/core';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { Employee } from '@entities/Employee';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ControllerService } from '../../../globalServices/controller.service';
import { StoreControllerServiceBase } from './StoreControllerServiceBase';

@Injectable({
  providedIn: 'root',
})
export class MitarbeiterService extends StoreControllerServiceBase<Employee> {
  //#region StoreControllerServiceBase

  protected GetStoreName(): string {
    return Employee.StoreName;
  }

  protected entityConstructor(element): Employee {
    return new Employee(element);
  }

  public async Sync(emit?: boolean): Promise<string[]> {
    const personal = await this.backendService.post<Employee[]>('V2/PersonalPwa').toPromise();
    if (personal && personal.length > 0) {
      await this.controllerservice.resetStore(this.GetStoreName(), personal, emit).toPromise();
      this.controllerRefresh.next(null);
      return [this.GetStoreName()];
    }
    return [];
  }

  //#endregion

  constructor(
    protected readonly injector: Injector,
    private readonly controllerservice: ControllerService,
    private readonly sessionService: UserSessionService,
    private readonly backendService: ZEFBackendService,
    private readonly globalService: GlobalSettingService
  ) {
    super(injector);
  }

  readonly recentlyBookedEmployees = this.globalService.createSetting<string[]>('recentlyBookedEmployees');

  /**@description Refresh-Event outside of Store-Refresh for refresh between multiple Store-Sync-Steps */
  private controllerRefresh: BehaviorSubject<void>;

  private getControllerRefresh(): BehaviorSubject<void> {
    if (!this.controllerRefresh) this.controllerRefresh = new BehaviorSubject<void>(null);
    return this.controllerRefresh;
  }

  protected getRefresh(): Observable<string[]> {
    return this.getControllerRefresh().pipe(switchMap(_ => super.getRefresh()));
  }

  employee$(pnr: string): Observable<Employee> {
    return this.firstInStoreData$(employee => employee && employee.Pnr === pnr).pipe(
      tap(employee => {
        if (employee) return;
        console.error('Employee unknown', pnr);
      })
    );
  }

  readonly currentUser$ = this.sessionService.pnr$.pipe(switchMap(pnr => this.employee$(pnr)));

  subordinates$(pnr: string): Observable<Employee[]> {
    return this.employee$(pnr).pipe(
      map(user => user?.Pnrs),
      switchMap(pnrs => combineLatest(pnrs?.map(subordinatePnr => this.employee$(subordinatePnr)))),
      map(employees => employees.filter(e => !!e))
    );
  }

  readonly currentUserSubordinates$ = this.sessionService.pnr$.pipe(
    switchMap(pnr => this.subordinates$(pnr)),
    shareReplay(1)
  );

  readonly currentUserRights$ = this.currentUser$.pipe(map(employee => employee?.getRightsObject()));
}
