import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpConnectionError } from '@app/utils/ErrorHandling/Error';

export interface HttpRequest {
  url: string;
  reqInit?: RequestInit;
}

/**@description  */
@Injectable()
export abstract class HttpClientWrapper {
  abstract Get<TType>(request: HttpRequest): Observable<TType>;
}

@Injectable()
export class FetchHttpClientWrapper extends HttpClientWrapper {
  constructor() {
    super();
  }

  Get<TType>(request: HttpRequest): Observable<TType> {
    return from(fetch(request.url, request.reqInit).then(async response => await this.handleResponse<TType>(response)));
  }

  private async handleResponse<TType>(response: Response): Promise<TType> {
    if (response.ok) {
      try {
        return await response.json();
      } catch (error) {
        return null;
      }
    }
    let body;
    try {
      if (response.body !== null) body = await response.json();
      else console.log('error without body');
    } catch (error) {
      console.error(error);
    }
    throw new HttpConnectionError('Verbindungsfehler', response, body);
  }
}

export const HttpClientWrapperProvider = { provide: HttpClientWrapper, useClass: FetchHttpClientWrapper };
