import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TimeService } from '@app/globalServices/time.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';

import { ModalAction, ModalActionType } from '@app/utils/types';
import { getDateWithoutTime } from '@app/utils/utils';
import { BaseBottomsheetComponent } from '@base-components/BaseBottomsheetComponent';
import { ButtonStyle } from '@components/reuseable/bottom-sheet-button/ButtonStyle';
import { TitleStyle } from '@components/reuseable/bottom-sheet-frame/TitleStyle';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuBescheinigungBildErfassenComponent } from '../au-bescheinigung-bild-erfassen/au-bescheinigung-bild-erfassen.component';

export interface AuBescheinigungDatenErfassenParameter {
  mitarbeiter: string;
  vonDatum: string;
  bisDatum: string;
  notiz: string;
}

const DateFrameValidator: ValidatorFn = (formGroup: UntypedFormGroup) => {
  const von = formGroup.get('vonDatum').value;
  const bis = formGroup.get('bisDatum').value;
  if (!von || !bis) {
    return null;
  }
  return { wrongDateFrame: getDateWithoutTime(von).valueOf() <= getDateWithoutTime(bis).valueOf() };
};

@Component({
  selector: 'app-au-bescheinigung-daten-erfassen',
  templateUrl: './au-bescheinigung-daten-erfassen.component.html',
  styleUrls: ['./au-bescheinigung-daten-erfassen.component.scss'],
})
export class AuBescheinigungDatenErfassenComponent extends BaseBottomsheetComponent<void, void> implements OnInit {
  constructor(
    protected readonly injector: Injector,
    protected readonly timeService: TimeService,
    protected readonly userSessionService: UserSessionService
  ) {
    super(injector);
  }

  async ngOnInit() {
    await super.ngOnInit();

    this.formGroup.patchValue({
      vonDatum: new Date(),
      bisDatum: new Date(),
    });
  }

  readonly TitleStyle: TitleStyle = {
    name: 'AU-Bescheinigung',
    color: 'black',
    fontweight: 'bold',
  };

  readonly ContinueButton: ButtonStyle = {
    color: 'white',
    backgroundColor: '#004080',
    icon: 'assets/icons/navigate_right.png',
    description: 'Weiter',
  };

  private readonly formBuilder = this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
  readonly formGroup = this.createFormGroup(this.formBuilder);

  readonly checkDateFrameError$ = this.formGroup.valueChanges.pipe(
    map(_ => {
      return this.formGroup.errors.wrongDateFrame;
    })
  );

  readonly ContinueButtonAction$: Observable<ModalAction> = this.checkDateFrameError$.pipe(
    map(errorState => {
      if (this.formGroup.errors) {
        return {
          Type: ModalActionType.RECALL,
          Disabled: !errorState,
          Execute: () => this.submitData(),
        };
      }
    })
  );

  protected createFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return formBuilder.group(
      {
        vonDatum: ['', Validators.required],
        bisDatum: ['', Validators.required],
        notiz: '',
      },
      { validators: DateFrameValidator }
    );
  }

  async submitData() {
    const data: AuBescheinigungDatenErfassenParameter = {
      mitarbeiter: (await this.userSessionService.loginDetails.get()).id,
      vonDatum: this.timeService.getDateFromRawDate(this.formGroup.value.vonDatum, true),
      bisDatum: this.timeService.getDateFromRawDate(this.formGroup.value.bisDatum, true),
      notiz: this.formGroup.value.notiz,
    };
    await AuBescheinigungBildErfassenComponent.ShowWith(this.bottomSheet, data);
  }
}
