import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatabaseService } from '@app/globalServices/database.service';
import { DialogService } from '@app/globalServices/dialog.service';
import { EmployeeLoginService } from '@app/globalServices/employee-login.service';
import { GlobalSettingService } from '@app/globalServices/settings/global-setting.service';
import { SyncService } from '@app/globalServices/sync.service';
import { UserSessionService } from '@app/globalServices/user-session-state.service';
import { ConnectionInfoCacheService } from '@app/globalServices/webservice-connection-services/connectioninfo-cache.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-einstellungen',
  templateUrl: './einstellungen.component.html',
  styleUrls: ['./einstellungen.component.scss'],
})
export class EinstellungenComponent implements OnInit, OnDestroy {
  programmStart = true;

  auftragSyncVorBuchung = false;
  personalSyncVorBuchung = false;
  fehlzeitSyncVorBuchung = false;
  syncTaetigkeitenAmount = 100;

  gruppenbuchungen = true;
  auftragStartDate: Date;
  barcodePrefixAuftrag = 'A;';
  barcodePrefixTaetigkeit = 'T;';
  barcodePrefixAbwesenheit = 'G;';

  kundenAnzeige = false;
  auftragsAnzeige = false;
  taetigkeitsAnzeige = false;

  constructor(
    private readonly globalSettingService: GlobalSettingService,
    private readonly syncService: SyncService,
    private readonly connectionInfoService: ConnectionInfoCacheService,
    private dialogService: DialogService,
    private databaseService: DatabaseService,
    private loginService: EmployeeLoginService,
    private userSession: UserSessionService
  ) {}

  private readonly subscriptions = new Subscription();

  readonly connectionInfo$ = this.connectionInfoService.connectionInfo$;

  private hiddenFeatureCounter: number = 0;

  async ngOnInit() {
    this.programmStart = await this.syncService.syncOnStart.get();
    this.auftragSyncVorBuchung = await this.syncService.syncAuftragVorBuchung.get();
    this.personalSyncVorBuchung = await this.syncService.syncPersonalVorBuchung.get();
    this.auftragStartDate = await this.syncService.auftragSyncLimitDate.get();

    this.syncTaetigkeitenAmount =
      (await this.globalSettingService.syncTaetigkeitenAmount.get()) == 'all'
        ? 600
        : Number(await this.globalSettingService.syncTaetigkeitenAmount.get());

    this.gruppenbuchungen = await this.globalSettingService.gruppenBuchen.get();
    this.barcodePrefixAuftrag = await this.globalSettingService.prefixAuftrag.get();
    this.barcodePrefixTaetigkeit = await this.globalSettingService.prefixTaetigkeit.get();
    this.barcodePrefixAbwesenheit = await this.globalSettingService.prefixAbwesenheit.get();

    this.kundenAnzeige = await this.globalSettingService.kundenAnzeige.get();
    this.auftragsAnzeige = await this.globalSettingService.auftragsAnzeige.get();
    this.taetigkeitsAnzeige = await this.globalSettingService.taetigkeitsAnzeige.get();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async onChangeProgrammStart() {
    this.programmStart = !this.programmStart;
    await this.syncService.syncOnStart.set(this.programmStart);
  }

  async onChangeAuftragSync() {
    this.auftragSyncVorBuchung = !this.auftragSyncVorBuchung;
    await this.syncService.syncAuftragVorBuchung.set(this.auftragSyncVorBuchung);
  }

  async onChangePersonalSync() {
    this.personalSyncVorBuchung = !this.personalSyncVorBuchung;
    await this.syncService.syncPersonalVorBuchung.set(this.personalSyncVorBuchung);
  }

  async onChangeFehlzeitSync() {
    this.fehlzeitSyncVorBuchung = !this.fehlzeitSyncVorBuchung;
    await this.syncService.syncFehlzeitVorBuchung.set(this.fehlzeitSyncVorBuchung);
  }

  async onChangeGruppenbuchungen() {
    this.gruppenbuchungen = !this.gruppenbuchungen;
    await this.globalSettingService.gruppenBuchen.set(this.gruppenbuchungen);
  }

  async onChangePrefix1() {
    await this.globalSettingService.prefixAuftrag.set(this.barcodePrefixAuftrag);
  }

  async onChangePrefix2() {
    await this.globalSettingService.prefixTaetigkeit.set(this.barcodePrefixTaetigkeit);
  }
  async onChangePrefix3() {
    await this.globalSettingService.prefixAbwesenheit.set(this.barcodePrefixAbwesenheit);
  }

  async onChangeSyncTaetigkeitenAmount(value) {
    if (value == 600) {
      this.globalSettingService.syncTaetigkeitenAmount.set('all');
    } else {
      this.globalSettingService.syncTaetigkeitenAmount.set(value.toString());
    }
  }

  async safeAuftragAbholDatum() {
    await this.globalSettingService.auftragsSyncAb.set(this.auftragStartDate);
    await this.syncService.auftragSyncLimitDate.set(this.auftragStartDate);
  }

  async onChangeKundenAnzeige() {
    this.kundenAnzeige = !this.kundenAnzeige;
    await this.globalSettingService.kundenAnzeige.set(this.kundenAnzeige);
  }

  async onChangeAuftragsAnzeige() {
    this.auftragsAnzeige = !this.auftragsAnzeige;
    await this.globalSettingService.auftragsAnzeige.set(this.auftragsAnzeige);
  }

  async onChangeTaetigkeitsAnzeige() {
    this.taetigkeitsAnzeige = !this.taetigkeitsAnzeige;
    await this.globalSettingService.taetigkeitsAnzeige.set(this.taetigkeitsAnzeige);
  }

  async hiddenFeature() {
    this.hiddenFeatureCounter++;
    if (this.hiddenFeatureCounter > 2) {
      const confirmation = await this.dialogService.QueryYesNo(
        'Achtung',
        'Mit dieser Funktion löschen Sie alle mobilen Daten. Nicht synchronisierte Daten gehen unwiederbringlich verloren. Löschen?'
      );
      if (confirmation === true) {
        await this.userSession.loggedIn.set(false);
        await this.databaseService.clearAllStores();
        await this.dialogService.ShowInformation('Bestätigung', 'Aktion durchgeführt.');
        await this.loginService.logout(true);
        window.location.reload();
      }
      if (confirmation === false) {
        this.hiddenFeatureCounter = 0;
      }
    }
  }
}
