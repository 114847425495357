import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss'],
})
export class StatusSelectorComponent implements OnInit {
  @Input() options = [];
  @Input() label = '';
  @Output() selectionChange = new EventEmitter<string>();
  defaultOption: string = '';

  constructor() {}

  ngOnInit() {
    this.defaultOption = this.options?.length > 0 ? this.options[0] : '';
  }
}
