import { Injectable } from '@angular/core';
import { ZEFBackendService } from '@app/globalServices/webservice-connection-services/zef-backend.service';
import { TKennzeichen, TKennzeichenBooking } from '@entities/TKennzeichen';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TKennzeichenService {
  constructor(private readonly backendService: ZEFBackendService) {}

  getTKennzeichen$: Observable<TKennzeichen[]> = this.backendService.post<TKennzeichen[]>('GetTKennzeichen');

  async getTKennzeichenBookingForPnr(pnr: string, month: number, year: number): Promise<TKennzeichenBooking[]> {
    const tkennzeichenBooking = await this.backendService
      .post<any>(`GetTKennzeichenBookingForPnr/PNR/${pnr}/MONTH/${month}/YEAR/${year}`)
      .toPromise();
    return tkennzeichenBooking;
  }

  async setTKennzeichenBooking(tkennzeichenBooking: TKennzeichenBooking): Promise<number> {
    const response = await this.backendService.post<any>('SetTKennzeichenBooking', tkennzeichenBooking).toPromise();
    return response;
  }

  async updateTKennzeichenBooking(tkennzeichenBooking: TKennzeichenBooking): Promise<boolean> {
    const response = await this.backendService.post<any>('UpdateTKennzeichenBooking', tkennzeichenBooking).toPromise();
    return response;
  }

  async deleteTKennzeichenBooking(tkennzeichenBooking: TKennzeichenBooking): Promise<boolean> {
    const response = await this.backendService
      .post<any>('DeleteTKennzeichenBooking', tkennzeichenBooking.Nummer)
      .pipe()
      .toPromise();
    return response;
  }

  async getTKennzeichenFromKennzeichen(kennzeichen: string): Promise<TKennzeichen> {
    return await this.getTKennzeichen$
      .pipe(map(tkennzeichen => tkennzeichen.find(tk => tk.Kennzeichen == kennzeichen)))
      .toPromise();
  }
}
