import { Component, Input } from '@angular/core';
import { ModalAction } from '@app/utils/types';
import { Observable } from 'rxjs';
import { ButtonStyle, ButtonStyleMapping } from '../bottom-sheet-button/ButtonStyle';
import { TitleStyle } from './TitleStyle';

export interface BottomSheetStyleProvider {
  // titleStyle: ...
  buttonStyleMapping: ButtonStyleMapping;
}

@Component({
  selector: 'app-bottom-sheet-frame',
  templateUrl: './bottom-sheet-frame.component.html',
  styleUrls: ['./bottom-sheet-frame.component.scss'],
})
export class BottomSheetFrameComponent {
  @Input() Title: string;
  @Input() Actions: ModalAction[];
  @Input() StyleProvider$: Observable<BottomSheetStyleProvider>;

  constructor() {}

  public DefaultTitle: TitleStyle = {
    name: 'Titel',
    color: 'black',
    fontweight: 'normal',
  };
}
