<button class="buttonContainer" mat-button (click)="previous()">
  <img class="arrow" src="assets/icons/ArrowLeft.png" />
</button>
<mat-form-field class="datePicker">
  <input
    class="dateInput"
    matInput
    [matDatepicker]="picker1"
    [(ngModel)]="pickedDate"
    (dateInput)="select($event.value)"
  />
  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
  <mat-datepicker #picker1></mat-datepicker>
</mat-form-field>
<button class="buttonContainer" mat-button (click)="next()">
  <img class="arrow" src="assets/icons/ArrowRight.png" />
</button>
