import { Fehlzeit } from './db-types/Fehlzeiten';
import { Antrag } from './db-types/Antrag';
import { Auftrag } from './db-types/Auftrag';
import { Auswertung } from './db-types/Auswertung';
import { BookingTime } from './db-types/BookingTime';
import { Setting } from './db-types/Setting';
import { Kunden } from './db-types/Customer';
import { Employee } from './db-types/Employee';
import { Salden } from './db-types/Salden';
import { BookingTimeFrame } from './db-types/BookingTimeFrame';
import { Taetigkeit } from './db-types/Taetigkeit';
import { AUBescheinigung } from '@entities/AUBescheinigung';
import { IDBPDatabase, IDBPIndex, IDBPObjectStore, openDB } from 'idb';

type AccessMethod = 'readwrite' | 'readonly';
class DBStore {
  Database: IDBPDatabase<unknown>;
  Store: IDBPObjectStore<unknown, [string], string, AccessMethod>;
}

export class Database {
  dbVersion = 3;
  dbName = 'tkzef';

  constructor() {}

  /**
   * @description: Funktion um die Version der Datenbank zu ändern. In der Methode 'InitStores' wird das Versioning gehandelt.
   * @returns Promise vom Typ void
   */
  public async setDbVersion(): Promise<void> {
    await this.initStores(this.dbVersion);
  }

  /**@description StoreZugriff zentralisiert */
  public async accessStore(storeName: string, accessMethod: AccessMethod): Promise<DBStore> {
    let db: IDBPDatabase<unknown>;
    try {
      await this.initStores(this.dbVersion).then(async () => {
        db = await openDB(this.dbName, this.dbVersion);
      });
      const tx = db.transaction(storeName, accessMethod);
      const store = tx.objectStore(storeName);
      return { Database: db, Store: store };
    } catch (error) {
      throw new Error('Error accessing Store: ' + storeName + ' with accessMethod: ' + accessMethod);
    }
  }

  /**
   *
   * @description Methode um in bestimmte Tabelle in der Datenbank zu schreiben
   * @param <storeName: string, list: object[]>
   * @returns void
   */
  public async insert(storeName: string, list: unknown[]): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    for (const element of list) await dbStore.Store.put(element);
    dbStore.Database.close();
  }

  public async getAll<Type>(storeName: string, keyValue?: string, index?: string): Promise<Type> {
    const dbStore = await this.accessStore(storeName, 'readonly');
    const accessor = index ? this.getIndex(dbStore, index) : dbStore.Store;
    try {
      const data = await accessor.getAll(keyValue);
      dbStore.Database.close();
      return data as Type;
    } catch (error) {
      const keyValueText = keyValue ? ' with keyValue: ' + keyValue : '';
      const indexText = index ? ' with index: ' + index : '';
      throw new Error(
        'getAll in dbStore: ' + dbStore?.Store?.name + indexText + ' ' + keyValueText + ' resulted in an error'
      );
    }
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank alle Einträge auszulesen
   * @param <storeName: string>
   * @returns Promise<object[]>
   */
  public async getAllData<Type>(storeName: string): Promise<Type> {
    return await this.getAll<Type>(storeName);
  }

  public getIndex(dbStore: DBStore, index: string): IDBPIndex<unknown, [string], string, string, AccessMethod> {
    try {
      return dbStore.Store.index(index);
    } catch (error) {
      let errorMessage = `Index: ${index} of store: ${dbStore.Store.name} not found\n`;
      errorMessage += 'The Store needs the selector to be a IndexField instead of a DataField';
      throw new Error(errorMessage);
    }
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank bestimme Einträge auszulesen
   * @param <storeName: string, index: string, keyValue: string>
   * @returns Promise<object[]>
   */
  public async getAllSpecificData<Type>(storeName: string, index: string, keyValue: string): Promise<Type> {
    return await this.getAll<Type>(storeName, keyValue, index);
  }

  /**
   * @description      Funktion, um aus bestimmter Tabelle in der Datenbank unter Angabe eines Schlüssels alle Einträge auszulesen
   * @param key:       string (Schlüssel für die Abfrage in der Datenbank)
   * @returns          Promise<object[]>
   */
  public async getAllSpecificDataWithKey(storeName: string, keyValue: string): Promise<object[]> {
    const dbStore = await this.accessStore(storeName, 'readonly');
    const data = (await dbStore.Store.getAll(keyValue)) as object[];
    dbStore.Database.close();
    return data;
  }

  /**
   *
   * @description Methode um aus bestimmter Tabelle in der Datenbank bestimme Einträge zu löschen
   * @param <storeName: string, index: string, keyValue: string>
   * @returns Promise<object[]>
   */
  public async deleteSpecificData(storeName: string, index: string, keyValue: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    const cursor = await dbStore.Store.openCursor();
    while (cursor) {
      try {
        if (cursor.value[index] === keyValue) {
          await cursor.delete();
        }
        await cursor.continue();
      } catch {
        return;
      }
    }
    dbStore.Database.close();
  }

  public async deleteSpecificData2(
    storeName: string,
    index: string,
    keyValue: string,
    index2: string,
    keyValue2: string
  ): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    const cursor = await dbStore.Store.openCursor();
    while (cursor) {
      try {
        if (cursor.value[index] === keyValue && cursor.value[index2] === keyValue2) {
          await cursor.delete();
        }
        await cursor.continue();
      } catch {
        return;
      }
    }
    dbStore.Database.close();
  }

  public async deleteData(storeName: string, keyValue: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    await dbStore.Store.delete(keyValue);
    dbStore.Database.close();
  }

  public async clearStore(storeName: string): Promise<void> {
    const dbStore = await this.accessStore(storeName, 'readwrite');
    await dbStore.Store.clear();
    dbStore.Database.close();
  }

  public async initStores(dbVersion: number): Promise<void> {
    const db = await openDB(this.dbName, dbVersion, {
      upgrade(upgradeDB, oldVersion, newVersion, transaction) {
        console.log('upgradeDB');
        Antrag.InitStore(upgradeDB);
        AUBescheinigung.InitStore(upgradeDB);
        Auftrag.InitStore(upgradeDB);
        Auswertung.InitStore(upgradeDB);
        BookingTime.InitStore(upgradeDB);
        BookingTimeFrame.InitStore(upgradeDB);
        Employee.InitStore(upgradeDB);
        Fehlzeit.InitStore(upgradeDB);
        Kunden.InitStore(upgradeDB);
        Salden.InitStore(upgradeDB);
        Setting.InitStore(upgradeDB);
        Taetigkeit.InitStore(upgradeDB);
      },
      blocked() {},
      blocking() {},
      terminated() {},
    });
    db.close();
  }

  public async rebuildDB(dbVersion: number): Promise<void> {
    this.deleteOldIDB();
    await this.initStores(dbVersion);
  }

  public deleteOldIDB(): void {
    const request = indexedDB.deleteDatabase(this.dbName);
    request.onsuccess = function (): void {};
    request.onerror = function (): void {};
    request.onblocked = function (): void {};
  }

  storeNames = [
    'AUBescheinigung',
    'Auftrag',
    'Auswertung',
    'BookingTime',
    'Stempelung',
    'Mitarbeiter',
    'Fehlzeit',
    'Kunden',
    'Salden',
    'Taetigkeit',
  ];

  async clearAllStores(): Promise<void> {
    for (const storeName of this.storeNames) {
      await this.clearStore(storeName);
    }
  }
}
