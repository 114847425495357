import { Component, Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@app/globalServices/dialog.service';
import { TermConverterService } from '@app/globalServices/termConverter.service';
import { ModalActionType, ModalQueryResult, Validator } from '@app/utils/types';
import { isNullOrUndefined, qrImageToString, readFileAsync } from '@app/utils/utils';
import { BaseDialogComponent } from '@base-components/BaseDialogComponent';

export interface QrReaderParameters {
  validator: Validator<string>;
}

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
@Injectable()
export class QrcodeComponent extends BaseDialogComponent<QrReaderParameters, string> {
  public static async ScanQRCode(dialog: MatDialog, validator: Validator<string>): Promise<ModalQueryResult<string>> {
    const ref = dialog.open<QrcodeComponent, QrReaderParameters, ModalQueryResult<string>>(QrcodeComponent, {
      data: { validator },
    });

    let result = await ref.afterClosed().toPromise();
    while (result.Type === ModalActionType.RECALL) result = await result.Recall();
    return result;
  }

  constructor(
    protected readonly injector: Injector,
    private readonly dialogService: DialogService,
    private readonly termConverterService: TermConverterService
  ) {
    super(injector);
  }

  pictureMode = this.termConverterService.getIosStandAloneOrQs(true);
  isIOS = this.termConverterService.isUserAgentIOS();

  scanning = !this.isIOS; //Nur auf iOS erst deaktivieren
  desiredDevice: MediaDeviceInfo = null;
  nthDevice = 0;
  possibleDevices: MediaDeviceInfo[] = [];
  cameraSwitchUrl = 'assets/icons/camera_switch.svg';

  /**@description Funktion wenn ein Foto statt eines Video-Streams zum Auswerten eines QRCodes verwendet wird */
  async processQrImage(event): Promise<void> {
    const file = event?.target?.files[0];
    if (isNullOrUndefined(file)) return;

    let fileContent: any;
    let qrContent: string;
    try {
      fileContent = await readFileAsync(file);
    } catch (error) {
      console.error(error);
      await this.dialogService.ShowInformation('Dateifehler', 'Die angegebene Datei konnte nicht gelesen werden. ');
      return;
    }
    try {
      qrContent = await qrImageToString(fileContent);
      await this.handleQrContent(qrContent);
    } catch (error) {
      console.error(error);
      await this.dialogService.ShowInformation(
        'Formatfehler',
        'Der Inhalt der Datei konnte nicht richtig verarbeitet werden. '
      );
    }
  }

  /**
   * @description Togglefunktion für den Button zum Scannen.
   * @param close Closes the dialog aswell(automatic on succes, no need here)
   */
  stopScanning(close = true): void {
    this.scanning = false;
    if (close) this.Close({ Type: ModalActionType.CANCEL });
  }

  /**
   * @description Funktion für einen erfolgreichen Scan. Hier wird geprüft, ob mit den
   *              gescannten Daten eine Allokation geholt werden kann. Zudem wird hier
   *              geprüft ob mit den gescannten Daten ein Login möglich ist.
   *              Ist dies der Fall, werden die gescannten Daten in globalen Properties
   *              hinterlegt und es wird in den nächsten View weitergeleitet.
   * @param       qrContent: event
   */
  async handleQrContent(qrContent: string): Promise<void> {
    try {
      const validation = await this.Parameter.validator(qrContent);
      if (validation === true) {
        this.Close({ Type: ModalActionType.OK, Data: qrContent });
        this.stopScanning(false);
        return;
      } else await this.dialogService.ShowValidationResult(validation);
    } catch (error) {
      await this.dialogService.ShowInformation(
        'Fehler bei der Verarbeitung',
        `Der QR-Code konnte nicht erkannt werden. Der QR-Code sollte sich in der Mitte des Bildes befinden und die Hälfte des Bildes einnehmen. `
      );
    }
  }

  //#region Camera-Selection

  /**
   * @description   Funktion um Kamera auf der Rückseite des Gerätes auszuwählen und alle möglichen Kameras in einem Array zu speichern.
   * @param deviceInfos:  event
   */
  camerasFoundHandler(deviceInfos: MediaDeviceInfo[]): void {
    let backFound = false;
    for (let i = 0; i < deviceInfos.length; i++) {
      if (this.isIOS) {
        if (deviceInfos[i].label === 'Rückkamera' || deviceInfos[i].label === 'Back Camera') {
          this.desiredDevice = deviceInfos[i];
          this.nthDevice = i;
          backFound = true;
        }
      } else if (deviceInfos[i].label.includes('back')) {
        this.desiredDevice = deviceInfos[i];
        this.nthDevice = i;
        backFound = true;
      }
      this.possibleDevices.push(deviceInfos[i]);
    }
    if (!backFound) {
      this.desiredDevice = deviceInfos.find(_ => true) || null;
    }
    this.scanning = true;
  }

  /**
   * @description Wird aufgerufen, wenn der Nutzer den Kamera-Wechsel im Scan betätigt - versucht durch die möglichen Geärte zu iterieren
   * Aktuell ist die Kamera an der Stelle nthDevice, deswegen nthDevice erhöhen, gucken ob es noch Device ist, ansonsten nthDevice auf 0 setzen
   * und Iterierung wieder bei 0 beginnen
   */
  switchBetweenDevices(): void {
    this.nthDevice++;
    const device = this.possibleDevices[this.nthDevice];
    if (device) {
      this.desiredDevice = device || null;
    } else {
      this.nthDevice = 0;
      this.desiredDevice = this.possibleDevices[this.nthDevice] || null;
    }
  }

  //#endregion
}
