import { Injectable } from '@angular/core';
import { DomainError } from '@app/utils/ErrorHandling/DomainError';
import { HttpConnectionError } from '@app/utils/ErrorHandling/Error';
import { combineLatest, MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, retryWhen, switchMap } from 'rxjs/operators';
import { retryStrategy } from '../../utils/rxjsUtils';
import { UserSessionService } from '../user-session-state.service';
import { AllocationManagerService } from './allocation-manager.service';
import { BackendAuthenticationService } from './backend-authentication.service';
import { ConnectionInfoCacheService } from './connectioninfo-cache.service';
import { HttpClientWrapper, HttpRequest } from './httpclient-wrapper.service';

interface IConnectionHeaders {
  GeraeteID: string;
  Mandant: string;
  Application: string;
  EmployeeID: string;
}

@Injectable({ providedIn: 'root' })
export class ZEFBackendService {
  constructor(
    private readonly connectionInfoService: ConnectionInfoCacheService,
    private readonly allocManager: AllocationManagerService,
    private readonly httpClient: HttpClientWrapper,
    private readonly authService: BackendAuthenticationService,
    private readonly userSessionService: UserSessionService
  ) {}

  readonly connectionHeaders = combineLatest([
    this.userSessionService.pnr$,
    this.userSessionService.deviceID$,
    this.userSessionService.mandant.value$,
  ]).pipe(
    map<[string, any, string], IConnectionHeaders>(([pnr, deviceID, mandant]) => {
      const result: IConnectionHeaders = {
        GeraeteID: deviceID,
        Mandant: mandant,
        EmployeeID: pnr,
        Application: 'TKZPWA',
      };
      return result;
    })
  );

  /**@description Send and Compare Version and DNSGUID with backend */
  ping(): Observable<boolean> {
    return this.preparePingRequest('ServicePing').pipe(
      switchMap(request => this.connectionInfoService.applyConnection(request)),
      switchMap(([request]) => this.completeHeaders(request)),
      switchMap(request => this.httpClient.Get<any>(request)),
      this.generalRetry(),
      this.retryAfterAllocationRefresh()
    );
  }

  get<TResult>(urlExtension: string, headers?: [string, string][]): Observable<TResult> {
    return this.request<TResult>('GET', urlExtension, undefined, headers);
  }

  post<TResult>(urlExtension: string, body?, headers?: [string, string][]): Observable<TResult> {
    return this.request<TResult>('POST', urlExtension, body ? JSON.stringify(body) : null, headers);
  }

  private request<TResult>(
    method: 'GET' | 'POST',
    urlExtension: string,
    body?,
    headers?: [string, string][]
  ): Observable<TResult> {
    return this.prepareRequest(method, urlExtension, body, headers).pipe(
      switchMap(request => this.connectionInfoService.applyConnection(request)),
      switchMap(([request, connectionInfo]) =>
        this.authService.authenticateRequest(request, connectionInfo, urlExtension)
      ),
      switchMap(request => this.completeHeaders(request)),
      switchMap(request => this.httpClient.Get<TResult>(request)),
      this.handleResponse(),
      this.generalRetry(),
      this.retryAfterAllocationRefresh()
    );
  }

  /** @description Handles Response and maps it to a fitting DomainError in case of failure */
  private handleResponse<TResult>(): MonoTypeOperatorFunction<TResult> {
    return catchError(err => {
      if (!(err instanceof HttpConnectionError)) {
        return throwError(err);
      }
      const httpErr = err as HttpConnectionError;
      if ([400, 403].includes(httpErr.ErrorCode) && httpErr.body && httpErr.body.StatusCode) {
        const domainErr = new DomainError(
          httpErr.body.StatusCode,
          httpErr.body.DetailCode,
          httpErr.body.Message + (httpErr.body.HasDebugMessage ? '\n' + httpErr.body.DebugMessage : '')
        );
        return throwError(domainErr);
      }
      return throwError(err);
    });
  }

  login(pnr: string, pw: string): Observable<any> {
    const endpoint = 'TryLogin';
    return this.prepareRequest('GET', endpoint).pipe(
      switchMap(request => this.connectionInfoService.applyConnection(request)),
      switchMap(([request, connectionInfo]) =>
        this.authService.authenticateLoginRequest(request, connectionInfo, endpoint, pnr, pw)
      ),
      switchMap(request => this.completeHeaders(request)),
      switchMap(request => this.httpClient.Get<any>(request)),

      this.handleResponse(),
      this.generalRetry(),
      this.retryAfterAllocationRefresh()
    );
  }

  private generalRetry<T>(): MonoTypeOperatorFunction<T> {
    return retryWhen<T>(
      retryStrategy({
        maxRetryAttempts: 2,
        duration: 800,
        retryCondition: () => navigator.onLine,
        errorCondition: error => {
          const result = !(error instanceof DomainError);
          return result;
        },
      })
    );
  }

  private retryAfterAllocationRefresh<T>(): MonoTypeOperatorFunction<T> {
    return retryWhen<T>(
      retryStrategy({
        maxRetryAttempts: 1,
        duration: 0,
        retryCondition: () => this.retryCondition(),
        errorCondition: error => {
          return !(error instanceof DomainError);
        },
      })
    );
  }

  /**@description Bedingung für retry ist das Vorhandensein einer AllocationGuid und ein erfolgreicher Alloc-Refresh*/
  private retryCondition(): Observable<boolean> {
    return this.allocManager.allocationGuid.value$.pipe(
      first(),
      switchMap(guid => (navigator.onLine && guid ? this.connectionInfoService.refreshAllocation() : of(false)))
    );
  }

  /** @description Baut Basis-Request
   * @param urlExtension Url-Erweiterung für Endpunkt, wird um API-URL ergänzt
   */
  private prepareRequest(
    method: 'GET' | 'POST',
    urlExtension: string,
    body?,
    headers?: [string, string][]
  ): Observable<HttpRequest> {
    return this.connectionHeaders.pipe(
      first(),
      map(connectionHeaders => {
        const requestHeaders = {};
        if (headers) {
          headers.forEach(tuple => (requestHeaders[tuple[0]] = tuple[1]));
        }

        const reqInit: RequestInit = {
          method,
          mode: 'cors',
          headers: {
            Application: 'TKZPWA',
            Version: '1.4.0',
            'content-type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            ...connectionHeaders,
            ...requestHeaders,
          },
          body: body,
        };
        return { url: urlExtension, reqInit };
      })
    );
  }

  private preparePingRequest(urlExtension: string): Observable<HttpRequest> {
    const reqInit: RequestInit = {
      method: 'GET',
      mode: 'cors',
      headers: { 'content-type': 'application/json; charset=utf-8' },
    };
    return of({ url: urlExtension, reqInit });
  }

  /** @description Prüft und setzt notwendige Request-Header */
  private completeHeaders(request: HttpRequest): Observable<HttpRequest> {
    const headernames = Object.keys(request.reqInit.headers).join(', ');

    const accessControlHeader = {
      'Access-Control-Allow-Headers':
        headernames +
        ', Access-Control-Allow-Headers, Access-Control-Allow-Origin, Authorization, X-Requested-With, Referer, Sec-Fetch-Dest, User-Agent',
      'Access-Control-Allow-Origin': '*',
    };

    const headers = { ...request.reqInit.headers, ...accessControlHeader };
    const reqInit = { ...request.reqInit, headers };

    return of({ url: request.url, reqInit });
  }
}
