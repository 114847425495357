import { Component, OnInit, SkipSelf } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TeamSelectorStateService } from './team-selector.state.service';

@Component({
  selector: 'app-team-selector-tabs',
  templateUrl: './team-selector-tabs.component.html',
  styleUrls: ['./team-selector-tabs.component.scss'],
})
export class TeamSelectorTabsComponent implements OnInit {
  constructor(@SkipSelf() private readonly stateService: TeamSelectorStateService) {}

  ngOnInit(): void {
    this.stateService.select(0);
  }

  readonly employees$ = this.stateService.state$;

  selectionChanged(event: MatTabChangeEvent) {
    this.stateService.select(event.index);

    const fullElement = document.getElementById('team-select');
    const header = fullElement.getElementsByClassName('mdc-tab mat-mdc-tab');
    const correctHeader = header[event.index];
    correctHeader.scrollIntoView();
  }
}
